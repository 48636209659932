<template>
    <div class="modal fade" id="AppModalCode" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="false">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Código de función</h5>
                    <button   type="button" class="close" @click="closeModal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="height: 600px">
                    <BlocklyComponentParte id="blockly1" ref="blockly1" :options="options">
                        
                    </BlocklyComponentParte>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import BlocklyComponentParte from "../components/BlocklyComponentParte";
import "../views/prompt";
export default {
    name: 'AppModalCode',
    components: {
        BlocklyComponentParte
    },
    data() {
        return {
            options: {
                scrollbars: true,
            },
        }
    },
    methods: {
      closeModal() {
        let index = 0
        const blocks = this.$refs["blockly1"].workspace.getAllBlocks()
        blocks.forEach(element => {
            if(element.type == 'repeats') {
                if(index == 0) {
                window.indexForTc = element.inputList[0].fieldRow[1].value_
                window.indexForAs = element.inputList[0].fieldRow[1].value_;
                window.indexForDs = element.inputList[0].fieldRow[1].value_;
                }
                if(index == 1) {
                    window.indexForAs = element.inputList[0].fieldRow[1].value_;
                }
                index++
            }
            if(element.type == 'girar') {
                if(element.id == 'girar1')
                    window.TrazarCuadradoGiro_0 = element.inputList[0].fieldRow[1].value_;
                if(element.id == 'girar2')
                    window.TrazarCuadradoGiro_1 = element.inputList[0].fieldRow[1].value_;
                if(element.id == 'girar3')
                    window.TrazarCuadradoGiro_2 = element.inputList[0].fieldRow[1].value_;
            }
        });
        $("#AppModalCode").modal('hide')
      }
    },
}
</script>