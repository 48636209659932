<template>
  <div>
    <app-header></app-header>
    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>
      <!--  BEGIN SIDEBAR  -->
      <app-sidebar></app-sidebar>
     
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-12 layout-spacing">
              <div class="widget-content-area">
                <div class="widget-content-area">
                  <div class="widget-one">
                    <div class="row mt-n4">
                      <div class="col-md-11 col-sm-10">
                        <div id="texto" :key="instructions">{{ instructions }}</div>
                        <button class="btn btn-primary btn-sm btn-rounded mr-1" @click="evaluate" id="evaluate"> {{ $t('text.buttonEvaluate') }} <i class="fa fa-send"></i></button>
                        <button id="runcode" class="btn btn-success btn-sm btn-rounded" @click="runCode"> {{ $t('text.buttonTest') }} <i class="fa fa-play"></i></button>
                        <button class="btn btn-warning mr-2 rounded-circle bs-tooltip float-right" data-placement="right" title=""
                          data-original-title="Pista" @click="showModalHelp"><i class="fa fa-lightbulb-o"></i></button>
                        <button class="btn btn-warning mr-2 rounded-circle bs-tooltip float-right" data-placement="right" title="" data-original-title="Funciones" @click="showModalCode" v-if="$route.query.multiple > 1 && $route.query.multiple != 16 && $route.query.multiple != 17"><i class="fa fa-code"></i></button>
                        <button class="btn btn-warning mr-2 rounded-circle bs-tooltip float-right" data-placement="right" title="" data-original-title="Funciones" @click="showVideo(9)" v-if="withVideo == 1"><i class="fa fa-video-camera"></i></button>
                        <button class="btn btn-warning mr-2 rounded-circle bs-tooltip float-right" data-placement="right" title="" data-original-title="Funciones" @click="showVideo(18)" v-if="$route.query.activityType == 3 && $route.query.idQuestion != 26334 && $route.query.idQuestion != 26333  && $route.query.idQuestion != 26306 && $route.query.idQuestion != 26351"><i class="fa fa-music"></i></button>
                      </div>
                      <div class="col-md-1 col-sm-1">
                        <button id="runcodeDos" class="btn btn-info rounded-circle" @click.prevent="playSound(instructions_audio)"><i class="fa fa-volume-up"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="widget-one" style="height: 400px">
                  <BlocklyComponent id="blockly1" ref="blockly1" :options="options" v-if="arrayBlocks.length > 0" :key="activity_id">
                    <block :type="block.type" :class="block.class" v-for="(block, index) in arrayBlocks" :key="index"></block>
                   
                  </BlocklyComponent>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12 layout-spacing">
              <div class="widget-content-area br-4 mt-4">
                <div class="widget-one scenario">
                  <div id="tire"></div>
                  <div id="gorilla"></div>
                  <div id="character"></div>
                  <div id="character3"></div>
                  <div id="gem"></div>
                  <div id="gem2"></div>
                  <div id="gem3"></div>
                  <div id="gem4"></div>
                  <div id="gem5"></div>
                  <div id="gem6"></div>
                  <div id="gem7"></div>
                  <div id="gem8"></div>
                  <div id="gem9"></div>
                  <div id="gem10"></div>
                  <div id="gem11"></div>
                  <div id="gem12"></div>
                  <div id="gem13"></div>
                  <div id="gem14"></div>
                  <div id="gem15"></div>
                  <div id="gem16"></div>
                  <div id="gem17"></div>
                  <div id="gem18"></div>
                  <div id="gem19"></div>
                  <div id="palette"></div>
                  <div id="icecream"></div>
                  <div id="gift"></div>
                  <div id="gift2"></div>
                  <div id="gift3"></div>
                  <div id="sweet"></div>
                  <div id="tail"></div>
                  <div id="eyes"></div>
                  <div id="panda"></div>
                  <div id="duck1"></div>
                  <div id="duck2"></div>
                  <div id="delfin"></div>
                  <div id="oso"></div>
                  <div id="rusa"></div>
                  <div id="targetShooting"></div>
                  <div id="waterJet"></div>
                  <div id="Payaso1"></div>
                  <div id="swing"></div>
                  <div id="semilla-01"></div>
                  <div id="circle-dark"></div>
                  <div id="circle-dark1"></div>
                  <div id="catarina"></div>
                  <div id="hada2"></div>
                  <div id="hada3"></div>
                  <div id="LamparaCorazon"></div>
                  <div id="z"></div>
                  <div id="hada1"></div>
                  <div id="luzventana"></div>
                  <div id="bellota1"></div>
                  <div id="bellota2"></div>
                  <div id="bellota3"></div>
                  <div id="bellota4"></div>
                  <div id="bellota5"></div>
                  <div id="Rana"></div>
                  <div id="Principe"></div>
                  <div id="hojas"></div>
                  <div id="hojas2"></div>
                  <div id="gnomo1"></div>  
                  <div id="gnomo2"></div> 
                  <div id="canngreyconcha"></div> 
                  <div id="tiburon"></div> 
                  <div id="notas"></div> 
                  <div id="cangrejo"></div> 
                  <div id="gaviota"></div> 
                  <div id="ola1"></div>
                  <div id="barrilenagua"></div>
                  <div id="shine"></div>
                  <div id="arana1"></div>
                  <div id="arana2"></div>
                  <div id="tesoropirata"></div>
                  <div id="mancha"></div>
                  <div id="mancha1"></div>
                  <div id="mancha2"></div>
                  <div id="mancha3"></div>
                  <div id="mancha4"></div>
                  <div id="mancha5"></div>
                  <div id="mancha6"></div>
                  <div id="mancha7"></div>
                  <div id="mancha8"></div>
                  <div id="mancha9"></div>
                  <div id="mancha10"></div>
                  <div id="mancha11"></div>
                  <div id="character2"></div>
                  <div id="tentacle"></div>
                  <div id="Spike"></div>
                  <div id="ShakeBush"></div>
                  <div id="Planta-Carnivora2"></div>
                  <div id="Planta-Carnivora1"></div>
                  <div id="Mushroom"></div>
                  <div id="MudMonster"></div>
                  <div id="Monstruo2"></div>
                  <div id="Monstruo1"></div>
                  <div id="eyeTree"></div>
                  <div id="Craneo"></div>
                  <div id="hoyo"></div>
                  <div id="hoyo2"></div>
                  <div id="hoyo3"></div>
                  <div id="nieve"></div>
                  <div id="nieve2"></div>
                  <div id="nieve3"></div>
                  <div id="Esquiador"></div>
                  <div id="IceMan"></div>
                  <div id="FrozenFish"></div>
                  <div id="YetiJump"></div>
                  <div id="YetiWalk"></div>
                  <div id="Termo"></div>
                  <div id="Grieta"></div>
                  <div id="IceCream"></div>
                  <div id="meta"></div>
                  <div id="salida"></div>
                  <div id="alegre1"></div>
                  <div id="alegre2"></div>
                  <div id="alegre3"></div>
                  <div id="alegre4"></div>
                  <div id="alegre5"></div>
                  <div id="alegre6"></div>
                  <div id="alegre7"></div>
                  <div id="alegre8"></div>
                  <div id="alegre9"></div>
                  <div id="alegre10"></div>
                  <div id="alegre11"></div>
                  <div id="alegre12"></div>
                  <div id="alegre13"></div>
                  <div id="alegre14"></div>
                  <div id="alegre15"></div>
                  <div id="alegre16"></div>
                  <div id="alegre17"></div>
                  <div id="alegre18"></div>
                  <div id="alegre19"></div>
                  <div id="alegre20"></div>
                  <div id="angustiado1"></div>
                  <div id="angustiado2"></div>
                  <div id="angustiado3"></div>
                  <div id="angustiado4"></div>
                  <div id="angustiado5"></div>
                  <div id="angustiado6"></div>
                  <div id="angustiado7"></div>
                  <div id="angustiado8"></div>
                  <div id="angustiado9"></div>
                  <div id="angustiado10"></div>
                  <div id="angustiado11"></div>
                  <div id="angustiado12"></div>
                  <div id="angustiado13"></div>
                  <div id="angustiado14"></div>
                  <div id="angustiado15"></div>
                  <div id="angustiado16"></div>
                  <div id="angustiado17"></div>
                  <div id="angustiado18"></div>
                  <div id="angustiado19"></div>
                  <div id="angustiado20"></div>
                  <div id="enojado1"></div>
                  <div id="enojado2"></div>
                  <div id="enojado3"></div>
                  <div id="enojado4"></div>
                  <div id="enojado5"></div>
                  <div id="enojado6"></div>
                  <div id="enojado7"></div>
                  <div id="enojado8"></div>
                  <div id="enojado9"></div>
                  <div id="enojado10"></div>
                  <div id="enojado11"></div>
                  <div id="enojado12"></div>
                  <div id="enojado13"></div>
                  <div id="enojado14"></div>
                  <div id="enojado15"></div>
                  <div id="enojado16"></div>
                  <div id="enojado17"></div>
                  <div id="enojado18"></div>
                  <div id="enojado19"></div>
                  <div id="enojado20"></div>
                  <div id="feliz1"></div>
                  <div id="feliz2"></div>
                  <div id="feliz3"></div>
                  <div id="feliz4"></div>
                  <div id="feliz5"></div>
                  <div id="feliz6"></div>
                  <div id="feliz7"></div>
                  <div id="feliz8"></div>
                  <div id="feliz9"></div>
                  <div id="feliz10"></div>
                  <div id="feliz11"></div>
                  <div id="feliz12"></div>
                  <div id="feliz13"></div>
                  <div id="feliz14"></div>
                  <div id="feliz15"></div>
                  <div id="feliz16"></div>
                  <div id="feliz17"></div>
                  <div id="feliz18"></div>
                  <div id="feliz19"></div>
                  <div id="feliz20"></div>
                  <div id="sorprendido1"></div>
                  <div id="sorprendido2"></div>
                  <div id="sorprendido3"></div>
                  <div id="sorprendido4"></div>
                  <div id="sorprendido5"></div>
                  <div id="sorprendido6"></div>
                  <div id="sorprendido7"></div>
                  <div id="sorprendido8"></div>
                  <div id="sorprendido9"></div>
                  <div id="sorprendido10"></div>
                  <div id="sorprendido11"></div>
                  <div id="sorprendido12"></div>
                  <div id="sorprendido13"></div>
                  <div id="sorprendido14"></div>
                  <div id="sorprendido15"></div>
                  <div id="sorprendido16"></div>
                  <div id="sorprendido17"></div>
                  <div id="sorprendido18"></div>
                  <div id="sorprendido19"></div>
                  <div id="sorprendido20"></div>
                  <div id="triste1"></div>
                  <div id="triste2"></div>
                  <div id="triste3"></div>
                  <div id="triste4"></div>
                  <div id="triste5"></div>
                  <div id="triste6"></div>
                  <div id="triste7"></div>
                  <div id="triste8"></div>
                  <div id="triste9"></div>
                  <div id="triste10"></div>
                  <div id="triste11"></div>
                  <div id="triste12"></div>
                  <div id="triste13"></div>
                  <div id="triste14"></div>
                  <div id="triste15"></div>
                  <div id="triste16"></div>
                  <div id="triste17"></div>
                  <div id="triste18"></div>
                  <div id="triste19"></div>
                  <div id="triste20"></div>
                  <div id="vocal_a"></div>
                  <div id="vocal_e"></div>
                  <div id="vocal_i"></div>
                  <div id="vocal_o"></div>
                  <div id="vocal_u"></div>
                  <div id="abeja"></div>
                  <div id="aguila"></div>
                  <div id="alce"></div>
                  <div id="arana"></div>
                  <div id="ave"></div>
                  <div id="caballo"></div>
                  <div id="cocodrilo"></div>
                  <div id="leon"></div>
                  <div id="rana"></div>
                  <div id="elefante"></div>
                  <div id="do_active"></div>
                  <div id="re_active"></div>
                  <div id="mi_active"></div>
                  <div id="fa_active"></div>
                  <div id="sol_active"></div>
                  <div id="la_active"></div>
                  <div id="si_active"></div>
                  <div id="do2_active"></div>
                  <div id="circulo_blanco1"></div>
                  <div id="circulo_blanco2"></div>
                  <div id="circulo_blanco3"></div>
                  <div id="circulo_blanco4"></div>
                  <div id="circulo_blanco5"></div>
                  <div id="circulo_blanco6"></div>
                  <div id="circulo_blanco7"></div>
                  <div id="circulo_blanco8"></div>
                  <div id="circulo_blanco9"></div>
                  <div id="circulo_blanco10"></div>
                  <div id="amarillo1"></div>
                  <div id="amarillo2"></div>
                  <div id="amarillo3"></div>
                  <div id="amarillo4"></div>
                  <div id="amarillo5"></div>
                  <div id="amarillo6"></div>
                  <div id="amarillo7"></div>
                  <div id="amarillo8"></div>
                  <div id="amarillo9"></div>
                  <div id="amarillo10"></div>
                  <div id="amarillo_animated1"></div>
                  <div id="amarillo_animated2"></div>
                  <div id="amarillo_animated3"></div>
                  <div id="amarillo_animated4"></div>
                  <div id="amarillo_animated5"></div>
                  <div id="amarillo_animated6"></div>
                  <div id="amarillo_animated7"></div>
                  <div id="amarillo_animated8"></div>
                  <div id="amarillo_animated9"></div>
                  <div id="amarillo_animated10"></div>
                  <div id="azul1"></div>
                  <div id="azul2"></div>
                  <div id="azul3"></div>
                  <div id="azul4"></div>
                  <div id="azul5"></div>
                  <div id="azul6"></div>
                  <div id="azul7"></div>
                  <div id="azul8"></div>
                  <div id="azul9"></div>
                  <div id="azul10"></div>
                  <div id="azul11"></div>
                  <div id="azul_animated1"></div>
                  <div id="azul_animated2"></div>
                  <div id="azul_animated3"></div>
                  <div id="azul_animated4"></div>
                  <div id="azul_animated5"></div>
                  <div id="azul_animated6"></div>
                  <div id="azul_animated7"></div>
                  <div id="azul_animated8"></div>
                  <div id="azul_animated9"></div>
                  <div id="azul_animated10"></div>
                  <div id="rojo1"></div>
                  <div id="rojo2"></div>
                  <div id="rojo3"></div>
                  <div id="rojo4"></div>
                  <div id="rojo5"></div>
                  <div id="rojo6"></div>
                  <div id="rojo7"></div>
                  <div id="rojo8"></div>
                  <div id="rojo9"></div>
                  <div id="rojo10"></div>
                  <div id="rojo11"></div>
                  <div id="rojo12"></div>
                  <div id="rojo13"></div>
                  <div id="rojo14"></div>
                  <div id="rojo15"></div>
                  <div id="rojo_animated1"></div>
                  <div id="rojo_animated2"></div>
                  <div id="rojo_animated3"></div>
                  <div id="rojo_animated4"></div>
                  <div id="rojo_animated5"></div>
                  <div id="rojo_animated6"></div>
                  <div id="rojo_animated7"></div>
                  <div id="rojo_animated8"></div>
                  <div id="rojo_animated9"></div>
                  <div id="rojo_animated10"></div>
                  <div id="verde1"></div>
                  <div id="verde2"></div>
                  <div id="verde3"></div>
                  <div id="verde4"></div>
                  <div id="verde5"></div>
                  <div id="verde6"></div>
                  <div id="verde7"></div>
                  <div id="verde8"></div>
                  <div id="verde9"></div>
                  <div id="verde10"></div>
                  <div id="verde_animated1"></div>
                  <div id="verde_animated2"></div>
                  <div id="verde_animated3"></div>
                  <div id="verde_animated4"></div>
                  <div id="verde_animated5"></div>
                  <div id="verde_animated6"></div>
                  <div id="verde_animated7"></div>
                  <div id="verde_animated8"></div>
                  <div id="verde_animated9"></div>
                  <div id="verde_animated10"></div>
                  <div id="little_noteC"></div>
                  <div id="little_noteD"></div>
                  <div id="little_noteE"></div>
                  <div id="little_noteF"></div>
                  <div id="little_noteG"></div>
                  <div id="little_noteA"></div>
                  <div id="little_noteB"></div>
                  <div id="little_noteC2"></div>
                  <div id="cuadrado_blanco1"></div>
                  <div id="cuadrado_blanco2"></div>
                  <div id="cuadrado_blanco3"></div>
                  <div id="cuadrado_blanco4"></div>
                  <div id="cuadrado_blanco5"></div>
                  <div id="cuadrado_blanco6"></div>
                  <div id="cuadrado_blanco7"></div>
                  <div id="cuadrado_blanco8"></div>
                  <div id="cuadrado_blanco9"></div>
                  <div id="cuadrado_blanco10"></div>
                  <div id="cuadrado_amarillo1"></div>
                  <div id="cuadrado_amarillo2"></div>
                  <div id="cuadrado_amarillo3"></div>
                  <div id="cuadrado_amarillo4"></div>
                  <div id="cuadrado_amarillo5"></div>
                  <div id="cuadrado_amarillo_animated1"></div>
                  <div id="cuadrado_amarillo_animated2"></div>
                  <div id="cuadrado_amarillo_animated3"></div>
                  <div id="cuadrado_amarillo_animated4"></div>
                  <div id="cuadrado_amarillo_animated5"></div>
                  <div id="cuadrado_amarillo_animated6"></div>
                  <div id="cuadrado_amarillo_animated7"></div>
                  <div id="cuadrado_amarillo_animated8"></div>
                  <div id="cuadrado_amarillo_animated9"></div>
                  <div id="cuadrado_amarillo_animated10"></div>
                  <div id="cuadrado_azul1"></div>
                  <div id="cuadrado_azul2"></div>
                  <div id="cuadrado_azul3"></div>
                  <div id="cuadrado_azul4"></div>
                  <div id="cuadrado_azul5"></div>
                  <div id="cuadrado_azul_animated1"></div>
                  <div id="cuadrado_azul_animated2"></div>
                  <div id="cuadrado_azul_animated3"></div>
                  <div id="cuadrado_azul_animated4"></div>
                  <div id="cuadrado_azul_animated5"></div>
                  <div id="cuadrado_azul_animated6"></div>
                  <div id="cuadrado_azul_animated7"></div>
                  <div id="cuadrado_azul_animated8"></div>
                  <div id="cuadrado_azul_animated9"></div>
                  <div id="cuadrado_azul_animated10"></div>
                  <div id="cuadrado_rojo1"></div>
                  <div id="cuadrado_rojo2"></div>
                  <div id="cuadrado_rojo3"></div>
                  <div id="cuadrado_rojo4"></div>
                  <div id="cuadrado_rojo5"></div>
                  <div id="cuadrado_rojo_animated1"></div>
                  <div id="cuadrado_rojo_animated2"></div>
                  <div id="cuadrado_rojo_animated3"></div>
                  <div id="cuadrado_rojo_animated4"></div>
                  <div id="cuadrado_rojo_animated5"></div>
                  <div id="cuadrado_rojo_animated6"></div>
                  <div id="cuadrado_rojo_animated7"></div>
                  <div id="cuadrado_rojo_animated8"></div>
                  <div id="cuadrado_rojo_animated9"></div>
                  <div id="cuadrado_rojo_animated10"></div>
                  <div id="cuadrado_verde1"></div>
                  <div id="cuadrado_verde2"></div>
                  <div id="cuadrado_verde3"></div>
                  <div id="cuadrado_verde4"></div>
                  <div id="cuadrado_verde5"></div>
                  <div id="cuadrado_verde_animated1"></div>
                  <div id="cuadrado_verde_animated2"></div>
                  <div id="cuadrado_verde_animated3"></div>
                  <div id="cuadrado_verde_animated4"></div>
                  <div id="cuadrado_verde_animated5"></div>
                  <div id="cuadrado_verde_animated6"></div>
                  <div id="cuadrado_verde_animated7"></div>
                  <div id="cuadrado_verde_animated8"></div>
                  <div id="cuadrado_verde_animated9"></div>
                  <div id="cuadrado_verde_animated10"></div>
                  <div id="triangulo_blanco1"></div>
                  <div id="triangulo_blanco2"></div>
                  <div id="triangulo_blanco3"></div>
                  <div id="triangulo_blanco4"></div>
                  <div id="triangulo_blanco5"></div>
                  <div id="triangulo_blanco6"></div>
                  <div id="triangulo_blanco7"></div>
                  <div id="triangulo_blanco8"></div>
                  <div id="triangulo_blanco9"></div>
                  <div id="triangulo_blanco10"></div>
                  <div id="triangulo_amarillo1"></div>
                  <div id="triangulo_amarillo2"></div>
                  <div id="triangulo_amarillo3"></div>
                  <div id="triangulo_amarillo4"></div>
                  <div id="triangulo_amarillo5"></div>
                  <div id="triangulo_amarillo_animated1"></div>
                  <div id="triangulo_amarillo_animated2"></div>
                  <div id="triangulo_amarillo_animated3"></div>
                  <div id="triangulo_amarillo_animated4"></div>
                  <div id="triangulo_amarillo_animated5"></div>
                  <div id="triangulo_amarillo_animated6"></div>
                  <div id="triangulo_amarillo_animated7"></div>
                  <div id="triangulo_amarillo_animated8"></div>
                  <div id="triangulo_amarillo_animated9"></div>
                  <div id="triangulo_amarillo_animated10"></div>
                  <div id="triangulo_azul1"></div>
                  <div id="triangulo_azul2"></div>
                  <div id="triangulo_azul3"></div>
                  <div id="triangulo_azul4"></div>
                  <div id="triangulo_azul5"></div>
                  <div id="triangulo_azul_animated1"></div>
                  <div id="triangulo_azul_animated2"></div>
                  <div id="triangulo_azul_animated3"></div>
                  <div id="triangulo_azul_animated4"></div>
                  <div id="triangulo_azul_animated5"></div>
                  <div id="triangulo_azul_animated6"></div>
                  <div id="triangulo_azul_animated7"></div>
                  <div id="triangulo_azul_animated8"></div>
                  <div id="triangulo_azul_animated9"></div>
                  <div id="triangulo_azul_animated10"></div>
                  <div id="triangulo_rojo1"></div>
                  <div id="triangulo_rojo2"></div>
                  <div id="triangulo_rojo3"></div>
                  <div id="triangulo_rojo4"></div>
                  <div id="triangulo_rojo5"></div>
                  <div id="triangulo_rojo_animated1"></div>
                  <div id="triangulo_rojo_animated2"></div>
                  <div id="triangulo_rojo_animated3"></div>
                  <div id="triangulo_rojo_animated4"></div>
                  <div id="triangulo_rojo_animated5"></div>
                  <div id="triangulo_rojo_animated6"></div>
                  <div id="triangulo_rojo_animated7"></div>
                  <div id="triangulo_rojo_animated8"></div>
                  <div id="triangulo_rojo_animated9"></div>
                  <div id="triangulo_rojo_animated10"></div>
                  <div id="triangulo_verde1"></div>
                  <div id="triangulo_verde2"></div>
                  <div id="triangulo_verde3"></div>
                  <div id="triangulo_verde4"></div>
                  <div id="triangulo_verde5"></div>
                  <div id="triangulo_verde_animated1"></div>
                  <div id="triangulo_verde_animated2"></div>
                  <div id="triangulo_verde_animated3"></div>
                  <div id="triangulo_verde_animated4"></div>
                  <div id="triangulo_verde_animated5"></div>
                  <div id="triangulo_verde_animated6"></div>
                  <div id="triangulo_verde_animated7"></div>
                  <div id="triangulo_verde_animated8"></div>
                  <div id="triangulo_verde_animated9"></div>
                  <div id="triangulo_verde_animated10"></div>

                  <div id="osoN"></div>
                 


                  <!--<div id="totalNieve1">{{totalNieve}}</div>-->
         
                </div>
                <app-modal :idQuestion="nextQuestion" :subWorldId="subWorldId" :multiple="multipleNextQuestion" :nextQuestionSubworldId="nextQuestionSubworldId" :activityType="activityType"></app-modal>
                <app-modal-help :help="help"></app-modal-help>
                <app-modal-code></app-modal-code>
                <audio id="audioInst" controls style="display: none">
                  <source type="audio/mp3" src="../assets/instrucciones/fail_pegman.mp3"/>
                </audio>




               
                <!--<audio id="audiodo" controls style="display: none">
                  <source type="audio/mp3" src="../assets/gifs/silofano/do.mp3"/>
                </audio>-->
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @fileoverview Main Vue component that includes the Blockly component.
 * @author samelh@google.com (Sam El-Husseini)
 */
import BlocklyComponent from "../components/BlocklyComponent.vue";
import BlocklyComponentParte from "../components/BlocklyComponentParte.vue";
import "../blocks/stocks";
import "./prompt";
import BlocklyJS from "blockly/javascript";
import AppHeader from "../components/AppHeader.vue";
import AppSidebar from "../components/AppSidebar.vue";
import AppModal from "../components/AppModal.vue";
import AppModalHelp from "../components/AppModalHelp.vue";
import AppModalCode from "../components/AppModalCode.vue";

export default {
  name: "Activity",
  components: {
    BlocklyComponent,
    AppHeader,
    AppSidebar,
    AppModal,
    AppModalHelp,
    BlocklyComponentParte,
    AppModalCode
  },
  data() {
    return {
      code: "",
      name: "",
      instructions: "",
      instructions_audio: "",
      nextQuestion: "",
      subWorldId: "",
      nextQuestionSubworldId: "",
      activityType: 0,
      activity_number:0,
      options: {
        scrollbars: true,
      },
      help: 0,
      score: 100,
      activity_id: 0,
      activityDone: 0,
      clicando: false,

      runtimeTranscription_: "",
      transcription_: [],
      lang_: "fr-FR",
      arrayBlocks: [],
      blocksToUse: '',
      multiple: 0,
      multipleNextQuestion: 0,
      totalNieve: "",
      withVideo: 0,
    };
  },
  methods: {

    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
        runcodeDos.disabled = true;
      }

      jQuery.fn.extend({
        resaltar: function (busqueda, claseCSSbusqueda) {
          var regex = new RegExp("(<[^>]*>)|(" + busqueda.replace(/([-.*+?^${}()|[\]\/\\])/g, "\\$1") +")", "ig");
          var nuevoHtml = this.html(
            this.html().replace(regex, function (a, b, c) {
              return a.charAt(0) == "<" ? a : '<span class="' + claseCSSbusqueda + '">' + c + "</span>";
            })
          );
          return nuevoHtml;
        },
      });

      var instrucciones = this.instructions;
      var longitud = this.instructions.length;
      var subvalor = Math.ceil(longitud / 5);
      var cadena1 = instrucciones.substr(0, subvalor);
      var cadena2 = instrucciones.substr(subvalor, subvalor);
      var cadena3 = instrucciones.substr(subvalor * 2, subvalor);
      var cadena4 = instrucciones.substr(subvalor * 3, subvalor);
      var cadena5 = instrucciones.substr(subvalor * 4, subvalor);

      var arreglo = this.sound_delay.split("|");
      let t1 = arreglo[0]
      let t2 = arreglo[1]
      let t3 = arreglo[2]
      let t4 = arreglo[3]
      let t5 = arreglo[4]
      let t6 = arreglo[5]
      
      
      setTimeout(function () {$("#texto").resaltar(cadena1, "resaltarTexto"); }, t1);
      setTimeout(function () {$("#texto").resaltar(cadena2, "resaltarTexto"); }, t2);
      setTimeout(function () {$("#texto").resaltar(cadena3, "resaltarTexto"); }, t3);
      setTimeout(function () {$("#texto").resaltar(cadena4, "resaltarTexto"); }, t4);
      setTimeout(function () {$("#texto").resaltar(cadena5, "resaltarTexto"); }, t5);
      setTimeout(function () {$("#texto").resaltar(cadena1, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena2, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena3, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena4, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena5, "quitarresaltarTexto"); }, t6);
      setTimeout(() => {runcodeDos.disabled = false;}, t6)
    },
    SetParams(){
    currentTime = 300;
    currentTime2 = 300;
    currentPositionX = window.currentPosiitionX;
    currentPositionY = window.currentPosiitionY;
    currentPositionX2 = window.currentPosiition2X; 
    currentPositionY2 = window.currentPosiition2Y;
    var code = '';
    goalP2C =0;
    window.goalscollected = 0;
    window.goalscollected2 = 0;
    window.stainClean = 0;
    window.bCorrectA1 = false;
    window.bCorrectA2 = false;
    stainClean = 0;
    stainCleanA2 = 0;


    if(window.orientacion == "FP"){
      //window.blocks[0].select();  
      if(this.$route.query.multiple != 16)
        $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); 
      if(this.$route.query.multiple == 16)
        $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteParado.gif')"); 
        $("#character").css("top",window.ia1y + "px"); 
        $("#character").css("left",window.ia1x +"px");
      if(this.$route.query.multiple != 16 && window.avatarType == 3)
        $("#character3").css("background-image","url('../assets/img/personaje/robot/frenteParado.gif')"); 

    }else if(window.orientacion == "DP"){
      //window.blocks[0].select();
      if(this.$route.query.multiple != 16)
        $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')");
      if(this.$route.query.multiple == 16)
        $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')");
        $("#character").css("top",window.ia1y + "px"); 
        $("#character").css("left",window.ia1x + "px");
      if(this.$route.query.multiple != 16 && window.avatarType == 3)
        $("#character3").css("background-image","url('../assets/img/personaje/robot/derechaParado.gif')");

    }else if(window.orientacion == "IP"){
      //window.blocks[0].select();  
      if(this.$route.query.multiple != 16)
      $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); 
      if(this.$route.query.multiple == 16)
      $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); 
      $("#character").css("top",window.ia1y + "px"); 
      $("#character").css("left",window.ia1x + "px");
      if(this.$route.query.multiple != 16  && window.avatarType == 3)
      $("#character3").css("background-image","url('../assets/img/personaje/robot/izqParado.gif')"); 

    }else if(window.orientacion == "EP"){
      //window.blocks[0].select();  
      if(this.$route.query.multiple != 16)
        $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')");
      if(this.$route.query.multiple == 16)
      $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); 
      $("#character").css("top",window.ia1y + "px"); 
      $("#character").css("left",window.ia1x + "px");
      if(this.$route.query.multiple != 16  && window.avatarType == 3)
      $("#character3").css("background-image","url('../assets/img/personaje/robot/espaldaparado.gif')"); 
    }
  
    if(window.orientacion2 == "FP"){
     // window.blocks[0].select();  
     if(this.$route.query.multiple != 16)
      $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')"); 
       if(this.$route.query.multiple == 16)
      $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteParado.gif')");
      $("#character2").css("top",window.ia2y + "px"); 
      $("#character2").css("left",window.ia2x + "px");
    }else if(window.orientacion2 == "DP"){
      //window.blocks[0].select();
      if(this.$route.query.multiple != 16)  
      $("#character2").css("background-image","url('../assets/img/personaje/pirata/derechaParado.gif')"); 
       if(this.$route.query.multiple == 16)
       $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')");
      $("#character2").css("top",window.ia2y + "px"); 
      $("#character2").css("left", window.ia2x+ "px");
    }else if(window.orientacion2 == "IP"){
      //window.blocks[0].select();
      if(this.$route.query.multiple != 16)
        $("#character2").css("background-image","url('../assets/img/personaje/pirata/izqParado.gif')");
      if(this.$route.query.multiple == 16)
        $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izquierdaParado.gif')");
        $("#character2").css("top",window.ia2y+"px"); 
        $("#character2").css("left",window.ia2x+"px");
    }else if(window.orientacion2 == "EP"){
      //window.blocks[0].select();  
       if(this.$route.query.multiple != 16)
         $("#character2").css("background-image","url('../assets/img/personaje/pirata/espaldaparado.gif')"); 
       if(this.$route.query.multiple == 16)
        $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaParado.gif')"); 
        $("#character2").css("top",window.ia2y+"px"); 
        $("#character2").css("left",window.ia2x+"px");
    }
    


    window.GoalsArray.forEach(element => {
     element.bCorrect = null;
    $("#"+element.Type).css("display", "block");
    });
    $("#hojas").css("display", "none");
    $("#hojas2").css("display", "none");
    window.newOrientation = window.orientacion;
    positx = window.posiitx;
    posity = window.posiity;
    posit2x = window.posiit2x;
    posit2y = window.posiit2y;
    window.setParams = 0;
    window.xyz=0;
    window.coordenada = '';
    window.namediv = '';
    window.figeometrico ='';


},

    runCode() {
      var connectedBlocks = true;
      this.activityDone = 0
      this.clicando= true;
      window.invalid = 0; //le decimos que todo es válido
      window.blocks = this.$refs["blockly1"].workspace.getAllBlocks();
      // console.log('Bloques', window.blocks)
      // console.log('Longitud', window.blocks.length - 1)

      for(var i=1; i <= window.blocks.length-1; i++) {
        var myconnection=window.blocks[i].getConnections_(false);
        // console.log(myconnection)
        for(var j=0; j<=myconnection.length-1;j++)
        {
          if(!myconnection[j].isSuperior() && myconnection[j].targetConnection==null)
            connectedBlocks = false;
        }
      }

      if(connectedBlocks){
        this.SetParams();
        runcode.disabled = true;
        evaluate.disabled = true
        window.espacioBlockly = this.$refs["blockly1"].workspace
        if(this.$route.query.multiple == 1)
          this.code = BlocklyJS.workspaceToCode(this.$refs["blockly1"].workspace) + "\n Daniel(); \n Mary();";
        if(this.$route.query.multiple == 0 || this.$route.query.multiple == 2 || this.$route.query.multiple == 3 || this.$route.query.multiple == 4 || this.$route.query.multiple == 5 || this.$route.query.multiple == 6 || this.$route.query.multiple == 7 || this.$route.query.multiple == 8 || this.$route.query.multiple == 9 || this.$route.query.multiple == 10 || this.$route.query.multiple == 11 || this.$route.query.multiple == 12 || this.$route.query.multiple == 13 || this.$route.query.multiple == 14 || this.$route.query.multiple == 15)
          this.code = BlocklyJS.workspaceToCode(this.$refs["blockly1"].workspace);
        if(this.$route.query.multiple == 16)
          this.code = BlocklyJS.workspaceToCode(this.$refs["blockly1"].workspace) + "\n Luis(); \n Lucia();";
        
        // console.log(this.code)
        eval(this.code);
        setTimeout(() => {runcode.disabled = false; evaluate.disabled = false;}, currentTime + 500)
      }else{
        this.showMessage('Error', "Todos los bloques deben estar conectados", 'error', '', 1)
      }
    },
    clear() {
      location.reload();
    },
    showModalHelp() {
      if (this.help + 1 <= 2) this.help++;
      Fire.$emit("assignHelpImages", {help: this.help});

      if (this.help == 1) {
        $("#AppModalHelp").modal({ backdrop: "true", keyboard: true });
        $("#AppModalHelp").appendTo("body").modal("show");
        var audio = new Audio(helpAudio1);
        audio.play();
      } else {
        $("#AppModalHelp").appendTo("body").modal("show");
        var audio = new Audio(helpAudio2);
        audio.play();
      }
    },
    showVideo(video) {
      Fire.$emit('showVideo', video)
      setTimeout(() => {
        $("#AppModalHelp").modal("show")
      }, 100);
    },
    async configActivity(idQuestion) {
      this.arrayBlocks = []
      await axios
        .get(apiETC + "api/smartc3/get-activity?question_id=" + idQuestion + `&school_id=${localStorage.school_id}&grade_id=${localStorage.idGrade}&subworld_id=${this.$route.query.subworld_id}`)
        .then((response) => {
          this.blocksToUse = response.data.blocks
          const blocksArray = response.data.blocks.split('|')
          blocksArray.forEach( (element) => {
            let arrayElement = element.split(',')
            this.arrayBlocks.push({
              type: arrayElement[0],
              class: arrayElement[1]
            })
          })
          
          //Fire.$emit("clearWorkspace");
          this.blocksReady = 1
          window.currentPosiitionX = response.data.avatar_position_x;
          window.currentPosiitionY = response.data.avatar_position_y;
          window.currentPosiition2X = response.data.avatar2_position_x;
          window.currentPosiition2Y = response.data.avatar2_position_y;
          window.originalPositionX = response.data.avatar_position_x;
          window.originalPositionY = response.data.avatar_position_y;

          window.ia1x = response.data.avatar_position_x;
          window.ia1y = response.data.avatar_position_y;
          window.ia2x = response.data.avatar2_position_x;
          window.ia2y = response.data.avatar2_position_y;

          window.currentPositionXGoal = response.data.goal_position_x;
          window.currentPositionYGoal = response.data.goal_position_y;
          window.posiitx = response.data.avatar_init_x;
          window.posiity = response.data.avatar_init_y;
          window.posiit2x = response.data.avatar2_init_x;
          window.posiit2y = response.data.avatar2_init_y;
          window.positx_goal = response.data.goal_init_x;
          window.posity_goal = response.data.goal_init_y;
          window.setParams = 0;
          window.avatarType= response.data.avatarType;
          this.totalNieve=2;
          window.nextQuestionRoute = response.data.next_question_route
          window.question_id =  response.data.question_id;
          window.helpQuestion = response.data.helps.find( element => element.order == 1)
          window.idioma=this.$store.getters.language;
          
          if(this.$store.getters.language == 'es') {
            this.instructions = response.data.instructions;
            this.instructions_audio = response.data.instructions_audio;
            this.sound_delay = response.data.sound_delay;
          }
          if(this.$store.getters.language != 'es') {
            const translation = response.data.translations.find(element => element.language_code == this.$store.getters.language)
            if(translation != undefined) {
              this.instructions = translation.translation;
              this.instructions_audio = translation.instructions_audio;
              this.sound_delay = translation.sound_delay;
            } else {
              this.instructions = response.data.instructions;
              this.instructions_audio = response.data.instructions_audio;
              this.sound_delay = response.data.sound_delay;
            }
          }
          this.nextQuestion = response.data.next_question.toString();
          this.subWorldId = response.data.subworld_id.toString();
          this.nextQuestionSubworldId = response.data.next_question_subworld_id.toString()
          this.activityType = response.data.next_question_activity_type
          this.activity_number = response.data.activity_number
          this.activity_id = response.data.id;
          
          this.multiple = response.data.multiple
          this.help = 0;
          this.multipleNextQuestion = response.data.next_question_multiple
          window.multiple = response.data.multiple
          window.videoUrl = response.data.video

          if(response.data.video != '' && response.data.video != null)
            this.withVideo = 1
          
          if(response.data.avatar_initial_gif.includes("frenteparado"))
            window.orientacion = "FP";
          else if (response.data.avatar_initial_gif.includes("derechaParado"))
            window.orientacion = "DP";
          else if (response.data.avatar_initial_gif.includes("espaldaparado"))
            window.orientacion = "EP";
          else if(response.data.avatar_initial_gif.includes("izqParado"))
            window.orientacion = "IP";

          if(response.data.avatar2_initial_gif != null){
          if(response.data.avatar2_initial_gif.includes("frenteparado")|| response.data.avatar2_initial_gif.includes("frenteParado"))
            window.orientacion2 = "FP";
          else if (response.data.avatar2_initial_gif.includes("derechaParado")|| response.data.avatar2_initial_gif.includes("derechaParado"))
            window.orientacion2 = "DP";
          else if (response.data.avatar2_initial_gif.includes("espaldaparado") || response.data.avatar2_initial_gif.includes("espaldaParado"))
            window.orientacion2 = "EP";
          else if(response.data.avatar2_initial_gif.includes("izqParado") || response.data.avatar2_initial_gif.includes("izquierdaParado"))
            window.orientacion2 = "IP";
          }


            window.goalscollected = 0;
           window.newOrientation = window.orientacion;
           window.newOrientation2 = window.orientacion2;
          //traemos de la base de datos las coordenadas invalidas
          //window.blocksNotAllowed = '3,0|3,1|3,2|3,3|0,3|1,3|2,3|2,4|2,5|2,6|3,7|4,7|5,7|6,2|7,2|'
          window.blocksYesAllowed = response.data.goals_order;
          window.blocksNotAllowed = response.data.coord_not_allowed;
          if (response.data.helps.length > 0) {
            window.helpImg1 = response.data.helps[0].help_image_url;
            window.helpImg2 = response.data.helps[1].help_image_url;
            if(this.$store.getters.language == 'es') {
            window.helpAudio1 = response.data.helps[0].help_audio_url;
            window.helpAudio2 = response.data.helps[1].help_audio_url;
            }
            if(this.$store.getters.language != 'es') {
            window.helpAudio1 = '../assets/instrucciones/help/path.mp3';
            window.helpAudio2 = '../assets/instrucciones/help/programming.mp3';
            }
          }
          var Goals = {};
          var ArrarObjects = [];

          $(".scenario").css("background-image",'url("' + response.data.scenario + '")');
          if(this.$store.getters.language == 'es') {
            Fire.$emit('setBreadcrumbs', { world: response.data.subworlds.worlds.name, subWorld: response.data.subworlds.name, world_id: response.data.subworlds.worlds.id, activity_number: response.data.activity_number })
          }
          if(this.$store.getters.language != 'es') {
            let translation = response.data.translations.find(element => element.language_code == this.$store.getters.language)
            if(translation != undefined) {
              Fire.$emit('setBreadcrumbs', { world: response.data.subworlds.worlds.name, subWorld: response.data.subworlds.name, world_id: response.data.subworlds.worlds.id, activity_number: response.data.activity_number })
            } else {
              Fire.$emit('setBreadcrumbs', { world: response.data.subworlds.worlds.name, subWorld: response.data.subworlds.name, world_id: response.data.subworlds.worlds.id, activity_number: response.data.activity_number })
            }
          }
          $(".scenario").css(
            "background-image",
            'url("' + response.data.scenario + '")'
          );
          if(window.avatarType == 3){

          $("#character3").css("background-image",'url("' + response.data.avatar_initial_gif + '")');
          $("#character3").css("top", window.currentPosiitionY + "px");
          $("#character3").css("left", window.currentPosiitionX + "px");
          $("#character3").css("display", "block");

          }else{
         
          $("#character").css("background-image",'url("' + response.data.avatar_initial_gif + '")');
          $("#character").css("top", window.currentPosiitionY + "px");
          $("#character").css("left", window.currentPosiitionX + "px");
          $("#character").css("display", "block");

          }
          if(response.data.avatar2_initial_gif != null){
            $("#character2").css("background-image",'url("' + response.data.avatar2_initial_gif + '")');
            $("#character2").css("top", window.currentPosiition2Y + "px");
            $("#character2").css("left", window.currentPosiition2X + "px");
            $("#character2").css("display", "block");
          }

          $("#gem").css("top", window.currentPositionYGoal + "px");
          $("#gem").css("left", window.currentPositionXGoal + "px");
          if(idQuestion<=25846){
            $("#gem").css("display", "block");
          }
          $("#palette").css("display", "none");
          $("#icecream").css("display", "none");
          $("#sweet").css("display", "none");
          $("#gift").css("display", "none");
          $("#gift2").css("display", "none");
          $("#gift3").css("display", "none");
          $("#eyes").css("display", "none");
          $("#tail").css("display", "none");
          $("#panda").css("display", "none");
          $("#duck1").css("display", "none");
          $("#duck2").css("display", "none");
          $("#delfin").css("display", "none");
          $("#oso").css("display", "none");
          $("#rusa").css("display", "none");
          $("#targetShooting").css("display", "none");
          $("#waterJet").css("display", "none");
          $("#Payaso1").css("display", "none");
          $("#swing").css("display", "none");
          $("#bellota").css("display", "none");
          $("#semilla-01").css("display", "none");
          $("#circle-dark").css("display", "none");
          $("#circle-dark1").css("display", "none");
          $("#catarina").css("display", "none");
          $("#hada2").css("display", "none");
          $("#hada3").css("display", "none");
          $("#LamparaCorazon").css("display", "none");
          $("#z").css("display", "none");
          $("#hada1").css("display", "none");
          $("#luzventana").css("display", "none");
          $("#bellota1").css("display", "none");
          $("#bellota2").css("display", "none");
          $("#bellota3").css("display", "none");
          $("#bellota4").css("display", "none");
          $("#bellota5").css("display", "none");
          $("#Rana").css("display", "none");
          $("#Principe").css("display", "none");
          $("#hojas").css("display", "none");
          $("#hojas2").css("display", "none");
          $("#gem2").css("display", "none");
          $("#gem3").css("display", "none");
          $("#gem4").css("display", "none");
          $("#gem5").css("display", "none");
          $("#gem6").css("display", "none");
          $("#gem7").css("display", "none");
          $("#gem8").css("display", "none");
          $("#gem9").css("display", "none");
          $("#gem10").css("display", "none");
          $("#gem11").css("display", "none");
          $("#gem12").css("display", "none");
          $("#gem13").css("display", "none");
          $("#gem14").css("display", "none");
          $("#gem15").css("display", "none");
          $("#gem16").css("display", "none");
          $("#gem17").css("display", "none");
          $("#gem18").css("display", "none");
          $("#gem19").css("display", "none");
          $("#gnomo1").css("display", "none");
          $("#gnomo2").css("display", "none");
          $("#canngreyconcha").css("display", "none");
          $("#tiburon").css("display", "none");
          $("#notas").css("display", "none");
          $("#cangrejo").css("display", "none");
          $("#gaviota").css("display", "none");
          $("#ola1").css("display", "none");
          $("#barrilenagua").css("display", "none");
          $("#shine").css("display", "none");
          $("#arana1").css("display", "none");
          $("#arana2").css("display", "none");
          $("#tesoropirata").css("display", "none");
          $("#mancha").css("display", "none");
          $("#mancha1").css("display", "none");
          $("#mancha2").css("display", "none");
          $("#mancha3").css("display", "none");
          $("#mancha4").css("display", "none");
          $("#mancha5").css("display", "none");
          $("#mancha6").css("display", "none");
          $("#mancha7").css("display", "none");
          $("#mancha8").css("display", "none");
          $("#mancha9").css("display", "none");
          $("#mancha10").css("display", "none");
          $("#mancha11").css("display", "none");
          $("#tentacle").css("display", "none");
          $("#Spike").css("display", "none");
          $("#ShakeBush").css("display", "none");
          $("#Planta-Carnivora2").css("display", "none");
          $("#Planta-Carnivora1").css("display", "none");
          $("#Mushroom").css("display", "none");
          $("#MudMonster").css("display", "none");
          $("#Monstruo2").css("display", "none");
          $("#Monstruo1").css("display", "none");
          $("#eyeTree").css("display", "none");
          $("#Craneo").css("display", "none");
          $("#nieve").css("display", "none");
          $("#nieve2").css("display", "none");
          $("#nieve3").css("display", "none");
          $("#hoyo").css("display", "none");
          $("#hoyo2").css("display", "none");
          $("#hoyo3").css("display", "none");
          $("#Esquiador").css("display", "none");
          $("#IceMan").css("display", "none");
          $("#FrozenFish").css("display", "none");
          $("#YetiJump").css("display", "none");
          $("#YetiWalk").css("display", "none");
          $("#Termo").css("display", "none");
          $("#Grieta").css("display", "none");
          $("#IceCream").css("display", "none");
          $("#totalNieve1").css("display", "none");
          $("#meta").css("display", "none");
          $("#salida").css("display", "none");
          $("#alegre1").css("display", "none");
          $("#alegre2").css("display", "none");
          $("#alegre3").css("display", "none");
          $("#alegre4").css("display", "none");
          $("#alegre5").css("display", "none");
          $("#alegre6").css("display", "none");
          $("#alegre7").css("display", "none");
          $("#alegre8").css("display", "none");
          $("#alegre9").css("display", "none");
          $("#alegre10").css("display", "none");
          $("#alegre11").css("display", "none");
          $("#alegre12").css("display", "none");
          $("#alegre13").css("display", "none");
          $("#alegre14").css("display", "none");
          $("#alegre15").css("display", "none");
          $("#alegre16").css("display", "none");
          $("#alegre17").css("display", "none");
          $("#alegre18").css("display", "none");
          $("#alegre19").css("display", "none");
          $("#alegre20").css("display", "none");
          $("#angustiado1").css("display", "none");
          $("#angustiado2").css("display", "none");
          $("#angustiado3").css("display", "none");
          $("#angustiado4").css("display", "none");
          $("#angustiado5").css("display", "none");
          $("#angustiado6").css("display", "none");
          $("#angustiado7").css("display", "none");
          $("#angustiado8").css("display", "none");
          $("#angustiado9").css("display", "none");
          $("#angustiado10").css("display", "none");
          $("#angustiado11").css("display", "none");
          $("#angustiado12").css("display", "none");
          $("#angustiado13").css("display", "none");
          $("#angustiado14").css("display", "none");
          $("#angustiado15").css("display", "none");
          $("#angustiado16").css("display", "none");
          $("#angustiado17").css("display", "none");
          $("#angustiado18").css("display", "none");
          $("#angustiado19").css("display", "none");
          $("#angustiado20").css("display", "none");
          $("#enojado1").css("display", "none");
          $("#enojado2").css("display", "none");
          $("#enojado3").css("display", "none");
          $("#enojado4").css("display", "none");
          $("#enojado5").css("display", "none");
          $("#enojado6").css("display", "none");
          $("#enojado7").css("display", "none");
          $("#enojado8").css("display", "none");
          $("#enojado19").css("display", "none");
          $("#enojado10").css("display", "none");
          $("#enojado11").css("display", "none");
          $("#enojado12").css("display", "none");
          $("#enojado13").css("display", "none");
          $("#enojado14").css("display", "none");
          $("#enojado15").css("display", "none");
          $("#enojado16").css("display", "none");
          $("#enojado17").css("display", "none");
          $("#enojado18").css("display", "none");
          $("#enojado19").css("display", "none");
          $("#enojado20").css("display", "none");
          $("#feliz1").css("display", "none");
          $("#feliz2").css("display", "none");
          $("#feliz3").css("display", "none");
          $("#feliz4").css("display", "none");
          $("#feliz5").css("display", "none");
          $("#feliz6").css("display", "none");
          $("#feliz7").css("display", "none");
          $("#feliz8").css("display", "none");
          $("#feliz9").css("display", "none");
          $("#feliz10").css("display", "none");
          $("#feliz11").css("display", "none");
          $("#feliz12").css("display", "none");
          $("#feliz13").css("display", "none");
          $("#feliz14").css("display", "none");
          $("#feliz15").css("display", "none");
          $("#feliz16").css("display", "none");
          $("#feliz17").css("display", "none");
          $("#feliz18").css("display", "none");
          $("#feliz19").css("display", "none");
          $("#feliz20").css("display", "none");
          $("#sorprendido1").css("display", "none");
          $("#sorprendido2").css("display", "none");
          $("#sorprendido3").css("display", "none");
          $("#sorprendido4").css("display", "none");
          $("#sorprendido5").css("display", "none");
          $("#sorprendido6").css("display", "none");
          $("#sorprendido7").css("display", "none");
          $("#sorprendido8").css("display", "none");
          $("#sorprendido9").css("display", "none");
          $("#sorprendido10").css("display", "none");
          $("#sorprendido11").css("display", "none");
          $("#sorprendido12").css("display", "none");
          $("#sorprendido13").css("display", "none");
          $("#sorprendido14").css("display", "none");
          $("#sorprendido15").css("display", "none");
          $("#sorprendido16").css("display", "none");
          $("#sorprendido17").css("display", "none");
          $("#sorprendido18").css("display", "none");
          $("#sorprendido19").css("display", "none");
          $("#sorprendido20").css("display", "none");
          $("#triste1").css("display", "none");
          $("#triste2").css("display", "none");
          $("#triste3").css("display", "none");
          $("#triste4").css("display", "none");
          $("#triste5").css("display", "none");
          $("#triste6").css("display", "none");
          $("#triste7").css("display", "none");
          $("#triste8").css("display", "none");
          $("#triste9").css("display", "none");
          $("#triste10").css("display", "none");
          $("#triste11").css("display", "none");
          $("#triste12").css("display", "none");
          $("#triste13").css("display", "none");
          $("#triste14").css("display", "none");
          $("#triste15").css("display", "none");
          $("#triste16").css("display", "none");
          $("#triste17").css("display", "none");
          $("#triste18").css("display", "none");
          $("#triste19").css("display", "none");
          $("#triste20").css("display", "none");
          $("#vocal_a").css("display", "none");
          $("#vocal_e").css("display", "none");
          $("#vocal_i").css("display", "none");
          $("#vocal_o").css("display", "none");
          $("#vocal_u").css("display", "none");
          $("#abeja").css("display", "none");
          $("#aguila").css("display", "none");
          $("#alce").css("display", "none");
          $("#arana").css("display", "none");
          $("#ave").css("display", "none");
          $("#caballo").css("display", "none");
          $("#cocodrilo").css("display", "none");
          $("#leon").css("display", "none");
          $("#rana").css("display", "none");
          $("#elefante").css("display", "none");
          $("#do_active").css("display", "none");
          $("#re_active").css("display", "none");
          $("#mi_active").css("display", "none");
          $("#fa_active").css("display", "none");
          $("#sol_active").css("display", "none");
          $("#la_active").css("display", "none");
          $("#si_active").css("display", "none");
          $("#do2_active").css("display", "none");
          $("#circulo_blanco1").css("display","none");
          $("#circulo_blanco2").css("display","none");
          $("#circulo_blanco3").css("display","none");
          $("#circulo_blanco4").css("display","none");
          $("#circulo_blanco5").css("display","none");
          $("#circulo_blanco6").css("display","none");
          $("#circulo_blanco7").css("display","none");
          $("#circulo_blanco8").css("display","none");
          $("#circulo_blanco9").css("display","none");
          $("#circulo_blanco10").css("display","none");
          $("#amarillo1").css("display","none");
          $("#amarillo2").css("display","none");
          $("#amarillo3").css("display","none");
          $("#amarillo4").css("display","none");
          $("#amarillo5").css("display","none");
          $("#amarillo6").css("display","none");
          $("#amarillo7").css("display","none");
          $("#amarillo8").css("display","none");
          $("#amarillo9").css("display","none");
          $("#amarillo10").css("display","none");
          $("#amarillo_animated1").css("display","none");
          $("#amarillo_animated2").css("display","none");
          $("#amarillo_animated3").css("display","none");
          $("#amarillo_animated4").css("display","none");
          $("#amarillo_animated5").css("display","none");
          $("#amarillo_animated6").css("display","none");
          $("#amarillo_animated7").css("display","none");
          $("#amarillo_animated8").css("display","none");
          $("#amarillo_animated9").css("display","none");
          $("#amarillo_animated10").css("display","none");
          $("#azul1").css("display","none");
          $("#azul2").css("display","none");
          $("#azul3").css("display","none");
          $("#azul4").css("display","none");
          $("#azul5").css("display","none");
          $("#azul6").css("display","none");
          $("#azul7").css("display","none");
          $("#azul8").css("display","none");
          $("#azul9").css("display","none");
          $("#azul10").css("display","none");
          $("#azul11").css("display","none");
          $("#azul_animated1").css("display","none");
          $("#azul_animated2").css("display","none");
          $("#azul_animated3").css("display","none");
          $("#azul_animated4").css("display","none");
          $("#azul_animated5").css("display","none");
          $("#azul_animated6").css("display","none");
          $("#azul_animated7").css("display","none");
          $("#azul_animated8").css("display","none");
          $("#azul_animated9").css("display","none");
          $("#azul_animated10").css("display","none");
          $("#rojo1").css("display","none");
          $("#rojo2").css("display","none");
          $("#rojo3").css("display","none");
          $("#rojo4").css("display","none");
          $("#rojo5").css("display","none");
          $("#rojo6").css("display","none");
          $("#rojo7").css("display","none");
          $("#rojo8").css("display","none");
          $("#rojo9").css("display","none");
          $("#rojo10").css("display","none");
          $("#rojo11").css("display","none");
          $("#rojo12").css("display","none");
          $("#rojo13").css("display","none");
          $("#rojo14").css("display","none");
          $("#rojo15").css("display","none");
          $("#rojo_animated1").css("display","none");
          $("#rojo_animated2").css("display","none");
          $("#rojo_animated3").css("display","none");
          $("#rojo_animated4").css("display","none");
          $("#rojo_animated5").css("display","none");
          $("#rojo_animated6").css("display","none");
          $("#rojo_animated7").css("display","none");
          $("#rojo_animated8").css("display","none");
          $("#rojo_animated9").css("display","none");
          $("#rojo_animated10").css("display","none");
          $("#verde1").css("display","none");
          $("#verde2").css("display","none");
          $("#verde3").css("display","none");
          $("#verde4").css("display","none");
          $("#verde5").css("display","none");
          $("#verde6").css("display","none");
          $("#verde7").css("display","none");
          $("#verde8").css("display","none");
          $("#verde9").css("display","none");
          $("#verde10").css("display","none");
          $("#verde_animated1").css("display","none");
          $("#verde_animated2").css("display","none");
          $("#verde_animated3").css("display","none");
          $("#verde_animated4").css("display","none");
          $("#verde_animated5").css("display","none");
          $("#verde_animated6").css("display","none");
          $("#verde_animated7").css("display","none");
          $("#verde_animated8").css("display","none");
          $("#verde_animated9").css("display","none");
          $("#verde_animated10").css("display","none");
          $("#little_noteC").css("display","none");
          $("#little_noteD").css("display","none");
          $("#little_noteE").css("display","none");
          $("#little_noteF").css("display","none");
          $("#little_noteG").css("display","none");
          $("#little_noteA").css("display","none");
          $("#little_noteB").css("display","none");
          $("#little_noteC2").css("display","none");

          $("#cuadrado_blanco1").css("display","none");
          $("#cuadrado_blanco2").css("display","none");
          $("#cuadrado_blanco3").css("display","none");
          $("#cuadrado_blanco4").css("display","none");
          $("#cuadrado_blanco5").css("display","none");
          $("#cuadrado_blanco6").css("display","none");
          $("#cuadrado_blanco7").css("display","none");
          $("#cuadrado_blanco8").css("display","none");
          $("#cuadrado_blanco9").css("display","none");
          $("#cuadrado_blanco10").css("display","none");
          
          $("#cuadrado_amarillo1").css("display","none");
          $("#cuadrado_amarillo2").css("display","none");
          $("#cuadrado_amarillo3").css("display","none");
          $("#cuadrado_amarillo4").css("display","none");
          $("#cuadrado_amarillo5").css("display","none");
          $("#cuadrado_amarillo_animated1").css("display","none");
          $("#cuadrado_amarillo_animated2").css("display","none");
          $("#cuadrado_amarillo_animated3").css("display","none");
          $("#cuadrado_amarillo_animated4").css("display","none");
          $("#cuadrado_amarillo_animated5").css("display","none");
          $("#cuadrado_amarillo_animated6").css("display","none");
          $("#cuadrado_amarillo_animated7").css("display","none");
          $("#cuadrado_amarillo_animated8").css("display","none");
          $("#cuadrado_amarillo_animated9").css("display","none");
          $("#cuadrado_amarillo_animated10").css("display","none");

          $("#cuadrado_azul1").css("display","none");
          $("#cuadrado_azul2").css("display","none");
          $("#cuadrado_azul3").css("display","none");
          $("#cuadrado_azul4").css("display","none");
          $("#cuadrado_azul5").css("display","none");
          $("#cuadrado_azul_animated1").css("display","none");
          $("#cuadrado_azul_animated2").css("display","none");
          $("#cuadrado_azul_animated3").css("display","none");
          $("#cuadrado_azul_animated4").css("display","none");
          $("#cuadrado_azul_animated5").css("display","none");
          $("#cuadrado_azul_animated6").css("display","none");
          $("#cuadrado_azul_animated7").css("display","none");
          $("#cuadrado_azul_animated8").css("display","none");
          $("#cuadrado_azul_animated9").css("display","none");
          $("#cuadrado_azul_animated10").css("display","none");

          $("#cuadrado_rojo1").css("display","none");
          $("#cuadrado_rojo2").css("display","none");
          $("#cuadrado_rojo3").css("display","none");
          $("#cuadrado_rojo4").css("display","none");
          $("#cuadrado_rojo5").css("display","none");
          $("#cuadrado_rojo_animated1").css("display","none");
          $("#cuadrado_rojo_animated2").css("display","none");
          $("#cuadrado_rojo_animated3").css("display","none");
          $("#cuadrado_rojo_animated4").css("display","none");
          $("#cuadrado_rojo_animated5").css("display","none");
          $("#cuadrado_rojo_animated6").css("display","none");
          $("#cuadrado_rojo_animated7").css("display","none");
          $("#cuadrado_rojo_animated8").css("display","none");
          $("#cuadrado_rojo_animated9").css("display","none");
          $("#cuadrado_rojo_animated10").css("display","none");

          $("#cuadrado_verde1").css("display","none");
          $("#cuadrado_verde2").css("display","none");
          $("#cuadrado_verde3").css("display","none");
          $("#cuadrado_verde4").css("display","none");
          $("#cuadrado_verde5").css("display","none");
          $("#cuadrado_verde_animated1").css("display","none");
          $("#cuadrado_verde_animated2").css("display","none");
          $("#cuadrado_verde_animated3").css("display","none");
          $("#cuadrado_verde_animated4").css("display","none");
          $("#cuadrado_verde_animated5").css("display","none");
          $("#cuadrado_verde_animated6").css("display","none");
          $("#cuadrado_verde_animated7").css("display","none");
          $("#cuadrado_verde_animated8").css("display","none");
          $("#cuadrado_verde_animated9").css("display","none");
          $("#cuadrado_verde_animated10").css("display","none");

          $("#triangulo_blanco1").css("display","none");
          $("#triangulo_blanco2").css("display","none");
          $("#triangulo_blanco3").css("display","none");
          $("#triangulo_blanco4").css("display","none");
          $("#triangulo_blanco5").css("display","none");
          $("#triangulo_blanco6").css("display","none");
          $("#triangulo_blanco7").css("display","none");
          $("#triangulo_blanco8").css("display","none");
          $("#triangulo_blanco9").css("display","none");
          $("#triangulo_blanco10").css("display","none");
          
          $("#triangulo_amarillo1").css("display","none");
          $("#triangulo_amarillo2").css("display","none");
          $("#triangulo_amarillo3").css("display","none");
          $("#triangulo_amarillo4").css("display","none");
          $("#triangulo_amarillo5").css("display","none");
          $("#triangulo_amarillo_animated1").css("display","none");
          $("#triangulo_amarillo_animated2").css("display","none");
          $("#triangulo_amarillo_animated3").css("display","none");
          $("#triangulo_amarillo_animated4").css("display","none");
          $("#triangulo_amarillo_animated5").css("display","none");
          $("#triangulo_amarillo_animated6").css("display","none");
          $("#triangulo_amarillo_animated7").css("display","none");
          $("#triangulo_amarillo_animated8").css("display","none");
          $("#triangulo_amarillo_animated9").css("display","none");
          $("#triangulo_amarillo_animated10").css("display","none");

          $("#triangulo_azul1").css("display","none");
          $("#triangulo_azul2").css("display","none");
          $("#triangulo_azul3").css("display","none");
          $("#triangulo_azul4").css("display","none");
          $("#triangulo_azul5").css("display","none");
          $("#triangulo_azul_animated1").css("display","none");
          $("#triangulo_azul_animated2").css("display","none");
          $("#triangulo_azul_animated3").css("display","none");
          $("#triangulo_azul_animated4").css("display","none");
          $("#triangulo_azul_animated5").css("display","none");
          $("#triangulo_azul_animated6").css("display","none");
          $("#triangulo_azul_animated7").css("display","none");
          $("#triangulo_azul_animated8").css("display","none");
          $("#triangulo_azul_animated9").css("display","none");
          $("#triangulo_azul_animated10").css("display","none");

          $("#triangulo_rojo1").css("display","none");
          $("#triangulo_rojo2").css("display","none");
          $("#triangulo_rojo3").css("display","none");
          $("#triangulo_rojo47").css("display","none");
          $("#triangulo_rojo5").css("display","none");
          $("#triangulo_rojo_animated1").css("display","none");
          $("#triangulo_rojo_animated2").css("display","none");
          $("#triangulo_rojo_animated3").css("display","none");
          $("#triangulo_rojo_animated4").css("display","none");
          $("#triangulo_rojo_animated5").css("display","none");
          $("#triangulo_rojo_animated6").css("display","none");
          $("#triangulo_rojo_animated7").css("display","none");
          $("#triangulo_rojo_animated8").css("display","none");
          $("#triangulo_rojo_animated9").css("display","none");
          $("#triangulo_rojo_animated10").css("display","none");

          $("#triangulo_verde1").css("display","none");
          $("#triangulo_verde2").css("display","none");
          $("#triangulo_verde3").css("display","none");
          $("#triangulo_verde4").css("display","none");
          $("#triangulo_verde5").css("display","none");
          $("#triangulo_verde_animated1").css("display","none");
          $("#triangulo_verde_animated2").css("display","none");
          $("#triangulo_verde_animated3").css("display","none");
          $("#triangulo_verde_animated4").css("display","none");
          $("#triangulo_verde_animated5").css("display","none");
          $("#triangulo_verde_animated6").css("display","none");
          $("#triangulo_verde_animated7").css("display","none");
          $("#triangulo_verde_animated8").css("display","none");
          $("#triangulo_verde_animated9").css("display","none");
          $("#triangulo_verde_animated10").css("display","none");

          $("#osoN").css("display","none");


           
          
             if(response.data.objects.length  > 0){
          response.data.objects.forEach(element => {
            if (element.type.type == "Objetivo"){
              Goals = {
                Type: element.type.name,
                TypeO: element.type.type,
                postx: element.init_x,
                posty: element.init_y,
                bCorrect: null,
                iPriority: element.priority,
                avatar_Number: element.avatar_number,
                color: element.color
                //temp_valor: 0
              };
            ArrarObjects.push(Goals);
            }
            if( element.type.active == 1 ){
              $("#" + element.type.name).css("top", element.position_y + "px");
              $("#"+ element.type.name).css("left", element.position_x + "px");
              $("#"+ element.type.name).css("display", "block");
            }
            if(element.priority == 3){
              $("#" + element.type.name).css("top", element.position_y + "px");
              $("#"+ element.type.name).css("left", element.position_x + "px");
              $("#"+ element.type.name).css("display", "none");
            }
          });
          }
          window.GoalsArray =ArrarObjects;
        });
    },
    async evaluate() {
      //validamos que haya usado al menos una vez los bloques
      let allBlocksUsed = 0; let arrayColor = [];
      let model = {}
      let blockType = '';
      const blocksToUse = this.blocksToUse.split('|')
      let arrayBlocksToUse = []
      let arrayBlocksUsed = this.$refs["blockly1"].workspace.getAllBlocks()
      this.arrayBlocks.forEach( element => {
        arrayBlocksToUse.push({
          block: element.type,
          used: 0
        })
      } )
      console.log(arrayBlocksToUse)
      arrayBlocksUsed.forEach( block => {
        console.log('block', block)
        if(block.type != 'initial' && block.type != 'procedures_defnoreturn') {
          let blockUsed = arrayBlocksToUse.find( element => element.block == block.type )
          if(blockUsed != undefined) {
            console.log('blockUsed', blockUsed)
            blockUsed.used = 1
          }
        }
      } )
      //validamos que se hayan usado todos los bloques
      if(this.$route.query.single_activity != 1) {
        if(this.help == 1)
          this.score = 80
        if(this.help == 2)
          this.score = 60
        model = {
          activity_id: this.activity_id,
          person_id: localStorage.idPerson,
          activation_id: localStorage.idActivation,
          opportunity_number: this.help + 1,
          score: this.score,
        };
      }

      if(this.$route.query.single_activity == 1) {
        if(this.help == 1)
          this.score = 80
        if(this.help == 2)
          this.score = 60
        model = {
          activity_id: this.activity_id,
          person_id: this.$route.query.id_person,
          activation_id: this.$route.query.id_activation,
          opportunity_number: this.help + 1,
          score: this.score,
        };
      }
      console.log('Positx', posiitx);
      console.log('window.positx_goal', window.positx_goal);

      if(window.checkColors == true) {
        arrayColor = window.coordenada.split('|');
        arrayColor.forEach((color) => {
          if(color != '') {
            let varColor = color.split(',');
            window.GoalsArray.forEach((goal) => {
              if(goal.iPriority == 1 && goal.TypeO == "Objetivo" && goal.postx == varColor[0] && goal.posty == varColor[1]) {
                console.log('goal', goal);
                console.log('varColor', varColor);
                if(goal.color == varColor[2] && this.activityDone == 1)
                  this.activityDone = 1
                if(goal.color != varColor[2])
                  this.activityDone = 0
              }
            });
          }
        })
      }

      if (this.activityDone == 1) {
        /*arrayBlocksToUse.forEach( element => {
          this.activityDone = element.used
          if(element.used == 0)
            blockType = blockType + element.block + ' '
        } )*/

        if(this.activityDone == 1) {
          this.openLoading();
          await axios
            .post(apiETC + "api/smartc3/evaluate-activity", model)
            .then((response) => {
              Swal.close();
              $("#AppModal").modal("show");
              //this.showMessage("Exito", response.data.message, "success", "", 1);
            })
            .error((error) => {
              Swal.close();
            });
        } else {
          alert('Tu solución funciona, pero te falto usar algunos bloques (' + blockType + ')')
        }
      } else {
        $("#AppModal").modal("show");
      }
    },
    showModalCode() {
      $("#AppModalCode").appendTo("body").modal("show")
      Fire.$emit('startBlockly')
    }
  },
  async mounted() {
    $(document).ready(function () {
      App.init();

      $('#AppModalCode').on('shown.bs.modal', function() {
        $(document).off('focusin.modal');
      });
    });

    await this.configActivity(this.$route.query.idQuestion);

    Fire.$on("clearWorkspace", () => {
      this.$refs["blockly1"].workspace.clear();
      Fire.$emit("reinitialize");
    });

    Fire.$on("activityDone", () => {
      this.activityDone = 1
    })
  },
  watch: {
    $route(to, from) {
      this.score = 100;
      this.activity_id = 0;
      this.activityDone = 0;
      this.configActivity(to.query.idQuestion);
    },
  },
};
</script>

<style>
#blockly1 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.scenario {
  background-repeat: no-repeat;
  position: relative;
  background-size: 95% 95%;
  width: 600px;
  height: 600px;
}

#character {
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
  background-size: 100% 100%;
  height: 100px;
  width: 80px;
  display: none;
}
#character2 {
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
  background-size: 100% 100%;
  height: 100px;
  width: 80px;
  display: none;
}
#character3 {
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
  background-size: 100% 100%;
  height: 70px;
  width: 70px;
  display: none;
}
#gem {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem2 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem3 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem4 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem5 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem6 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem7 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem8 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem9 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem10 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem11 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem12 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem13 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem14 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem15 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem16 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem17 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem18 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem19 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#palette {
  background-image: url("../assets/img/distractores/paleta.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 70px;
  width: 80px;
  display: none;
}
#sweet {
  background-image: url("../assets/img/distractores/dulce.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 70px;
  width: 80px;
  display: none;
}
#icecream {
  background-image: url("../assets/img/distractores/helado.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 70px;
  width: 80px;
  display: none;
}
#gift {
  background-image: url("../assets/img/distractores/regalo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#gift2 {
  background-image: url("../assets/img/distractores/regalo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#gift3 {
  background-image: url("../assets/img/distractores/regalo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#eyes {
  background-image: url("../assets/gifs/gif_zoo/ojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#tail {
  background-image: url("../assets/gifs/gif_zoo/cola.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#panda {
  background-image: url("../assets/gifs/gif_zoo/panda.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#duck1 {
  background-image: url("../assets/gifs/gif_zoo/1pato.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#duck2 {
  background-image: url("../assets/gifs/gif_zoo/2pato.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#delfin {
  background-image: url("../assets/gifs/gif_zoo/delfin.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 100px;
  display: none;
}
#oso {
  background-image: url("../assets/gifs/gif_zoo/cachorro.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 100px;
  display: none;
}
#rusa {
  background-image: url("../assets/gifs/gif_parq/rusa.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 565px;
  width: 220px;
  display: none;
}
#Payaso1 {
  background-image: url("../assets/gifs/gif_parq/Payaso1.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 200px;
  width: 134px;
  display: none;
}
#targetShooting {
  background-image: url("../assets/gifs/gif_parq/TiroPatos.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 120px;
  display: none;
}
#waterJet {
  background-image: url("../assets/gifs/gif_parq/waterJet.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 190px;
  display: none;
}
#swing {
  background-image: url("../assets/gifs/gif_parq/columpio.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 130px;
  width: 180px;
  display: none;
}
#bellota {
  background-image: url("../assets/img/activity/bellota.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#semilla-01 {
  background-image: url("../assets/gifs/gif_hadas/semilla-01.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 35px;
  width: 25px;
  display: none;
}
#circle-dark {
  background-image: url("../assets/gifs/gif_hadas/circle-dark.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 35px;
  width: 25px;
  display: none;
}
#circle-dark1 {
  background-image: url("../assets/gifs/gif_hadas/circle-dark.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 35px;
  width: 25px;
  display: none;
}
#hada2 {
  background-image: url("../assets/gifs/gif_hadas/hada2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 155px;
  width: 195px;
  display: none;
}
#catarina {
  background-image: url("../assets/gifs/gif_hadas/catarina.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 95px;
  width: 65px;
  display: none;
}
#hada3 {
  background-image: url("../assets/gifs/gif_hadas/hada3.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 155px;
  width: 195px;
  display: none;
}
#LamparaCorazon {
  background-image: url("../assets/gifs/gif_hadas/LamparaCorazon.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 100px;
  width: 115px;
  display: none;
}
#z {
  background-image: url("../assets/gifs/gif_hadas/z.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 65px;
  width: 45px;
  display: none;
}
#hada1 {
  background-image: url("../assets/gifs/gif_hadas/hada1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 155px;
  width: 195px;
  display: none;
}
#luzventana {
  background-image: url("../assets/gifs/gif_hadas/luzventana.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 120px;
  width: 80px;
  display: none;
}
#bellota1 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#bellota2 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#bellota3 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#bellota4 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#bellota5 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}

#Rana {
  background-image: url("../assets/gifs/gif_hadas/Rana.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 155px;
  width: 195px;
  display: none;
}
#Principe {
  background-image: url("../assets/gifs/gif_hadas/Principe.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 120px;
  width: 80px;
  display: none;
}

#hojas {
  background-image: url("../assets/gifs/gif_hadas/hojas.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}

#hojas2 {
  background-image: url("../assets/gifs/gif_hadas/hojas.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}

#gnomo1 {
  background-image: url("../assets/gifs/gif_gnomos/gnomo1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#gnomo2 {
  background-image: url("../assets/gifs/gif_gnomos/gnomo2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#canngreyconcha {
  background-image: url("../assets/gifs/gif_playa/canngreyconcha.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#tiburon {
  background-image: url("../assets/gifs/gif_playa/tiburon.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#notas {
  background-image: url("../assets/gifs/gif_playa/notas.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#cangrejo {
  background-image: url("../assets/gifs/gif_Cueva/cangrejo.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#gaviota {
  background-image: url("../assets/gifs/gif_playa/gaviota.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#barrilenagua {
  background-image: url("../assets/gifs/gif_Cueva/barrilenagua.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#ola1 {
  background-image: url("../assets/gifs/gif_Cueva/ola1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#shine {
  background-image: url("../assets/gifs/gif_Cueva/shine.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#arana1 {
  background-image: url("../assets/gifs/gif_Cueva/arana1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#arana2 {
  background-image: url("../assets/gifs/gif_Cueva/arana2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#tesoropirata {
  background-image: url("../assets/gifs/gif_Cueva/tesoropirata.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#mancha {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha1 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha2 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha3 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha4 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha5 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha6 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha7 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#mancha8 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#mancha9 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#mancha10 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#mancha11 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#tentacle {
  background-image: url("../assets/gifs/gif_valle/tentacle.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Spike {
  background-image: url("../assets/gifs/gif_valle/Spike.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#ShakeBush {
  background-image: url("../assets/gifs/gif_valle/ShakeBush.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Planta-Carnivora2 {
  background-image: url("../assets/gifs/gif_valle/Planta-Carnivora2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Planta-Carnivora1 {
  background-image: url("../assets/gifs/gif_valle/Planta-Carnivora1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Mushroom {
  background-image: url("../assets/gifs/gif_valle/Mushroom.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#MudMonster {
  background-image: url("../assets/gifs/gif_valle/MudMonster.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Monstruo2 {
  background-image: url("../assets/gifs/gif_valle/Monstruo2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}

#Monstruo1 {
  background-image: url("../assets/gifs/gif_valle/Monstruo1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 130px;
  width: 100px;
  display: none;
}

#eyeTree {
  background-image: url("../assets/gifs/gif_valle/eyeTree.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Craneo {
  background-image: url("../assets/gifs/gif_valle/Craneo.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#nieve {
  background-image: url("../assets/gifs/nieve.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#nieve2 {
  background-image: url("../assets/gifs/nieve.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#nieve3 {
  background-image: url("../assets/gifs/nieve.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#hoyo {
  background-image: url("../assets/gifs/hoyo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#hoyo2 {
  background-image: url("../assets/gifs/hoyo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#hoyo3 {
  background-image: url("../assets/gifs/hoyo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}

#Esquiador {
  background-image: url("../assets/gifs/gif_moustros/Esquiador.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#IceMan {
  background-image: url("../assets/gifs/gif_moustros/IceMan.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#FrozenFish {
  background-image: url("../assets/gifs/gif_moustros/FrozenFish.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#YetiJump {
  background-image: url("../assets/gifs/gif_moustros/YetiJump.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#YetiWalk {
  background-image: url("../assets/gifs/gif_moustros/YetiWalk.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#Termo {
  background-image: url("../assets/gifs/gif_moustros/Termo.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#Grieta {
  background-image: url("../assets/gifs/gif_moustros/Grieta.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#IceCream {
  background-image: url("../assets/gifs/gif_moustros/IceCream.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#totalNieve1 {
  /*background-image: url("../assets/gifs/gif_moustros/IceCream.gif");*/
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}

#salida {
  background-image: url("../assets/gifs/nuevos/salida.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#meta {
  background-image: url("../assets/gifs/nuevos/meta.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre1 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre2 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre3 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre4 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre5 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre6 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre7 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre8 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre9 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre10 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre11 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre12 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre13 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre14 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre15 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre16 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre17 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre18 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre19 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre20 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado1 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado2 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado3 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado4 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado5 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado6 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado7 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado8 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado9 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado10 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado11 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado12 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado13 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado14 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado15 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado16 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado17 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado18 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado19 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado20 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}

#enojado1 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado2 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado3 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado4 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado5 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado6 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado7 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado8 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado9 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado10 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado11 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado12 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado13 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado14 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado15 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado16 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado17 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado18 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado19 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado20 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz1 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;

  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}

#feliz2 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz3 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz4 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz5 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz6 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz7 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz8 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz9 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz10 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz11 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz12 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz13 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz14 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz15 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz16 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz17 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz18 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz19 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz20 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido1 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido2 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido3 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido4 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido5 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido6 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido7 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido8 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido9 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido10 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido11 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido12 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido13 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido14 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido15 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido16 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido17 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido18 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido19 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido20 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste1 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste2 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste3 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 75px;
  width: 75px;
  display: none;
}
#triste4 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste5 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste6 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste7 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste8 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste9 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste10 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste11 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste12 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste13 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste14 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste15 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste16 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste17 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste18 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste19 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste20 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}


#vocal_a {
  background-image: url("../assets/gifs/nuevos/vocal_a.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 50% 50%;
  height: 72px;
  width: 72px;
  display: none;
}
#vocal_e {
  background-image: url("../assets/gifs/nuevos/vocal_e.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#vocal_i {
  background-image: url("../assets/gifs/nuevos/vocal_i.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#vocal_o {
  background-image: url("../assets/gifs/nuevos/vocal_o.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#vocal_u {
  background-image: url("../assets/gifs/nuevos/vocal_u.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}

#abeja {
  background-image: url("../assets/gifs/nuevos/abeja.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#aguila {
  background-image: url("../assets/gifs/nuevos/aguila.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alce {
  background-image: url("../assets/gifs/nuevos/alce.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#arana {
  background-image: url("../assets/gifs/nuevos/arana.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#ave {
  background-image: url("../assets/gifs/nuevos/ave.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#caballo {
  background-image: url("../assets/gifs/nuevos/caballo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#cocodrilo {
  background-image: url("../assets/gifs/nuevos/cocodrilo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#leon {
  background-image: url("../assets/gifs/nuevos/leon.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#rana {
  background-image: url("../assets/gifs/nuevos/rana.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#elefante {
  background-image: url("../assets/gifs/nuevos/elefante.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}


#C_active {
  background-image: url("../assets/gifs/silofano/do_active.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 289px;
  width: 66px;
  display: none;
}

#little_noteC {
  background-image: url("../assets/gifs/silofano/little_note.gif");
  background-size: 45px 60px;
	position:absolute;
  width: 42px; 
  height: 60px;
  background-repeat: no-repeat;
  display: none;
}

#D_active {
  background-image: url("../assets/gifs/silofano/re_active.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 283px;
  width: 66px;
  display: none;
}

#little_noteD {
  background-image: url("../assets/gifs/silofano/little_note.gif");
  background-size: 45px 60px;
	position:absolute;
  width: 42px; 
  height: 60px;
  background-repeat: no-repeat;
  display: none;
}
 
#E_active {
  background-image: url("../assets/gifs/silofano/mi_active.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 275px;
  width: 66px;
  display: none;
}
#little_noteE {
  background-image: url("../assets/gifs/silofano/little_note.gif");
  background-size: 45px 60px;
	position:absolute;
  width: 42px; 
  height: 60px;
  background-repeat: no-repeat;
  display: none;
}

#F_active {
  background-image: url("../assets/gifs/silofano/fa_active.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 267px;
  width: 66px;
  display: none;
}
#little_noteF {
  background-image: url("../assets/gifs/silofano/little_note.gif");
  background-size: 45px 60px;
	position:absolute;
  width: 42px; 
  height: 60px;
  background-repeat: no-repeat;
  display: none;
}

#G_active {
  background-image: url("../assets/gifs/silofano/sol_active.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 258px;
  width: 66px;
  display: none;
}
#little_noteG {
  background-image: url("../assets/gifs/silofano/little_note.gif");
  background-size: 45px 60px;
	position:absolute;
  width: 42px; 
  height: 60px;
  background-repeat: no-repeat;
  display: none;
}

#A_active {
  background-image: url("../assets/gifs/silofano/la_active.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 250px;
  width: 66px;
  display: none;
}
#little_noteA {
  background-image: url("../assets/gifs/silofano/little_note.gif");
  background-size: 45px 60px;
	position:absolute;
  width: 42px; 
  height: 60px;
  background-repeat: no-repeat;
  display: none;
}

#B_active {
  background-image: url("../assets/gifs/silofano/si_active.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 245px;
  width: 66px;
  display: none;
}
#little_noteB {
  background-image: url("../assets/gifs/silofano/little_note.gif");
  background-size: 45px 60px;
	position:absolute;
  width: 42px; 
  height: 60px;
  background-repeat: no-repeat;
  display: none;
}

#C2_active {
  background-image: url("../assets/gifs/silofano/do2_active.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 235px;
  width: 66px;
  display: none;
}
#little_noteC2 {
  background-image: url("../assets/gifs/silofano/little_note.gif");
  background-size: 45px 60px;
	position:absolute;
  width: 42px; 
  height: 60px;
  background-repeat: no-repeat;
  display: none;
}

#circulo_blanco1 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco2 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco3 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco4 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco5 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco6 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco7 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco8 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco9 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#circulo_blanco10 {
  background-image: url("../assets/gifs/geometricas/circulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo1 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo2 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo3 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo4 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo5 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo6 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo7 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo8 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo9 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo10 {
  background-image: url("../assets/gifs/geometricas/amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated1 {
  /*background-image: url("../assets/gifs/geometricas/amarillo_animated.gif");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#amarillo_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#azul1 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul2 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul3 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul4 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul5 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul6 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul7 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul8 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul9 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul10 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul11 {
  background-image: url("../assets/gifs/geometricas/azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated1 {

  /*background-image: url("../assets/gifs/geometricas/azul_animated.gif");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#azul_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#rojo1 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo2 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo3 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo4 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo5 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo6 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo7 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo8 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo9 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo10 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo11 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo12 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo13 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo14 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo15 {
  background-image: url("../assets/gifs/geometricas/rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated1 {
  /*background-image: url("../assets/gifs/geometricas/rojo_animated.gif");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#rojo_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#verde1 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde2 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde3 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde4 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde5 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde6 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde7 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde8 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde9 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde10 {
  background-image: url("../assets/gifs/geometricas/verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated1 {
  /*background-image: url("../assets/gifs/geometricas/verde_animated.gif");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#verde_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#cuadrado_blanco1 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco2 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco3 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco4 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco5 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco6 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco7 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco8 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco9 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_blanco10 {
  background-image: url("../assets/gifs/geometricas/cuadrado_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo1 {
  background-image: url("../assets/gifs/geometricas/cuadrado_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo2 {
  background-image: url("../assets/gifs/geometricas/cuadrado_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo3 {
  background-image: url("../assets/gifs/geometricas/cuadrado_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo4 {
  background-image: url("../assets/gifs/geometricas/cuadrado_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo5 {
  background-image: url("../assets/gifs/geometricas/cuadrado_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated1 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_amarillo_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul1 {
  background-image: url("../assets/gifs/geometricas/cuadrado_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul2 {
  background-image: url("../assets/gifs/geometricas/cuadrado_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul3 {
  background-image: url("../assets/gifs/geometricas/cuadrado_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul4 {
  background-image: url("../assets/gifs/geometricas/cuadrado_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul5 {
  background-image: url("../assets/gifs/geometricas/cuadrado_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated1 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_azul_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#cuadrado_rojo1 {
  background-image: url("../assets/gifs/geometricas/cuadrado_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo2 {
  background-image: url("../assets/gifs/geometricas/cuadrado_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo3 {
  background-image: url("../assets/gifs/geometricas/cuadrado_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo4 {
  background-image: url("../assets/gifs/geometricas/cuadrado_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo5 {
  background-image: url("../assets/gifs/geometricas/cuadrado_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated1 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_rojo_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde1 {
  background-image: url("../assets/gifs/geometricas/cuadrado_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde2 {
  background-image: url("../assets/gifs/geometricas/cuadrado_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde3 {
  background-image: url("../assets/gifs/geometricas/cuadrado_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde4 {
  background-image: url("../assets/gifs/geometricas/cuadrado_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde5 {
  background-image: url("../assets/gifs/geometricas/cuadrado_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated1 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#cuadrado_verde_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#triangulo_blanco1 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco2 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco3 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco4 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco5 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco6 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco7 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco8 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco9 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_blanco10 {
  background-image: url("../assets/gifs/geometricas/triangulo_blanco.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#triangulo_amarillo1 {
  background-image: url("../assets/gifs/geometricas/triangulo_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo2 {
  background-image: url("../assets/gifs/geometricas/triangulo_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo3 {
  background-image: url("../assets/gifs/geometricas/triangulo_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo4 {
  background-image: url("../assets/gifs/geometricas/triangulo_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo5 {
  background-image: url("../assets/gifs/geometricas/triangulo_amarillo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated1 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_amarillo_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#triangulo_azul1 {
  background-image: url("../assets/gifs/geometricas/triangulo_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul2 {
  background-image: url("../assets/gifs/geometricas/triangulo_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul3 {
  background-image: url("../assets/gifs/geometricas/triangulo_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul4 {
  background-image: url("../assets/gifs/geometricas/triangulo_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul5 {
  background-image: url("../assets/gifs/geometricas/triangulo_azul.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated1 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_azul_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#triangulo_rojo1 {
  background-image: url("../assets/gifs/geometricas/triangulo_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo2 {
  background-image: url("../assets/gifs/geometricas/triangulo_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo3 {
  background-image: url("../assets/gifs/geometricas/triangulo_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo4 {
  background-image: url("../assets/gifs/geometricas/triangulo_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo5 {
  background-image: url("../assets/gifs/geometricas/triangulo_rojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated1 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_rojo_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#triangulo_verde1 {
  background-image: url("../assets/gifs/geometricas/triangulo_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde2 {
  background-image: url("../assets/gifs/geometricas/triangulo_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde3 {
  background-image: url("../assets/gifs/geometricas/triangulo_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde4 {
  background-image: url("../assets/gifs/geometricas/triangulo_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde5 {
  background-image: url("../assets/gifs/geometricas/triangulo_verde.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated1 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated2 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated3 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated4 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated5 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated6 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated7 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated8 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated9 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}
#triangulo_verde_animated10 {
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 80px;
  display: none;
}

#osoN {
  background-image: url("../assets/gifs/trazo/osoN.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}


.speech-to-txt,
.txt-to-speech {
  display: grid;
  width: 200px;
  height: 100px;
  border-radius: 20px;
  border: 2px solid grey;
  background-color: rgb(248, 245, 245);
  font-size: 38px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  place-items: center;
}
.speech-transciption {
  width: 500px;
  padding: 20px;
  border: 2px solid grey;
  background-color: rgb(211, 228, 253);
  border-radius: 20px;
}

#mark {
  background-color: blue;
}

.quitarresaltarTexto {
 color: #070707;
 background-color: #fffffd;
}

.resaltarTexto{
    color: #F3AA04;
    background-color: #FFFF96;
}


</style>
