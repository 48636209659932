<template>
    <div class="modal fade" id="AppModalActivation" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('text.modalCodeTitleText') }}</h5>
                    <button   type="button" class="close" @click="closeModal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="username-field" class="field-wrapper input">
                        <input id="sAccessCode" name="sAccessCode" type="name" class="form-control" :placeholder="$t('text.placeholderCode')" v-model="model.sAccessCode" required autofocus>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" value="" @click="activateCode">{{ $t('text.buttonCode') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'AppModalActivation',
    data() {
        return {
            model: {
                sAccessCode: '',
                idGroupActivation: '',
            }
        }
    },
    props: {
      
    },
    methods: {
      closeModal() {
          $("#AppModalActivation").modal("hide")
      },
      async activateCode() {
        if(this.model.sAccessCode == '') {
            this.showMessage('Error', this.$t('text.codeErrorMessage'), 'error', '', 1)
            return
        }
        this.openLoading()
        this.model.idGroupActivation = localStorage.idGroupActivation
        await axios.post(apiETC + 'api/smartc3/activate-code', this.model)
            .then( response => {
                Swal.close()
                if(response.data.status == false) {
                    this.showMessage('Error', response.data.message, 'error', '', 1)
                    return
                }
                if(response.data.status == true) {
                    this.showMessage('Error', response.data.message, 'success', '', 1)
                    this.closeModal()
                }

            }).catch( error => {
                this.catchError(error)
            })
      }
    },
}
</script>