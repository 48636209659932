<template>
    <div>
        <app-header></app-header>
        <div class="main-container" id="container">
            <div class="overlay"></div>
            <div class="search-overlay"></div>
            <app-sidebar></app-sidebar>
            <div id="content" class="main-content">
                <div class="layout-px-spacing">
                    <h2 class="text-center">Asignación de actividades</h2>
                    <div class="col-4 statbox widget box box-shadow d-flex mx-auto">
                        <select class="custom-select custom-select-sm" v-model="model.school_id">
                            <option value="">Selecciona tu colegio</option>
                            <option :value="school.idPartner" v-for="school in schools" :key="school.idPartner">{{ school.sPartnerName }}</option>
                        </select>
                    </div>
                    <div class="col-4 statbox widget box box-shadow d-flex mx-auto">
                        <select class="custom-select custom-select-sm" v-model="model.grade_id">
                            <option value="">Selecciona un grado</option>
                            <option value="13">1ro Preescolar</option>
                            <option value="14">2do Preescolar</option>
                            <option value="15">3ro Preescolar</option>
                            <option value="1">1ro Primaria</option>
                            <option value="2">2do Primaria</option>
                            <option value="3">3ro Primaria</option>
                            <option value="4">4to Primaria</option>
                        </select>
                    </div>
                    <div class="col-4 statbox widget box box-shadow d-flex mx-auto">
                        <button class="btn btn-primary mr-1" @click.prevent="getReport()" v-if="model.school_id > 0 && model.grade_id > 0">Consultar</button> <button class="btn btn-primary mr-1" @click.prevent="assignActivities()" v-if="activities.length > 0">Asignar</button>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12 layout-top-spacing">
                            <div class="row">
                                <div class="col-md-3" v-for="world in activities" :key="world.id">
                                    <div id="treeviewBasic" class="col-lg-12 layout-spacing">
                                        <div class="statbox widget box box-shadow">
                                            <div class="widget-header">
                                                <div class="row">
                                                    <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                                        <h4>{{ world.name }}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="widget-content widget-content-area">
                                                <ul id="myUL">
                                                    <li v-for="subworld in world.subworlds" :key="subworld.id">
                                                        <span class="caret caret-down">{{ subworld.name }}</span>
                                                        <ul class="nested">
                                                            <li v-for="activity in subworld.activities" :key="activity.id">
                                                                <div class="n-chk">
                                                                    <label class="new-control text-black new-checkbox checkbox-primary">
                                                                        <input type="checkbox" class="new-control-input" v-model="activity.assigned">
                                                                        <span class="new-control-indicator"></span>{{ activity.name }} <span class="ml-1" v-if="activity.icon_alt != ''"><img :src="activity.icon_alt"></span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import AppHeader from "../components/AppHeader";
import AppSidebar from "../components/AppSidebar.vue";
export default {
    name: 'AssignActivities',
    components: {
        AppHeader, AppSidebar
    },
    data() {
        return {
            model: {
                school_id: '',
                grade_id: ''
            },
            schools: [],
            activities: [],
            toggler: false,
        }
    },
    methods: {
        async getReport() {
            this.openLoading()
            await axios.get(apiETC + 'api/smartc3/get-activities-school?school_id=' + this.model.school_id + '&grade_id=' + this.model.grade_id, { headers: clientAPI })
            .then( (response) => {
                this.activities = response.data
                if(this.toggler == false) {
                    const script = document.createElement("script");
                    script.src = "plugins/treeview/custom-jstree.js";
                    document.body.appendChild(script);
                    this.toggler = true
                }
            })
            .catch( error => {
                this.catchError(error)
            })
            Swal.close()
        },
        async assignActivities() {
            this.openLoading()
            await axios.post(apiETC + 'api/smartc3/assign-school-activities', {activities: this.activities, info: this.model}, { headers: clientAPI })
            .then( (response) => {
                Swal.close()
                if(response.data.status == true)
                    this.showMessage("Exito", response.data.message, "success", '', 1)
            })
            .catch( error => {
                this.catchError(error)
            })
        }
    },
    async mounted() {
        $(document).ready(function() {
            App.init();
        });

        await this.getSchoolsByAdvisor()
    }
}
</script>