<template>
  <div>
    <div class="blocklyDiv" ref="blocklyDiv">
    </div>
    <xml ref="blocklyToolbox" style="display:none">
      <slot></slot>
    </xml>
    <xml ref="startBlocks" style="display:none">
        <block type="initial" inline="true" movable="false" deletable="false" x="30" y="30"></block>
    </xml>
    <xml ref="startBlocks2" style="display:none">
        <block type="procedures_defnoreturn" x="10" y="10" deletable="false" editable="false">
          <field name="NAME">Daniel</field>
        </block>
        <block type="procedures_defnoreturn" x="10" y="300" deletable="false" editable="false">
          <field name="NAME">Mary</field>
        </block>
    </xml>
    <xml ref="startBlocks3" style="display:none">
        <block type="procedures_defnoreturn" x="10" y="10" deletable="false" editable="false">
          <field name="NAME">Luis</field>
        </block>
        <block type="procedures_defnoreturn" x="10" y="300" deletable="false" editable="false">
          <field name="NAME">Lucia</field>
        </block>
    </xml>
  </div>
</template>

<script>
/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @fileoverview Blockly Vue Component.
 * @author samelh@google.com (Sam El-Husseini)
 */
/* eslint-disable */
import Blockly from 'blockly';
import '../blocks/stocks';
export default {
  name: 'BlocklyComponent',
  props: ['options'],
  data(){
    return {
      workspace: null
    }
  },
  mounted() {
    var options = this.$props.options || {};
    if (!options.toolbox) {
      options.toolbox = this.$refs["blocklyToolbox"];
    }
    this.workspace = Blockly.inject(this.$refs["blocklyDiv"], options);
    if(this.$route.query.multiple != 1 && this.$route.query.multiple != 16 && this.$route.query.multiple != -1)
      Blockly.Xml.domToWorkspace(this.$refs['startBlocks'], Blockly.mainWorkspace);
    if(this.$route.query.multiple == 1)
      Blockly.Xml.domToWorkspace(this.$refs['startBlocks2'], Blockly.mainWorkspace);
    if(this.$route.query.multiple == 16)
      Blockly.Xml.domToWorkspace(this.$refs['startBlocks3'], Blockly.mainWorkspace);

    Fire.$on('reinitialize', () => {
      Blockly.Xml.domToWorkspace(this.$refs['startBlocks'], Blockly.mainWorkspace);
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blocklyDiv {
  height: 100%;
  width: 100%;
  text-align: left;
}
</style>