<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <app-header></app-header>
    <!--  END NAVBAR  -->
    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>
      <!--  BEGIN SIDEBAR  -->
      <app-sidebar></app-sidebar>
      <!--  END SIDEBAR  -->
      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
              <div class="widget-content-area">
                <div class="widget-content-area">
                  <div class="widget-one">
                    <div class="row">
                      <div
                        class="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing"
                      >
                        <div class="widget-content-area">
                          <div class="widget-content-area">
                            <div class="widget-one">
                              <div class="row">
                                <div class="col-md-1 col-sm-1">
                                  <img src="assets/img/icono_avatar.png" />
                                </div>
                                <div class="col-md-10 col-sm-8">
                                  <!--<p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Maecenas pellentesque eros
                                    non nulla semper ultrices.
                                  </p>-->
                                  <div id="texto" :key="instructions">{{ instructions }}</div>
                                </div>
                                <!--<button class="btn btn-info rounded-circle" id="btn_1" @click="trackInstructions()">
                                  <i class="fa fa-volume-up"></i>
                                </button>-->
                                 <button id="runcodeDos" class="btn btn-info rounded-circle" @click.prevent="playSound(instructions_audio)"><i class="fa fa-volume-up"></i></button>
                              </div>
                              <button class="btn btn-success btn-rounded mt-n3 float-right" @click="runCode">
                                Siguiente <i class="fa fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                          <div class="widget-one">
                            <div class="row mt-3">
                              <div class="col-md-7">
                                <h5>
                                  <pre>Mis bloques y espacio de trabajo </pre>
                                </h5>
                                <BlocklyComponentParte id="blockly1" ref="blockly1" :options="options">
                                  <block type="puzzlePayasoB" class="PelotaB"></block>
                                  <block type="puzzlePayasoA" class="PelotaA"></block>
                                  <block type="puzzlePayasoC" class="PelotaA"></block>
                                </BlocklyComponentParte>
                                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                              </div>
                              <div class="col-md-5">
                                <h5 class="text-center">
                                  <pre>Mi resultado</pre>
                                </h5>
                                <div class="widget-one scenario">
                                  <div id="puzzlePayasoA" v-show="false"></div>
                                  <div id="puzzlePayasoB" v-show="false"></div>
                                  <div id="puzzlePayasoC" v-show="false"></div>
                                  <div id="resultado" v-show="false"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  END CONTENT AREA  -->
          <audio id="audio" controls style="display: none">
            <source type="audio/mp3" src="../../../assets/instrucciones/20210111-054722.mp3"/>
          </audio>
           <audio id="audioInst" controls style="display: none">
            <source type="audio/mp3" src="../../../assets/instrucciones/correcto_efecto_de_sonido.mp3"/>
          </audio>
        </div>
      </div>
      <!--  END CONTENT AREA  -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Blockly from "blockly";
import AppHeader from "../../../components/AppHeader.vue";
import AppSidebar from "../../../components/AppSidebar.vue";
import BlocklyComponentParte from "../../../components/BlocklyComponentParte.vue";
import "../../../blocks/stocks";
import BlocklyJS from "blockly/javascript";
export default {
  components: { AppHeader, AppSidebar, BlocklyComponentParte },
  name: "Activity",
  data() {
    return {
      options: {
        scrollbars: true,
      },
      code: "",
      primIMG: "",
      valido: 0,
      activityDone: 0,
      instructions: "",
      instructions_audio: "",
    };
  },
  methods: {
            playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
        runcodeDos.disabled = true;
      }

      jQuery.fn.extend({
        resaltar: function (busqueda, claseCSSbusqueda) {
          var regex = new RegExp("(<[^>]*>)|(" + busqueda.replace(/([-.*+?^${}()|[\]\/\\])/g, "\\$1") +")", "ig");
          var nuevoHtml = this.html(
            this.html().replace(regex, function (a, b, c) {
              return a.charAt(0) == "<" ? a : '<span class="' + claseCSSbusqueda + '">' + c + "</span>";
            })
          );
          return nuevoHtml;
        },
      });

      var instrucciones = this.instructions;
      var longitud = this.instructions.length;
      var subvalor = Math.ceil(longitud / 5);
      var cadena1 = instrucciones.substr(0, subvalor);
      var cadena2 = instrucciones.substr(subvalor, subvalor);
      var cadena3 = instrucciones.substr(subvalor * 2, subvalor);
      var cadena4 = instrucciones.substr(subvalor * 3, subvalor);
      var cadena5 = instrucciones.substr(subvalor * 4, subvalor);
      var sound_delay ='300|900|1600|2500|3000|3200'
      var arreglo = sound_delay.split("|");
      let t1 = arreglo[0]
      let t2 = arreglo[1]
      let t3 = arreglo[2]
      let t4 = arreglo[3]
      let t5 = arreglo[4]
      let t6 = arreglo[5]
      
      
      setTimeout(function () {$("#texto").resaltar(cadena1, "resaltarTexto"); }, t1);
      setTimeout(function () {$("#texto").resaltar(cadena2, "resaltarTexto"); }, t2);
      setTimeout(function () {$("#texto").resaltar(cadena3, "resaltarTexto"); }, t3);
      setTimeout(function () {$("#texto").resaltar(cadena4, "resaltarTexto"); }, t4);
      setTimeout(function () {$("#texto").resaltar(cadena5, "resaltarTexto"); }, t5);
      setTimeout(function () {$("#texto").resaltar(cadena1, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena2, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena3, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena4, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena5, "quitarresaltarTexto"); }, t6);
      setTimeout(() => {runcodeDos.disabled = false;}, t6)
    },
    runCode(event) {
      const primaryEvent = event.toJson();
      //console.log(primaryEvent);
      let blocks = this.$refs["blockly1"].workspace.getAllBlocks();

      if(blocks.length > 0){
                $("#puzzlePayasoA").hide()
                $("#puzzlePayasoB").hide()
                $("#puzzlePayasoC").hide()
                $("#resultado").hide()
                if(blocks.length == 1){
                    this.primIMG = blocks[0].type;
                    $("#" + blocks[0].type).show();
                    this.activityDone = 0
                }else if (blocks.length == 2){
                  if (blocks[0].childBlocks_.length > 0){
                    blocks.forEach((element) => {
                    $("#" + element.type).show();
                    this.activityDone = 0
                    });
                  }else{
                    $("#" + blocks[0].type).show();
                    this.activityDone = 0
                  }
                }else if(blocks.length == 3 && blocks[0].childBlocks_.length > 0
                && blocks[0].childBlocks_[0].childBlocks_.length == 0){
                  if (blocks[0].type == "puzzlePayasoA"){
                    if(blocks[0].childBlocks_[0].type == "puzzlePayasoB"){
                      $("#puzzlePayasoA").show()
                      $("#puzzlePayasoB").show()
                      this.activityDone = 0
                    }else if(blocks[0].childBlocks_[0].type == "puzzlePayasoC"){
                      $("#puzzlePayasoA").show()
                      $("#puzzlePayasoC").show()
                      this.activityDone = 0
                    }   
                  }else if(blocks[0].type == "puzzlePayasoB"){
                    if(blocks[0].childBlocks_[0].type == "puzzlePayasoC"){
                      $("#puzzlePayasoB").show()
                      $("#puzzlePayasoC").show()
                      this.activityDone = 0
                    }   
                  }
                }else if(blocks.length == 3 && blocks[1].childBlocks_.length > 0
                && blocks[0].childBlocks_.length == 0){
                    if(blocks[1].type == "puzzlePayasoB"){
                    if(blocks[1].childBlocks_[0].type == "puzzlePayasoC"){
                      $("#puzzlePayasoB").show()
                      $("#puzzlePayasoC").show()
                      this.activityDone = 0
                    }   
                  }
                }else if(blocks.length == 3 && blocks[0].childBlocks_.length > 0 &&
                blocks[0].childBlocks_[0].childBlocks_.length >0 && blocks[0].type == "puzzlePayasoA"
                && blocks[0].childBlocks_[0].type == "puzzlePayasoB" 
                && blocks[0].childBlocks_[0].childBlocks_[0].type == "puzzlePayasoC"){
                $("#puzzlePayasoA").hide()
                $("#puzzlePayasoB").hide()
                $("#puzzlePayasoC").hide()
                $("#resultado").show()
                   if (primaryEvent.element == 'dragStop'){
                this.activityDone = 1
                audioInst.play()
                }
                }else if(blocks.length == 3 && blocks[0].childBlocks_.length == 0 
                 && blocks[1].childBlocks_.length == 0){
                  $("#puzzlePayasoA").hide()
                $("#puzzlePayasoB").hide()
                $("#puzzlePayasoC").hide()
                $("#"+this.primIMG).show()
                this.activityDone = 0               
                }
            }else{
                $("#puzzlePayasoA").hide()
                $("#puzzlePayasoB").hide()
                $("#puzzlePayasoC").hide()
                $("#resultado").hide()
                this.activityDone = 0
            }


    /*  if (primaryEvent.type == "ui" && primaryEvent.element == "dragStop") {
        //setTimeout(() => {
        if (blocks.length == 0) {
          $("#puzzlePayasoA").hide();
          $("#puzzlePayasoB").hide();
          $("#puzzlePayasoC").hide();
        }
        blocks.forEach((element) => {
          if (
            element.type == "puzzlePayasoA" &&
            this.$refs["blockly1"].workspace.getAllBlocks().length == 3
          ) {
            if (element.childBlocks_.length > 0) {
              if (element.childBlocks_[0].type == "puzzlePayasoB") {
                this.valido++;
              }
            } else {
              this.valido = 0;
            }
          }

          if (
            element.type == "puzzlePayasoB" &&
            this.$refs["blockly1"].workspace.getAllBlocks().length == 3
          ) {
            if (element.childBlocks_.length > 0) {
              if (element.childBlocks_[0].type == "puzzlePayasoC") {
                this.valido++;
              }
            } else {
              this.valido = 0;
            }
          }

          if (
            element.type == "puzzlePayasoC" &&
            this.$refs["blockly1"].workspace.getAllBlocks().length == 3
          ) {
            if (element.parentBlock_) {
              if (element.parentBlock_.type == "puzzlePayasoB") {
                this.valido++;
              } else {
                this.valido = 0;
              }
            }
          }

          if (this.valido == 3) {
           // $("#puzzlePayasoA").hide();
            //$("#puzzlePayasoB").hide();
            //$("#puzzlePayasoC").hide();
            //$("#resultado").show();
          } 
        });
        // }, 1000);
      }*/

    },

      trackInstructions() {
    audio.play();
  },
  },
  //},
  //},
  mounted() {
    $(document).ready(function () {
      App.init();
    });

    Fire.$emit('setBreadcrumbs', { world: 'La Ciudad', subWorld: 'El circo', world_id: 1 })

    this.$refs["blockly1"].workspace.addChangeListener(this.runCode);
  },
  async evaluate() {
            const model = {
                activity_id: 1,
                person_id: localStorage.idPerson,
                activation_id: localStorage.idActivation,
                opportunity_number: 1,
                score: 100
            }

            if(this.activityDone == 1) {
                await axios.post(apiETC + 'api/smartc3/evaluate-activity', model)
                .then( (response) => {
                    if(response.data.status == true) {
                        alert(response.data.message)
                        router.push('/activity-tent?idQuestion=25823')
                    }
                })
            } else {
                alert('Debes de resolver la actividad antes de enviar tu resultado')
            }

        },

};
</script>
<style>
#blockly1 {
  position: absolute;
  left: 120;
  width: 100%;
  height: 100%;
}

#puzzlePayasoA {
  background-image: url("../../../assets/img/circo/payaso_top.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 130px;
  width: 220px;
  top: 10px;
  left: 130px;
}
#puzzlePayasoB {
  background-image: url("../../../assets/img/circo/payaso_middle.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 130px;
  width: 220px;
  top: 0px;
  left: 130px;
}
#puzzlePayasoC {
  background-image: url("../../../assets/img/circo/payaso_bottom.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 130px;
  width: 220px;
  top: -10px;
  left: 130px;
}

#resultado {
  background-image: url("../../../assets/img/circo/payaso_animado.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 390px;
  width: 220px;
  top: 10px;
  left: 130px;
}
#mark {
  background-color: blue;
}

.quitarresaltarTexto {
 color: #070707;
 background-color: #fffffd;
}

.resaltarTexto{
    color: #F3AA04;
    background-color: #FFFF96;
}
</style>
