/* eslint-disable */

function setHit(gifToPlay, timeToPlay) {
  var imgurl='';
  if (window.avatarType == 2) {
    imgurl = '../assets/img/personaje/crayolin/crayolin_frente_verde.gif';
  }else{
    imgurl ='../assets/img/personaje/frenteparado.gif';
  }

  const code = `
    setTimeout(function(){
      $("#character").css("background-image","url('${gifToPlay}')");
    }, ${timeToPlay + 500});
    
    setTimeout(function(){
      audioInst.play()
    }, ${timeToPlay + 500});
    
    setTimeout(function(){
      $("#character").css("background-image","url('${imgurl}?a=${Math.random()}')");
    }, ${timeToPlay + 1000});`;

  return code;
};

window.drawLine = function (ctx, begin, end, stroke = 'black', width = 1) {

  if (stroke) {
    ctx.strokeStyle = stroke;
  }

  if (width) {
    ctx.lineWidth = width;
  }
  ctx.beginPath();
  ctx.moveTo(...begin);
  ctx.lineTo(...end);
  ctx.stroke();
};

function avatarMovePosition(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder = false) {
  let code = ``; let drawLineCode = ``; let movePixels = 0;
  window.currentBlock = ''; let codeHit = ``; let directionTo = ''; let secondDirectionTo = '';
  window.currentBlockColor = '';

  //recorremos cuantas posiciones vamos a recorrer
  for (let index = 1; index <= positionsToMove; index++) {
    if (direction == 'right') {
      window.positx++;
      directionTo = 'left';
      window.newOrientation = "DP";
      window.linePositionX += animationPixels;
    }
    if (direction == 'left') {
      window.positx--;
      directionTo = 'left'
      window.newOrientation = "IP";
      window.linePositionX -= animationPixels;
    }
    if (direction == 'down') {
      window.posity++;
      directionTo = 'top';
      window.newOrientation = "FP";
      window.linePositionY += animationPixels;
    }
    if (direction == 'up') {
      window.posity--;
      directionTo = 'top';
      window.newOrientation = "EP";
      window.linePositionY -= animationPixels;
    }
    if (direction == 'southeast') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "IP";
      window.linePositionX += animationPixels;
      window.linePositionY += animationPixels;
    }
    if (direction == 'northwest') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "DP";
      window.linePositionX -= animationPixels;
      window.linePositionY -= animationPixels;
    }
    if (direction == 'northeast') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "IP";
      window.linePositionX += animationPixels;
      window.linePositionY -= animationPixels;
    }
    if (direction == 'southwest') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "DP";
      window.linePositionX -= animationPixels;
      window.linePositionY += animationPixels;
    }

    window.currentBlock = window.positx + ',' + window.posity;
    //si esta dentro del escenario
    if (window.positx <= maxPositions && window.positx >= 0 && window.posity <= maxPositions && window.posity >= 0) {
      if (direction == 'right' || direction == 'down' || direction == 'southeast' || direction == 'southwest')
        movePixels += animationPixels;
      if (direction == 'left' || direction == 'up' || direction == 'northwest' || direction == 'northeast')
        movePixels -= animationPixels;
      // si la posición es válida
      if (!window.blocksNotAllowed.includes(window.currentBlock)) {
        //revisamos si en la posición se encuentra algún objetivo
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo" && gem.postx == window.positx && gem.posty == window.posity && gem.bCorrect == null) {
            window.goalscollected++; //incrementamos el número de objetivos logrados
            window.xyz++;
            window.coordenada = window.coordenada.concat(`${positx},${posity}|`);
            gem.bCorrect = true;
            window.namediv = '(' + gem.Type.substr(-1) + ')';
            window.figeometrico = gem.Type;
          }
        });
        //choque si hay objetos dentro del tablero
      } else {

        if (window.avatarType == 2) {
          if (direction == 'right' || direction == 'northwest' || direction == 'southwest')
            code += setHit(`../assets/img/personaje/crayolin/crayolin_choque_verde.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
          if (direction == 'left' || direction == 'southeast' || direction == 'northeast')
            code += setHit(`../assets/img/personaje/crayolin/crayolin_choque_verde.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
          if (direction == 'down')
            code += setHit(`../assets/img/personaje/crayolin/crayolin_choque_verde.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
          if (direction == 'up')
            code += setHit(`../assets/img/personaje/crayolin/crayolin_choque_verde.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
          window.invalid = 1;
        }else{
          if (direction == 'right' || direction == 'northwest' || direction == 'southwest')
            code += setHit(`../assets/img/personaje/choquederecha.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
          if (direction == 'left' || direction == 'southeast' || direction == 'northeast')
            code += setHit(`../assets/img/personaje/choqueizquierda.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
          if (direction == 'down')
            code += setHit(`../assets/img/personaje/choquefrente.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
          if (direction == 'up')
            code += setHit(`../assets/img/personaje/choquearriba.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
          window.invalid = 1;

        }

   
      }
    }
  }

  let codeAnimate = ``;
  // si se mueve arriba, abajo, izquierda o derecha
  if (!direction.includes('east') && !direction.includes('west') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    }
  // si se mueve sureste, noroeste
  if ((direction == 'southeast' || direction == 'northwest') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixels}px", "${secondDirectionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px", "${secondDirectionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    }
  if ((direction == 'northeast' || direction == 'southwest') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixels}px", "${secondDirectionTo}": "-=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px", "${secondDirectionTo}": "-=${movePixels}px"}, ${timeToPlay});`;
    }

  if (isDrawing && window.invalid == 0) {
    drawLineCode = `
        setTimeout(function() {
          window.drawLine(window.ctxCanvas, [${window.currentLinePositionX}, ${window.currentLinePositionY}], [${window.linePositionX}, ${window.linePositionY}], "green", 5);
        }, ${window.currentTime + 1000});
      `;

    if (direction == 'right' || direction == 'left' || direction == 'southeast' || direction == 'northwest' || direction == 'northeast' || direction == 'southwest')
      window.currentLinePositionX = window.linePositionX;
    if (direction == 'down' || direction == 'up' || direction == 'southeast' || direction == 'northwest' || direction == 'northeast' || direction == 'southwest')
      window.currentLinePositionY = window.linePositionY;
  }

  code += `
      setTimeout(function(){
        window.blocks[${blockPosition}].select();
      }, ${window.currentTime});
  
      setTimeout(function(){
        $("#character").css("background-image","url('${gifToPlay}')");
        ${codeAnimate}
      }, ${window.currentTime});
      
      ${drawLineCode}`;

  window.currentTime = parseInt(window.currentTime) + parseInt(timeToPlay);

  //si el avatar se sale del escenario
  if ((window.positx > maxPositions || window.positx < 0) && (direction == 'right' || direction == 'left')) {
    if (window.avatarType == 2) {
      if (direction == 'right')
        code += setHit(`../assets/img/personaje/crayolin/crayolin_choque_verde.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
      if (direction == 'left')
        code += setHit(`../assets/img/personaje/crayolin/crayolin_choque_verde.gif?a=${Math.random()}`, window.currentTime + timeToPlay);

    }else{
      if (direction == 'right')
        code += setHit(`../assets/img/personaje/choquederecha.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
      if (direction == 'left')
        code += setHit(`../assets/img/personaje/choqueizquierda.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
    }
   
  }

  if ((window.posity > maxPositions || window.posity < 0) && (direction == 'down' || direction == 'up')) {
    if (window.avatarType == 2) {
      if (direction == 'down')
        code += setHit(`../assets/img/personaje/crayolin/crayolin_choque_verde.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
      if (direction == 'up')
        code += setHit(`../assets/img/personaje/crayolin/crayolin_choque_verde.gif?a=${Math.random()}`, window.currentTime + timeToPlay);

    } else {
      if (direction == 'down')
        code += setHit(`../assets/img/personaje/choquefrente.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
      if (direction == 'up')
        code += setHit(`../assets/img/personaje/choquearriba.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
    }
  }
  //console.log(window.avatarType);
  var Ac1 = '';
  if (window.totalGoals == window.goalscollected) {
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed && checkOrder) {
      //Fire.$emit('activityDone');
      if (window.avatarType == null || window.avatarType == 0)
        Ac1 = 1;
      if (window.avatarType == 2)
        Ac1 = 2;
      if (window.avatarType == 3)
        Ac1 = 3;
    }
    if (!checkOrder)
      //Fire.$emit('activityDone');
      if (window.avatarType == null || window.avatarType == 0)
        Ac1 = 1;
    if (window.avatarType == 2)
      Ac1 = 2;
    if (window.avatarType == 3)
      Ac1 = 3;
  }
  console.log(Ac1);

  if (Ac1 == 1) {
    Fire.$emit('activityDone');
    code += `
      setTimeout(function(){
          window.blocks[${blockPosition}].select();
          $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); 
      }, ${window.currentTime + 500}); `;
  } else if (Ac1 == 2) {
    Fire.$emit('activityDone');
    code += `
      setTimeout(function(){
          window.blocks[${blockPosition}].select();
          $("#character").css("background-image","url('../assets/img/personaje/crayolin/crayolin_frente_verde.gif')"); 
      }, ${window.currentTime + 500}); `;

  } else if (Ac1 == 3) {
    Fire.$emit('activityDone');
    code += `
        setTimeout(function(){
            window.blocks[${blockPosition}].select();
            $("#character3").css("background-image","url('../assets/img/personaje/robot/frenteparado.gif')"); 
        }, ${window.currentTime + 500}); `;
  }
  /*
    if(window.totalGoals == window.goalscollected) {
      if(window.avatarType == null){
              if(window.coordenada.slice(0,-1) == window.blocksYesAllowed && checkOrder){
                  Fire.$emit('activityDone');
                  code += `
                  setTimeout(function(){
                      window.blocks[${blockPosition}].select();
                      $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); 
                  }, ${window.currentTime + 500}); `;
              }
      }else if(window.avatarType == 2){
         if(window.coordenada.slice(0,-1) == window.blocksYesAllowed && checkOrder){
            Fire.$emit('activityDone');
            code += `
            setTimeout(function(){
                window.blocks[${blockPosition}].select();
                $("#character").css("background-image","url('../assets/img/personaje/crayolin/crayolin_frente_verde.gif')"); 
            }, ${window.currentTime + 500}); `;
        }
      }else if(window.avatarType == 3){
         if(window.coordenada.slice(0,-1) == window.blocksYesAllowed && checkOrder){
                  Fire.$emit('activityDone');
                  code += `
                  setTimeout(function(){
                      window.blocks[${blockPosition}].select();
                      $("#character").css("background-image","url('../assets/img/personaje/robot/frenteparado.gif')"); 
                  }, ${window.currentTime + 500}); `;
              }

      }
     if(!checkOrder)
          Fire.$emit('activityDone'); 
      console.log(window.avatarType);
      
    }
    */
  console.log('arrayColors', window.arrayColors);
  return code;
};

window.rola = function (nota) {


  var audio = '';
  var tono = '';
  if (nota == 1) {
    tono = "C";
  } else if (nota == 2 || nota == 9) {
    tono = "D";
  } else if (nota == 3 || nota == 10) {
    tono = "E";
  } else if (nota == 4) {
    tono = "F";
  } else if (nota == 5) {
    tono = "G";
  } else if (nota == 6) {
    tono = "A";
  } else if (nota == 7) {
    tono = "B";
  } else if (nota == 8) {
    tono = "C2";
  }

  $("#" + tono + "_active").css("display", "block");
  $("#little_note" + tono).css("display", "block");

  currentTime = parseInt(750);
  setTimeout(() => {
    $("#" + tono + "_active").css("display", "none");
    $("#little_note" + tono).css("display", "none");
  }, currentTime);

  audio = new Audio("../assets/gifs/silofano/" + tono + ".mp3");
  audio.volume = 1;
  audio.playbackRate = 1;
  audio.currentTime = 0;
  audio.play();


};

window.imgs = function (imagens, nActiviti, Fig) {
  var color = '';
  var activo = ''; let currentBlockColor = [];
  if (imagens == 1) { color = 'azul'; }
  if (imagens == 2) { color = 'verde'; }
  if (imagens == 3) { color = 'rojo'; }
  if (imagens == 4) { color = 'amarillo'; }
  if (Fig == 1) {
    $('#triangulo_amarillo_animated' + nActiviti).css("display", "none");
    $('#triangulo_azul_animated' + nActiviti).css("display", "none");
    $('#triangulo_rojo_animated' + nActiviti).css("display", "none");
    $('#triangulo_verde_animated' + nActiviti).css("display", "none");
    var img = new Image();
    img.src = '../assets/gifs/geometricas/triangulo_' + color + '_animated.gif';
    $('#triangulo_' + color + '_animated' + nActiviti).css('background-image', 'url("' + img.src + '?x=' + Date.now() + '")');
    $('#triangulo_' + color + '_animated' + nActiviti).css("display", "block");
  }
  if (Fig == 2) {
    $('#cuadrado_amarillo_animated' + nActiviti).css("display", "none");
    $('#cuadrado_azul_animated' + nActiviti).css("display", "none");
    $('#cuadrado_rojo_animated' + nActiviti).css("display", "none");
    $('#cuadrado_verde_animated' + nActiviti).css("display", "none");
    var img = new Image();
    img.src = '../assets/gifs/geometricas/cuadrado_' + color + '_animated.gif';
    $('#cuadrado_' + color + '_animated' + nActiviti).css('background-image', 'url("' + img.src + '?x=' + Date.now() + '")');
    $('#cuadrado_' + color + '_animated' + nActiviti).css("display", "block");

  }
  if (Fig == 3) {
    $('#azul_animated' + nActiviti).css("display", "none");
    $('#verde_animated' + nActiviti).css("display", "none");
    $('#rojo_animated' + nActiviti).css("display", "none");
    $('#amarillo_animated' + nActiviti).css("display", "none");
    var img = new Image();
    img.src = '../assets/gifs/geometricas/' + color + '_animated.gif';
    $('#' + color + '_animated' + nActiviti).css('background-image', 'url("' + img.src + '?x=' + Date.now() + '")');
    $('#' + color + '_animated' + nActiviti).css("display", "block");
  }

}

function avatarCalculateDirection(position, degrees, direction) {
  window.lastTurn = direction;
 
  if (position == "DP") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ESE15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ESE30';
      if (degrees == 45)
        window.newOrientation = 'SE';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSE60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSE75';
      if (degrees == 90)
        window.newOrientation = 'FP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSW75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSW60';
      if (degrees == 135)
        window.newOrientation = 'SW';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WSW30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WSW15';
      if (degrees == 180)
        window.newOrientation = 'IP';
      if (degrees > 180 && degrees < 196)
      window.newOrientation = 'WNW15';
      if (degrees > 195 && degrees < 225)
      window.newOrientation = 'WNW30';
      if (degrees == 225)
      window.newOrientation = 'NW';
      if (degrees > 225 && degrees < 241)
      window.newOrientation = 'NNW60';
      if (degrees > 240 && degrees < 270)
      window.newOrientation = 'NNW75';
      if (degrees == 270)
        window.newOrientation = 'EP';
      if (degrees > 270 && degrees < 286)
      window.newOrientation = 'NNE75';
      if (degrees > 285 && degrees < 315)
      window.newOrientation = 'NNE60';
      if (degrees == 315)
      window.newOrientation = 'NE';
      if (degrees > 315 && degrees < 331)
      window.newOrientation = 'ENE30';
      if (degrees > 331 && degrees < 355)
      window.newOrientation = 'ENE15';
      if (degrees == 0)
        window.newOrientation = 'DP';
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ENE15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ENE30';
      if (degrees == 45)
        window.newOrientation = 'NE';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNE60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNE75';
      if (degrees == 90)
        window.newOrientation = 'EP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNW75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNW60';
      if (degrees == 135)
        window.newOrientation = 'NW';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WNW45';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WNW15';
      if (degrees == 180)
        window.newOrientation = 'IP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WSW15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WSW30';
      if (degrees == 225)
        window.newOrientation = 'SW';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSW60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSW75';
      if (degrees == 270)
        window.newOrientation = 'FP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSE75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSE60';
      if (degrees == 315)
        window.newOrientation = 'SE';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ESE30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ESE15';
      if (degrees == 0)
        window.newOrientation = 'DP';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "ENE15") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'DP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ESE15';
      if (degrees == 45)
        window.newOrientation = 'ESE30';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'SE';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSE60';
      if (degrees == 90)
        window.newOrientation = 'SSE75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'FP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSW75';
      if (degrees == 135)
        window.newOrientation = 'SSW60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SW';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WSW30';
      if (degrees == 180)
        window.newOrientation = 'WSW15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'IP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WNW15';
      if (degrees == 225)
        window.newOrientation = 'WNW30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NW';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNW60';
      if (degrees == 270)
        window.newOrientation = 'NNW75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'EP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNE75';
      if (degrees == 315)
        window.newOrientation = 'NNE60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NE';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ENE30';
      if (degrees == 0)
        window.newOrientation = 'ENE15';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ENE30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NE';
      if (degrees == 45)
        window.newOrientation = 'NNE60';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'NNE75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'EP';
      if (degrees == 90)
        window.newOrientation = 'NNW75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNW60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NW';
      if (degrees == 135)
        window.newOrientation = 'WNW30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WNW15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'IP';
      if (degrees == 180)
        window.newOrientation = 'WSW15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WSW30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SW';
      if (degrees == 225)
        window.newOrientation = 'SSW60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSW75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'FP';
      if (degrees == 270)
        window.newOrientation = 'SSE75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSE60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SE';
      if (degrees == 315)
        window.newOrientation = 'ESE30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ESE15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'DP';
      if (degrees == 0)
        window.newOrientation = 'ENE15';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "ENE30") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ENE15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'DP';
      if (degrees == 45)
        window.newOrientation = 'ESE15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ESE30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SE';
      if (degrees == 90)
        window.newOrientation = 'SSE60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSE75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'FP';
      if (degrees == 135)
        window.newOrientation = 'SSW75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSW60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SW';
      if (degrees == 180)
        window.newOrientation = 'WSW30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WSW15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'IP';
      if (degrees == 225)
        window.newOrientation = 'WNW15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WNW30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NW';
      if (degrees == 270)
        window.newOrientation = 'NNW60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNW75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'EP';
      if (degrees == 315)
        window.newOrientation = 'NNE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNE60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NE';
      if (degrees == 0)
        window.newOrientation = 'ENE30';
     
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NE';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNE60';
      if (degrees == 45)
        window.newOrientation = 'NNE75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'EP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNW75';
      if (degrees == 90)
        window.newOrientation = 'NNW60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NW';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WNW30';
      if (degrees == 135)
        window.newOrientation = 'WNW15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'IP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WSW15';
      if (degrees == 180)
        window.newOrientation = 'WSW30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SW';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSW60';
      if (degrees == 225)
        window.newOrientation = 'SSW75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'FP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE75';
      if (degrees == 270)
        window.newOrientation = 'SSE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SE';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ESE30';
      if (degrees == 315)
        window.newOrientation = 'ESE15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'DP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ENE15';
      if (degrees == 0)
        window.newOrientation = 'ENE30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NE") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ENE30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ENE15';
      if (degrees == 45)
        window.newOrientation = 'DP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ESE15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ESE30';
      if (degrees == 90)
        window.newOrientation = 'SE';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSE60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSE75';
      if (degrees == 135)
        window.newOrientation = 'FP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSW75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSW60';
      if (degrees == 180)
        window.newOrientation = 'SW';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WSW30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WSW15';
      if (degrees == 225)
        window.newOrientation = 'IP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WNW15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WNW30';
      if (degrees == 270)
        window.newOrientation = 'NW';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNW60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNW75';
      if (degrees == 315)
        window.newOrientation = 'EP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNE75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNE60';
      if (degrees == 0)
        window.newOrientation = 'NE';
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNE60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNE75';
      if (degrees == 45)
        window.newOrientation = 'EP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNW75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNW60';
      if (degrees == 90)
        window.newOrientation = 'NW';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WNW30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WNW15';
      if (degrees == 135)
        window.newOrientation = 'IP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WSW15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WSW30';
      if (degrees == 180)
        window.newOrientation = 'SW';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSW60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSW75';
      if (degrees == 225)
        window.newOrientation = 'FP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE60';
      if (degrees == 270)
        window.newOrientation = 'SE';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ESE30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ESE15';
      if (degrees == 315)
        window.newOrientation = 'DP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ENE15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ENE30';
      if (degrees == 0)
        window.newOrientation = 'NE';
      degrees *= -1
    }
    return degrees;
  }
  if (position == "NNE60") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NE';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ENE30';
      if (degrees == 45)
        window.newOrientation = 'ENE15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'DP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ESE15';
      if (degrees == 90)
        window.newOrientation = 'ESE30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SE';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSE60';
      if (degrees == 135)
        window.newOrientation = 'SSE75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'FP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSW75';
      if (degrees == 180)
        window.newOrientation = 'SSW60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SW';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WSW30';
      if (degrees == 225)
        window.newOrientation = 'WSW15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'IP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WNW15';
      if (degrees == 270)
        window.newOrientation = 'WNW30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NW';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNW60';
      if (degrees == 315)
        window.newOrientation = 'NNW75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'EP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNE75';
      if (degrees == 0)
        window.newOrientation = 'NNE60';
     
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNE75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'EP';
      if (degrees == 45)
        window.newOrientation = 'NNW75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNW60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NW';
      if (degrees == 90)
        window.newOrientation = 'WNW30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WNW15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'IP';
      if (degrees == 135)
        window.newOrientation = 'WSW15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WSW30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SW';
      if (degrees == 180)
        window.newOrientation = 'SSW60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSW75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'FP';
      if (degrees == 225)
        window.newOrientation = 'SSE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SE';
      if (degrees == 270)
        window.newOrientation = 'ESE30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ESE15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'DP';
      if (degrees == 315)
        window.newOrientation = 'ENE15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ENE30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NE';
      if (degrees == 0)
        window.newOrientation = 'NNE60';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NNE75") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNE60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NE';
      if (degrees == 45)
        window.newOrientation = 'ENE30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ENE15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'DP';
      if (degrees == 90)
        window.newOrientation = 'ESE15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ESE30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SE';
      if (degrees == 135)
        window.newOrientation = 'SSE60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSE75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'FP';
      if (degrees == 180)
        window.newOrientation = 'SSW75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSW60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SW';
      if (degrees == 225)
        window.newOrientation = 'WSW30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WSW15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'IP';
      if (degrees == 270)
        window.newOrientation = 'WNW15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WNW30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NW';
      if (degrees == 315)
        window.newOrientation = 'NNW60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNW75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'EP';
      if (degrees == 0)
        window.newOrientation = 'NNE75';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'EP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNW75';
      if (degrees == 45)
        window.newOrientation = 'NNW60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NW';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WNW30';
      if (degrees == 90)
        window.newOrientation = 'WNW15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'IP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WSW15';
      if (degrees == 135)
        window.newOrientation = 'WSW30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SW';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSW60';
      if (degrees == 180)
        window.newOrientation = 'SSW75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'FP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSE75';
      if (degrees == 225)
        window.newOrientation = 'SSE60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SE';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ESE30';
      if (degrees == 270)
        window.newOrientation = 'ESE15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'DP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ENE15';
      if (degrees == 315)
        window.newOrientation = 'ENE30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NE';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNE15';
      if (degrees == 0)
        window.newOrientation = 'NNE30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "EP") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNE75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNE60';
      if (degrees == 45)
        window.newOrientation = 'NE';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ENE30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ENE15';
      if (degrees == 90)
        window.newOrientation = 'DP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ESE15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ESE30';
      if (degrees == 135)
        window.newOrientation = 'SE';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSE60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSE75';
      if (degrees == 180)
        window.newOrientation = 'FP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSW75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSW60';
      if (degrees == 225)
        window.newOrientation = 'SW';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WSW30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WSW15';
      if (degrees == 270)
        window.newOrientation = 'IP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WNW15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WNW30';
      if (degrees == 315)
        window.newOrientation = 'NW';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNW60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNW75';
      if (degrees == 0)
        window.newOrientation = 'EP';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNW75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNW60';
      if (degrees == 45)
        window.newOrientation = 'NW';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WNW30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WNW15';
      if (degrees == 90)
        window.newOrientation = 'IP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WSW15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WSW30';
      if (degrees == 135)
        window.newOrientation = 'SW';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSW60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSW75';
      if (degrees == 180)
        window.newOrientation = 'FP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSE75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSE60';
      if (degrees == 225)
        window.newOrientation = 'SE';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ESE30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ESE15';
      if (degrees == 270)
        window.newOrientation = 'DP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ENE15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ENE30';
      if (degrees == 315)
        window.newOrientation = 'NE';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNE60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNE75';
      if (degrees == 0)
        window.newOrientation = 'EP';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NNW75") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'FP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNE75';
      if (degrees == 45)
        window.newOrientation = 'NNE60';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'NE';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ENE30';
      if (degrees == 90)
        window.newOrientation = 'ENE15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'DP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ESE15';
      if (degrees == 135)
        window.newOrientation = 'ESE30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SE';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSE60';
      if (degrees == 180)
        window.newOrientation = 'SSE75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'FP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSW75';
      if (degrees == 225)
        window.newOrientation = 'SSW60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SW';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WSW30';
      if (degrees == 270)
        window.newOrientation = 'WSW15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'IP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WNW15';
      if (degrees == 315)
        window.newOrientation = 'WNW30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NW';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNW60';
      if (degrees == 0)
        window.newOrientation = 'NNW75';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNW60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NW';
      if (degrees == 45)
        window.newOrientation = 'WNW30';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'WNW15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'IP';
      if (degrees == 90)
        window.newOrientation = 'WSW15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WSW30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SW';
      if (degrees == 135)
        window.newOrientation = 'SSW60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSW75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'FP';
      if (degrees == 180)
        window.newOrientation = 'SSE75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSE60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SE';
      if (degrees == 225)
        window.newOrientation = 'ESE30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ESE15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'DP';
      if (degrees == 270)
        window.newOrientation = 'ENE15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ENE30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NE';
      if (degrees == 315)
        window.newOrientation = 'NNE60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNE75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'EP';
      if (degrees == 0)
        window.newOrientation = 'NNW75';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NNW60") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNW75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'EP';
      if (degrees == 45)
        window.newOrientation = 'NNE75';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'NNE60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NE';
      if (degrees == 90)
        window.newOrientation = 'ENE30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ENE15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'DP';
      if (degrees == 135)
        window.newOrientation = 'ESE15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ESE30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SE';
      if (degrees == 180)
        window.newOrientation = 'SSE60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSE75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'FP';
      if (degrees == 225)
        window.newOrientation = 'SSW75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSW60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SW';
      if (degrees == 270)
        window.newOrientation = 'WSW30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WSW15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'IP';
      if (degrees == 315)
        window.newOrientation = 'WNW15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WNW30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NW';
      if (degrees == 0)
        window.newOrientation = 'NNW60';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NW';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WNW30';
      if (degrees == 45)
        window.newOrientation = 'WNW15';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'IP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WSW15';
      if (degrees == 90)
        window.newOrientation = 'WSW30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SW';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSW60';
      if (degrees == 135)
        window.newOrientation = 'SSW75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'FP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSE75';
      if (degrees == 180)
        window.newOrientation = 'SSE60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SE';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ESE30';
      if (degrees == 225)
        window.newOrientation = 'ESE15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'DP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ENE15';
      if (degrees == 270)
        window.newOrientation = 'ENE30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NE';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNE60';
      if (degrees == 315)
        window.newOrientation = 'NNE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'EP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNW75';
      if (degrees == 0)
        window.newOrientation = 'NNW60';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NW") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNW60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNW75';
      if (degrees == 45)
        window.newOrientation = 'EP';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'NNE75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNE60';
      if (degrees == 90)
        window.newOrientation = 'NE';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ENE30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ENE15';
      if (degrees == 135)
        window.newOrientation = 'DP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ESE15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ESE30';
      if (degrees == 180)
        window.newOrientation = 'SE';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSE60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSE75';
      if (degrees == 225)
        window.newOrientation = 'FP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE60';
      if (degrees == 270)
        window.newOrientation = 'SW';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WSW30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WSW15';
      if (degrees == 315)
        window.newOrientation = 'IP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WNW15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WNW30';
      if (degrees == 0)
        window.newOrientation = 'NW';
   
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WNW30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WNW15';
      if (degrees == 45)
        window.newOrientation = 'IP';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'WSW15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WSW30';
      if (degrees == 90)
        window.newOrientation = 'SW';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSW60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSW75';
      if (degrees == 135)
        window.newOrientation = 'FP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSE75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSE60';
      if (degrees == 180)
        window.newOrientation = 'SE';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ESE30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ESE15';
      if (degrees == 225)
        window.newOrientation = 'DP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ENE15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ENE30';
      if (degrees == 270)
        window.newOrientation = 'NE';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNE60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNE75';
      if (degrees == 315)
        window.newOrientation = 'EP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNW75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNW60';
      if (degrees == 0)
        window.newOrientation = 'NW';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "WNW30") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NW';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNW60';
      if (degrees == 45)
        window.newOrientation = 'NNW75';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'EP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNE75';
      if (degrees == 90)
        window.newOrientation = 'NNE60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NE';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ENE30';
      if (degrees == 135)
        window.newOrientation = 'ENE15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'DP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ESE15';
      if (degrees == 180)
        window.newOrientation = 'ESE30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SE';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSE60';
      if (degrees == 225)
        window.newOrientation = 'SSE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'FP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE75';
      if (degrees == 270)
        window.newOrientation = 'SSE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SW';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WSW30';
      if (degrees == 315)
        window.newOrientation = 'WSW15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'IP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WNW15';
      if (degrees == 0)
        window.newOrientation = 'WNW30';
     
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WNW15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'IP';
      if (degrees == 45)
        window.newOrientation = 'WSW15';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'WSW30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SW';
      if (degrees == 90)
        window.newOrientation = 'SSW60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSW75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'FP';
      if (degrees == 135)
        window.newOrientation = 'SSE75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSE60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SE';
      if (degrees == 180)
        window.newOrientation = 'ESE30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ESE15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'DP';
      if (degrees == 225)
        window.newOrientation = 'ENE15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ENE30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NE';
      if (degrees == 270)
        window.newOrientation = 'NNE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNE75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'EP';
      if (degrees == 315)
        window.newOrientation = 'NNW75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNW60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NW';
      if (degrees == 0)
        window.newOrientation = 'WNW30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "WNW15") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WNW30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NW';
      if (degrees == 45)
        window.newOrientation = 'NNW60';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'NNW75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'EP';
      if (degrees == 90)
        window.newOrientation = 'NNE75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNE60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NE';
      if (degrees == 135)
        window.newOrientation = 'ENE30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ENE15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'DP';
      if (degrees == 180)
        window.newOrientation = 'ESE15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ESE30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SE';
      if (degrees == 225)
        window.newOrientation = 'SSE60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'FP';
      if (degrees == 270)
        window.newOrientation = 'SSW75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSW60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SW';
      if (degrees == 315)
        window.newOrientation = 'WSW30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WSW15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'IP';
      if (degrees == 0)
        window.newOrientation = 'WNW15';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'IP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WSW15';
      if (degrees == 45)
        window.newOrientation = 'WSW30';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'SW';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSW60';
      if (degrees == 90)
        window.newOrientation = 'SSW75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'FP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSE75';
      if (degrees == 135)
        window.newOrientation = 'SSE60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SE';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ESE30';
      if (degrees == 180)
        window.newOrientation = 'ESE15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'DP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ENE15';
      if (degrees == 225)
        window.newOrientation = 'ENE30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NE';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNE60';
      if (degrees == 270)
        window.newOrientation = 'NNE75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'EP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNW75';
      if (degrees == 315)
        window.newOrientation = 'NNW60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NW';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WNW30';
      if (degrees == 0)
        window.newOrientation = 'WNW15';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "IP") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WNW15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WNW30';
      if (degrees == 45)
        window.newOrientation = 'NW';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'NNW60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNW75';
      if (degrees == 90)
        window.newOrientation = 'EP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNE75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNE60';
      if (degrees == 135)
        window.newOrientation = 'NE';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ENE30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ENE15';
      if (degrees == 180)
        window.newOrientation = 'DP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ESE15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ESE30';
      if (degrees == 225)
        window.newOrientation = 'SE';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE75';
      if (degrees == 270)
        window.newOrientation = 'FP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSW75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSW60';
      if (degrees == 315)
        window.newOrientation = 'SW';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WSW30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WSW15';
      if (degrees == 0)
        window.newOrientation = 'IP';
    
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WSW15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WSW30';
      if (degrees == 45)
        window.newOrientation = 'SW';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'SSW60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSW75';
      if (degrees == 90)
        window.newOrientation = 'FP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSE75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSE60';
      if (degrees == 135)
        window.newOrientation = 'SE';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ESE30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ESE15';
      if (degrees == 180)
        window.newOrientation = 'DP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ENE15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ENE30';
      if (degrees == 225)
        window.newOrientation = 'NE';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNE75';
      if (degrees == 270)
        window.newOrientation = 'EP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNW75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNW60';
      if (degrees == 315)
        window.newOrientation = 'NW';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WNW30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WNW15';
      if (degrees == 0)
        window.newOrientation = 'IP';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "WSW15") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'IP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WNW15';
      if (degrees == 45)
        window.newOrientation = 'WNW30';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'NW';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNW60';
      if (degrees == 90)
        window.newOrientation = 'NNW75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'EP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNE75';
      if (degrees == 135)
        window.newOrientation = 'NNE60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NE';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ENE30';
      if (degrees == 180)
        window.newOrientation = 'ENE15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'DP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ESE15';
      if (degrees == 225)
        window.newOrientation = 'ESE30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SE';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE60';
      if (degrees == 270)
        window.newOrientation = 'SSE75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'FP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSW75';
      if (degrees == 315)
        window.newOrientation = 'SSW60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SW';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WSW30';
      if (degrees == 0)
        window.newOrientation = 'WSW15';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WSW30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SW';
      if (degrees == 45)
        window.newOrientation = 'SSW60';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'SSW75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'FP';
      if (degrees == 90)
        window.newOrientation = 'SSE75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSE60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SE';
      if (degrees == 135)
        window.newOrientation = 'ESE30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ESE15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'DP';
      if (degrees == 180)
        window.newOrientation = 'ENE15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ENE30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NE';
      if (degrees == 225)
        window.newOrientation = 'NNE60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'EP';
      if (degrees == 270)
        window.newOrientation = 'NNW75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNW60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NW';
      if (degrees == 315)
        window.newOrientation = 'WNW30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WNW15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'IP';
      if (degrees == 0)
        window.newOrientation = 'WSW15';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "WSW30") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WSW15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'IP';
      if (degrees == 45)
        window.newOrientation = 'WNW15';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'WNW30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NW';
      if (degrees == 90)
        window.newOrientation = 'NNW60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNW75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'EP';
      if (degrees == 135)
        window.newOrientation = 'NNE75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNE60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NE';
      if (degrees == 180)
        window.newOrientation = 'ENE30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ENE15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'DP';
      if (degrees == 225)
        window.newOrientation = 'ESE15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ESE30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SE';
      if (degrees == 270)
        window.newOrientation = 'SSE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSE75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'FP';
      if (degrees == 315)
        window.newOrientation = 'SSE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSW60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SW';
      if (degrees == 0)
        window.newOrientation = 'WSW30';
    
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SW';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSW60';
      if (degrees == 45)
        window.newOrientation = 'SSW75';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'FP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSE75';
      if (degrees == 90)
        window.newOrientation = 'SSE60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SE';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ESE30';
      if (degrees == 135)
        window.newOrientation = 'ESE15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'DP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ENE15';
      if (degrees == 180)
        window.newOrientation = 'ENE30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NE';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNE60';
      if (degrees == 225)
        window.newOrientation = 'NNE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'EP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNW75';
      if (degrees == 270)
        window.newOrientation = 'NNW60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NW';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WNW30';
      if (degrees == 315)
        window.newOrientation = 'WNW15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'IP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WSW15';
      if (degrees == 0)
        window.newOrientation = 'WSW30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SW") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WSW30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WSW15';
      if (degrees == 45)
        window.newOrientation = 'IP';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'WNW15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WNW30';
      if (degrees == 90)
        window.newOrientation = 'NW';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNW60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNW75';
      if (degrees == 135)
        window.newOrientation = 'EP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNE75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNE60';
      if (degrees == 180)
        window.newOrientation = 'NE';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ENE30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ENE15';
      if (degrees == 225)
        window.newOrientation = 'DP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ESE15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ESE30';
      if (degrees == 270)
        window.newOrientation = 'SE';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSE60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSE75';
      if (degrees == 315)
        window.newOrientation = 'FP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSW75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSW60';
      if (degrees == 0)
        window.newOrientation = 'SW';
     
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSW60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSW75';
      if (degrees == 45)
        window.newOrientation = 'FP';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'SSE75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSE60';
      if (degrees == 90)
        window.newOrientation = 'SE';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ESE30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ESE15';
      if (degrees == 135)
        window.newOrientation = 'DP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ENE15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ENE30';
      if (degrees == 180)
        window.newOrientation = 'NE';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNE60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNE75';
      if (degrees == 225)
        window.newOrientation = 'EP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNW75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNW60';
      if (degrees == 270)
        window.newOrientation = 'NW';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WNW30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WNW15';
      if (degrees == 315)
        window.newOrientation = 'IP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WSW15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WSW30';
      if (degrees == 0)
        window.newOrientation = 'SW';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SSW60") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SW';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WSW30';
      if (degrees == 45)
        window.newOrientation = 'WSW15';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'IP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WNW15';
      if (degrees == 90)
        window.newOrientation = 'WNW30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NW';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNW60';
      if (degrees == 135)
        window.newOrientation = 'NNW75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'EP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNE75';
      if (degrees == 180)
        window.newOrientation = 'NNE60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NE';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ENE30';
      if (degrees == 225)
        window.newOrientation = 'ENE15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'DP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ESE15';
      if (degrees == 270)
        window.newOrientation = 'ESE30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SE';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSE60';
      if (degrees == 315)
        window.newOrientation = 'SSE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'FP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSW75';
      if (degrees == 0)
        window.newOrientation = 'SSW60';
     
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSW75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'FP';
      if (degrees == 45)
        window.newOrientation = 'SSE75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSE60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SE';
      if (degrees == 90)
        window.newOrientation = 'ESE30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ESE15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'DP';
      if (degrees == 135)
        window.newOrientation = 'ENE15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ENE30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NE';
      if (degrees == 180)
        window.newOrientation = 'NNE60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNE75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'EP';
      if (degrees == 225)
        window.newOrientation = 'NNE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NW';
      if (degrees == 270)
        window.newOrientation = 'WNW30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WNW15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'IP';
      if (degrees == 315)
        window.newOrientation = 'WSW15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WSW30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SW';
      if (degrees == 0)
        window.newOrientation = 'SSW60';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SSW75") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSW60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SW';
      if (degrees == 45)
        window.newOrientation = 'WSW30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WSW15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'IP';
      if (degrees == 90)
        window.newOrientation = 'WNW15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WNW30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NW';
      if (degrees == 135)
        window.newOrientation = 'NNW60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNW75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'EP';
      if (degrees == 180)
        window.newOrientation = 'NNE75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNE60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NE';
      if (degrees == 225)
        window.newOrientation = 'ENE30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ENE15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'DP';
      if (degrees == 270)
        window.newOrientation = 'ESE15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ESE30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SE';
      if (degrees == 315)
        window.newOrientation = 'SSE60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSE75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'FP';
      if (degrees == 0)
        window.newOrientation = 'SSW75';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'FP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSE75';
      if (degrees == 45)
        window.newOrientation = 'SSE60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SE';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ESE30';
      if (degrees == 90)
        window.newOrientation = 'ESE15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'DP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ENE15';
      if (degrees == 135)
        window.newOrientation = 'ENE30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NE';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNE60';
      if (degrees == 180)
        window.newOrientation = 'NNE75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'EP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNW75';
      if (degrees == 225)
        window.newOrientation = 'NNW60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NW';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WNW30';
      if (degrees == 270)
        window.newOrientation = 'WNW15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'IP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WSW15';
      if (degrees == 315)
        window.newOrientation = 'WSW30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SW';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSW60';
      if (degrees == 0)
        window.newOrientation = 'SSW75';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "FP") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSW75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSW60';
      if (degrees == 45)
        window.newOrientation = 'SW';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'WSW30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WSW15';
      if (degrees == 90)
        window.newOrientation = 'IP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WNW15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WNW30';
      if (degrees == 135)
        window.newOrientation = 'NW';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNW60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNW75';
      if (degrees == 180)
        window.newOrientation = 'EP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNE75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNE60';
      if (degrees == 225)
        window.newOrientation = 'NE';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ENE30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ENE15';
      if (degrees == 270)
        window.newOrientation = 'DP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ESE15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ESE30';
      if (degrees == 315)
        window.newOrientation = 'SE';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSE60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSE75';
      if (degrees == 0)
        window.newOrientation = 'FP';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSE75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSE60';
      if (degrees == 45)
        window.newOrientation = 'SE';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'ESE30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ESE15';
      if (degrees == 90)
        window.newOrientation = 'DP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ENE15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ENE30';
      if (degrees == 135)
        window.newOrientation = 'NE';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNE60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNE75';
      if (degrees == 180)
        window.newOrientation = 'EP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNW75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNW60';
      if (degrees == 225)
        window.newOrientation = 'NW';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WNW30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WNW15';
      if (degrees == 270)
        window.newOrientation = 'IP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WSW15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WSW30';
      if (degrees == 315)
        window.newOrientation = 'SW';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSW60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSW75';
      if (degrees == 0)
        window.newOrientation = 'FP';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SSE75") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'FP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSW75';
      if (degrees == 45)
        window.newOrientation = 'SSW60';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'SW';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WSW30';
      if (degrees == 90)
        window.newOrientation = 'WSW15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'IP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WNW15';
      if (degrees == 135)
        window.newOrientation = 'WNW30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NW';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNW60';
      if (degrees == 180)
        window.newOrientation = 'NNW75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'EP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNE75';
      if (degrees == 225)
        window.newOrientation = 'NNE60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NE';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ENE30';
      if (degrees == 270)
        window.newOrientation = 'ENE15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'DP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ESE15';
      if (degrees == 315)
        window.newOrientation = 'ESE30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SE';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSE60';
      if (degrees == 0)
        window.newOrientation = 'SSE75';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSE60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SE';
      if (degrees == 45)
        window.newOrientation = 'ESE30';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'ESE15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'DP';
      if (degrees == 90)
        window.newOrientation = 'ENE15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ENE30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NE';
      if (degrees == 135)
        window.newOrientation = 'NNE60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNE75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'EP';
      if (degrees == 180)
        window.newOrientation = 'NNW75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNW60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NW';
      if (degrees == 225)
        window.newOrientation = 'WNW30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WNW15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'IP';
      if (degrees == 270)
        window.newOrientation = 'WSW15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WSW30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SW';
      if (degrees == 315)
        window.newOrientation = 'SSW60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSW75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'FP';
      if (degrees == 0)
        window.newOrientation = 'SSE75';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SSE60") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSE75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'FP';
      if (degrees == 45)
        window.newOrientation = 'SSW75';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'SSW60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SW';
      if (degrees == 90)
        window.newOrientation = 'WSW30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WSW15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'IP';
      if (degrees == 135)
        window.newOrientation = 'WNW15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WNW30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NW';
      if (degrees == 180)
        window.newOrientation = 'NNW60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNW75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'EP';
      if (degrees == 225)
        window.newOrientation = 'NNE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NE';
      if (degrees == 270)
        window.newOrientation = 'ENE30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ENE15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'DP';
      if (degrees == 315)
        window.newOrientation = 'ESE15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ESE30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SE';
      if (degrees == 0)
        window.newOrientation = 'SSE60';
     
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SE';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ESE30';
      if (degrees == 45)
        window.newOrientation = 'ESE15';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'DP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ENE15';
      if (degrees == 90)
        window.newOrientation = 'ENE30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NE';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNE60';
      if (degrees == 135)
        window.newOrientation = 'NNE75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'EP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNW75';
      if (degrees == 180)
        window.newOrientation = 'NNW60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NW';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WNW30';
      if (degrees == 225)
        window.newOrientation = 'WNW15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'IP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WSW15';
      if (degrees == 270)
        window.newOrientation = 'WSW30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SW';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSW60';
      if (degrees == 315)
        window.newOrientation = 'SSW75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'FP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSE75';
      if (degrees == 0)
        window.newOrientation = 'SSE60';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SE") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSE60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSE75';
      if (degrees == 45)
        window.newOrientation = 'FP';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'SSW75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSW60';
      if (degrees == 90)
        window.newOrientation = 'SW';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WSW30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WSW15';
      if (degrees == 135)
        window.newOrientation = 'IP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WNW15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WNW30';
      if (degrees == 180)
        window.newOrientation = 'NW';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNW60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNW75';
      if (degrees == 225)
        window.newOrientation = 'EP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNE60';
      if (degrees == 270)
        window.newOrientation = 'NE';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ENE30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ENE15';
      if (degrees == 315)
        window.newOrientation = 'DP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ESE15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ESE30';
      if (degrees == 0)
        window.newOrientation = 'SE';
     
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ESE30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ESE15';
      if (degrees == 45)
        window.newOrientation = 'DP';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'ENE15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ENE30';
      if (degrees == 90)
        window.newOrientation = 'NE';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNE60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNE75';
      if (degrees == 135)
        window.newOrientation = 'EP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNW75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNW60';
      if (degrees == 180)
        window.newOrientation = 'NW';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WNW30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WNW15';
      if (degrees == 225)
        window.newOrientation = 'IP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WSW15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WSW30';
      if (degrees == 270)
        window.newOrientation = 'SW';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSW60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSW75';
      if (degrees == 315)
        window.newOrientation = 'FP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSE75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSE60';
      if (degrees == 0)
        window.newOrientation = 'SE';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "ESE30") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SE';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSE60';
      if (degrees == 45)
        window.newOrientation = 'SSE75';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'FP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSW75';
      if (degrees == 90)
        window.newOrientation = 'SSW60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SW';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WSW30';
      if (degrees == 135)
        window.newOrientation = 'WSW15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'IP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WNW15';
      if (degrees == 180)
        window.newOrientation = 'WNW30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NW';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNW60';
      if (degrees == 225)
        window.newOrientation = 'NNW75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'EP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNE75';
      if (degrees == 270)
        window.newOrientation = 'NNE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NE';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ENE30';
      if (degrees == 315)
        window.newOrientation = 'ENE15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'DP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ESE15';
      if (degrees == 0)
        window.newOrientation = 'ESE30';
      
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ESE15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'DP';
      if (degrees == 45)
        window.newOrientation = 'ENE15';
      if (degrees > 45 && degrees < 61)
      window.newOrientation = 'ENE30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NE';
      if (degrees == 90)
        window.newOrientation = 'NNE60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNE75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'EP';
      if (degrees == 135)
        window.newOrientation = 'NNW75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNW60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NW';
      if (degrees == 180)
        window.newOrientation = 'WNW30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WNW15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'IP';
      if (degrees == 225)
        window.newOrientation = 'WSW15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WSW30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SW';
      if (degrees == 270)
        window.newOrientation = 'SSW60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSW75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'FP';
      if (degrees == 315)
        window.newOrientation = 'SSE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSE60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SE';
      if (degrees == 0)
        window.newOrientation = 'ESE30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "ESE15") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ESE30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SE';
      if (degrees == 45)
        window.newOrientation = 'SSE60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSE75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'FP';
      if (degrees == 90)
        window.newOrientation = 'SSW75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSW60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SW';
      if (degrees == 135)
        window.newOrientation = 'WSW30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WSW15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'IP';
      if (degrees == 180)
        window.newOrientation = 'WNW15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WNW30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NW';
      if (degrees == 225)
        window.newOrientation = 'NNW60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNW75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'EP';
      if (degrees == 270)
        window.newOrientation = 'NNE75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNE60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NE';
      if (degrees == 315)
        window.newOrientation = 'ENE30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ENE15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'DP';
      if (degrees == 0)
        window.newOrientation = 'ESE15';
    
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'DP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ENE15';
      if (degrees == 45)
        window.newOrientation = 'ENE30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NE';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNE60';
      if (degrees == 90)
        window.newOrientation = 'NNE75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'EP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNW75';
      if (degrees == 135)
        window.newOrientation = 'NNW60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NW';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WNW30';
      if (degrees == 180)
        window.newOrientation = 'WNW15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'IP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WSW15';
      if (degrees == 225)
        window.newOrientation = 'WSW30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SW';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSW60';
      if (degrees == 270)
        window.newOrientation = 'SSW75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'FP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSE75';
      if (degrees == 315)
        window.newOrientation = 'SSE60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SE';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ESE30';
      if (degrees == 0)
        window.newOrientation = 'ESE15';

      degrees *= -1
    }
    return degrees;
  }
 



}

function avatarMovePositionTurns(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder, lastTurn) {
  let code = ``; let drawLineCode = ``; let movePixels = 0; let movePixelsY = 0;
  window.currentBlock = ''; let codeHit = ``; let directionTo = ''; let secondDirectionTo = ''; window.colorin='green'
  window.currentBlockColor = '';

  if (window.typeColor == '#403fff') { window.colorin = 'blue'}
  if (window.typeColor == '#40ff3f') { window.colorin = 'green'}
  if (window.typeColor == '#ff4040') { window.colorin = 'red'}
  if (window.typeColor == '#ffc616') { window.colorin = 'yellow'}

 //alert(window.typeColor)
  //recorremos cuantas posiciones vamos a recorrer
  for (let index = 1; index <= positionsToMove; index++) {

    if (direction == 'right') {
      window.positx++;
      directionTo = 'left';
      window.newOrientation = "DP";
      window.linePositionX += animationPixels;
      movePixelsY += animationPixels;
    }
    if (direction == 'eastnortheast15') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ENE15";
      window.linePositionX += animationPixels;
      window.linePositionY -= 15;
      movePixelsY -= 15;
    }
    if (direction == 'eastnortheast30') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ENE30";
      window.linePositionX += animationPixels;
      window.linePositionY -= 30;
      movePixelsY -= 30;
    }
    if (direction == 'northeast') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NE";
      window.linePositionX += animationPixels;
      window.linePositionY -= animationPixels;
      movePixelsY -= animationPixels;
    }
    if (direction == 'northnortheast60') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNE60";
      window.linePositionX += animationPixels;
      window.linePositionY -= 60;
      movePixelsY -= 60;
    }
    if (direction == 'northnortheast75') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNE75";
      window.linePositionX += animationPixels;
      window.linePositionY -= 75;
      movePixelsY -= 75;
    }
    if (direction == 'up') {
      window.posity--;
      directionTo = 'top';
      window.newOrientation = "EP";
      window.linePositionY -= animationPixels;
      movePixelsY -= animationPixels;
    }
    if (direction == 'northnorthwest75') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNW75";
      window.linePositionX -= animationPixels;
      window.linePositionY -= 75;
      movePixelsY -= 75;
    }
    if (direction == 'northnorthwest60') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNW60";
      window.linePositionX -= animationPixels;
      window.linePositionY -= 60;
      movePixelsY -= 60;
    }
    if (direction == 'northwest') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NW";
      window.linePositionX -= animationPixels;
      window.linePositionY -= animationPixels;
      movePixelsY -= animationPixels;
    }
    if (direction == 'westnorthwest30') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WNW30";
      window.linePositionX -= animationPixels;
      window.linePositionY -= 30;
      movePixelsY -= 30;
    }
    if (direction == 'westnorthwest15') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WNW15";
      window.linePositionX -= animationPixels;
      window.linePositionY -= 15;
      movePixelsY -= 15;
    }
    if (direction == 'left') {
      window.positx--;
      directionTo = 'left'
      window.newOrientation = "IP";
      window.linePositionX -= animationPixels;
      movePixelsY -= animationPixels;
    }
    if (direction == 'westsouthwest15') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WSW15";
      window.linePositionX -= animationPixels;
      window.linePositionY += 15;
      movePixelsY += 15;
    }
    if (direction == 'westsouthwest30') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WSW30";
      window.linePositionX -= animationPixels;
      window.linePositionY += 30;
      movePixelsY += 30;
    }
    if (direction == 'southwest') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SW";
      window.linePositionX -= animationPixels;
      window.linePositionY += animationPixels;
      movePixelsY += animationPixels;
    }
    if (direction == 'southsouthwest60') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSW60";
      window.linePositionX -= animationPixels;
      window.linePositionY += 60;
      movePixelsY += 60;
    }
    if (direction == 'southsouthwest75') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSW75";
      window.linePositionX -= animationPixels;
      window.linePositionY += 75;
      movePixelsY += 75;
    }
    if (direction == 'down') {
      window.posity++;
      directionTo = 'top';
      window.newOrientation = "FP";
      window.linePositionY += animationPixels;
      movePixelsY += animationPixels;
    }
    if (direction == 'southsoutheast75') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSE75";
      window.linePositionX += animationPixels;
      window.linePositionY += 75;
      movePixelsY += 75;
    }
    if (direction == 'southsoutheast60') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSE60";
      window.linePositionX += animationPixels;
      window.linePositionY += 60;
      movePixelsY += 60;
    }
    if (direction == 'southeast') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SE";
      window.linePositionX += animationPixels;
      window.linePositionY += animationPixels;
      movePixelsY += animationPixels;
    }
    if (direction == 'eastsoutheast30') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ESE30";
      window.linePositionX += animationPixels;
      window.linePositionY += 30;
      movePixelsY += 30;
    }
    if (direction == 'eastsoutheast15') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ESE15";
      window.linePositionX += animationPixels;
      window.linePositionY += 15;
      movePixelsY += 15;
    }


    /*
    if (direction == 'right') {
      window.positx++;
      directionTo = 'left';
      window.newOrientation = "DP";
      window.linePositionX += animationPixels;
    }
    if (direction == 'left') {
      window.positx--;
      directionTo = 'left'
      window.newOrientation = "IP";
      window.linePositionX -= animationPixels;
    }
    if (direction == 'down') {
      window.posity++;
      directionTo = 'top';
      window.newOrientation = "FP";
      window.linePositionY += animationPixels;
    }
    if (direction == 'up') {
      window.posity--;
      directionTo = 'top';
      window.newOrientation = "EP";
      window.linePositionY -= animationPixels;
    }
    if (direction == 'eastsoutheast') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ESE";
      window.linePositionX += animationPixels;
      window.linePositionY += animationPixels;
    }
    if (direction == 'southeast') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SE";
      window.linePositionX += animationPixels;
      window.linePositionY += animationPixels;
    }
    if (direction == 'southsoutheast') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSE";
      window.linePositionX += animationPixels;
      window.linePositionY += animationPixels;
    }
    if (direction == 'westnorthwest') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WNW";
      window.linePositionX -= animationPixels;
      window.linePositionY -= animationPixels;
    }
    if (direction == 'northwest') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NW";
      window.linePositionX -= animationPixels;
      window.linePositionY -= animationPixels;
    }
    if (direction == 'northnorthwest') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNW";
      window.linePositionX -= animationPixels;
      window.linePositionY -= animationPixels;
    }


    if (direction == 'northnortheast') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNE";
      window.linePositionX += animationPixels;
      window.linePositionY -= animationPixels;
    }
    if (direction == 'northeast') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NE";
      window.linePositionX += animationPixels;
      window.linePositionY -= animationPixels;
    }
    if (direction == 'eastnortheast') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ENE";
      window.linePositionX += animationPixels;
      window.linePositionY -= animationPixels;
    }
    if (direction == 'southsouthwest') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSW";
      window.linePositionX -= animationPixels;
      window.linePositionY += animationPixels;
    }
    if (direction == 'southwest') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SW";
      window.linePositionX -= animationPixels;
      window.linePositionY += animationPixels;
    }
    if (direction == 'westsouthwest') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WSW";
      window.linePositionX -= animationPixels;
      window.linePositionY += animationPixels;
    }
    */


    window.currentBlock = window.positx + ',' + window.posity;
    //si esta dentro del escenario
    if (window.positx <= maxPositions && window.positx >= 0 && window.posity <= maxPositions && window.posity >= 0) {
      if (direction == 'right' || direction == 'down' || direction == 'southeast' || direction == 'southwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30')
        movePixels += animationPixels;
      if (direction == 'left' || direction == 'up' || direction == 'northwest' || direction == 'northeast' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30')
        movePixels -= animationPixels;
      // si la posición es válida
      if (!window.blocksNotAllowed.includes(window.currentBlock)) {
        //revisamos si en la posición se encuentra algún objetivo
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo" && gem.postx == window.positx && gem.posty == window.posity && gem.bCorrect == null) {
            window.goalscollected++; //incrementamos el número de objetivos logrados
            window.xyz++;
            window.coordenada = window.coordenada.concat(`${positx},${posity}|`);
            gem.bCorrect = true;
            window.namediv = '(' + gem.Type.substr(-1) + ')';
            window.figeometrico = gem.Type;
          }
        });
        //choque si hay objetos dentro del tablero
      } else {
        if (direction == 'right' || direction == 'northwest' || direction == 'southwest')
          code += setHit(`../assets/img/personaje/choquederecha.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
        if (direction == 'left' || direction == 'southeast' || direction == 'northeast')
          code += setHit(`../assets/img/personaje/choqueizquierda.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
        if (direction == 'down')
          code += setHit(`../assets/img/personaje/choquefrente.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
        if (direction == 'up')
          code += setHit(`../assets/img/personaje/choquearriba.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
        window.invalid = 1;
      }
    }
  }

  let codeAnimate = ``;
  // si se mueve arriba, abajo, izquierda o derecha
  if (!direction.includes('east') && !direction.includes('west') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    }
  // si se mueve sureste, noroeste
  if ((direction == 'southeast' || direction == 'northwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixelsY}px", "${secondDirectionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px", "${secondDirectionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    }
  if ((direction == 'northeast' || direction == 'southwest' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixelsY}px", "${secondDirectionTo}": "-=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px", "${secondDirectionTo}": "-=${movePixels}px"}, ${timeToPlay});`;
    }

  if (isDrawing && window.invalid == 0) {
    drawLineCode = `
        setTimeout(function() {
          window.drawLine(window.ctxCanvas, [${window.currentLinePositionX}, ${window.currentLinePositionY}], [${window.linePositionX}, ${window.linePositionY}], "${window.colorin}", 5);
        }, ${window.currentTime + 1000});
      `;

    if (direction == 'right' || direction == 'left' || direction == 'southeast' || direction == 'northwest' || direction == 'northeast' || direction == 'southwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30')
      window.currentLinePositionX = window.linePositionX;
    if (direction == 'down' || direction == 'up' || direction == 'southeast' || direction == 'northwest' || direction == 'northeast' || direction == 'southwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30')
      window.currentLinePositionY = window.linePositionY;
  }else{
    if (direction == 'right' || direction == 'left' || direction == 'southeast' || direction == 'northwest' || direction == 'northeast' || direction == 'southwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30')
      window.currentLinePositionX = window.linePositionX;
    if (direction == 'down' || direction == 'up' || direction == 'southeast' || direction == 'northwest' || direction == 'northeast' || direction == 'southwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30')
      window.currentLinePositionY = window.linePositionY;
  }

  code += `
      setTimeout(function(){
        window.blocks[${blockPosition}].select();
      }, ${window.currentTime});
  
      setTimeout(function(){
        $("#character3").css("background-image","url('${gifToPlay}')");
        ${codeAnimate}
      }, ${window.currentTime});
      
      ${drawLineCode}`;

  window.currentTime = parseInt(window.currentTime) + parseInt(timeToPlay);

  //si el avatar se sale del escenario
  if ((window.positx > maxPositions || window.positx < 0) && (direction == 'right' || direction == 'left')) {
    if (direction == 'right')
      code += setHit(`../assets/img/personaje/choquederecha.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
    if (direction == 'left')
      code += setHit(`../assets/img/personaje/choqueizquierda.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
  }

  if ((window.posity > maxPositions || window.posity < 0) && (direction == 'down' || direction == 'up')) {
    if (direction == 'down')
      code += setHit(`../assets/img/personaje/choquefrente.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
    if (direction == 'up')
      code += setHit(`../assets/img/personaje/choquearriba.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
  }

  if (window.totalGoals == window.goalscollected) {
    if (window.avatarType == null) {
      if (window.coordenada.slice(0, -1) == window.blocksYesAllowed && checkOrder) {
        Fire.$emit('activityDone');
        code += `
                  setTimeout(function(){
                      window.blocks[${blockPosition}].select();
                      $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); 
                  }, ${window.currentTime + 500}); `;
      }
    } else if (window.avatarType == 2) {
      if (window.coordenada.slice(0, -1) == window.blocksYesAllowed && checkOrder) {
        Fire.$emit('activityDone');
        code += `
            setTimeout(function(){
                window.blocks[${blockPosition}].select();
                $("#character").css("background-image","url('../assets/img/personaje/crayolin/crayolin_frente_verde.gif')"); 
            }, ${window.currentTime + 500}); `;
      }
    } else if (window.avatarType == 3) {
      if (window.coordenada.slice(0, -1) == window.blocksYesAllowed && checkOrder) {
        Fire.$emit('activityDone');
        code += `
                  setTimeout(function(){
                      window.blocks[${blockPosition}].select();
                      $("#character").css("background-image","url('../assets/img/personaje/robot/frenteparado.gif')"); 
                  }, ${window.currentTime + 500}); `;
      }

    }
    if (!checkOrder)
      Fire.$emit('activityDone');
  }
  //console.log('arrayColors',window.arrayColors);
  return code;
}

//copias para flor
function avatarCalculateDirectionDos(position, degrees, direction) {
  window.lastTurn = direction;
 

  if (position == "DP") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ESE15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ESE30';
      if (degrees == 45)
        window.newOrientation = 'SE';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSE60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSE75';
      if (degrees == 90)
        window.newOrientation = 'FP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSW75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSW60';
      if (degrees == 135)
        window.newOrientation = 'SW';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WSW30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WSW15';
      if (degrees == 180)
        window.newOrientation = 'IP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WNW15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WNW30';
      if (degrees == 225)
        window.newOrientation = 'NW';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNW60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNW75';
      if (degrees == 270)
        window.newOrientation = 'EP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNE75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNE60';
      if (degrees == 315)
        window.newOrientation = 'NE';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ENE30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ENE15';
      if (degrees == 0)
        window.newOrientation = 'DP';
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ENE15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ENE30';
      if (degrees == 45)
        window.newOrientation = 'NE';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNE60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNE75';
      if (degrees == 90)
        window.newOrientation = 'EP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNW75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNW60';
      if (degrees == 135)
        window.newOrientation = 'NW';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WNW45';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WNW15';
      if (degrees == 180)
        window.newOrientation = 'IP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WSW15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WSW30';
      if (degrees == 225)
        window.newOrientation = 'SW';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSW60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSW75';
      if (degrees == 270)
        window.newOrientation = 'FP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSE75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSE60';
      if (degrees == 315)
        window.newOrientation = 'SE';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ESE30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ESE15';
      if (degrees == 0)
        window.newOrientation = 'DP';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "ENE15") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'DP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ESE15';
      if (degrees == 45)
        window.newOrientation = 'ESE30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SE';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSE60';
      if (degrees == 90)
        window.newOrientation = 'SSE75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'FP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSW75';
      if (degrees == 135)
        window.newOrientation = 'SSW60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SW';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WSW30';
      if (degrees == 180)
        window.newOrientation = 'WSW15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'IP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WNW15';
      if (degrees == 225)
        window.newOrientation = 'WNW30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NW';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNW60';
      if (degrees == 270)
        window.newOrientation = 'NNW75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'EP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNE75';
      if (degrees == 315)
        window.newOrientation = 'NNE60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NE';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ENE30';
      if (degrees == 0)
        window.newOrientation = 'ENE15';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ENE30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NE';
      if (degrees == 45)
        window.newOrientation = 'NNE60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNE75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'EP';
      if (degrees == 90)
        window.newOrientation = 'NNW75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNW60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NW';
      if (degrees == 135)
        window.newOrientation = 'WNW30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WNW15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'IP';
      if (degrees == 180)
        window.newOrientation = 'WSW15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WSW30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SW';
      if (degrees == 225)
        window.newOrientation = 'SSW60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSW75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'FP';
      if (degrees == 270)
        window.newOrientation = 'SSE75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSE60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SE';
      if (degrees == 315)
        window.newOrientation = 'ESE30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ESE15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'DP';
      if (degrees == 0)
        window.newOrientation = 'ENE15';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "ENE30") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ENE15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'DP';
      if (degrees == 45)
        window.newOrientation = 'ESE15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ESE30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SE';
      if (degrees == 90)
        window.newOrientation = 'SSE60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSE75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'FP';
      if (degrees == 135)
        window.newOrientation = 'SSW75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSW60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SW';
      if (degrees == 180)
        window.newOrientation = 'WSW30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WSW15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'IP';
      if (degrees == 225)
        window.newOrientation = 'WNW15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WNW30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NW';
      if (degrees == 270)
        window.newOrientation = 'NNW60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNW75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'EP';
      if (degrees == 315)
        window.newOrientation = 'NNE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNE60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NE';
      if (degrees == 0)
        window.newOrientation = 'ENE30';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NE';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNE60';
      if (degrees == 45)
        window.newOrientation = 'NNE75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'EP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNW75';
      if (degrees == 90)
        window.newOrientation = 'NNW60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NW';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WNW30';
      if (degrees == 135)
        window.newOrientation = 'WNW15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'IP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WSW15';
      if (degrees == 180)
        window.newOrientation = 'WSW30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SW';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSW60';
      if (degrees == 225)
        window.newOrientation = 'SSW75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'FP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE75';
      if (degrees == 270)
        window.newOrientation = 'SSE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SE';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ESE30';
      if (degrees == 315)
        window.newOrientation = 'ESE15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'DP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ENE15';
      if (degrees == 0)
        window.newOrientation = 'ENE30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NE") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ENE30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ENE15';
      if (degrees == 45)
        window.newOrientation = 'DP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ESE15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ESE30';
      if (degrees == 90)
        window.newOrientation = 'SE';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSE60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSE75';
      if (degrees == 135)
        window.newOrientation = 'FP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSW75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSW60';
      if (degrees == 180)
        window.newOrientation = 'SW';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WSW30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WSW15';
      if (degrees == 225)
        window.newOrientation = 'IP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WNW15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WNW30';
      if (degrees == 270)
        window.newOrientation = 'NW';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNW60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNW75';
      if (degrees == 315)
        window.newOrientation = 'EP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNE75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNE60';
      if (degrees == 0)
        window.newOrientation = 'NE';
    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNE60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNE75';
      if (degrees == 45)
        window.newOrientation = 'EP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNW75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNW60';
      if (degrees == 90)
        window.newOrientation = 'NW';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WNW30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WNW15';
      if (degrees == 135)
        window.newOrientation = 'IP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WSW15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WSW30';
      if (degrees == 180)
        window.newOrientation = 'SW';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSW60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSW75';
      if (degrees == 225)
        window.newOrientation = 'FP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE60';
      if (degrees == 270)
        window.newOrientation = 'SE';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ESE30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ESE15';
      if (degrees == 315)
        window.newOrientation = 'DP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ENE15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ENE30';
      if (degrees == 0)
        window.newOrientation = 'NE';
      degrees *= -1
    }
    return degrees;
  }
  if (position == "NNE60") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NE';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ENE30';
      if (degrees == 45)
        window.newOrientation = 'ENE15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'DP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ESE15';
      if (degrees == 90)
        window.newOrientation = 'ESE30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SE';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSE60';
      if (degrees == 135)
        window.newOrientation = 'SSE75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'FP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSW75';
      if (degrees == 180)
        window.newOrientation = 'SSW60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SW';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WSW30';
      if (degrees == 225)
        window.newOrientation = 'WSW15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'IP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WNW15';
      if (degrees == 270)
        window.newOrientation = 'WNW30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NW';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNW60';
      if (degrees == 315)
        window.newOrientation = 'NNW75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'EP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNE75';
      if (degrees == 0)
        window.newOrientation = 'NNE60';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNE75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'EP';
      if (degrees == 45)
        window.newOrientation = 'NNW75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNW60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NW';
      if (degrees == 90)
        window.newOrientation = 'WNW30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WNW15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'IP';
      if (degrees == 135)
        window.newOrientation = 'WSW15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WSW30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SW';
      if (degrees == 180)
        window.newOrientation = 'SSW60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSW75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'FP';
      if (degrees == 225)
        window.newOrientation = 'SSE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SE';
      if (degrees == 270)
        window.newOrientation = 'ESE30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ESE15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'DP';
      if (degrees == 315)
        window.newOrientation = 'ENE15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ENE30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NE';
      if (degrees == 0)
        window.newOrientation = 'NNE60';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NNE75") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNE60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NE';
      if (degrees == 45)
        window.newOrientation = 'ENE30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ENE15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'DP';
      if (degrees == 90)
        window.newOrientation = 'ESE15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ESE30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SE';
      if (degrees == 135)
        window.newOrientation = 'SSE60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSE75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'FP';
      if (degrees == 180)
        window.newOrientation = 'SSW75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSW60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SW';
      if (degrees == 225)
        window.newOrientation = 'WSW30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WSW15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'IP';
      if (degrees == 270)
        window.newOrientation = 'WNW15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WNW30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NW';
      if (degrees == 315)
        window.newOrientation = 'NNW60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNW75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'EP';
      if (degrees == 0)
        window.newOrientation = 'NNE75';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'EP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNW75';
      if (degrees == 45)
        window.newOrientation = 'NNW60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NW';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WNW30';
      if (degrees == 90)
        window.newOrientation = 'WNW15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'IP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WSW15';
      if (degrees == 135)
        window.newOrientation = 'WSW30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SW';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSW60';
      if (degrees == 180)
        window.newOrientation = 'SSW75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'FP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSE75';
      if (degrees == 225)
        window.newOrientation = 'SSE60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SE';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ESE30';
      if (degrees == 270)
        window.newOrientation = 'ESE15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'DP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ENE15';
      if (degrees == 315)
        window.newOrientation = 'ENE30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NE';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNE15';
      if (degrees == 0)
        window.newOrientation = 'NNE30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "EP") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNE75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNE60';
      if (degrees == 45)
        window.newOrientation = 'NE';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ENE30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ENE15';
      if (degrees == 90)
        window.newOrientation = 'DP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ESE15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ESE30';
      if (degrees == 135)
        window.newOrientation = 'SE';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSE60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSE75';
      if (degrees == 180)
        window.newOrientation = 'FP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSW75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSW60';
      if (degrees == 225)
        window.newOrientation = 'SW';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WSW30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WSW15';
      if (degrees == 270)
        window.newOrientation = 'IP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WNW15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WNW30';
      if (degrees == 315)
        window.newOrientation = 'NW';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNW60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNW75';
      if (degrees == 0)
        window.newOrientation = 'EP';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNW75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNW60';
      if (degrees == 45)
        window.newOrientation = 'NW';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WNW30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WNW15';
      if (degrees == 90)
        window.newOrientation = 'IP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WSW15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WSW30';
      if (degrees == 135)
        window.newOrientation = 'SW';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSW60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSW75';
      if (degrees == 180)
        window.newOrientation = 'FP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSE75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSE60';
      if (degrees == 225)
        window.newOrientation = 'SE';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ESE30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ESE15';
      if (degrees == 270)
        window.newOrientation = 'DP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ENE15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ENE30';
      if (degrees == 315)
        window.newOrientation = 'NE';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNE60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNE75';
      if (degrees == 0)
        window.newOrientation = 'EP';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NNW75") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'FP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNE75';
      if (degrees == 45)
        window.newOrientation = 'NNE60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NE';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ENE30';
      if (degrees == 90)
        window.newOrientation = 'ENE15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'DP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ESE15';
      if (degrees == 135)
        window.newOrientation = 'ESE30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SE';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSE60';
      if (degrees == 180)
        window.newOrientation = 'SSE75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'FP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSW75';
      if (degrees == 225)
        window.newOrientation = 'SSW60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SW';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WSW30';
      if (degrees == 270)
        window.newOrientation = 'WSW15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'IP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WNW15';
      if (degrees == 315)
        window.newOrientation = 'WNW30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NW';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNW60';
      if (degrees == 0)
        window.newOrientation = 'NNW75';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNW60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NW';
      if (degrees == 45)
        window.newOrientation = 'WNW30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WNW15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'IP';
      if (degrees == 90)
        window.newOrientation = 'WSW15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WSW30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SW';
      if (degrees == 135)
        window.newOrientation = 'SSW60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSW75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'FP';
      if (degrees == 180)
        window.newOrientation = 'SSE75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSE60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SE';
      if (degrees == 225)
        window.newOrientation = 'ESE30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ESE15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'DP';
      if (degrees == 270)
        window.newOrientation = 'ENE15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ENE30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NE';
      if (degrees == 315)
        window.newOrientation = 'NNE60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNE75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'EP';
      if (degrees == 0)
        window.newOrientation = 'NNW75';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NNW60") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNW75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'EP';
      if (degrees == 45)
        window.newOrientation = 'NNE75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNE60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NE';
      if (degrees == 90)
        window.newOrientation = 'ENE30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ENE15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'DP';
      if (degrees == 135)
        window.newOrientation = 'ESE15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ESE30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SE';
      if (degrees == 180)
        window.newOrientation = 'SSE60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSE75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'FP';
      if (degrees == 225)
        window.newOrientation = 'SSW75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSW60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SW';
      if (degrees == 270)
        window.newOrientation = 'WSW30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WSW15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'IP';
      if (degrees == 315)
        window.newOrientation = 'WNW15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WNW30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NW';
      if (degrees == 0)
        window.newOrientation = 'NNW60';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NW';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WNW30';
      if (degrees == 45)
        window.newOrientation = 'WNW15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'IP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WSW15';
      if (degrees == 90)
        window.newOrientation = 'WSW30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SW';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSW60';
      if (degrees == 135)
        window.newOrientation = 'SSW75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'FP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSE75';
      if (degrees == 180)
        window.newOrientation = 'SSE60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SE';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ESE30';
      if (degrees == 225)
        window.newOrientation = 'ESE15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'DP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ENE15';
      if (degrees == 270)
        window.newOrientation = 'ENE30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NE';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNE60';
      if (degrees == 315)
        window.newOrientation = 'NNE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'EP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNW75';
      if (degrees == 0)
        window.newOrientation = 'NNW60';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "NW") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NNW60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNW75';
      if (degrees == 45)
        window.newOrientation = 'EP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNE75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNE60';
      if (degrees == 90)
        window.newOrientation = 'NE';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ENE30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ENE15';
      if (degrees == 135)
        window.newOrientation = 'DP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ESE15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ESE30';
      if (degrees == 180)
        window.newOrientation = 'SE';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SSE60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSE75';
      if (degrees == 225)
        window.newOrientation = 'FP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE60';
      if (degrees == 270)
        window.newOrientation = 'SW';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WSW30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WSW15';
      if (degrees == 315)
        window.newOrientation = 'IP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WNW15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WNW30';
      if (degrees == 0)
        window.newOrientation = 'NW';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WNW30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WNW15';
      if (degrees == 45)
        window.newOrientation = 'IP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WSW15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WSW30';
      if (degrees == 90)
        window.newOrientation = 'SW';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSW60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSW75';
      if (degrees == 135)
        window.newOrientation = 'FP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSE75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SSE60';
      if (degrees == 180)
        window.newOrientation = 'SE';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ESE30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ESE15';
      if (degrees == 225)
        window.newOrientation = 'DP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ENE15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ENE30';
      if (degrees == 270)
        window.newOrientation = 'NE';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNE60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNE75';
      if (degrees == 315)
        window.newOrientation = 'EP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNW75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NNW60';
      if (degrees == 0)
        window.newOrientation = 'NW';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "WNW30") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'NW';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NNW60';
      if (degrees == 45)
        window.newOrientation = 'NNW75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'EP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNE75';
      if (degrees == 90)
        window.newOrientation = 'NNE60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NE';
      if (degrees > 105 && degrees < 135)
       // window.newOrientation = 'ENE30';
      window.newOrientation = 'ENE15';
      if (degrees == 135)
        window.newOrientation = 'ENE15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'DP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ESE15';
      if (degrees == 180)
        window.newOrientation = 'ESE30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'SE';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SSE60';
      if (degrees == 225)
        window.newOrientation = 'SSE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'FP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE75';
      if (degrees == 270)
        window.newOrientation = 'SSE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SW';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WSW30';
      if (degrees == 315)
        window.newOrientation = 'WSW15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'IP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WNW15';
      if (degrees == 0)
        window.newOrientation = 'WNW30';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WNW15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'IP';
      if (degrees == 45)
        window.newOrientation = 'WSW15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WSW30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SW';
      if (degrees == 90)
        window.newOrientation = 'SSW60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSW75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'FP';
      if (degrees == 135)
        window.newOrientation = 'SSE75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SSE60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'SE';
      if (degrees == 180)
        window.newOrientation = 'ESE30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ESE15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'DP';
      if (degrees == 225)
        window.newOrientation = 'ENE15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ENE30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NE';
      if (degrees == 270)
        window.newOrientation = 'NNE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNE75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'EP';
      if (degrees == 315)
        window.newOrientation = 'NNW75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NNW60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'NW';
      if (degrees == 0)
        window.newOrientation = 'WNW30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "WNW15") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WNW30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'NW';
      if (degrees == 45)
        window.newOrientation = 'NNW60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNW75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'EP';
      if (degrees == 90)
        window.newOrientation = 'NNE75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNE60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NE';
      if (degrees == 135)
        window.newOrientation = 'ENE30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ENE15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'DP';
      if (degrees == 180)
        window.newOrientation = 'ESE15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ESE30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'SE';
      if (degrees == 225)
        window.newOrientation = 'SSE60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'FP';
      if (degrees == 270)
        window.newOrientation = 'SSW75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSW60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SW';
      if (degrees == 315)
        window.newOrientation = 'WSW30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WSW15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'IP';
      if (degrees == 0)
        window.newOrientation = 'WNW15';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'IP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WSW15';
      if (degrees == 45)
        window.newOrientation = 'WSW30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SW';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSW60';
      if (degrees == 90)
        window.newOrientation = 'SSW75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'FP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSE75';
      if (degrees == 135)
        window.newOrientation = 'SSE60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'SE';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ESE30';
      if (degrees == 180)
        window.newOrientation = 'ESE15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'DP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ENE15';
      if (degrees == 225)
        window.newOrientation = 'ENE30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NE';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNE60';
      if (degrees == 270)
        window.newOrientation = 'NNE75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'EP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNW75';
      if (degrees == 315)
        window.newOrientation = 'NNW60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'NW';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WNW30';
      if (degrees == 0)
        window.newOrientation = 'WNW15';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "IP") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WNW15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WNW30';
      if (degrees == 45)
        window.newOrientation = 'NW';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NNW60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNW75';
      if (degrees == 90)
        window.newOrientation = 'EP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNE75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNE60';
      if (degrees == 135)
        window.newOrientation = 'NE';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ENE30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ENE15';
      if (degrees == 180)
        window.newOrientation = 'DP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ESE15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ESE30';
      if (degrees == 225)
        window.newOrientation = 'SE';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SSE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE75';
      if (degrees == 270)
        window.newOrientation = 'FP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSW75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSW60';
      if (degrees == 315)
        window.newOrientation = 'SW';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WSW30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WSW15';
      if (degrees == 0)
        window.newOrientation = 'IP';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WSW15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WSW30';
      if (degrees == 45)
        window.newOrientation = 'SW';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSW60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSW75';
      if (degrees == 90)
        window.newOrientation = 'FP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSE75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SSE60';
      if (degrees == 135)
        window.newOrientation = 'SE';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ESE30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ESE15';
      if (degrees == 180)
        window.newOrientation = 'DP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ENE15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ENE30';
      if (degrees == 225)
        window.newOrientation = 'NE';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNE75';
      if (degrees == 270)
        window.newOrientation = 'EP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNW75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NNW60';
      if (degrees == 315)
        window.newOrientation = 'NW';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WNW30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WNW15';
      if (degrees == 0)
        window.newOrientation = 'IP';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "WSW15") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'IP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WNW15';
      if (degrees == 45)
        window.newOrientation = 'WNW30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NW';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNW60';
      if (degrees == 90)
        window.newOrientation = 'NNW75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'EP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNE75';
      if (degrees == 135)
        window.newOrientation = 'NNE60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NE';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ENE30';
      if (degrees == 180)
        window.newOrientation = 'ENE15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'DP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ESE15';
      if (degrees == 225)
        window.newOrientation = 'ESE30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SE';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSE60';
      if (degrees == 270)
        window.newOrientation = 'SSE75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'FP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSW75';
      if (degrees == 315)
        window.newOrientation = 'SSW60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SW';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WSW30';
      if (degrees == 0)
        window.newOrientation = 'WSW15';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WSW30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SW';
      if (degrees == 45)
        window.newOrientation = 'SSW60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSW75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'FP';
      if (degrees == 90)
        window.newOrientation = 'SSE75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSE60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SE';
      if (degrees == 135)
        window.newOrientation = 'ESE30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ESE15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'DP';
      if (degrees == 180)
        window.newOrientation = 'ENE15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ENE30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NE';
      if (degrees == 225)
        window.newOrientation = 'NNE60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'EP';
      if (degrees == 270)
        window.newOrientation = 'NNW75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNW60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NW';
      if (degrees == 315)
        window.newOrientation = 'WNW30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WNW15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'IP';
      if (degrees == 0)
        window.newOrientation = 'WSW15';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "WSW30") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WSW15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'IP';
      if (degrees == 45)
        window.newOrientation = 'WNW15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WNW30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NW';
      if (degrees == 90)
        window.newOrientation = 'NNW60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNW75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'EP';
      if (degrees == 135)
        window.newOrientation = 'NNE75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNE60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NE';
      if (degrees == 180)
        window.newOrientation = 'ENE30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ENE15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'DP';
      if (degrees == 225)
        window.newOrientation = 'ESE15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ESE30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SE';
      if (degrees == 270)
        window.newOrientation = 'SSE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSE75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'FP';
      if (degrees == 315)
        window.newOrientation = 'SSE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSW60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SW';
      if (degrees == 0)
        window.newOrientation = 'WSW30';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SW';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSW60';
      if (degrees == 45)
        window.newOrientation = 'SSW75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'FP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSE75';
      if (degrees == 90)
        window.newOrientation = 'SSE60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SE';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ESE30';
      if (degrees == 135)
        window.newOrientation = 'ESE15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'DP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ENE15';
      if (degrees == 180)
        window.newOrientation = 'ENE30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NE';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNE60';
      if (degrees == 225)
        window.newOrientation = 'NNE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'EP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNW75';
      if (degrees == 270)
        window.newOrientation = 'NNW60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NW';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WNW30';
      if (degrees == 315)
        window.newOrientation = 'WNW15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'IP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WSW15';
      if (degrees == 0)
        window.newOrientation = 'WSW30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SW") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'WSW30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WSW15';
      if (degrees == 45)
        window.newOrientation = 'IP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WNW15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WNW30';
      if (degrees == 90)
        window.newOrientation = 'NW';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNW60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNW75';
      if (degrees == 135)
        window.newOrientation = 'EP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNE75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNE60';
      if (degrees == 180)
        window.newOrientation = 'NE';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'ENE30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ENE15';
      if (degrees == 225)
        window.newOrientation = 'DP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ESE15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ESE30';
      if (degrees == 270)
        window.newOrientation = 'SE';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSE60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSE75';
      if (degrees == 315)
        window.newOrientation = 'FP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSW75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSW60';
      if (degrees == 0)
        window.newOrientation = 'SW';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSW60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSW75';
      if (degrees == 45)
        window.newOrientation = 'FP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSE75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSE60';
      if (degrees == 90)
        window.newOrientation = 'SE';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ESE30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ESE15';
      if (degrees == 135)
        window.newOrientation = 'DP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ENE15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'ENE30';
      if (degrees == 180)
        window.newOrientation = 'NE';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNE60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNE75';
      if (degrees == 225)
        window.newOrientation = 'EP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNW75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNW60';
      if (degrees == 270)
        window.newOrientation = 'NW';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WNW30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WNW15';
      if (degrees == 315)
        window.newOrientation = 'IP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WSW15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'WSW30';
      if (degrees == 0)
        window.newOrientation = 'SW';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SSW60") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SW';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'WSW30';
      if (degrees == 45)
        window.newOrientation = 'WSW15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'IP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WNW15';
      if (degrees == 90)
        window.newOrientation = 'WNW30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NW';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNW60';
      if (degrees == 135)
        window.newOrientation = 'NNW75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'EP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNE75';
      if (degrees == 180)
        window.newOrientation = 'NNE60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NE';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'ENE30';
      if (degrees == 225)
        window.newOrientation = 'ENE15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'DP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ESE15';
      if (degrees == 270)
        window.newOrientation = 'ESE30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SE';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSE60';
      if (degrees == 315)
        window.newOrientation = 'SSE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'FP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSW75';
      if (degrees == 0)
        window.newOrientation = 'SSW60';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSW75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'FP';
      if (degrees == 45)
        window.newOrientation = 'SSE75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSE60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SE';
      if (degrees == 90)
        window.newOrientation = 'ESE30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ESE15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'DP';
      if (degrees == 135)
        window.newOrientation = 'ENE15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'ENE30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NE';
      if (degrees == 180)
        window.newOrientation = 'NNE60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNE75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'EP';
      if (degrees == 225)
        window.newOrientation = 'NNE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NW';
      if (degrees == 270)
        window.newOrientation = 'WNW30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WNW15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'IP';
      if (degrees == 315)
        window.newOrientation = 'WSW15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'WSW30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SW';
      if (degrees == 0)
        window.newOrientation = 'SSW60';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SSW75") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSW60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SW';
      if (degrees == 45)
        window.newOrientation = 'WSW30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WSW15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'IP';
      if (degrees == 90)
        window.newOrientation = 'WNW15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WNW30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NW';
      if (degrees == 135)
        window.newOrientation = 'NNW60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNW75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'EP';
      if (degrees == 180)
        window.newOrientation = 'NNE75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNE60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NE';
      if (degrees == 225)
        window.newOrientation = 'ENE30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ENE15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'DP';
      if (degrees == 270)
        window.newOrientation = 'ESE15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ESE30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SE';
      if (degrees == 315)
        window.newOrientation = 'SSE60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSE75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'FP';
      if (degrees == 0)
        window.newOrientation = 'SSW75';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'FP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSE75';
      if (degrees == 45)
        window.newOrientation = 'SSE60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SE';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ESE30';
      if (degrees == 90)
        window.newOrientation = 'ESE15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'DP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ENE15';
      if (degrees == 135)
        window.newOrientation = 'ENE30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NE';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNE60';
      if (degrees == 180)
        window.newOrientation = 'NNE75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'EP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNW75';
      if (degrees == 225)
        window.newOrientation = 'NNW60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NW';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WNW30';
      if (degrees == 270)
        window.newOrientation = 'WNW15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'IP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WSW15';
      if (degrees == 315)
        window.newOrientation = 'WSW30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SW';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSW60';
      if (degrees == 0)
        window.newOrientation = 'SSW75';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "FP") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSW75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSW60';
      if (degrees == 45)
        window.newOrientation = 'SW';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'WSW30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WSW15';
      if (degrees == 90)
        window.newOrientation = 'IP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WNW15';
      if (degrees > 105 && degrees < 135)
        //window.newOrientation = 'WNW30';
        window.newOrientation = 'NW';
      if (degrees == 135)
        window.newOrientation = 'NW';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNW60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNW75';
      if (degrees == 180)
        window.newOrientation = 'EP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNE75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNE60';
      if (degrees == 225)
        window.newOrientation = 'NE';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'ENE30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ENE15';
      if (degrees == 270)
        window.newOrientation = 'DP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ESE15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ESE30';
      if (degrees == 315)
        window.newOrientation = 'SE';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSE60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSE75';
      if (degrees == 0)
        window.newOrientation = 'FP';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSE75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSE60';
      if (degrees == 45)
        window.newOrientation = 'SE';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ESE30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ESE15';
      if (degrees == 90)
        window.newOrientation = 'DP';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ENE15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'ENE30';
      if (degrees == 135)
        window.newOrientation = 'NE';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNE60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNE75';
      if (degrees == 180)
        window.newOrientation = 'EP';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNW75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNW60';
      if (degrees == 225)
        window.newOrientation = 'NW';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WNW30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WNW15';
      if (degrees == 270)
        window.newOrientation = 'IP';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WSW15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'WSW30';
      if (degrees == 315)
        window.newOrientation = 'SW';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSW60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSW75';
      if (degrees == 0)
        window.newOrientation = 'FP';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SSE75") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'FP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSW75';
      if (degrees == 45)
        window.newOrientation = 'SSW60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SW';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'WSW30';
      if (degrees == 90)
        window.newOrientation = 'WSW15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'IP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WNW15';
      if (degrees == 135)
        window.newOrientation = 'WNW30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NW';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNW60';
      if (degrees == 180)
        window.newOrientation = 'NNW75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'EP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNE75';
      if (degrees == 225)
        window.newOrientation = 'NNE60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NE';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'ENE30';
      if (degrees == 270)
        window.newOrientation = 'ENE15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'DP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ESE15';
      if (degrees == 315)
        window.newOrientation = 'ESE30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SE';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSE60';
      if (degrees == 0)
        window.newOrientation = 'SSE75';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSE60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SE';
      if (degrees == 45)
        window.newOrientation = 'ESE30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ESE15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'DP';
      if (degrees == 90)
        window.newOrientation = 'ENE15';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'ENE30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NE';
      if (degrees == 135)
        window.newOrientation = 'NNE60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNE75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'EP';
      if (degrees == 180)
        window.newOrientation = 'NNW75';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNW60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NW';
      if (degrees == 225)
        window.newOrientation = 'WNW30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WNW15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'IP';
      if (degrees == 270)
        window.newOrientation = 'WSW15';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'WSW30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SW';
      if (degrees == 315)
        window.newOrientation = 'SSW60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSW75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'FP';
      if (degrees == 0)
        window.newOrientation = 'SSE75';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SSE60") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSE75';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'FP';
      if (degrees == 45)
        window.newOrientation = 'SSW75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSW60';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SW';
      if (degrees == 90)
        window.newOrientation = 'WSW30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WSW15';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'IP';
      if (degrees == 135)
        window.newOrientation = 'WNW15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WNW30';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NW';
      if (degrees == 180)
        window.newOrientation = 'NNW60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNW75';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'EP';
      if (degrees == 225)
        window.newOrientation = 'NNE75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE60';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NE';
      if (degrees == 270)
        window.newOrientation = 'ENE30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ENE15';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'DP';
      if (degrees == 315)
        window.newOrientation = 'ESE15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ESE30';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SE';
      if (degrees == 0)
        window.newOrientation = 'SSE60';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SE';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ESE30';
      if (degrees == 45)
        window.newOrientation = 'ESE15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'DP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ENE15';
      if (degrees == 90)
        window.newOrientation = 'ENE30';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NE';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNE60';
      if (degrees == 135)
        window.newOrientation = 'NNE75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'EP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNW75';
      if (degrees == 180)
        window.newOrientation = 'NNW60';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NW';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WNW30';
      if (degrees == 225)
        window.newOrientation = 'WNW15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'IP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WSW15';
      if (degrees == 270)
        window.newOrientation = 'WSW30';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SW';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSW60';
      if (degrees == 315)
        window.newOrientation = 'SSW75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'FP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSE75';
      if (degrees == 0)
        window.newOrientation = 'SSE60';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "SE") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SSE60';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSE75';
      if (degrees == 45)
        window.newOrientation = 'FP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSW75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSW60';
      if (degrees == 90)
        window.newOrientation = 'SW';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'WSW30';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WSW15';
      if (degrees == 135)
        window.newOrientation = 'IP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WNW15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WNW30';
      if (degrees == 180)
        window.newOrientation = 'NW';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NNW60';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNW75';
      if (degrees == 225)
        window.newOrientation = 'EP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNE75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNE60';
      if (degrees == 270)
        window.newOrientation = 'NE';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'ENE30';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ENE15';
      if (degrees == 315)
        window.newOrientation = 'DP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ESE15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ESE30';
      if (degrees == 0)
        window.newOrientation = 'SE';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ESE30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ESE15';
      if (degrees == 45)
        window.newOrientation = 'DP';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ENE15';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'ENE30';
      if (degrees == 90)
        window.newOrientation = 'NE';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNE60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNE75';
      if (degrees == 135)
        window.newOrientation = 'EP';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNW75';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NNW60';
      if (degrees == 180)
        window.newOrientation = 'NW';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WNW30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WNW15';
      if (degrees == 225)
        window.newOrientation = 'IP';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WSW15';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'WSW30';
      if (degrees == 270)
        window.newOrientation = 'SW';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSW60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSW75';
      if (degrees == 315)
        window.newOrientation = 'FP';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSE75';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SSE60';
      if (degrees == 0)
        window.newOrientation = 'SE';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "ESE30") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'SE';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SSE60';
      if (degrees == 45)
        window.newOrientation = 'SSE75';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'FP';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'SSW75';
      if (degrees == 90)
        window.newOrientation = 'SSW60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SW';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'WSW30';
      if (degrees == 135)
        window.newOrientation = 'WSW15';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'IP';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WNW15';
      if (degrees == 180)
        window.newOrientation = 'WNW30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'NW';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NNW60';
      if (degrees == 225)
        window.newOrientation = 'NNW75';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'EP';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'NNE75';
      if (degrees == 270)
        window.newOrientation = 'NNE60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NE';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'ENE30';
      if (degrees == 315)
        window.newOrientation = 'ENE15';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'DP';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ESE15';
      if (degrees == 0)
        window.newOrientation = 'ESE30';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ESE15';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'DP';
      if (degrees == 45)
        window.newOrientation = 'ENE15';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'ENE30';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NE';
      if (degrees == 90)
        window.newOrientation = 'NNE60';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'NNE75';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'EP';
      if (degrees == 135)
        window.newOrientation = 'NNW75';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NNW60';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'NW';
      if (degrees == 180)
        window.newOrientation = 'WNW30';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WNW15';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'IP';
      if (degrees == 225)
        window.newOrientation = 'WSW15';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'WSW30';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SW';
      if (degrees == 270)
        window.newOrientation = 'SSW60';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'SSW75';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'FP';
      if (degrees == 315)
        window.newOrientation = 'SSE75';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SSE60';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'SE';
      if (degrees == 0)
        window.newOrientation = 'ESE30';

      degrees *= -1
    }
    return degrees;
  }
  if (position == "ESE15") {
    if (direction == "Derecha") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'ESE30';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'SE';
      if (degrees == 45)
        window.newOrientation = 'SSE60';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'SSE75';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'FP';
      if (degrees == 90)
        window.newOrientation = 'SSW75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'SSW60';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'SW';
      if (degrees == 135)
        window.newOrientation = 'WSW30';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'WSW15';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'IP';
      if (degrees == 180)
        window.newOrientation = 'WNW15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'WNW30';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'NW';
      if (degrees == 225)
        window.newOrientation = 'NNW60';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'NNW75';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'EP';
      if (degrees == 270)
        window.newOrientation = 'NNE75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'NNE60';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'NE';
      if (degrees == 315)
        window.newOrientation = 'ENE30';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'ENE15';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'DP';
      if (degrees == 0)
        window.newOrientation = 'ESE15';

    }
    if (direction == "Izquierda") {
      if (degrees > 0 && degrees < 16)
        window.newOrientation = 'DP';
      if (degrees > 15 && degrees < 45)
        window.newOrientation = 'ENE15';
      if (degrees == 45)
        window.newOrientation = 'ENE30';
      if (degrees > 45 && degrees < 61)
        window.newOrientation = 'NE';
      if (degrees > 60 && degrees < 90)
        window.newOrientation = 'NNE60';
      if (degrees == 90)
        window.newOrientation = 'NNE75';
      if (degrees > 90 && degrees < 106)
        window.newOrientation = 'EP';
      if (degrees > 105 && degrees < 135)
        window.newOrientation = 'NNW75';
      if (degrees == 135)
        window.newOrientation = 'NNW60';
      if (degrees > 135 && degrees < 151)
        window.newOrientation = 'NW';
      if (degrees > 150 && degrees < 180)
        window.newOrientation = 'WNW30';
      if (degrees == 180)
        window.newOrientation = 'WNW15';
      if (degrees > 180 && degrees < 196)
        window.newOrientation = 'IP';
      if (degrees > 195 && degrees < 225)
        window.newOrientation = 'WSW15';
      if (degrees == 225)
        window.newOrientation = 'WSW30';
      if (degrees > 225 && degrees < 241)
        window.newOrientation = 'SW';
      if (degrees > 240 && degrees < 270)
        window.newOrientation = 'SSW60';
      if (degrees == 270)
        window.newOrientation = 'SSW75';
      if (degrees > 270 && degrees < 286)
        window.newOrientation = 'FP';
      if (degrees > 285 && degrees < 315)
        window.newOrientation = 'SSE75';
      if (degrees == 315)
        window.newOrientation = 'SSE60';
      if (degrees > 315 && degrees < 331)
        window.newOrientation = 'SE';
      if (degrees > 331 && degrees < 355)
        window.newOrientation = 'ESE30';
      if (degrees == 0)
        window.newOrientation = 'ESE15';

      degrees *= -1
    }
    return degrees;
  }


}

function avatarMovePositionTurnsDos(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder, lastTurn) {
  let code = ``; let drawLineCode = ``; let movePixels = 0; let movePixelsY = 0;
  window.currentBlock = ''; let codeHit = ``; let directionTo = ''; let secondDirectionTo = '';
  window.currentBlockColor = ''; window.colorin = 'green';


  if (window.typeColor == '#403fff') { window.colorin = 'blue' }
  if (window.typeColor == '#40ff3f') { window.colorin = 'green' }
  if (window.typeColor == '#ff4040') { window.colorin = 'red' }
  if (window.typeColor == '#ffc616') { window.colorin = 'yellow' }


  //recorremos cuantas posiciones vamos a recorrer
  for (let index = 1; index <= positionsToMove; index++) {

    if (direction == 'right') {
      window.positx++;
      directionTo = 'left';
      window.newOrientation = "DP";
      window.linePositionX += animationPixels + 10;
      movePixelsY += animationPixels;
    }
    if (direction == 'eastnortheast15') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ENE15";
      window.linePositionX += animationPixels+17;
      window.linePositionY -= 15;
      movePixelsY -= 15;
    }
    if (direction == 'eastnortheast30') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ENE30";
      window.linePositionX += animationPixels+15;
      window.linePositionY -= 30;
      movePixelsY -= 30;
    }
    if (direction == 'northeast') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NE";
      window.linePositionX += animationPixels+10;
      window.linePositionY -= 50;
      movePixelsY -= 50;
    }
    if (direction == 'northnortheast60') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNE60";
      window.linePositionX += animationPixels;
      window.linePositionY -= 60;
      movePixelsY -= 60;
    }
    if (direction == 'northnortheast75') {
      window.positx++;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNE75";
      window.linePositionX += animationPixels;
      window.linePositionY -= 75;
      movePixelsY -= 75;
    }
    if (direction == 'up') {
      window.posity--;
      directionTo = 'top';
      window.newOrientation = "EP";
      window.linePositionY -= animationPixels +10;
      movePixelsY -= animationPixels;
    }
    if (direction == 'northnorthwest75') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNW75";
      window.linePositionX -= animationPixels-40;
      window.linePositionY -= 75;
      movePixelsY -= 75;
    }
    if (direction == 'northnorthwest60') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NNW60";
      window.linePositionX -= animationPixels;
      window.linePositionY -= 60;
      movePixelsY -= 60;
    }
    if (direction == 'northwest') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "NW";
      window.linePositionX -= animationPixels+10;
      window.linePositionY -= 50;
      movePixelsY -= 50;
    }
    if (direction == 'westnorthwest30') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WNW30";
      window.linePositionX -= animationPixels + 10;
      window.linePositionY -= 30;
      movePixelsY -= 30;
    }
    if (direction == 'westnorthwest15') {
      window.positx--;
      window.posity--;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WNW15";
      window.linePositionX -= animationPixels;
      window.linePositionY -= 15;
      movePixelsY -= 15;
    }
    if (direction == 'left') {
      window.positx--;
      directionTo = 'left'
      window.newOrientation = "IP";
      window.linePositionX -= animationPixels+10;
      movePixelsY -= animationPixels;
    }
    if (direction == 'westsouthwest15') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WSW15";
      window.linePositionX -= animationPixels+17;
      window.linePositionY += 15;
      movePixelsY += 15;
    }
    if (direction == 'westsouthwest30') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "WSW30";
      window.linePositionX -= animationPixels+15;
      window.linePositionY += 30;
      movePixelsY += 30;
    }
    if (direction == 'southwest') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SW";
      window.linePositionX -= animationPixels+10;
      window.linePositionY += 50;
      movePixelsY += 50;
    }
    if (direction == 'southsouthwest60') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSW60";
      window.linePositionX -= animationPixels;
      window.linePositionY += 60;
      movePixelsY += 60;
    }
    if (direction == 'southsouthwest75') {
      window.positx--;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSW75";
      window.linePositionX -= animationPixels;
      window.linePositionY += 75;
      movePixelsY += 75;
    }
    if (direction == 'down') {
      window.posity++;
      directionTo = 'top';
      window.newOrientation = "FP";
      window.linePositionY += animationPixels+10;
      movePixelsY += animationPixels;
    }
    if (direction == 'southsoutheast75') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSE75";
      window.linePositionX += animationPixels-40;
      window.linePositionY += 75;
      movePixelsY += 75;
    }
    if (direction == 'southsoutheast60') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SSE60";
      window.linePositionX += animationPixels;
      window.linePositionY += 60;
      movePixelsY += 60;
    }
    if (direction == 'southeast') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "SE";
      window.linePositionX += animationPixels+10;
      window.linePositionY += 50;
      movePixelsY += 50;
    }
    if (direction == 'eastsoutheast30') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ESE30";
      window.linePositionX += animationPixels+10;
      window.linePositionY += 30;
      movePixelsY += 30;
    }
    if (direction == 'eastsoutheast15') {
      window.positx++;
      window.posity++;
      directionTo = 'top';
      secondDirectionTo = 'left';
      window.newOrientation = "ESE15";
      window.linePositionX += animationPixels;
      window.linePositionY += 15;
      movePixelsY += 15;
    }


   

    window.currentBlock = window.positx + ',' + window.posity;
    //si esta dentro del escenario
    if (window.positx <= maxPositions && window.positx >= 0 && window.posity <= maxPositions && window.posity >= 0) {
      if (direction == 'right' || direction == 'down' || direction == 'southeast' || direction == 'southwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30')
        movePixels += animationPixels;
      if (direction == 'left' || direction == 'up' || direction == 'northwest' || direction == 'northeast' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30')
        movePixels -= animationPixels;
      // si la posición es válida
      if (!window.blocksNotAllowed.includes(window.currentBlock)) {
        //revisamos si en la posición se encuentra algún objetivo
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo" && gem.postx == window.positx && gem.posty == window.posity && gem.bCorrect == null) {
            window.goalscollected++; //incrementamos el número de objetivos logrados
            window.xyz++;
            window.coordenada = window.coordenada.concat(`${positx},${posity}|`);
            gem.bCorrect = true;
            window.namediv = '(' + gem.Type.substr(-1) + ')';
            window.figeometrico = gem.Type;
          }
        });
        //choque si hay objetos dentro del tablero
      } else {
        if (direction == 'right' || direction == 'northwest' || direction == 'southwest')
          code += setHit(`../assets/img/personaje/choquederecha.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
        if (direction == 'left' || direction == 'southeast' || direction == 'northeast')
          code += setHit(`../assets/img/personaje/choqueizquierda.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
        if (direction == 'down')
          code += setHit(`../assets/img/personaje/choquefrente.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
        if (direction == 'up')
          code += setHit(`../assets/img/personaje/choquearriba.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
        window.invalid = 1;
      }
    }
  }

  let codeAnimate = ``;
  // si se mueve arriba, abajo, izquierda o derecha
  if (!direction.includes('east') && !direction.includes('west') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    }
  // si se mueve sureste, noroeste
  if ((direction == 'southeast' || direction == 'northwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixelsY}px", "${secondDirectionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px", "${secondDirectionTo}": "+=${movePixels}px"}, ${timeToPlay});`;
    }
  if ((direction == 'northeast' || direction == 'southwest' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30') && window.invalid == 0)
    if (window.avatarType == 3) {
      codeAnimate = `$("#character3").animate({"${directionTo}": "+=${movePixelsY}px", "${secondDirectionTo}": "-=${movePixels}px"}, ${timeToPlay});`;
    } else {
      codeAnimate = `$("#character").animate({"${directionTo}": "+=${movePixels}px", "${secondDirectionTo}": "-=${movePixels}px"}, ${timeToPlay});`;
    }

  if (isDrawing && window.invalid == 0) {
    drawLineCode = `
        setTimeout(function() {
         window.drawLine(window.ctxCanvas, [${window.currentLinePositionX}, ${window.currentLinePositionY}], [${window.linePositionX}, ${window.linePositionY}], "${window.colorin}", 5);
        }, ${window.currentTime + 1000});
      `;

    if (direction == 'right' || direction == 'left' || direction == 'southeast' || direction == 'northwest' || direction == 'northeast' || direction == 'southwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30')
      window.currentLinePositionX = window.linePositionX;
    if (direction == 'down' || direction == 'up' || direction == 'southeast' || direction == 'northwest' || direction == 'northeast' || direction == 'southwest' || direction == 'eastsoutheast30' || direction == 'eastsoutheast15' || direction == 'southsoutheast60' || direction == 'southsoutheast75' || direction == 'southsouthwest60' || direction == 'southsouthwest75' || direction == 'westsouthwest15' || direction == 'westsouthwest30' || direction == 'westnorthwest15' || direction == 'westnorthwest30' || direction == 'northnorthwest60' || direction == 'northnorthwest75' || direction == 'northnortheast60' || direction == 'northnortheast75' || direction == 'eastnortheast15' || direction == 'eastnortheast30')
      window.currentLinePositionY = window.linePositionY;
  }

  code += `
      setTimeout(function(){
        window.blocks[${blockPosition}].select();
      }, ${window.currentTime});
  
      setTimeout(function(){
        $("#character3").css("background-image","url('${gifToPlay}')");
        ${codeAnimate}
      }, ${window.currentTime});
      
      ${drawLineCode}`;

  window.currentTime = parseInt(window.currentTime) + parseInt(timeToPlay);

  //si el avatar se sale del escenario
  if ((window.positx > maxPositions || window.positx < 0) && (direction == 'right' || direction == 'left')) {
    if (direction == 'right')
      code += setHit(`../assets/img/personaje/choquederecha.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
    if (direction == 'left')
      code += setHit(`../assets/img/personaje/choqueizquierda.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
  }

  if ((window.posity > maxPositions || window.posity < 0) && (direction == 'down' || direction == 'up')) {
    if (direction == 'down')
      code += setHit(`../assets/img/personaje/choquefrente.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
    if (direction == 'up')
      code += setHit(`../assets/img/personaje/choquearriba.gif?a=${Math.random()}`, window.currentTime + timeToPlay);
  }

  if (window.totalGoals == window.goalscollected) {
    if (window.avatarType == null) {
      if (window.coordenada.slice(0, -1) == window.blocksYesAllowed && checkOrder) {
        Fire.$emit('activityDone');
        code += `
                  setTimeout(function(){
                      window.blocks[${blockPosition}].select();
                      $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); 
                  }, ${window.currentTime + 500}); `;
      }
    } else if (window.avatarType == 2) {
      if (window.coordenada.slice(0, -1) == window.blocksYesAllowed && checkOrder) {
        Fire.$emit('activityDone');
        code += `
            setTimeout(function(){
                window.blocks[${blockPosition}].select();
                $("#character").css("background-image","url('../assets/img/personaje/crayolin/crayolin_frente_verde.gif')"); 
            }, ${window.currentTime + 500}); `;
      }
    } else if (window.avatarType == 3) {
      if (window.coordenada.slice(0, -1) == window.blocksYesAllowed && checkOrder) {
        Fire.$emit('activityDone');
        code += `
                  setTimeout(function(){
                      window.blocks[${blockPosition}].select();
                      $("#character").css("background-image","url('../assets/img/personaje/robot/frenteparado.gif')"); 
                  }, ${window.currentTime + 500}); `;
      }

    }
    if (!checkOrder)
      Fire.$emit('activityDone');
  }
  //console.log('arrayColors',window.arrayColors);
  return code;
}

export function movePosition(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder) {
  return avatarMovePosition(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder);
}

export function calculateDirection(position, degrees, direction) {
  return avatarCalculateDirection(position, degrees, direction);
}

export function movePositionWithTurns(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder, lastTurn) {
  return avatarMovePositionTurns(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder, lastTurn);
}

//copias para flor
export function calculateDirectionDos(position, degrees, direction) {
  return avatarCalculateDirectionDos(position, degrees, direction);
}

export function movePositionWithTurnsDos(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder, lastTurn) {
  return avatarMovePositionTurnsDos(direction, maxPositions, timeToPlay, blockPosition, animationPixels, isDrawing, positionsToMove, gifToPlay, checkOrder, lastTurn);
}