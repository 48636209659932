<template>
  <div>
    <div class="blocklyDiv" ref="blocklyDiv2">
    </div>
    <xml ref="blocklyToolbox2" style="display:none">
      <slot></slot>
    </xml>
    <xml ref="startBlocks3" style="display:none" v-if="$route.query.multiple == 2">
        <block type="procedures_defnoreturn" x="20" y="20" editable="false" deletable="false" executable="false">
          <title name="NAME">Trazar camino</title>
          <statement name="STACK" v-pre>
            <block type="repeats" movable="false" deletable="false">
              <title name="TIMES">4</title>
              <statement name="DO">
                <block type="avanzar" movable="false" deletable="false" editable="false">					
                  <next>
                    <block type="collectGema" movable="false" deletable="false" editable="false"></block>
                  </next>
                </block>
              </statement>
            </block>
          </statement>
        </block>
    </xml>
    <xml ref="startBlocks4" style="display:none" v-if="$route.query.multiple == 3">
        <block type="procedures_defnoreturn" x="20" y="20" deletable="false" editable="false">												
          <title name="NAME">Encuentra gema</title>
          <statement name="STACK" v-pre>
            <block type="avanzar" movable="false" deletable="false" editable="false">																															
              <next>
                <block type="avanzar" movable="false" deletable="false" editable="false">																															
                  <next>
                    <block type="collectGema" movable="false" deletable="false" editable="false"></block>
                  </next>
                </block>
              </next>
            </block>
          </statement>
        </block>
    </xml>
    <xml ref="startBlocks5" style="display:none" v-if="$route.query.multiple == 4">
        <block type="procedures_defnoreturn"  deletable="false" editable="false" x="20" y="20">
          <field name="NAME">Camino con salto</field>
          <statement name="STACK" v-pre>
            <block type="repeats" movable="false" deletable="false" >
              <title name="TIMES">2</title>
              <statement name="DO">
                <block type="repeats" movable="false" deletable="false" >
                  <title name="TIMES">2</title>
                  <statement name="DO">
                    <block type="avanzar" movable="false" deletable="false" editable="false">
                      <next>
                        <block type="collectGema" movable="false" deletable="false" editable="false">
                        </block>
                      </next>
                    </block>
                  </statement>
                  <next>
                    <block type="avanzar" movable="false" deletable="false" editable="false">
                    </block>
                  </next>
                </block>
              </statement>
            </block>
          </statement>
        </block>
    </xml>
    <xml ref="startBlocks6" style="display:none" v-if="$route.query.multiple == 5">
        <block type="procedures_defnoreturn" x="20" y="20"  deletable="false" editable="true">
          <field name="NAME">Diagonal ascendente</field>
          <statement name="STACK" v-pre>
            <block type="repeats" movable="false" deletable="false">
              <title name="TIMES">1</title>
              <statement name="DO" editable="true">
                <block type="avanzar" movable="false" deletable="false" editable="false">
                  <next>
                    <block type="girar" movable="false" deletable="false">
                      <next>
                        <block type="avanzar" movable="false" deletable="false" editable="false">
                          <next>
                            <block type="collectGema" movable="false" deletable="false" editable="false">
                              <next>
                                <block type="girar" movable="false" deletable="false">
                                    <field name="DIR">derecha</field>
                                </block>
                              </next>
                            </block>
                          </next>
                        </block>
                      </next>
                    </block>
                  </next>
                </block>
              </statement>
            </block>
          </statement>
        </block>
    </xml>
    <xml ref="startBlocks7" style="display:none" v-if="$route.query.multiple == 6">
        <block type="procedures_defnoreturn"  x="20" y="20" deletable="false" editable="false">
          <field name="NAME">Diagonal descendente</field>
          <statement name="STACK" v-pre>
            <block type="repeats" movable="false" deletable="false">
              <title name="TIMES">1</title>
              <statement name="DO">
                <block type="avanzar" movable="false" deletable="false" editable="false">
                  <next>
                    <block type="girar" movable="false" deletable="false">
                          <field name="DIR">derecha</field>   
                      <next>
                        <block type="avanzar" movable="false" deletable="false" editable="false">
                          <next>
                            <block type="collectGema" movable="false" deletable="false" editable="false">
                              <next>
                                <block type="girar" movable="false" deletable="false">
                                </block>
                              </next>
                            </block>
                          </next>
                        </block>
                      </next>
                    </block>
                  </next>
                </block>
              </statement>
            </block>
          </statement>
        </block>
    </xml>
    <xml ref="startBlocks8" style="display:none" v-if="$route.query.multiple == 7">
      <block type="procedures_defnoreturn"  x="20" y="20" deletable="false" editable="false">
        <field name="NAME">Diagonal descendente</field>
        <statement name="STACK" v-pre>
          <block type="repeats" movable="false" deletable="false">
            <title name="TIMES">1</title>
            <statement name="DO">
              <block type="avanzar" movable="false" deletable="false" editable="false">
                <next>
                  <block type="girar" movable="false" deletable="false">
                        <field name="DIR">derecha</field>   
                    <next>
                      <block type="avanzar" movable="false" deletable="false" editable="false">
                        <next>
                          <block type="collectGema" movable="false" deletable="false" editable="false">
                            <next>
                              <block type="girar" movable="false" deletable="false">
                              </block>
                            </next>
                          </block>
                        </next>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
            </statement>
          </block>
        </statement>
      </block>
      <block type="procedures_defnoreturn" x="20" y="300" deletable="false" editable="false">
        <field name="NAME">Diagonal ascendente</field>
        <statement name="STACK" v-pre>
          <block type="repeats" movable="false" deletable="false">
            <title name="TIMES">1</title>
            <statement name="DO">
              <block type="avanzar" movable="false" deletable="false" editable="false">
                <next>
                  <block type="girar" movable="false" deletable="false">
                    <next>
                      <block type="avanzar" movable="false" deletable="false" editable="false">
                        <next>
                          <block type="collectGema" movable="false" deletable="false" editable="false">
                            <next>
                              <block type="girar" movable="false" deletable="false">
                                  <field name="DIR">derecha</field>
                              </block>
                            </next>
                          </block>
                        </next>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
            </statement>
          </block>
        </statement>
      </block>
    </xml>
    <xml ref="startBlocks9" style="display:none" v-if="$route.query.multiple == 8">
      <block type="procedures_defnoreturn" x="20" y="20" deletable="false" editable="false" >
        <field name="NAME">Camino horizontal</field>
        <statement name="STACK" v-pre>
          <block type="girar" movable="false" deletable="false">
              <field name="DIR">derecha</field>
            <next>
              <block type="avanzar" movable="false" deletable="false" editable="false">
                <next>
                      <block type="avanzar" movable="false" deletable="false" editable="false">
                        <next>
                          <block type="girar" movable="false" deletable="false">
                              <field name="DIR">derecha</field>   
                          </block>
                        </next>
                      </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>
      <block type="procedures_defnoreturn" x="20" y="250"  deletable="false" editable="false"  >
        <field name="NAME">Camino vertical</field>
        <statement name="STACK" v-pre>
          <block type="avanzar" movable="false" deletable="false" editable="false">
            <next>
              <block type="avanzar" movable="false" deletable="false" editable="false">
                <next>
                  <block type="collectGema" movable="false" deletable="false" editable="false">
                    <next>
                      <block type="girar" movable="false" deletable="false">
                        <next>
                          <block type="girar" movable="false" deletable="false">
                            <next>
                              <block type="avanzar" movable="false" deletable="false" editable="false">
                                <next>
                                  <block type="avanzar" movable="false" deletable="false" editable="false">
                                  </block>
                                </next>
                              </block>
                            </next>
                          </block>
                        </next>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>
    </xml>
    <xml ref="startBlocks10" style="display:none" v-if="$route.query.multiple == 9">
      <block type="procedures_defnoreturn" x="10" y="50" deletable="false" editable="false" >
        <field name="NAME">Camino horizontal</field>
        <statement name="STACK" v-pre>
          <block type="girar" movable="false" deletable="false">
              <field name="DIR">derecha</field>
            <next>
              <block type="avanzar" movable="false" deletable="false" editable="false">
                <next>
                      <block type="avanzar" movable="false" deletable="false" editable="false">
                        <next>
                          <block type="girar" movable="false" deletable="false">
                              <field name="DIR">derecha</field>   
                          </block>
                        </next>
                      </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>
      <block type="procedures_defnoreturn" x="250" y="50"  deletable="false" editable="false"  >
        <field name="NAME">Camino vertical</field>
        <statement name="STACK" v-pre>
          <block type="avanzar" movable="false" deletable="false" editable="false">
            <next>
              <block type="avanzar" movable="false" deletable="false" editable="false">
                <next>
                  <block type="collectGema" movable="false" deletable="false" editable="false">
                    <next>
                      <block type="girar" movable="false" deletable="false">
                        <next>
                          <block type="girar" movable="false" deletable="false">
                            <next>
                              <block type="avanzar" movable="false" deletable="false" editable="false">
                                <next>
                                  <block type="avanzar" movable="false" deletable="false" editable="false">
                                  </block>
                                </next>
                              </block>
                            </next>
                          </block>
                        </next>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>
      <block type="procedures_defnoreturn" x="20" y="220" deletable="false" editable="false">
        <field name="NAME">Desplazamiento</field>
        <statement name="STACK" v-pre>
          <block type="procedures_callnoreturn" movable="false" deletable="false" editable="false">
            <mutation name="Camino vertical"></mutation>
            <next>
              <block type="procedures_callnoreturn" movable="false" deletable="false" editable="false">
                <mutation name="Camino horizontal"></mutation>
                <next>
                  <block type="procedures_callnoreturn" movable="false" deletable="false" editable="false">
                    <mutation name="Camino vertical"></mutation>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>
    </xml>
    <xml ref="startBlocks11" style="display: none" v-if="$route.query.multiple == 10">
      <block type="procedures_defnoreturn" x="1" y="10" Editable="false" Deletable="false">
          <field name="NAME">Camino Horizontal</field>
          <statement name="STACK" v-pre>
            <block type="establecer" Deletable="false">
                <value name="Variable">
                  <block type="variable" Editable="false" Deletable="false">
                      <field name="Variable" >Horizontal</field>
                  </block>                                                          
                </value>
                <value name="Numero">
                    <block type="math_number" Deletable="false">
                      <field name="NUM">7</field>
                    </block>                                                          
                </value>
              <next>
                <block type="controls_repeat_ext" Deletable="false">
                  <value name="Variable">
                      <block type="variable" Editable="false" Deletable="false">
                          <field name="Variable" >Horizontal</field>
                      </block>                                                          
                  </value>
                  <statement name="DO">
                    <block type="avanzar" Deletable="false">
                    </block>
                  </statement>
                  <next>
                    <block type="girar" Deletable="false">
                        <field name="DIR">derecha</field>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </statement>
      </block>
      <block type="procedures_defnoreturn" x="1" y="250" Deletable="false" Editable="false">
        <field name="NAME">Camino Vertical</field>
        <statement name="STACK" v-pre>
          <block type="establecer" Deletable="false">
              <value name="Variable">
                <block type="variable" Editable="false" Deletable="false">
                    <field name="Variable" >Vertical</field>
                </block>                                                          
              </value>
              <value name="Numero">
                  <block type="math_number" Deletable="false">
                    <field name="NUM">3</field>
                  </block>                                                          
              </value>
            <next>
              <block type="controls_repeat_ext" Deletable="false">
                <value name="Variable">
                    <block type="variable" Editable="false" Deletable="false">
                        <field name="Variable" >Vertical</field>
                    </block>         
                </value>
                <statement name="DO">
                  <block type="avanzar" Deletable="false">
                  </block>
                </statement>
                <next>
                  <block type="collectGema" Deletable="false">
                  </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>               
    </xml>
    <xml ref="startBlocks12" style="display: none" v-if="$route.query.multiple == 11"> 
      <block type="procedures_defnoreturn" x="1" y="10" Editable="false" Deletable="false">
        <field name="NAME">Trazar Cuadrado</field>
        <statement name="STACK" v-pre>
          <block type="establecer" Deletable="false">
                  <value name="Variable">
                    <block type="variable" Editable="false" Deletable="false">
                        <field name="Variable" >Lados</field>
                    </block>                                                          
                  </value>
                  <value name="Numero">
                      <block type="math_number" Deletable="false">
                        <field name="NUM">4</field>
                      </block>                                                          
                  </value>
              <next>
              <block type="establecer" Deletable="false">
              <value name="Variable">
                    <block type="variable" Editable="false" Deletable="false">
                        <field name="Variable" >Longitud</field>
                    </block>                                                          
                  </value>
                  <value name="Numero">
                      <block type="math_number" Deletable="false">
                        <field name="NUM">4</field>
                      </block>                                                          
                  </value>
                <next>
                  <block type="controls_repeat_ext" Deletable="false">
                    <value name="Variable">
                      <block type="variable" Editable="false" Deletable="false">
                        <field name="Variable" >Lados</field>
                      </block>
                    </value>
                    <statement name="DO">
                      <block type="controls_repeat_ext" Deletable="false">
                        <value name="Variable">
                          <block type="variable" Editable="false" Deletable="false">
                            <field name="Variable" >Longitud</field>
                          </block>
                        </value>
                        <statement name="DO">
                          <block type="avanzar" Deletable="false">
                            <next>
                              <block type="collectGema" Deletable="false">
                              </block>
                            </next>
                          </block>
                        </statement>
                        <next>
                          <block type="girar" Deletable="false"></block>
                        </next>
                      </block>
                    </statement>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>
		</xml>
    <xml ref="startBlocks13" style="display: none" v-if="$route.query.multiple == 12">
      <block type="procedures_defnoreturn" x="1" y="10" Editable="false" Deletable="false">
        <field name="NAME">Trazar Cuadrado</field>
        <statement name="STACK" v-pre>
          <block type="controls_repeat_ext" Deletable="false">
            <value name="Variable">
              <block type="variable"  Editable="false" Deletable="false">
                <field name="Variable">Lados</field>
              </block>
            </value>
            <statement name="DO">
              <block type="controls_repeat_ext" Deletable="false">
                <value name="Variable">
                  <block type="variable" Editable="false" Deletable="false">
                    <field name="Variable">Longitud</field>
                  </block>
                </value>
                <statement name="DO">
                  <block type="avanzar" Deletable="false">
                    <next>
                      <block type="collectGema" Deletable="false">
                      </block>
                    </next>
                  </block>
                </statement>
                <next>
                  <block type="girar" Deletable="false">
                  </block>
                </next>
              </block>
            </statement>
          </block>
        </statement>
      </block>
      <block type="procedures_defnoreturn" x="1" y="300" Editable="false" Deletable="false">
        <field name="NAME">Trazar Ele</field>
        <statement name="STACK" v-pre>
          <block type="establecer" Deletable="false">
                <value name="Variable">
                    <block type="variable" Editable="false" Deletable="false">
                        <field name="Variable" >Lados</field>
                    </block>                                                          
                </value>
                <value name="Numero">
                  <block type="math_number" Deletable="false">
                    <field name="NUM">2</field>
                  </block>                                                          
                </value>
            <next>
              <block type="controls_repeat_ext" Deletable="false">
                <value name="Variable">
                  <block type="variable" Editable="false" Deletable="false">
                    <field name="Variable">Lados</field>
                  </block>
                </value>
                <statement name="DO">
                  <block type="controls_repeat_ext" Deletable="false">
                    <value name="Variable">
                      <block type="variable" Editable="false" Deletable="false">
                        <field name="Variable">Longitud</field>
                      </block>
                    </value>
                    <statement name="DO">
                      <block type="avanzar" Deletable="false">
                        <next>
                          <block type="collectGema" Deletable="false">
                          </block>
                        </next>
                      </block>
                    </statement>
                    <next>
                      <block type="girar" Deletable="false">
                      </block>
                    </next>
                  </block>
                </statement>
              </block>
            </next>
          </block>
        </statement>
      </block>
		</xml>
    <xml ref="startBlocks14" style="display: none" v-if="$route.query.multiple == 13">
      <block type="procedures_defnoreturn" x="1" y="10" Deletable="falase" Editable="false">
        <field name="NAME">Conector</field>
        <statement name="STACK" v-pre>
          <block type="controls_repeat_ext" Deletable="falase">
            <value name="Variable">
              <block type="variable"  Editable="false" Deletable="falase">
                <field name="Variable">Conexión</field>
              </block>
            </value>
            <statement name="DO">
              <block type="controls_repeat_ext" Deletable="falase">
                <value name="Variable">
                  <block type="variable"  Editable="false" Deletable="falase">
                    <field name="Variable">Longitud</field>
                  </block>
                </value>
                <statement name="DO">
                  <block type="avanzar" Deletable="falase"></block>
                </statement>
                <next>
                  <block type="girar" Deletable="falase">
                      <field name="DIR">derecha</field>
                  </block>
                </next>
              </block>
            </statement>
            <next>
              <block type="collectGema" Deletable="falase"></block>
            </next>
          </block>
        </statement>
      </block>
      <block type="procedures_defnoreturn" x="1" y="250" Editable="false" Deletable="falase">
        <field name="NAME">Trazar cuadrado</field>
        <statement name="STACK" v-pre>
          <block type="controls_repeat_ext" Deletable="falase">
            <value name="Variable">
              <block type="variable"  Editable="false" Deletable="falase">
                <field name="Variable">Lados</field>
              </block>
            </value>
            <statement name="DO">
              <block type="controls_repeat_ext" Deletable="falase">
                <value name="Variable">
                  <block type="variable"  Editable="false" Deletable="falase">
                    <field name="Variable">Longitud</field>
                  </block>
                </value>
                <statement name="DO">
                  <block type="avanzar" Deletable="falase"></block>
                </statement>
                <next>
                  <block type="collectGema" Deletable="falase">
                    <next>
                      <block type="girar" Deletable="falase"></block>
                    </next>
                  </block>
                </next>
              </block>
            </statement>
          </block>
        </statement>
      </block>
		</xml>
    <xml ref="startBlocks15" style="display: none" v-if="$route.query.multiple == 14">
      <block type="procedures_defnoreturn" x="1" y="10" Editable="false" Deletable="false">
        <field name="NAME">Trazar cuadrado</field>
        <statement name="STACK" v-pre>
          <block type="controls_repeat_ext" Deletable="false">
            <value name="Variable">
              <block type="variable" Editable="false" Deletable="false">
                <field name="Variable">Lados</field>
              </block>
            </value>
            <statement name="DO">
              <block type="controls_repeat_ext" Deletable="false">
                <value name="Variable">
                  <block type="variable" Editable="false" Deletable="false">
                    <field name="Variable">Longitud</field>
                  </block>
                </value>
                <statement name="DO">
                  <block type="avanzar" Deletable="false"></block>
                </statement>
                <next>
                  <block type="collectGema" Deletable="false">
                    <next>
                      <block type="girar" Deletable="false" id="girar1">
                        <field name="DIR">derecha</field>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
            </statement>
          </block>
        </statement>
      </block>                            
      <block type="procedures_defnoreturn" x="1" y="250" Editable="false" Deletable="false">
        <field name="NAME">Enlace</field>
        <statement name="STACK" v-pre>
              <block type="controls_repeat_ext" Deletable="false">
                <value name="Variable">
                  <block type="variable" Editable="false" Deletable="false">
                    <field name="Variable">Longitud</field>
                  </block>
                </value>
                <statement name="DO">
                  <block type="avanzar" Deletable="false"></block>
                </statement>
                <next>
                  <block type="girar" Deletable="false" id="girar2">
                    <next>
                      <block type="avanzar" Deletable="false">
                        <next>
                          <block type="girar" Deletable="false" id="girar3">
                            <field name="DIR">derecha</field>
                          </block>
                        </next>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
        </statement>
      </block>                              
		</xml>
    <xml ref="startBlocks16" style="display: none" v-if="$route.query.multiple == 15">
      <block type="procedures_defnoreturn" x="1" y="10" Editable="false" Deletable="false">
        <field name="NAME">Horizontal</field>
        <statement name="STACK" v-pre>
          <block type="controls_repeat_ext" Deletable="false">
            <value name="Variable">
              <block type="variable" Editable="false" Deletable="false">
                <field name="Variable">Lados</field>
              </block>
            </value>
            <statement name="DO">
              <block type="avanzar" Deletable="false">
              </block>
            </statement>
            <next>
              <block type="collectGema" Deletable="false">
                <next>
                  <block type="girar" Deletable="false">
                      <field name="DIR">derecha</field>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>
      <block type="procedures_defnoreturn" x="1" y="250" Editable="false" Deletable="false">
        <field name="NAME">Vertical</field>
        <statement name="STACK" v-pre>
          <block type="controls_repeat_ext" Deletable="false">
            <value name="Variable">
              <block type="variable" Editable="false" Deletable="false">
                <field name="Variable" >Longitud</field>
              </block>
            </value>
            <statement name="DO">
              <block type="avanzar" Deletable="false">
              </block>
            </statement>
            <next>
              <block type="collectGema"  Deletable="false">
                <next>
                  <block type="girar"  Deletable="false">
                      <field name="DIR">derecha</field>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
      </block>                                            
		</xml>

  </div>
</template>

<script>
/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @fileoverview Blockly Vue Component.
 * @author samelh@google.com (Sam El-Husseini)
 */
/* eslint-disable */
import Blockly from 'blockly';
import '../blocks/stocks';
export default {
  name: 'BlocklyComponentParte',
  props: ['options'],
  data(){
    return {
      workspace: null,
      blocklyDisplay: 0,
    }
  },
  mounted() {
    Fire.$on('startBlockly', () => {
      if(this.blocklyDisplay == 0) {
        setTimeout(() => {
          var options = this.$props.options || {};
          if (!options.toolbox) {
            options.toolbox = this.$refs["blocklyToolbox2"];
          }
          this.workspace = Blockly.inject(this.$refs["blocklyDiv2"], options);
          if(this.$route.query.multiple == 2)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks3'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 3)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks4'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 4)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks5'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 5)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks6'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 6)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks7'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 7)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks8'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 8)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks9'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 9)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks10'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 10)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks11'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 11)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks12'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 12)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks13'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 13)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks14'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 14)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks15'], Blockly.mainWorkspace);
          if(this.$route.query.multiple == 15)
            Blockly.Xml.domToWorkspace(this.$refs['startBlocks16'], Blockly.mainWorkspace);
          
          this.blocklyDisplay = 1
        }, 500);
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blocklyDiv {
  height: 100%;
  width: 100%;
  text-align: left;
}
</style>