<template>
  <body class="sidebar-noneoverflow starterkit">
    <!--  BEGIN NAVBAR  -->
    <div class="header-container fixed-top">
      <app-header></app-header>
    </div>
    <!--  END NAVBAR  -->
    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <!-- CONTENT AREA -->
          <h2 class="text-center text-primary font-weight-bold">{{$route.query.world_name}}</h2>
          <h2 class="display-4 text-center">{{ $t('text.placeText') }}</h2>
          <div class="row layout-top-spacing">
            <template v-for="subWorld in subWorlds">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-1 col-12 layout-spacing" v-if="subWorld.initial_question > 0" :key="subWorld.id">
                <div class="card">
                  <a href="javascript:void(0)" class="btn btn-primary">
                  <input type="image" 
                  name="botondeenvio" 
                  :src="subWorld.image" 
                  class="card-img-top" 
                  alt="widget-card-2">
                  </a>
                  <div class="card-body">
                    <template v-if="$store.getters.language == 'es'">
                      <h5 class="card-title">{{ subWorld.name }}</h5>
                    </template>
                    <template v-if="$store.getters.language != 'es'">
                      <h5 class="card-title" v-if="subWorld.translations.length > 0 || $store.getters.language != 'es'">{{ subWorld.translations.find(element => element.language_code == $store.getters.language).translation }}</h5>
                    </template>
                    <a :href="`${activityType.initial_route}?idQuestion=${activityType.initial_question}&subworld_id=${subWorld.id}&multiple=${activityType.multiple}&activityType=${activityType.id}`" v-for="activityType in subWorld.activityTypes" :key="activityType.id" class="mr-2" :title="'Actividades de ' + activityType.name" v-show="activityType.initial_question > 0">
                      <img :src="activityType.icon" width="40"/>
                    </a>
                    <!--<router-link
                      :to="subWorld.initial_route + subWorld.initial_question + '&subworld_id=' + subWorld.id + '&multiple=' + subWorld.multiple"
                      class="btn btn-primary"
                      ><i class="fa fa-sign-in"></i> Entrar</router-link
                    > -->
                  </div> 
                </div>
              </div>
            </template>
          </div>
          <!--//row-->
        </div>
      </div>
      <!--  END CONTENT AREA  -->
    </div>
    <!-- END MAIN CONTAINER -->

    <!-- BEGIN PAGE LEVEL PLUGINS/CUSTOM SCRIPTS -->
  </body>
</template>
<script>
/* eslint-disable */
import AppHeader from "../components/AppHeader.vue";

export default {
  name: "mundos",
  components: { AppHeader },
  data() {
    return {
      initialQuestionId: '',
      subWorlds: [],
      role_id: 0,
    }
  },
  async mounted() {
    const script = document.createElement("script");
    script.src = "js/jquery.min.js";
    document.body.appendChild(script);

    this.initialQuestionId = localStorage.initialQuestionId
    this.role_id = localStorage.iRol

    this.openLoading()
    await this.getSubworlds(this.$route.query.world_id, localStorage.idGrade, localStorage.iRol)
    Swal.close()
  },
};
</script>
