<template>
    <div class="modal fade" id="AppModalContent" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button   type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="text-align: center">
                  <div class="row col-12" v-if="title == 'Documentos'">
                    <div class="col-6">
                      <a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/DOSIFICACION_3.pdf" target="_blank"><img src="../assets/img/dosificacion_3.png"/></a>
                    </div>
                    <div class="col-6">
                      <a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/DOSIFICACION_4.pdf" target="_blank"><img src="../assets/img/dosificacion_4.png"/></a>
                    </div>
                    <div class="col-6">
                      <a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/DOSIFICACION_5.pdf" target="_blank"><img src="../assets/img/dosificacion_5.png"/></a>
                    </div>
                    <div class="col-6">
                      <a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/DOSIFICACION_6.pdf" target="_blank"><img src="../assets/img/dosificacion_6.png"/></a>
                    </div>
                    <div class="col-6">
                      <a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/DOSIFICACION_7.pdf" target="_blank"><img src="../assets/img/dosificacion_7.png"/></a>
                    </div>
                    <div class="col-6">
                      <a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/DOSIFICACION_8.pdf" target="_blank"><img src="../assets/img/dosificacion_8.png"/></a>
                    </div>
                    <div class="col-6">
                      <a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/DOSIFICACION_9.pdf" target="_blank"><img src="../assets/img/dosificacion_9.png"/></a>
                    </div>
                  </div>
                  <div class="row col-12" v-if="title == 'Videos'">
                    <div class="col-6">
                      <a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/SmartC3_promo_video.mp4" target="_blank"><img src="../assets/img/promo_smartc3.png"/></a>
                    </div>
                  </div>


                  <div class="row col-12" v-if="title == 'Guías didácticas'">           
                        <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                          <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">3 Años</a>
                          <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">4 Años</a>
                          <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">5 Años</a>
                          <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-sexto" role="tab" aria-controls="nav-sexto" aria-selected="false">6 Años</a>
                          <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-sep" role="tab" aria-controls="nav-sep" aria-selected="false">7 Años</a>
                          <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-oct" role="tab" aria-controls="nav-oct" aria-selected="false">8 Años</a>
                          <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-nov" role="tab" aria-controls="nav-nov" aria-selected="false">9 Años</a>
                        </div>
                      </nav>
                      <div class="tab-content" id="nav-tabContent">

                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div class="row mb-3">
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/1_Ciudad-Circo-Ensamble.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/1CirEns.png"/></a></div>
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/2_Ciudad-Circo-Movimiento.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/1CirMov.png"/></a></div>
                            </div>
                             <div class="row mb-3">
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/3_Ciudad-Circo-Musica.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/1CirMus.png"/></a></div>
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/4_Ciudad-Zoologico-Movimiento.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/1zoolMov.png"/></a></div>
                            </div>
                             <div class="row mb-3">
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/5_Ciudad-Circo-Trazo.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/1CirTraz.png"/></a></div>
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/6_Ciudad-Circo-Patrones.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/1CirPat.png"/></a></div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                          <div class="row mb-3">
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/4_1_Ciudad-ParqueDeDiversiones-Movimiento.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/4_ParqMov.png"/></a></div>
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/4_2_Ciudad-Circo-Direccion.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/4_CirDir.png"/></a></div>
                            </div>
                             <div class="row mb-3">
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/4_3_Ciudad-Circo-Musica.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/4_CirMus.png"/></a></div>
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/4_4_Ciudad-Circo-Trazo.pdf#toolbar=0&navpanes=0" target="_blank"><img src="../assets/img/4_CirTraz.png"/></a></div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/1_Ciudad-Circo-Direccion.pdf" target="_blank"><img src="../assets/img/5cirDir.png"/></a></div>
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/2_Ciudad-Circo-Patrones.pdf" target="_blank"><img src="../assets/img/5cirPat.png"/></a></div>
                            
                           </div>
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/3_Ciudad-Circo-Musica2.pdf" target="_blank"><img src="../assets/img/5cirMus.png"/></a></div>
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/4_Ciudad-Zoologico-Trazo.pdf" target="_blank"><img src="../assets/img/5ciuTra.png"/></a></div>
                            
                           </div>
                             <div class="row mb-3">
                              <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/5_TierraDeFantasia-TierraDeHadas-Movimiento.pdf" target="_blank"><img src="../assets/img/5tieMov.png"/></a></div>
                             </div>
                        </div>
                         <div class="tab-pane fade" id="nav-sexto" role="tabpanel" aria-labelledby="nav-contact-tab">
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/1_TierraDeFantasia-TierraDeHadas-Direccion.pdf" target="_blank"><img src="../assets/img/6tirDir.png"/></a></div>
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/2_TierraDeFantasia-Cameloth-Movimiento.pdf" target="_blank"><img src="../assets/img/6tirMov.png"/></a></div>
                           </div>
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/3_TierraDeFantasia-ValleDeGnomos-Movimiento.pdf" target="_blank"><img src="../assets/img/6tirGnoMov.png"/></a></div>
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/4_Ciudad-Circo-Musica.pdf" target="_blank"><img src="../assets/img/6cirMus.png"/></a></div>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="nav-sep" role="tabpanel" aria-labelledby="nav-contact-tab">
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/7/1.%20Isla%20del%20Caribe%20-%20La%20Playa%20-%20Movimiento.pdf" target="_blank"><img src="../assets/img/7/1.png"/></a></div>
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/7/2.%20Isla%20del%20Caribe%20-%20Cueva%20-%20Movimiento.pdf" target="_blank"><img src="../assets/img/7/2.png"/></a></div>
                           </div>
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/7/3_TierraDeFantasia_TierraDeHadas-Musica.pdf" target="_blank"><img src="../assets/img/7/3.png"/></a></div>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="nav-oct" role="tabpanel" aria-labelledby="nav-contact-tab">
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/8/1.%20Isla%20del%20Caribe%20-%20Cueva%20-%20Movimiento.pdf" target="_blank"><img src="../assets/img/8/1.png"/></a></div>
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/8/2.%20Isla%20del%20Caribe%20-%20Cueva%20-%20Trazo.pdf" target="_blank"><img src="../assets/img/8/2.png"/></a></div>
                           </div>
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/8/3.%20Isla%20del%20Caribe%20-%20Barco%20Pirata%20-%20Movimiento.pdf" target="_blank"><img src="../assets/img/8/3.png"/></a></div>
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/8/4.%20Isla%20del%20Caribe%20-%20La%20Playa%20-%20Musica.pdf" target="_blank"><img src="../assets/img/8/4.png"/></a></div>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="nav-nov" role="tabpanel" aria-labelledby="nav-contact-tab">
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/9/1.%20Isla%20de%20Monstruos%20-%20Monstruos%20de%20nieve%20-%20Movimiento.pdf" target="_blank"><img src="../assets/img/9/1.png"/></a></div>
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/9/2.%20Isla%20de%20Monstruos%20-%20Valle%20de%20los%20Monstruos%20-Movimiento.pdf" target="_blank"><img src="../assets/img/9/2.png"/></a></div>
                           </div>
                           <div class="row mb-3">
                            <div class="col-6"><a href="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/documentos/9/3.%20Isla%20del%20Caribe%20-%20La%20Playa%20-%20Musica.pdf" target="_blank"><img src="../assets/img/9/3.png"/></a></div>

                           </div>
                        </div>

                      </div>                  
                  </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
const tabNames = {
PRI:'Primero',
SEG:'Segundo',
TER:'Tercero',

};
export default {
  name: "AppModalContent",
  props: {
    help: {
      type: Number,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      image1: "../assets/pistas/pista25833-1.png",
      image2: "../assets/pistas/pista25833-2.png",
      videoUrl2: '',
      video: 0,
      help_number: 0,
      helpImages: [],

    }
    
  },


 
  methods: {
    closeModal() {
      $("#AppModalHelp").modal('hide')
    },
    
  },
  mounted() {
    
  }
};
</script>
