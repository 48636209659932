<template>
    <div class="sidebar-wrapper sidebar-theme">
        <nav id="compactSidebar">
            <img src="../assets/img/icono_sidebar.png" class="navbar-logo img-fluid" alt="logo">
            <ul class="menu-categories">
                <li class="menu">
                    <a href="#dashboard" data-active="false" class="menu-toggle">
                        <div class="base-menu">
                            <div class="base-icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                            </div>
                        </div>
                    </a>
                    <div class="tooltip"><span>Inicio</span></div>
                </li>

                <li class="menu" id="liTutorial">
                    <a href="#app" data-active="false" class="menu-toggle">
                        <div class="base-menu">
                            <div class="base-icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-film"><rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect><line x1="7" y1="2" x2="7" y2="22"></line><line x1="17" y1="2" x2="17" y2="22"></line><line x1="2" y1="12" x2="22" y2="12"></line><line x1="2" y1="7" x2="7" y2="7"></line><line x1="2" y1="17" x2="7" y2="17"></line><line x1="17" y1="17" x2="22" y2="17"></line><line x1="17" y1="7" x2="22" y2="7"></line></svg></div>
                        </div>
                    </a>
                    <div class="tooltip"><span>Video tutoriales</span></div>
                </li>

                <!--<li class="menu">
                    <a href="#users" data-active="false" class="menu-toggle">
                        <div class="base-menu">
                            <div class="base-icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                            </div>
                        </div>
                    </a>
                    <div class="tooltip"><span>Menu 3</span></div>
                </li>

                <li class="menu">
                    <a href="#more" data-active="false" class="menu-toggle">
                        <div class="base-menu">
                            <div class="base-icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        </div>
                        </div>
                    </a>
                    <div class="tooltip"><span>Actividades adicionales</span></div>
                </li>-->
            </ul>
        </nav>
        <div id="compact_submenuSidebar" class="submenu-sidebar">
            <div class="theme-brand-name">
                <a href="#"><img src="../assets/img/SmartC3_logo.png"></a>
            </div>
            <div class="submenu" id="dashboard">
                <div class="category-info">
                    <h5>Menu</h5>
                    <p></p>
                </div>

                <ul class="submenu-list" data-parent-element="#dashboard"> 
                    <li>
                        <router-link to="/worlds"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg> Inicio </router-link>
                    </li>
                </ul>
            </div>
            <div class="submenu" id="app">
                <div class="category-info">
                    <h5>Bloques</h5>
                    <p>Video Tutoriales</p>
                </div>
                <ul class="submenu-list" data-parent-element="#app">
                    <li>
                        <a href="#" @click.prevent="showVideo(10)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Introducción a SmartC3 </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 25847">
                        <a href="#" @click.prevent="showVideo(1)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Recoger y sembrar </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 25861">
                        <a href="#" @click.prevent="showVideo(2)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Repetir </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 25893 || $route.query.idQuestion == 25864 || $route.query.idQuestion == 25866 || $route.query.idQuestion == 25889">
                        <a href="#" @click.prevent="showVideo(3)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Dos veces repetir </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 26038">
                        <a href="#" @click.prevent="showVideo(4)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Si hay gema </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 25870">
                        <a href="#" @click.prevent="showVideo(5)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Trazar camino </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 25901 || $route.query.idQuestion == 25880">
                        <a href="#" @click.prevent="showVideo(6)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Dos personajes </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 25882">
                        <a href="#" @click.prevent="showVideo(7)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Variables </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 25906">
                        <a href="#" @click.prevent="showVideo(8)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Variables y funciones </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 26301">
                        <a href="#" @click.prevent="showVideo(11)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Trazo </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 26308">
                        <a href="#" @click.prevent="showVideo(12)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Bloque esperar </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 26306">
                        <a href="#" @click.prevent="showVideo(13)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Música </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 26307">
                        <a href="#" @click.prevent="showVideo(14)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Patrones </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 25938">
                        <a href="#" @click.prevent="showVideo(15)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Puntos cardinales </a>
                    </li>
                    <li v-if="$route.query.idQuestion == 26300">
                        <a href="#" @click.prevent="showVideo(16)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Salto </a>
                    </li>
                      <li v-if="$route.query.idQuestion == 26327">
                        <a href="#" @click.prevent="showVideo(17)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Música </a>
                    </li>
                     <li v-if="$route.query.idQuestion == 26325">
                        <a href="#" @click.prevent="showVideo(17)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Música </a>
                    </li>
                </ul>
            </div>
            <div class="submenu" id="users">
                <div class="category-info">
                    <h5>Menu 3</h5>
                    <p>Info menú 3</p>
                </div>
                <ul class="submenu-list" data-parent-element="#users">
                    <li>
                        <a href="javascript:void(0)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Submenu 1 </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Submenu 2 </a>
                    </li>
                    <li class="sub-submenu">
                        <a role="menu" class="collapsed" data-toggle="collapse" data-target="#starter-kit" aria-expanded="false"><div><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Submenu 3 </div> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></a>
                        <ul id="starter-kit" class="collapse" data-parent="#compact_submenuSidebar">
                            <li>
                                <a href="javascript:void(0);"> Sub Submenu 1 </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);"> Sub Submenu 2 </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);"> Sub Submenu 2 </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="submenu" id="more">
                <div class="category-info">
                    <h5>Menú 4</h5>
                    <p>Info menú 4</p>
                </div>
                <ul class="submenu-list" data-parent-element="#more">
                    <li class="active">
                        <a href="#"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Link 1 </a>
                    </li>
                    <li>
                        <a href="#"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Link 2 </a>
                    </li>
                    <li>
                        <a href="#"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg></span> Link 3 </a>
                    </li>
                </ul>
            </div>
        </div>
        <app-modal-help :help="0"></app-modal-help>
    </div>
</template>
<script>
/* eslint-disable */
import AppModalHelp from "./AppModalHelp.vue";
export default {
    name: 'AppSidebar',
    components: {
        AppModalHelp
    },
    data() {
        return {
        }
    },
    methods: {
        showVideo(video) {
            Fire.$emit('showVideo', video)
            $("#AppModalHelp").modal("show")
        }
    },
    mounted() {
        $("#liTutorial").addClass('with-video')
            if (this.$store.getters.language === 'es') {
                 if(this.$route.query.idQuestion == 26300)
                    this.showVideo(16)
                 if(this.$route.query.idQuestion == 25822)
                    this.showVideo(10)
                 if(this.$route.query.idQuestion == 26306)
                     this.showVideo(13)
                 if(this.$route.query.idQuestion == 26301) 
                     this.showVideo(11)
                 if(this.$route.query.idQuestion == 25938)
                     this.showVideo(15)
                 if(this.$route.query.idQuestion == 25847)
                     this.showVideo(1)
                 if(this.$route.query.idQuestion == 26308)
                     this.showVideo(12)
                 if(this.$route.query.idQuestion == 25861)
                     this.showVideo(2)
                 if(this.$route.query.idQuestion == 25864)
                     this.showVideo(3)
                 if(this.$route.query.idQuestion == 26038)
                     this.showVideo(4)
                 if(this.$route.query.idQuestion == 25870)
                     this.showVideo(5)
                 if(this.$route.query.idQuestion == 25880)
                     this.showVideo(6)
                 if(this.$route.query.idQuestion == 25882)
                     this.showVideo(7)
                 if(this.$route.query.idQuestion == 25906)
                     this.showVideo(8)
                 if(this.$route.query.idQuestion == 26307) 
                     this.showVideo(14)
                 if(this.$route.query.idQuestion == 26325)
                     this.showVideo(17)
                 if(this.$route.query.idQuestion == 26327)
                     this.showVideo(22)
                 if(this.$route.query.idQuestion == 26334)
                    this.showVideo(19)
                 if(this.$route.query.idQuestion == 26337) 
                    this.showVideo(20)
                 if(this.$route.query.idQuestion == 26312)
                    this.showVideo(21)
                 if(this.$route.query.idQuestion == 26342)
                    this.showVideo(23)
                 if(this.$route.query.idQuestion == 26351)
                    this.showVideo(24)

            } if (this.$store.getters.language !== 'es') {

                 if(this.$route.query.idQuestion == 26306)
                    this.showVideo(25)
                 if(this.$route.query.idQuestion == 26301) 
                    this.showVideo(29)
                 if(this.$route.query.idQuestion == 26307)  
                    this.showVideo(27)
                 if(this.$route.query.idQuestion == 26334)
                    this.showVideo(25)
                 if(this.$route.query.idQuestion == 26337) 
                    this.showVideo(28)
                 if(this.$route.query.idQuestion == 26342)
                    this.showVideo(26)
                 if(this.$route.query.idQuestion == 25938)
                    this.showVideo(30)  
                 if(this.$route.query.idQuestion == 26300)
                    this.showVideo(31) 
                 if(this.$route.query.idQuestion == 25861)
                    this.showVideo(32)
                 if(this.$route.query.idQuestion == 26038)
                    this.showVideo(33)
                 if(this.$route.query.idQuestion == 25870)
                    this.showVideo(34)
                 if(this.$route.query.idQuestion == 26312)
                    this.showVideo(35)
                 if(this.$route.query.idQuestion == 25882)
                    this.showVideo(36)
                 if(this.$route.query.idQuestion == 26335)
                    this.showVideo(37)
                 if(this.$route.query.idQuestion == 26336)
                    this.showVideo(38)
                 if(this.$route.query.idQuestion == 26349)
                    this.showVideo(39)
                 if(this.$route.query.idQuestion == 26350)
                    this.showVideo(40)
                 if(this.$route.query.idQuestion == 26311)
                    this.showVideo(41)
                 if(this.$route.query.idQuestion == 26324)
                    this.showVideo(42)
                 if(this.$route.query.idQuestion == 26325)
                    this.showVideo(43)
                 if(this.$route.query.idQuestion == 26327)
                    this.showVideo(44)
                 if(this.$route.query.idQuestion == 26328)
                    this.showVideo(45)
                 if(this.$route.query.idQuestion == 25822)
                    this.showVideo(46)

            }

       
        
        

        
    
    }
}
</script>
<style>
    .with-video {
        background-color: green;
    }
</style>