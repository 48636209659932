<template>
    <div class="modal fade" id="AppModalHelp" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button   type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="text-align: center">
                
                    <p v-if="help_number == 2 && video == 0 && $route.query.idQuestion  == 26327">
                    <img width="500" height="950" v-bind:src="image2" :key="image2" />
                    </p>
                    <p v-if="help_number == 2 && video == 0 && $route.query.idQuestion  != 26327">
                     <img width="500" height="650" v-bind:src="image2" :key="image2"/>
                    </p>
                    <p v-if="help_number == 1 && video == 0 && $route.query.activityType != 3">
                    <img width="400" height="450" v-bind:src="image1" :key="image1"/>
                    </p>
                    <video width="700" controls v-if="video == 1" id="videoTutorial">
                      <source src="../assets/videos/recoger_y_sembrar_nuevo.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 2" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Act%202_cameloth_mov.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 3" id="videoTutorial">
                      <source src="../assets/videos/dos_veces_repetir.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 4" id="videoTutorial">
                      <source src="../assets/videos/Si_hay_nuevo.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 5" id="videoTutorial">
                      <source src="../assets/videos/Trazar_camino.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 6" id="videoTutorial">
                      <source src="../assets/videos/dos_personajes.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 7" id="videoTutorial">
                      <source src="../assets/videos/variables.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 8" id="videoTutorial">
                      <source src="../assets/videos/practica9.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 9" id="videoTutorial">
                      <source :src="videoUrl2" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 10" id="videoTutorial">
                      <source src="../assets/videos/Introduccion SmartC3.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 11" id="videoTutorial">
                      <source src="../assets/videos/trazo 1.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 12" id="videoTutorial">
                      <source src="../assets/videos/bloque esperar.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 13" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Intro2Musica.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 14" id="videoTutorial">
                      <source src="../assets/videos/Patrones2.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 15" id="videoTutorial">
                      <source src="../assets/videos/puntos cardinales 2.mp4" type="video/mp4">
                    </video>
                    <video width="700" controls v-if="video == 16" id="videoTutorial">
                      <source src="../assets/videos/salto.mp4" type="video/mp4">
                    </video>
                     <video width="700" controls v-if="video == 17" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/pentagramaynotas2.mp4" type="video/mp4">
                    </video>
                     <video width="700" controls v-if="video == 19" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Intro_musica1.mp4" type="video/mp4">
                      </video>
                      <video width="700" controls v-if="video == 20" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/trazo1.mp4" type="video/mp4">
                      </video>
                      <video width="700" controls v-if="video == 21" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/pixeles.mp4" type="video/mp4">
                      </video>
                     <video width="700" controls v-if="video == 22" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos%20M%C3%BAsica/JingleBells.mp4" type="video/mp4">
                     </video>
                      <video width="700" controls v-if="video == 23" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/videosmusucaypatrones/Patrones_inicio.mp4" type="video/mp4">
                     </video>
                      <video width="700" controls v-if="video == 24" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Musica%201.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 25" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/ingles/intro_musica_ingle.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 26" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/pendientes/Patrones_intro_ingles.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 27" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/pendientes/Patrones2%20ingles.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 28" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/ingles/Ingles%20Trazo%201.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 29" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/ingles/trazo%201%20ingles.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 30" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/ingles/puntos%20cardinales%202%20ingles.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 31" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/ingles/salto%20ingles.mp4" type="video/mp4">
                     </video>
                      <video width="700" controls v-if="video == 32" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/pendientes/Introduccion%20SmartC3%20V2ingles.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 33" id="videoTutorial">
                      <source src="" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 34" id="videoTutorial">
                      <source src="" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 35" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/pendientes/trazo%201%20ingles.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 36" id="videoTutorial">
                      <source src="" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 37" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Little%20Star.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 38" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Happy%20Birthday.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 39" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Himno%201.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 40" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Are%20You%20Sleeping%20Brother%20Jhon.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 41" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Baby%20Shark.mp4" type="video/mp4">
                     </video>
                       <video width="700" controls v-if="video == 42" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Are%20You%20Sleeping%20Brother%20Jhon%202.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 43" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Happy%20Birthday%202.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 44" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Jingle%20Bells.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 45" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/Videos_ingles/Himno%201.mp4" type="video/mp4">
                     </video>
                     <video width="700" controls v-if="video == 46" id="videoTutorial">
                      <source src="https://f002.backblazeb2.com/file/csb-eThinking/EthinkingPro/smartc3/videos/pendientes/Introduccion%20SmartC3%20V2ingles.mp4" type="video/mp4">
                     </video>
                    <p v-if="video == 18">
                      <img :src="imagePartitura" :key="imagePartitura" width="800"/>
                    </p>

                    
                    <div id="carouselExampleControls" class="carousel slide" v-if="help_number == 1 && video == 0 && $route.query.activityType == 3">
                      <div class="carousel-inner">
                        <template v-for="(image, index) in helpImages">
                          <div :class=" index == 0 ? 'carousel-item active' : 'carousel-item'" :key="index">
                            <img class="d-block w-100" :src="image">
                          </div>
                        </template>
                      </div>
                      <a style="color: blue" class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" @click="prevSlide">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                      </a>                                            
                      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" @click="nextSlide">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                      </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
  name: "AppModalHelp",
  props: {
    help: {
      type: Number,
    },
  },
  data() {
    return {
      image1: "../assets/pistas/pista25833-1.png",
      image2: "../assets/pistas/pista25833-2.png",
      videoUrl2: '',
      video: 0,
      help_number: 0,
      title: 'Pista ',
      helpImages: [],
      imagePartitura: '',
    }
  },
  methods: {
    closeModal() {
      if(this.video == 1) {
        let myVideo = document.getElementById("videoTutorial");
        myVideo.pause()
      }
      if(this.video == 2) {
        let myVideo = document.getElementById("videoTutorial");
        myVideo.pause()
      }
      if(this.video == 3) {
        let myVideo = document.getElementById("videoTutorial");
        myVideo.pause()
      }
      if(this.video == 4 || this.video == 5 || this.video == 6 || this.video == 7 || this.video == 8 || this.video == 9 || this.video == 10 || this.video == 11 || this.video == 12 || this.video == 13 || this.video == 14 || this.video == 15 || this.video == 16|| this.video == 19|| this.video == 20|| this.video == 21) {
        let myVideo = document.getElementById("videoTutorial");
        myVideo.pause()
      }
      $("#AppModalHelp").modal('hide')
    },
    nextSlide() {
      $('.carousel').carousel('next');
    },
    prevSlide() {
      $('.carousel').carousel('prev');
    }
  },
  mounted() {
    Fire.$on('assignHelpImages', (data) => {
      this.image1 = window.helpImg1
      this.image2 = window.helpImg2
      this.help_number = data.help
      this.title = 'Pista ' + this.help_number
      this.video = 0

      if(this.help_number == 1 && this.video == 0 && this.$route.query.activityType == 3) {
        if(window.helpQuestion != null && window.helpQuestion != undefined) {
          this.helpImages = window.helpQuestion.help_images_url.split('|')
        }
        setTimeout(() => {
          
            $('.carousel').carousel({
              interval: 15000
            })
        }, 200);
      }
    })
    Fire.$on('showVideo', (video) => {
      if(video != 18) {
        this.video = video
        this.title = 'Video'
        this.videoUrl2 = window.videoUrl
      }

      if(video == 18) {
        this.video = video
        this.title = 'Partitura'  

       if(this.$store.getters.language == 'es'){
        if(this.$route.query.idQuestion == 26311)
          this.imagePartitura = '../assets/img/Partitura/Partitura_BabyShark.png'
        if(this.$route.query.idQuestion == 26324)
          this.imagePartitura = '../assets/img/Partitura/Partitura_Martinillo2.png'
        if(this.$route.query.idQuestion == 26325)
          this.imagePartitura = '../assets/img/Partitura/Partitura_HappyBirthday2.png'
        if(this.$route.query.idQuestion == 26326)
          this.imagePartitura = '../assets/img/Partitura/Partitura_Estrellita2.png'
        if(this.$route.query.idQuestion == 26327)
          this.imagePartitura = '../assets/img/Partitura/Partitura_JingleBells.png'
        if(this.$route.query.idQuestion == 26328)
          this.imagePartitura = '../assets/img/Partitura/Partitura_Himno2.png'
        if(this.$route.query.idQuestion == 26335)
           this.imagePartitura = '../assets/img/Partitura/Partitura_Estrellita1.png'
        if(this.$route.query.idQuestion == 26336)
          this.imagePartitura = '../assets/img/Partitura/Partitura_HappyBirthday1.png'
        if(this.$route.query.idQuestion == 26349)
          this.imagePartitura = '../assets/img/Partitura/Partitura_Himno1.png'
        if(this.$route.query.idQuestion == 26350)
          this.imagePartitura = '../assets/img/Partitura/Partitura_Martinillo1.png'
       }else{
          if(this.$route.query.idQuestion == 26311)
            this.imagePartitura = '../assets/img/Partitura/Partitura_BabyShark.png'
          if(this.$route.query.idQuestion == 26324)
            this.imagePartitura = '../assets/img/Partitura/ingles/The_City_Circus_Level_10_Are_You_Sleeping.png'
          if(this.$route.query.idQuestion == 26325)
            this.imagePartitura = '../assets/img/Partitura/Partitura_HappyBirthday2.png'
          if(this.$route.query.idQuestion == 26326)
            this.imagePartitura = '../assets/img/Partitura/ingles/Land_of_Fantasy_Fairy_Land_Level_2_Twinkle_twinkle.png'
          if(this.$route.query.idQuestion == 26327)
            this.imagePartitura = '../assets/img/Partitura/Partitura_JingleBells.png'
          if(this.$route.query.idQuestion == 26328)
            this.imagePartitura = '../assets/img/Partitura/ingles/Caribbean_Island_The_Beach_Level_2_Son_of_Joy.png'
          if(this.$route.query.idQuestion == 26335)
            this.imagePartitura = '../assets/img/Partitura/ingles/The_City_Circus_Level_3_Twinkle_Twinkle.png'
          if(this.$route.query.idQuestion == 26336)
            this.imagePartitura = '../assets/img/Partitura/Partitura_HappyBirthday1.png'
          if(this.$route.query.idQuestion == 26349)
            this.imagePartitura = '../assets/img/Partitura/ingles/The_City_Circus_Level_6_Song_of_Joy.png'
          if(this.$route.query.idQuestion == 26350)
            this.imagePartitura = '../assets/img/Partitura/ingles/The_City_Circus_Level_7_Are_You_sleeping.png'
       }        
         
      }
    })
  }
};
</script>