<template>
    <div></div>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2';
export default {
    name: 'Token',
    async mounted() {
        this.openLoading()
        await axios.post(apiETC + 'api/smartc3/v1/users/login-with-token', { token: this.$route.params.token }, { headers: clientAPI })
            .then( response => {
                Swal.close()
                if(response.data.status == true) {
                    localStorage.idPerson = response.data.user.id
                    localStorage.idActivation = response.data.user.id
                    localStorage.idGrade = response.data.user.grade_id
                    localStorage.iRol = response.data.user.role_id
                    localStorage.school_id = response.data.user.school_id
                    localStorage.sName = `${response.data.user.name} ${response.data.user.last_name}`
                    localStorage.tokenETCSmartC3 = `Bearer ${response.data.token}`
                    window.location.href = '/'
                }
                if(response.data.status == false) {
                    this.showMessage('Error', response.data.message, 'error', '', 1)
                }
            }).catch( error => {
                this.catchError(error)
            })
    }
}
</script>