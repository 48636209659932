<template>
    <div>
        <app-header></app-header>
        <div class="main-container" id="container">
            <div class="overlay"></div>
            <div class="search-overlay"></div>
            <app-sidebar></app-sidebar>
            <div id="content" class="main-content">
                <div class="layout-px-spacing">
                    <div class="container layout-spacing">
                        <h2 class="display-4 text-center">Recursos para el docente</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                             <a href="#" @click.prevent="showModal('Guías didácticas')">
                                <div class="col-lg-12 layout-spacing text-center">
                                    <div class="statbox widget box box-shadow">
                                        <div class="widget-header">
                                            <div class="row">
                                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                                    <h4>Guías didácticas</h4>
                                                    <img src="assets/img/docente/guide-book.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>                            
                                        <div class="widget-content widget-content-area">
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <!--<a href="#">
                                <div class="col-lg-12 layout-spacing text-center">
                                    <div class="statbox widget box box-shadow">
                                        <div class="widget-header">
                                            <div class="row">
                                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                                    <h4>Guías didácticas</h4>
                                                </div>
                                            </div>
                                        </div>                            
                                        <div class="widget-content widget-content-area">
                                        <img src="assets/img/docente/guide-book.png" class="img-fluid">

                                        </div>
                                    </div>
                                </div>
                            </a>-->
                        </div>
                        <div class="col-md-3">
                            <a href="#">
                                <div class="col-lg-12 layout-spacing text-center">
                                    <div class="statbox widget box box-shadow">
                                        <div class="widget-header">
                                            <div class="row">
                                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                                    <h4>Presentaciones</h4>
                                                    <img src="assets/img/docente/presentation.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>                            
                                        <div class="widget-content widget-content-area">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a href="#" @click.prevent="showModal('Documentos')">
                                <div class="col-lg-12 layout-spacing text-center">
                                    <div class="statbox widget box box-shadow">
                                        <div class="widget-header">
                                            <div class="row">
                                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                                    <h4>Dosificaciones</h4>
                                                    <img src="assets/img/docente/document.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>                            
                                        <div class="widget-content widget-content-area">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a href="#" @click.prevent="showModal('Videos')">
                                <div class="col-lg-12 layout-spacing text-center">
                                    <div class="statbox widget box box-shadow">
                                        <div class="widget-header">
                                            <div class="row">
                                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                                    <h4>Videos</h4>
                                                    <img src="assets/img/docente/video.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>                            
                                        <div class="widget-content widget-content-area">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-modal-content :title="title"></app-modal-content>
    </div>
</template>
<script>
/* eslint-disable */
import AppHeader from "../components/AppHeader";
import AppSidebar from "../components/AppSidebar.vue";
import AppModalContent from "../components/AppModalContent.vue";
export default {
    name: 'TeacherPanel',
    components: {
        AppHeader,
        AppSidebar,
        AppModalContent
    },
    data() {
        return {
            title: '',
        }
    },
    methods: {
        showModal(title) {
            this.title = title
            $("#AppModalContent").modal("show");
        }
    }
}
</script>