<template>
  <div>
    <app-header></app-header>
    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>
      <!--  BEGIN SIDEBAR  -->
      <app-sidebar></app-sidebar>
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-12 layout-spacing">
              <div class="widget-content-area">
                <div class="widget-content-area">
                  <div class="widget-one">
                    <div class="row mt-n4">
                      <div class="col-md-11 col-sm-10">
                        <div id="texto" :key="instructions">{{ instructions }}</div>
                        <button class="btn btn-primary btn-sm btn-rounded mr-1" @click="evaluate" id="btnEvaluate"> Evaluar <i class="fa fa-send"></i></button>
                        <button id="runcode" class="btn btn-success btn-sm btn-rounded" @click="runCode"> Probar <i class="fa fa-play"></i></button>
                        <button class="btn btn-warning mr-2 rounded-circle bs-tooltip float-right" data-placement="right" title=""
                          data-original-title="Pista" @click="showModalHelp"><i class="fa fa-lightbulb-o"></i></button>
                        <button class="btn btn-warning mr-2 rounded-circle bs-tooltip float-right" data-placement="right" title=""
                          data-original-title="Funciones" @click="showModalCode" v-if="$route.query.multiple > 1 && $route.query.multiple != 16"><i class="fa fa-code"></i></button>
                      </div>
                      <div class="col-md-1 col-sm-1">
                        <button id="runcodeDos" class="btn btn-info rounded-circle" @click.prevent="playSound(instructions_audio)"><i class="fa fa-volume-up"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="widget-one" style="height: 400px">
                  <BlocklyComponent id="blockly1" ref="blockly1" :options="options" v-if="arrayBlocks.length > 0" :key="activity_id">
                    <block :type="block.type" :class="block.class" v-for="(block, index) in arrayBlocks" :key="index"></block>
                    <!--<block type="right" class="right"></block>
                    <block type="up" class="up"></block>
                    <block type="down" class="down"></block>
                    <block type="bellota" class="down" v-if="activityDone == 1"></block>
                    <block type="sembrar" class="down"></block>
                    <block type="avanzar" class="down"></block>
                    <block type="girar" class="down"></block>
                    <block type="axion" class="down"></block>
                   <block type="axion_b" class="down"></block>
                    <block type="direccion" class="down"></block>
                    <block type="direccion_b" class="down"></block>
                    <block type="collectGema" class="down"></block>
                    <block type="haygema" class="down"></block>
                    <block type="repeats" class="down"></block>
                    <block type="collectSnow" class="down"></block>
                    <block type="pillHole" class="down"></block>
                    <block type="trazarCamino" class="down"></block>
                    <block type="trazarCuadrado" class="down"></block>
                    <block type="trazarCuadrado2" class="down"></block>
                    <block type="trazarEle" class="down"></block>
                    <block type="encuentraGema" class="down"></block>
                    <block type="caminoHorizontal" class="down"></block>
                    <block type="caminoVertical" class="down"></block>
                    <block type="say" class="down"></block>
                    <block type="sayp" class="down"></block>
                    <block type="avanzarp" class="down"></block>
                    <block type="elegirp" class="down"></block>
                    <block type="mostrarp" class="down"></block>
                    <block type="animop" class="down"></block>
                    <block type="choquep" class="down"></block>
                    <block type="terminarh" class="down"></block>
                    <block type="stone" class="down"></block>
                    <block type="Linea_4_pasos" class="down"></block> -->
                  </BlocklyComponent>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12 layout-spacing">
              <div class="widget-content-area br-4 mt-5">
                <div id="canvas-wrap">
                  <canvas id="canvas" width="600" height="600" style="z-index: 3"></canvas>
                  <div class="widget-one scenario" id="overlay">
                    <div id="tire"></div>
                    <div id="gorilla"></div>
                    <div id="character"></div>
                    <div id="character3"></div>
                    <div id="gem"></div>
                    <div id="gem2"></div>
                    <div id="gem3"></div>
                    <div id="gem4"></div>
                    <div id="gem5"></div>
                    <div id="gem6"></div>
                    <div id="gem7"></div>
                    <div id="gem8"></div>
                    <div id="gem9"></div>
                    <div id="gem10"></div>
                    <div id="gem11"></div>
                    <div id="gem12"></div>
                    <div id="gem13"></div>
                    <div id="gem14"></div>
                    <div id="gem15"></div>
                    <div id="gem16"></div>
                    <div id="gem17"></div>
                    <div id="gem18"></div>
                    <div id="gem19"></div>
                    <div id="palette"></div>
                    <div id="icecream"></div>
                    <div id="gift"></div>
                    <div id="gift2"></div>
                    <div id="gift3"></div>
                    <div id="sweet"></div>
                    <div id="tail"></div>
                    <div id="eyes"></div>
                    <div id="panda"></div>
                    <div id="duck1"></div>
                    <div id="duck2"></div>
                    <div id="delfin"></div>
                    <div id="oso"></div>
                    <div id="rusa"></div>
                    <div id="targetShooting"></div>
                    <div id="waterJet"></div>
                    <div id="Payaso1"></div>
                    <div id="swing"></div>
                    <div id="semilla-01"></div>
                    <div id="circle-dark"></div>
                    <div id="circle-dark1"></div>
                    <div id="catarina"></div>
                    <div id="hada2"></div>
                    <div id="hada3"></div>
                    <div id="LamparaCorazon"></div>
                    <div id="z"></div>
                    <div id="hada1"></div>
                    <div id="luzventana"></div>
                    <div id="bellota1"></div>
                    <div id="bellota2"></div>
                    <div id="bellota3"></div>
                    <div id="bellota4"></div>
                    <div id="bellota5"></div>
                    <div id="Rana"></div>
                    <div id="Principe"></div>
                    <div id="hojas"></div>
                    <div id="hojas2"></div>
                    <div id="gnomo1"></div>  
                    <div id="gnomo2"></div> 
                    <div id="canngreyconcha"></div> 
                    <div id="tiburon"></div> 
                    <div id="notas"></div> 
                    <div id="cangrejo"></div> 
                    <div id="gaviota"></div> 
                    <div id="ola1"></div>
                    <div id="barrilenagua"></div>
                    <div id="shine"></div>
                    <div id="arana1"></div>
                    <div id="arana2"></div>
                    <div id="tesoropirata"></div>
                    <div id="mancha"></div>
                    <div id="mancha1"></div>
                    <div id="mancha2"></div>
                    <div id="mancha3"></div>
                    <div id="mancha4"></div>
                    <div id="mancha5"></div>
                    <div id="mancha6"></div>
                    <div id="mancha7"></div>
                    <div id="mancha8"></div>
                    <div id="mancha9"></div>
                    <div id="mancha10"></div>
                    <div id="mancha11"></div>
                    <div id="character2"></div>
                    <div id="tentacle"></div>
                    <div id="Spike"></div>
                    <div id="ShakeBush"></div>
                    <div id="Planta-Carnivora2"></div>
                    <div id="Planta-Carnivora1"></div>
                    <div id="Mushroom"></div>
                    <div id="MudMonster"></div>
                    <div id="Monstruo2"></div>
                    <div id="Monstruo1"></div>
                    <div id="eyeTree"></div>
                    <div id="Craneo"></div>
                    <div id="hoyo"></div>
                    <div id="hoyo2"></div>
                    <div id="hoyo3"></div>
                    <div id="nieve"></div>
                    <div id="nieve2"></div>
                    <div id="nieve3"></div>
                    <div id="Esquiador"></div>
                    <div id="IceMan"></div>
                    <div id="FrozenFish"></div>
                    <div id="YetiJump"></div>
                    <div id="YetiWalk"></div>
                    <div id="Termo"></div>
                    <div id="Grieta"></div>
                    <div id="IceCream"></div>
                    <div id="meta"></div>
                    <div id="salida"></div>
                    <div id="alegre1"></div>
                    <div id="alegre2"></div>
                    <div id="alegre3"></div>
                    <div id="alegre4"></div>
                    <div id="alegre5"></div>
                    <div id="alegre6"></div>
                    <div id="alegre7"></div>
                    <div id="alegre8"></div>
                    <div id="alegre9"></div>
                    <div id="alegre10"></div>
                    <div id="alegre11"></div>
                    <div id="alegre12"></div>
                    <div id="alegre13"></div>
                    <div id="alegre14"></div>
                    <div id="alegre15"></div>
                    <div id="alegre16"></div>
                    <div id="alegre17"></div>
                    <div id="alegre18"></div>
                    <div id="alegre19"></div>
                    <div id="alegre20"></div>
                    <div id="angustiado1"></div>
                    <div id="angustiado2"></div>
                    <div id="angustiado3"></div>
                    <div id="angustiado4"></div>
                    <div id="angustiado5"></div>
                    <div id="angustiado6"></div>
                    <div id="angustiado7"></div>
                    <div id="angustiado8"></div>
                    <div id="angustiado9"></div>
                    <div id="angustiado10"></div>
                    <div id="angustiado11"></div>
                    <div id="angustiado12"></div>
                    <div id="angustiado13"></div>
                    <div id="angustiado14"></div>
                    <div id="angustiado15"></div>
                    <div id="angustiado16"></div>
                    <div id="angustiado17"></div>
                    <div id="angustiado18"></div>
                    <div id="angustiado19"></div>
                    <div id="angustiado20"></div>
                    
                    <div id="enojado1"></div>
                    <div id="enojado2"></div>
                    <div id="enojado3"></div>
                    <div id="enojado4"></div>
                    <div id="enojado5"></div>
                    <div id="enojado6"></div>
                    <div id="enojado7"></div>
                    <div id="enojado8"></div>
                    <div id="enojado9"></div>
                    <div id="enojado10"></div>
                    <div id="enojado11"></div>
                    <div id="enojado12"></div>
                    <div id="enojado13"></div>
                    <div id="enojado14"></div>
                    <div id="enojado15"></div>
                    <div id="enojado16"></div>
                    <div id="enojado17"></div>
                    <div id="enojado18"></div>
                    <div id="enojado19"></div>
                    <div id="enojado20"></div>
                    <div id="feliz1"></div>
                    <div id="feliz2"></div>
                    <div id="feliz3"></div>
                    <div id="feliz4"></div>
                    <div id="feliz5"></div>
                    <div id="feliz6"></div>
                    <div id="feliz7"></div>
                    <div id="feliz8"></div>
                    <div id="feliz9"></div>
                    <div id="feliz10"></div>
                    <div id="feliz11"></div>
                    <div id="feliz12"></div>
                    <div id="feliz13"></div>
                    <div id="feliz14"></div>
                    <div id="feliz15"></div>
                    <div id="feliz16"></div>
                    <div id="feliz17"></div>
                    <div id="feliz18"></div>
                    <div id="feliz19"></div>
                    <div id="feliz20"></div>
                    <div id="sorprendido1"></div>
                    <div id="sorprendido2"></div>
                    <div id="sorprendido3"></div>
                    <div id="sorprendido4"></div>
                    <div id="sorprendido5"></div>
                    <div id="sorprendido6"></div>
                    <div id="sorprendido7"></div>
                    <div id="sorprendido8"></div>
                    <div id="sorprendido9"></div>
                    <div id="sorprendido10"></div>
                    <div id="sorprendido11"></div>
                    <div id="sorprendido12"></div>
                    <div id="sorprendido13"></div>
                    <div id="sorprendido14"></div>
                    <div id="sorprendido15"></div>
                    <div id="sorprendido16"></div>
                    <div id="sorprendido17"></div>
                    <div id="sorprendido18"></div>
                    <div id="sorprendido19"></div>
                    <div id="sorprendido20"></div>
                    <div id="triste1"></div>
                    <div id="triste2"></div>
                    <div id="triste3"></div>
                    <div id="triste4"></div>
                    <div id="triste5"></div>
                    <div id="triste6"></div>
                    <div id="triste7"></div>
                    <div id="triste8"></div>
                    <div id="triste9"></div>
                    <div id="triste10"></div>
                    <div id="triste11"></div>
                    <div id="triste12"></div>
                    <div id="triste13"></div>
                    <div id="triste14"></div>
                    <div id="triste15"></div>
                    <div id="triste16"></div>
                    <div id="triste17"></div>
                    <div id="triste18"></div>
                    <div id="triste19"></div>
                    <div id="triste20"></div>
                    
                    <div id="vocal_a"></div>
                    <div id="vocal_e"></div>
                    <div id="vocal_i"></div>
                    <div id="vocal_o"></div>
                    <div id="vocal_u"></div>
                    <div id="abeja"></div>
                    <div id="aguila"></div>
                    <div id="alce"></div>
                    <div id="arana"></div>
                    <div id="ave"></div>
                    <div id="caballo"></div>
                    <div id="cocodrilo"></div>
                    <div id="leon"></div>
                    <div id="rana"></div>
                    <div id="elefante"></div>

                    <div id="AAguila"></div>
                    <div id="Abuho"></div>
                    <div id="Acotorro"></div>
                    <div id="Apajaro"></div>
                    <div id="Atucan"></div>
                    <div id="borrego"></div>
                    <div id="caballo2"></div>
                    <div id="conejo1"></div>
                    <div id="pulpo"></div>
                    <div id="Pballena"></div>
                    <div id="Pdelfin"></div>
                    <div id="Pglobo"></div>
                    <div id="Ppayaso"></div>
                    <div id="Ptiburon"></div>
                    <div id="caballo3"></div>
                    <div id="conejo2"></div>
                    <div id="flor1"></div>
                    <div id="hipopotamo"></div>
                    <div id="roca"></div>
                    <div id="tronco"></div>
                    <div id="Gborrego"></div>
                    <div id="Gcaballo"></div>
                    <div id="Ggallina"></div>
                    <div id="Gpuerco"></div>
                    <div id="Gvaca"></div>
                    <div id="camello"></div>
                    <div id="madera"></div>
                    <div id="pajaro"></div>
                    <div id="pasto"></div>
                    <div id="roca"></div>
                    <div id="tucan"></div>
                    <div id="Zchango"></div>
                    <div id="Zelefante"></div>
                    <div id="Zjirafa"></div>
                    <div id="Zleon"></div>
                    <div id="Ztigre"></div>
                    <div id="caballo4"></div>
                    <div id="casarbol"></div>
                    <div id="flor2"></div>
                    <div id="gato"></div>
                    <div id="madera1"></div>
                    <div id="paisaje"></div>
                    <div id="perro"></div> 
                    <div id="vaca"></div> 
                     <div id="ardilla3"></div>
                     <div id="punto"></div>
                     <div id="punto2"></div>
                     <div id="punto3"></div>
                     <div id="punto4"></div>
                     <div id="punto5"></div>
                     <div id="punto6"></div>
                     <div id="punto7"></div>

                     <div id="lh1"></div>
                     <div id="lh2"></div>
                     <div id="lh3"></div>
                     <div id="lh4"></div>
                     <div id="lh5"></div>
                     <div id="lh6"></div>
                     <div id="lh7"></div>
                     <div id="lh8"></div>
                     <div id="lh9"></div>
                     <div id="lh10"></div>
                     <div id="lh11"></div>
                     <div id="lh12"></div>
                     <div id="lh13"></div>
                     <div id="lh14"></div>
                     <div id="lh15"></div>
                     <div id="lh16"></div>
                     <div id="lh17"></div>
                     <div id="lh18"></div>
                     <div id="lh19"></div>
                     <div id="lh20"></div>
                     <div id="lh21"></div>
                     <div id="lh22"></div>
                     <div id="lh23"></div>
                     <div id="lh24"></div>
                     <div id="lh25"></div>
                     <div id="lh26"></div>
                     <div id="lh27"></div>
                     <div id="lh28"></div>
                     <div id="lh29"></div>
                     <div id="lh30"></div>
                     <div id="lh31"></div>
                     <div id="lh32"></div>
                     <div id="lh33"></div>
                     <div id="lh34"></div>
                     <div id="lh35"></div>
                     <div id="lh36"></div>
                     <div id="lh37"></div>
                     <div id="lh38"></div>
                     <div id="lh39"></div>
                     <div id="lh40"></div>
                     <div id="lh41"></div>
                     <div id="lh42"></div>
                     <div id="lh43"></div>
                     <div id="lh44"></div>
                     <div id="lh45"></div>
                     <div id="lh46"></div>
                     <div id="lh47"></div>
                     <div id="lh48"></div>
                     <div id="lh49"></div>
                     <div id="lh50"></div>
                     <div id="lh51"></div>
                     <div id="lh52"></div>
                     <div id="lh53"></div>
                     <div id="lh54"></div>
                     <div id="lh55"></div>
                     <div id="lh56"></div>
                     <div id="lh57"></div>
                     <div id="lh58"></div>
                     <div id="lh59"></div>
                     <div id="lh60"></div>
                     <div id="lh61"></div>
                     <div id="lh62"></div>
                      <div id="lh63"></div>
                      <div id="lh64"></div>
                      <div id="lh65"></div>
                      <div id="lh66"></div>
                      <div id="lh67"></div>
                      <div id="lh68"></div>
                      <div id="lh69"></div>
                      <div id="lh70"></div>
                      <div id="lh71"></div>
                      <div id="lh72"></div>
                      <div id="lh73"></div>
                      <div id="lh74"></div>
                      <div id="lh75"></div>
                      <div id="lh76"></div>
                      <div id="lh77"></div>
                      <div id="lh78"></div>
                      <div id="lh79"></div>
                      <div id="lh80"></div>
                      <div id="lh81"></div>
                      <div id="lh82"></div>
                      <div id="lh83"></div>
                      <div id="lh84"></div>
                      <div id="lh85"></div>
                      <div id="lh86"></div>
                      <div id="lh87"></div>
                      <div id="lh88"></div>
                      <div id="lh89"></div>
                      <div id="lh90"></div>
                      <div id="lh91"></div>
                      <div id="lh92"></div>
                      <div id="lh93"></div>
                      <div id="lh94"></div>
                      <div id="lh95"></div>
                      <div id="lh96"></div>
                      <div id="lh97"></div>
                      <div id="lh98"></div>
                      <div id="lh99"></div>
                      <div id="lh100"></div>

                      <div id="lv1"></div>
                     <div id="lv2"></div>
                      <div id="lv3"></div>
                      <div id="lv4"></div>
                      <div id="lv5"></div>
                      <div id="lv6"></div>
                      <div id="lv7"></div>
                      <div id="lv8"></div>
                      <div id="lv9"></div>
                      <div id="lv10"></div>
                      <div id="lv11"></div>
                      <div id="lv12"></div>
                      <div id="lv13"></div>
                      <div id="lv14"></div>
                      <div id="lv15"></div>
                      <div id="lv16"></div>
                      <div id="lv17"></div>
                      <div id="lv18"></div>
                      <div id="lv19"></div>
                      <div id="lv20"></div>
                      <div id="lv21"></div>
                      <div id="lv22"></div>
                      <div id="lv23"></div>
                      <div id="lv24"></div>
                      <div id="lv25"></div>
                      <div id="lv26"></div>
                      <div id="lv27"></div>
                      <div id="lv28"></div>
                      <div id="lv29"></div>
                      <div id="lv30"></div>
                      <div id="lv31"></div>
                      <div id="lv32"></div>
                      <div id="lv33"></div>
                      <div id="lv34"></div>
                      <div id="lv35"></div>
                      <div id="lv36"></div>
                      <div id="lv37"></div>
                      <div id="lv38"></div>
                      <div id="lv39"></div>
                      <div id="lv40"></div>
                      <div id="lv41"></div>
                      <div id="lv42"></div>
                      <div id="lv43"></div>
                      <div id="lv44"></div>
                      <div id="lv45"></div>
                      <div id="lv46"></div>
                      <div id="lv47"></div>
                      <div id="lv48"></div>
                      <div id="lv49"></div>
                      <div id="lv50"></div>
                      <div id="lv51"></div>
                      <div id="lv52"></div>
                      <div id="lv53"></div>
                      <div id="lv54"></div>
                      <div id="lv55"></div>
                      <div id="lv56"></div>
                      <div id="lv57"></div>
                      <div id="lv58"></div>
                      <div id="lv59"></div>
                      <div id="lv60"></div>
                      <div id="lv61"></div>
                      <div id="lv62"></div>
                      <div id="lv63"></div>
                      <div id="lv64"></div>
                      <div id="lv65"></div>
                      <div id="lv66"></div>
                      <div id="lv67"></div>
                      <div id="lv68"></div>
                      <div id="lv69"></div>
                      <div id="lv70"></div>
                      <div id="lv71"></div>
                      <div id="lv72"></div>
                      <div id="lv73"></div>
                      <div id="lv74"></div>
                      <div id="lv75"></div>
                      <div id="lv76"></div>
                      <div id="lv77"></div>
                      <div id="lv78"></div>
                      <div id="lv79"></div>
                      <div id="lv80"></div>
                      <div id="lv81"></div>
                      <div id="lv82"></div>
                      <div id="lv83"></div>
                      <div id="lv84"></div>
                      <div id="lv85"></div>
                      <div id="lv86"></div>
                      <div id="lv87"></div>
                      <div id="lv88"></div>
                      <div id="lv89"></div>
                      <div id="lv90"></div>
                      <div id="lv91"></div>
                      <div id="lv92"></div>
                      <div id="lv93"></div>
                      <div id="lv94"></div>
                      <div id="lv95"></div>
                      <div id="lv96"></div>
                      <div id="lv97"></div>
                      <div id="lv98"></div>
                      <div id="lv99"></div>
                      <div id="lv100"></div>

                      <div id="llanta1t"></div>
                      <div id="llanta2t"></div>
                      <div id="llanta3t"></div>

                      <div id="lne1"></div>
                      <div id="lne2"></div>
                      <div id="lne3"></div>
                      <div id="lne4"></div>
                      <div id="lne5"></div>
                      <div id="lne6"></div>
                      <div id="lne7"></div>
                      <div id="lne8"></div>
                      <div id="lne9"></div>
                      <div id="lne10"></div>
                      <div id="lne11"></div>
                      <div id="lne12"></div>
                      <div id="lne13"></div>
                      <div id="lne14"></div>
                      <div id="lne15"></div>
                      <div id="lne16"></div>
                      <div id="lne17"></div>
                      <div id="lne18"></div>
                      <div id="lne19"></div>
                      <div id="lne20"></div>
                      <div id="lne21"></div>
                      <div id="lne22"></div>
                      <div id="lne23"></div>
                      <div id="lne24"></div>
                      <div id="lne25"></div>
                      <div id="lne26"></div>
                      <div id="lne27"></div>
                      <div id="lne28"></div>
                      <div id="lne29"></div>
                      <div id="lne30"></div>
                      <div id="lne31"></div>
                      <div id="lne32"></div>
                      <div id="lne33"></div>
                      <div id="lne34"></div>
                      <div id="lne35"></div>
                      <div id="lne36"></div>
                      <div id="lne37"></div>
                      <div id="lne38"></div>
                      <div id="lne39"></div>
                      <div id="lne40"></div>
                      <div id="lne41"></div>
                      <div id="lne42"></div>
                      <div id="lne43"></div>
                      <div id="lne44"></div>
                      <div id="lne45"></div>
                      <div id="lne46"></div>
                      <div id="lne47"></div>
                      <div id="lne48"></div>
                      <div id="lne49"></div>
                      <div id="lne50"></div>
                      <div id="lne51"></div>
                      <div id="lne52"></div>
                      <div id="lne53"></div>
                      <div id="lne54"></div>
                      <div id="lne55"></div>
                      <div id="lne56"></div>
                      <div id="lne57"></div>
                      <div id="lne58"></div>
                      <div id="lne59"></div>
                      <div id="lne60"></div>
                      <div id="lne61"></div>
                      <div id="lne62"></div>
                      <div id="lne63"></div>
                      <div id="lne64"></div>
                      <div id="lne65"></div>
                      <div id="lne66"></div>
                      <div id="lne67"></div>
                      <div id="lne68"></div>
                      <div id="lne69"></div>
                      <div id="lne70"></div>
                      <div id="lne71"></div>
                      <div id="lne72"></div>
                      <div id="lne73"></div>
                      <div id="lne74"></div>
                      <div id="lne75"></div>
                      <div id="lne76"></div>
                      <div id="lne77"></div>
                      <div id="lne78"></div>
                      <div id="lne79"></div>
                      <div id="lne80"></div>
                      <div id="lne81"></div>
                      <div id="lne82"></div>
                      <div id="lne83"></div>
                      <div id="lne84"></div>
                      <div id="lne85"></div>
                      <div id="lne86"></div>
                      <div id="lne87"></div>
                      <div id="lne88"></div>
                      <div id="lne89"></div>
                      <div id="lne90"></div>
                      <div id="lne91"></div>
                      <div id="lne92"></div>
                      <div id="lne93"></div>
                      <div id="lne94"></div>
                      <div id="lne95"></div>
                      <div id="lne96"></div>
                      <div id="lne97"></div>
                      <div id="lne98"></div>
                      <div id="lne99"></div>
                      <div id="lne100"></div>

                      <div id="lno1"></div>
                      <div id="lno2"></div>
                      <div id="lno3"></div>
                      <div id="lno4"></div>
                      <div id="lno5"></div>
                      <div id="lno6"></div>
                      <div id="lno7"></div>
                      <div id="lno8"></div>
                      <div id="lno9"></div>
                      <div id="lno10"></div>
                      <div id="lno11"></div>
                      <div id="lno12"></div>
                      <div id="lno13"></div>
                      <div id="lno14"></div>
                      <div id="lno15"></div>
                      <div id="lno16"></div>
                      <div id="lno17"></div>
                      <div id="lno18"></div>
                      <div id="lno19"></div>
                      <div id="lno20"></div>
                      <div id="lno21"></div>
                      <div id="lno22"></div>
                      <div id="lno23"></div>
                      <div id="lno24"></div>
                      <div id="lno25"></div>
                      <div id="lno26"></div>
                      <div id="lno27"></div>
                      <div id="lno28"></div>
                      <div id="lno29"></div>
                      <div id="lno30"></div>
                      <div id="lno31"></div>
                      <div id="lno32"></div>
                      <div id="lno33"></div>
                      <div id="lno34"></div>
                      <div id="lno35"></div>
                      <div id="lno36"></div>
                      <div id="lno37"></div>
                      <div id="lno38"></div>
                      <div id="lno39"></div>
                      <div id="lno40"></div>
                      <div id="lno41"></div>
                      <div id="lno42"></div>
                      <div id="lno43"></div>
                      <div id="lno44"></div>
                      <div id="lno45"></div>
                      <div id="lno46"></div>
                      <div id="lno47"></div>
                      <div id="lno48"></div>
                      <div id="lno49"></div>
                      <div id="lno50"></div>
                      <div id="lno51"></div>
                      <div id="lno52"></div>
                      <div id="lno53"></div>
                      <div id="lno54"></div>
                      <div id="lno55"></div>
                      <div id="lno56"></div>
                      <div id="lno57"></div>
                      <div id="lno58"></div>
                      <div id="lno59"></div>
                      <div id="lno60"></div>
                      <div id="lno61"></div>
                      <div id="lno62"></div>
                      <div id="lno63"></div>
                      <div id="lno64"></div>
                      <div id="lno65"></div>
                      <div id="lno66"></div>
                      <div id="lno67"></div>
                      <div id="lno68"></div>
                      <div id="lno69"></div>
                      <div id="lno70"></div>
                      <div id="lno71"></div>
                      <div id="lno72"></div>
                      <div id="lno73"></div>
                      <div id="lno74"></div>
                      <div id="lno75"></div>
                      <div id="lno76"></div>
                      <div id="lno77"></div>
                      <div id="lno78"></div>
                      <div id="lno79"></div>
                      <div id="lno80"></div>
                      <div id="lno81"></div>
                      <div id="lno82"></div>
                      <div id="lno83"></div>
                      <div id="lno84"></div>
                      <div id="lno85"></div>
                      <div id="lno86"></div>
                      <div id="lno87"></div>
                      <div id="lno88"></div>
                      <div id="lno89"></div>
                      <div id="lno90"></div>
                      <div id="lno91"></div>
                      <div id="lno92"></div>
                      <div id="lno93"></div>
                      <div id="lno94"></div>
                      <div id="lno95"></div>
                      <div id="lno96"></div>
                      <div id="lno97"></div>
                      <div id="lno98"></div>
                      <div id="lno99"></div>
                      <div id="lno100"></div>
                      <div id="blan"></div>
                      <div id="blan1"></div>
                      <div id="blan2"></div>
                      <div id="blan3"></div>
                      <div id="blan4"></div>
                      <div id="blan5"></div>
                      <div id="blan6"></div>
                      <div id="blan7"></div>
                      <div id="blan8"></div>
                      <div id="blan9"></div>
                      <div id="blan10"></div>

                      <div id="airplane"></div>
                      <div id="ambulance"></div>
                      <div id="angel"></div>
                      <div id="elephant"></div>
                      <div id="esmeralda"></div>
                      <div id="exercise"></div>
                      <div id="igloo"></div>
                      <div id="insect"></div>
                      <div id="inyeccion"></div>
                      <div id="ocean"></div>
                      <div id="office"></div>
                      <div id="oyster"></div>
                      <div id="unicorn"></div>
                      <div id="uniform"></div>
                      <div id="universe"></div>

                      <div id="balonN"></div>
                      <div id="carroN"></div>
                      <div id="libroN"></div>
                      <div id="osoN"></div>
                      <div id="solN"></div>
                    <!--<div id="totalNieve1">{{totalNieve}}</div>-->
          
                  </div>
                </div>
                <app-modal :idQuestion="nextQuestion" :subWorldId="subWorldId" :multiple="multipleNextQuestion" :nextQuestionSubworldId="nextQuestionSubworldId" :activityType="activityType"></app-modal>
                <app-modal-help :help="help"></app-modal-help>
                <app-modal-code></app-modal-code>
                <audio id="audioInst" controls style="display: none">
                  <source type="audio/mp3" src="../assets/instrucciones/fail_pegman.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @fileoverview Main Vue component that includes the Blockly component.
 * @author samelh@google.com (Sam El-Husseini)
 */
import BlocklyComponent from "../components/BlocklyComponent.vue";
import BlocklyComponentParte from "../components/BlocklyComponentParte.vue";
import "../blocks/stocks";
import "./prompt";
import BlocklyJS from "blockly/javascript";
import AppHeader from "../components/AppHeader.vue";
import AppSidebar from "../components/AppSidebar.vue";
import AppModal from "../components/AppModal.vue";
import AppModalHelp from "../components/AppModalHelp.vue";
import AppModalCode from "../components/AppModalCode.vue";

export default {
  name: "ActivityDraw",
  components: {
    BlocklyComponent,
    AppHeader,
    AppSidebar,
    AppModal,
    AppModalHelp,
    BlocklyComponentParte,
    AppModalCode
  },
  data() {
    return {
      code: "",
      name: "",
      instructions: "",
      instructions_audio: "",
      nextQuestion: "",
      subWorldId: "",
      nextQuestionSubworldId: "",
      activityType: 0,
      options: {
        scrollbars: true,
      },
      help: 0,
      score: 100,
      activity_id: 0,
      activityDone: 0,
      clicando: false,

      runtimeTranscription_: "",
      transcription_: [],
      lang_: "fr-FR",
      arrayBlocks: [],
      blocksToUse: '',
      multiple: 0,
      multipleNextQuestion: 0,
      totalNieve: ""
    };
  },
  methods: {
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
        runcodeDos.disabled = true;
      }

      jQuery.fn.extend({
        resaltar: function (busqueda, claseCSSbusqueda) {
          var regex = new RegExp("(<[^>]*>)|(" + busqueda.replace(/([-.*+?^${}()|[\]\/\\])/g, "\\$1") +")", "ig");
          var nuevoHtml = this.html(
            this.html().replace(regex, function (a, b, c) {
              return a.charAt(0) == "<" ? a : '<span class="' + claseCSSbusqueda + '">' + c + "</span>";
            })
          );
          return nuevoHtml;
        },
      });

      var instrucciones = this.instructions;
      var longitud = this.instructions.length;
      var subvalor = Math.ceil(longitud / 5);
      var cadena1 = instrucciones.substr(0, subvalor);
      var cadena2 = instrucciones.substr(subvalor, subvalor);
      var cadena3 = instrucciones.substr(subvalor * 2, subvalor);
      var cadena4 = instrucciones.substr(subvalor * 3, subvalor);
      var cadena5 = instrucciones.substr(subvalor * 4, subvalor);

      var arreglo = this.sound_delay.split("|");
      let t1 = arreglo[0]
      let t2 = arreglo[1]
      let t3 = arreglo[2]
      let t4 = arreglo[3]
      let t5 = arreglo[4]
      let t6 = arreglo[5]
      
      setTimeout(function () {$("#texto").resaltar(cadena1, "resaltarTexto"); }, t1);
      setTimeout(function () {$("#texto").resaltar(cadena2, "resaltarTexto"); }, t2);
      setTimeout(function () {$("#texto").resaltar(cadena3, "resaltarTexto"); }, t3);
      setTimeout(function () {$("#texto").resaltar(cadena4, "resaltarTexto"); }, t4);
      setTimeout(function () {$("#texto").resaltar(cadena5, "resaltarTexto"); }, t5);
      setTimeout(function () {$("#texto").resaltar(cadena1, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena2, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena3, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena4, "quitarresaltarTexto"); }, t6);
      setTimeout(function () {$("#texto").resaltar(cadena5, "quitarresaltarTexto"); }, t6);
      setTimeout(() => {runcodeDos.disabled = false;}, t6)
    },
    SetParams(){
    currentTime = 300;
    currentTime2 = 300;
    currentPositionX = window.currentPosiitionX;
    currentPositionY = window.currentPosiitionY;
    currentPositionX2 = window.currentPosiition2X; 
    currentPositionY2 = window.currentPosiition2Y;
    var code = '';
    goalP2C =0;
    window.goalscollected = 0;
    window.goalscollected2 = 0;
    window.stainClean = 0;
    window.bCorrectA1 = false;
    window.bCorrectA2 = false;
    stainClean = 0;
    stainCleanA2 = 0;

    if(window.orientacion == "FP"){
      //window.blocks[0].select();  
      if(this.$route.query.multiple != 16)
        $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); 
      if(this.$route.query.multiple == 16)
        $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteParado.gif')"); 
        $("#character").css("top",window.ia1y + "px"); 
        $("#character").css("left",window.ia1x +"px");
      if(this.$route.query.multiple != 16 && window.avatarType == 3)
        $("#character3").css("background-image","url('../assets/img/personaje/robot/frenteParado.gif')"); 

    }else if(window.orientacion == "DP"){
      //window.blocks[0].select();
      if(this.$route.query.multiple != 16)
        $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')");
      if(this.$route.query.multiple == 16)
        $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')");
        $("#character").css("top",window.ia1y + "px"); 
        $("#character").css("left",window.ia1x + "px");
      if(this.$route.query.multiple != 16 && window.avatarType == 3)
        $("#character3").css("background-image","url('../assets/img/personaje/robot/derechaParado.gif')");

    }else if(window.orientacion == "IP"){
      //window.blocks[0].select();  
      if(this.$route.query.multiple != 16)
      $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); 
      if(this.$route.query.multiple == 16)
      $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); 
      $("#character").css("top",window.ia1y + "px"); 
      $("#character").css("left",window.ia1x + "px");
      if(this.$route.query.multiple != 16  && window.avatarType == 3)
      $("#character3").css("background-image","url('../assets/img/personaje/robot/izqParado.gif')"); 

    }else if(window.orientacion == "EP"){
      //window.blocks[0].select();  
      if(this.$route.query.multiple != 16)
        $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')");
      if(this.$route.query.multiple == 16)
      $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); 
      $("#character").css("top",window.ia1y + "px"); 
      $("#character").css("left",window.ia1x + "px");
      if(this.$route.query.multiple != 16  && window.avatarType == 3)
      $("#character3").css("background-image","url('../assets/img/personaje/robot/espaldaparado.gif')"); 
    }
  
    if(window.orientacion2 == "FP"){
     // window.blocks[0].select();  
     if(this.$route.query.multiple != 16)
      $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')"); 
       if(this.$route.query.multiple == 16)
      $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteParado.gif')");
      $("#character2").css("top",window.ia2y + "px"); 
      $("#character2").css("left",window.ia2x + "px");
    }else if(window.orientacion2 == "DP"){
      //window.blocks[0].select();
      if(this.$route.query.multiple != 16)  
      $("#character2").css("background-image","url('../assets/img/personaje/pirata/derechaParado.gif')"); 
       if(this.$route.query.multiple == 16)
       $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')");
      $("#character2").css("top",window.ia2y + "px"); 
      $("#character2").css("left", window.ia2x+ "px");
    }else if(window.orientacion2 == "IP"){
      //window.blocks[0].select();
      if(this.$route.query.multiple != 16)
        $("#character2").css("background-image","url('../assets/img/personaje/pirata/izqParado.gif')");
      if(this.$route.query.multiple == 16)
        $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izquierdaParado.gif')");
        $("#character2").css("top",window.ia2y+"px"); 
        $("#character2").css("left",window.ia2x+"px");
    }else if(window.orientacion2 == "EP"){
      //window.blocks[0].select();  
       if(this.$route.query.multiple != 16)
         $("#character2").css("background-image","url('../assets/img/personaje/pirata/espaldaparado.gif')"); 
       if(this.$route.query.multiple == 16)
        $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaParado.gif')"); 
        $("#character2").css("top",window.ia2y+"px"); 
        $("#character2").css("left",window.ia2x+"px");
    }
    


    window.GoalsArray.forEach(element => {
     element.bCorrect = null;
    $("#"+element.Type).css("display", "block");
    });
    $("#hojas").css("display", "none");
    $("#hojas2").css("display", "none");
    window.newOrientation = window.orientacion;
    positx = window.posiitx;
    posity = window.posiity;
    posit2x = window.posiit2x;
    posit2y = window.posiit2y;
    window.setParams = 0;
    window.xyz=0;
    window.coordenada = '';
    window.sentido='';
},

    runCode() {
      var connectedBlocks = true;
      this.activityDone = 0
      this.clicando= true;
      window.invalid = 0; //le decimos que todo es válido
      window.blocks = this.$refs["blockly1"].workspace.getAllBlocks();
      console.log('Bloques', window.blocks)
      console.log('Longitud', window.blocks.length - 1)

      for(var i=1; i <= window.blocks.length-1; i++) {
        var myconnection=window.blocks[i].getConnections_(false);
        for(var j=0; j<=myconnection.length-1;j++)
        {
          if(!myconnection[j].isSuperior() && myconnection[j].targetConnection==null)
            connectedBlocks = false;
        }
      }

      if(connectedBlocks){
        this.SetParams();
        runcode.disabled = true;
        btnEvaluate.disabled = true;
        window.espacioBlockly = this.$refs["blockly1"].workspace
        if(this.$route.query.multiple == 1)
          this.code = BlocklyJS.workspaceToCode(this.$refs["blockly1"].workspace) + "\n Daniel(); \n Mary();";
        if(this.$route.query.multiple == 0 || this.$route.query.multiple == 2 || this.$route.query.multiple == 3 || this.$route.query.multiple == 4 || this.$route.query.multiple == 5 || this.$route.query.multiple == 6 || this.$route.query.multiple == 7 || this.$route.query.multiple == 8 || this.$route.query.multiple == 9 || this.$route.query.multiple == 10 || this.$route.query.multiple == 11 || this.$route.query.multiple == 12 || this.$route.query.multiple == 13 || this.$route.query.multiple == 14 || this.$route.query.multiple == 15)
          this.code = BlocklyJS.workspaceToCode(this.$refs["blockly1"].workspace);
        if(this.$route.query.multiple == 16)
          this.code = BlocklyJS.workspaceToCode(this.$refs["blockly1"].workspace) + "\n Luis(); \n Lucia();";
        
        eval(this.code);
        console.log(this.code)
        setTimeout(() => {runcode.disabled = false; btnEvaluate.disabled = false;}, currentTime + 500)
      }else{
        this.showMessage('Error', "Todos los bloques deben estar conectados", 'error', '', 1)
      }
    },
    clear() {
      location.reload();
    },
    showModalHelp() {
      if (this.help + 1 <= 2) this.help++;
      Fire.$emit("assignHelpImages", {help: this.help});

      if (this.help == 1) {
        $("#AppModalHelp").modal({ backdrop: "true", keyboard: true });
        $("#AppModalHelp").appendTo("body").modal("show");
        var audio = new Audio(helpAudio1);
        audio.play();
      } else {
        $("#AppModalHelp").appendTo("body").modal("show");
        var audio = new Audio(helpAudio2);
        audio.play();
      }
    },
    async configActivity(idQuestion) {
      this.arrayBlocks = []
      await axios
        .get(apiETC + "api/smartc3/get-activity?question_id=" + idQuestion)
        .then((response) => {
          this.blocksToUse = response.data.blocks
          const blocksArray = response.data.blocks.split('|')
          blocksArray.forEach( (element) => {
            let arrayElement = element.split(',')
            this.arrayBlocks.push({
              type: arrayElement[0],
              class: arrayElement[1]
            })
          })
          
          //Fire.$emit("clearWorkspace");
          this.blocksReady = 1
          window.currentPosiitionX = response.data.avatar_position_x;
          window.currentPosiitionY = response.data.avatar_position_y;
          window.currentPosiition2X = response.data.avatar2_position_x;
          window.currentPosiition2Y = response.data.avatar2_position_y;
          window.drawingInitialPositionX = response.data.drawing_position_x;
          window.drawingInitialPositionY = response.data.drawing_position_y;
          window.drawingOffsetX = response.data.drawing_offset_x;
          window.drawingOffsetY = response.data.drawing_offset_y;

          window.ia1x = response.data.avatar_position_x;
          window.ia1y = response.data.avatar_position_y;
          window.ia2x = response.data.avatar2_position_x;
          window.ia2y = response.data.avatar2_position_y;

          window.currentPositionXGoal = response.data.goal_position_x;
          window.currentPositionYGoal = response.data.goal_position_y;
          window.posiitx = response.data.avatar_init_x;
          window.posiity = response.data.avatar_init_y;
          window.posiit2x = response.data.avatar2_init_x;
          window.posiit2y = response.data.avatar2_init_y;
          window.positx_goal = response.data.goal_init_x;
          window.posity_goal = response.data.goal_init_y;
          window.setParams = 0;
          window.avatarType= response.data.avatarType;
          window.avatarInitialGif = response.data.avatar_initial_gif;
          this.totalNieve=2;
          window.nextQuestionRoute = response.data.next_question_route
          
          this.instructions = response.data.instructions;
          this.instructions_audio = response.data.instructions_audio;
          this.nextQuestion = response.data.next_question.toString();
          this.subWorldId = response.data.subworld_id.toString();
          this.nextQuestionSubworldId = response.data.next_question_subworld_id.toString()
          this.activityType = response.data.next_question_activity_type
          this.activity_id = response.data.id;
          this.sound_delay = response.data.sound_delay;
          this.multiple = response.data.multiple
          this.help = 0;
          this.multipleNextQuestion = response.data.next_question_multiple
          window.multiple = response.data.multiple
          window.idioma=this.$store.getters.language;
          
          if(response.data.avatar_initial_gif.includes("frenteparado") || response.data.avatar_initial_gif.includes("crayolin_espera"))
            window.orientacion = "FP";
          else if (response.data.avatar_initial_gif.includes("derechaParado"))
            window.orientacion = "DP";
          else if (response.data.avatar_initial_gif.includes("espaldaparado"))
            window.orientacion = "EP";
          else if(response.data.avatar_initial_gif.includes("izqParado"))
            window.orientacion = "IP";

          if(response.data.avatar2_initial_gif != null){
          if(response.data.avatar2_initial_gif.includes("frenteparado")|| response.data.avatar2_initial_gif.includes("frenteParado"))
            window.orientacion2 = "FP";
          else if (response.data.avatar2_initial_gif.includes("derechaParado")|| response.data.avatar2_initial_gif.includes("derechaParado"))
            window.orientacion2 = "DP";
          else if (response.data.avatar2_initial_gif.includes("espaldaparado") || response.data.avatar2_initial_gif.includes("espaldaParado"))
            window.orientacion2 = "EP";
          else if(response.data.avatar2_initial_gif.includes("izqParado") || response.data.avatar2_initial_gif.includes("izquierdaParado"))
            window.orientacion2 = "IP";
          }

          if(this.$store.getters.language == 'es') {
            this.instructions = response.data.instructions;
            this.instructions_audio = response.data.instructions_audio;
            this.sound_delay = response.data.sound_delay;
          }
          if(this.$store.getters.language != 'es') {
            const translation = response.data.translations.find(element => element.language_code == this.$store.getters.language)
            if(translation != undefined) {
              this.instructions = translation.translation;
              this.instructions_audio = translation.instructions_audio;
              this.sound_delay = translation.sound_delay;
            } else {
              this.instructions = response.data.instructions;
              this.instructions_audio = response.data.instructions_audio;
              this.sound_delay = response.data.sound_delay;
            }
          }


            window.goalscollected = 0;
           window.newOrientation = window.orientacion;
           window.newOrientation2 = window.orientacion2;
          //traemos de la base de datos las coordenadas invalidas
          //window.blocksNotAllowed = '3,0|3,1|3,2|3,3|0,3|1,3|2,3|2,4|2,5|2,6|3,7|4,7|5,7|6,2|7,2|'
          window.blocksYesAllowed = response.data.goals_order;
          window.blocksNotAllowed = response.data.coord_not_allowed;
          if (response.data.helps.length > 0) {
            window.helpImg1 = response.data.helps[0].help_image_url;
            window.helpImg2 = response.data.helps[1].help_image_url;
            if(this.$store.getters.language == 'es') {
            window.helpAudio1 = response.data.helps[0].help_audio_url;
            window.helpAudio2 = response.data.helps[1].help_audio_url;
            }
            if(this.$store.getters.language != 'es') {
            window.helpAudio1 = '../assets/instrucciones/help/path.mp3';
            window.helpAudio2 = '../assets/instrucciones/help/programming.mp3';
            }
          }
          var Goals = {};
          var ArrarObjects = [];

          //$(".scenario").css("background-image",'url("' + response.data.scenario + '")');
          window.background = new Image();
          background.src = response.data.scenario;
          setTimeout(() => {
            window.ctxCanvas.drawImage(background,0,0);
          }, 1000);
          Fire.$emit('setBreadcrumbs', { world: response.data.subworlds.worlds.name, subWorld: response.data.subworlds.name, world_id: response.data.subworlds.worlds.id, activity_number: response.data.activity_number })
          /*$(".scenario").css(
            "background-image",
            'url("' + response.data.scenario + '")'
          );*/
              if(window.avatarType == 3){

          $("#character3").css("background-image",'url("' + response.data.avatar_initial_gif + '")');
          $("#character3").css("top", window.currentPosiitionY + "px");
          $("#character3").css("left", window.currentPosiitionX + "px");
          $("#character3").css("display", "block");

          }else{
         
          $("#character").css("background-image",'url("' + response.data.avatar_initial_gif + '")');
          $("#character").css("top", window.currentPosiitionY + "px");
          $("#character").css("left", window.currentPosiitionX + "px");
          $("#character").css("display", "block");
          
          }
          if(response.data.avatar2_initial_gif != null){
            $("#character2").css("background-image",'url("' + response.data.avatar2_initial_gif + '")');
            $("#character2").css("top", window.currentPosiition2Y + "px");
            $("#character2").css("left", window.currentPosiition2X + "px");
            $("#character2").css("display", "block");
          }

          $("#gem").css("top", window.currentPositionYGoal + "px");
          $("#gem").css("left", window.currentPositionXGoal + "px");
          if(idQuestion<=25846){
            $("#gem").css("display", "block");
          }
          $("#palette").css("display", "none");
          $("#icecream").css("display", "none");
          $("#sweet").css("display", "none");
          $("#gift").css("display", "none");
          $("#gift2").css("display", "none");
          $("#gift3").css("display", "none");
          $("#eyes").css("display", "none");
          $("#tail").css("display", "none");
          $("#panda").css("display", "none");
          $("#duck1").css("display", "none");
          $("#duck2").css("display", "none");
          $("#delfin").css("display", "none");
          $("#oso").css("display", "none");
          $("#rusa").css("display", "none");
          $("#targetShooting").css("display", "none");
          $("#waterJet").css("display", "none");
          $("#Payaso1").css("display", "none");
          $("#swing").css("display", "none");
          $("#bellota").css("display", "none");
          $("#semilla-01").css("display", "none");
          $("#circle-dark").css("display", "none");
          $("#circle-dark1").css("display", "none");
          $("#catarina").css("display", "none");
          $("#hada2").css("display", "none");
          $("#hada3").css("display", "none");
          $("#LamparaCorazon").css("display", "none");
          $("#z").css("display", "none");
          $("#hada1").css("display", "none");
          $("#luzventana").css("display", "none");
          $("#bellota1").css("display", "none");
          $("#bellota2").css("display", "none");
          $("#bellota3").css("display", "none");
          $("#bellota4").css("display", "none");
          $("#bellota5").css("display", "none");
          $("#Rana").css("display", "none");
          $("#Principe").css("display", "none");
          $("#hojas").css("display", "none");
          $("#hojas2").css("display", "none");
          $("#gem2").css("display", "none");
          $("#gem3").css("display", "none");
          $("#gem4").css("display", "none");
          $("#gem5").css("display", "none");
          $("#gem6").css("display", "none");
          $("#gem7").css("display", "none");
          $("#gem8").css("display", "none");
          $("#gem9").css("display", "none");
          $("#gem10").css("display", "none");
          $("#gem11").css("display", "none");
          $("#gem12").css("display", "none");
          $("#gem13").css("display", "none");
          $("#gem14").css("display", "none");
          $("#gem15").css("display", "none");
          $("#gem16").css("display", "none");
          $("#gem17").css("display", "none");
          $("#gem18").css("display", "none");
          $("#gem19").css("display", "none");
          $("#gnomo1").css("display", "none");
          $("#gnomo2").css("display", "none");
          $("#canngreyconcha").css("display", "none");
          $("#tiburon").css("display", "none");
          $("#notas").css("display", "none");
          $("#cangrejo").css("display", "none");
          $("#gaviota").css("display", "none");
          $("#ola1").css("display", "none");
          $("#barrilenagua").css("display", "none");
          $("#shine").css("display", "none");
          $("#arana1").css("display", "none");
          $("#arana2").css("display", "none");
          $("#tesoropirata").css("display", "none");
          $("#mancha").css("display", "none");
          $("#mancha1").css("display", "none");
          $("#mancha2").css("display", "none");
          $("#mancha3").css("display", "none");
          $("#mancha4").css("display", "none");
          $("#mancha5").css("display", "none");
          $("#mancha6").css("display", "none");
          $("#mancha7").css("display", "none");
          $("#mancha8").css("display", "none");
          $("#mancha9").css("display", "none");
          $("#mancha10").css("display", "none");
          $("#mancha11").css("display", "none");
          $("#tentacle").css("display", "none");
          $("#Spike").css("display", "none");
          $("#ShakeBush").css("display", "none");
          $("#Planta-Carnivora2").css("display", "none");
          $("#Planta-Carnivora1").css("display", "none");
          $("#Mushroom").css("display", "none");
          $("#MudMonster").css("display", "none");
          $("#Monstruo2").css("display", "none");
          $("#Monstruo1").css("display", "none");
          $("#eyeTree").css("display", "none");
          $("#Craneo").css("display", "none");
          $("#nieve").css("display", "none");
          $("#nieve2").css("display", "none");
          $("#nieve3").css("display", "none");
          $("#hoyo").css("display", "none");
          $("#hoyo2").css("display", "none");
          $("#hoyo3").css("display", "none");
          $("#Esquiador").css("display", "none");
          $("#IceMan").css("display", "none");
          $("#FrozenFish").css("display", "none");
          $("#YetiJump").css("display", "none");
          $("#YetiWalk").css("display", "none");
          $("#Termo").css("display", "none");
          $("#Grieta").css("display", "none");
          $("#IceCream").css("display", "none");
          $("#totalNieve1").css("display", "none");
          $("#meta").css("display", "none");
          $("#salida").css("display", "none");
          $("#alegre1").css("display", "none");
          $("#alegre2").css("display", "none");
          $("#alegre3").css("display", "none");
          $("#alegre4").css("display", "none");
          $("#alegre5").css("display", "none");
          $("#alegre6").css("display", "none");
          $("#alegre7").css("display", "none");
          $("#alegre8").css("display", "none");
          $("#alegre9").css("display", "none");
          $("#alegre10").css("display", "none");
          $("#alegre11").css("display", "none");
          $("#alegre12").css("display", "none");
          $("#alegre13").css("display", "none");
          $("#alegre14").css("display", "none");
          $("#alegre15").css("display", "none");
          $("#alegre16").css("display", "none");
          $("#alegre17").css("display", "none");
          $("#alegre18").css("display", "none");
          $("#alegre19").css("display", "none");
          $("#alegre20").css("display", "none");
          $("#angustiado1").css("display", "none");
          $("#angustiado2").css("display", "none");
          $("#angustiado3").css("display", "none");
          $("#angustiado4").css("display", "none");
          $("#angustiado5").css("display", "none");
          $("#angustiado6").css("display", "none");
          $("#angustiado7").css("display", "none");
          $("#angustiado8").css("display", "none");
          $("#angustiado9").css("display", "none");
          $("#angustiado10").css("display", "none");
          $("#angustiado11").css("display", "none");
          $("#angustiado12").css("display", "none");
          $("#angustiado13").css("display", "none");
          $("#angustiado14").css("display", "none");
          $("#angustiado15").css("display", "none");
          $("#angustiado16").css("display", "none");
          $("#angustiado17").css("display", "none");
          $("#angustiado18").css("display", "none");
          $("#angustiado19").css("display", "none");
          $("#angustiado20").css("display", "none");
          $("#enojado1").css("display", "none");
          $("#enojado2").css("display", "none");
          $("#enojado3").css("display", "none");
          $("#enojado4").css("display", "none");
          $("#enojado5").css("display", "none");
          $("#enojado6").css("display", "none");
          $("#enojado7").css("display", "none");
          $("#enojado8").css("display", "none");
          $("#enojado19").css("display", "none");
          $("#enojado10").css("display", "none");
          $("#enojado11").css("display", "none");
          $("#enojado12").css("display", "none");
          $("#enojado13").css("display", "none");
          $("#enojado14").css("display", "none");
          $("#enojado15").css("display", "none");
          $("#enojado16").css("display", "none");
          $("#enojado17").css("display", "none");
          $("#enojado18").css("display", "none");
          $("#enojado19").css("display", "none");
          $("#enojado20").css("display", "none");
          $("#feliz1").css("display", "none");
          $("#feliz2").css("display", "none");
          $("#feliz3").css("display", "none");
          $("#feliz4").css("display", "none");
          $("#feliz5").css("display", "none");
          $("#feliz6").css("display", "none");
          $("#feliz7").css("display", "none");
          $("#feliz8").css("display", "none");
          $("#feliz9").css("display", "none");
          $("#feliz10").css("display", "none");
          $("#feliz11").css("display", "none");
          $("#feliz12").css("display", "none");
          $("#feliz13").css("display", "none");
          $("#feliz14").css("display", "none");
          $("#feliz15").css("display", "none");
          $("#feliz16").css("display", "none");
          $("#feliz17").css("display", "none");
          $("#feliz18").css("display", "none");
          $("#feliz19").css("display", "none");
          $("#feliz20").css("display", "none");
          $("#sorprendido1").css("display", "none");
          $("#sorprendido2").css("display", "none");
          $("#sorprendido3").css("display", "none");
          $("#sorprendido4").css("display", "none");
          $("#sorprendido5").css("display", "none");
          $("#sorprendido6").css("display", "none");
          $("#sorprendido7").css("display", "none");
          $("#sorprendido8").css("display", "none");
          $("#sorprendido9").css("display", "none");
          $("#sorprendido10").css("display", "none");
          $("#sorprendido11").css("display", "none");
          $("#sorprendido12").css("display", "none");
          $("#sorprendido13").css("display", "none");
          $("#sorprendido14").css("display", "none");
          $("#sorprendido15").css("display", "none");
          $("#sorprendido16").css("display", "none");
          $("#sorprendido17").css("display", "none");
          $("#sorprendido18").css("display", "none");
          $("#sorprendido19").css("display", "none");
          $("#sorprendido20").css("display", "none");
          $("#triste1").css("display", "none");
          $("#triste2").css("display", "none");
          $("#triste3").css("display", "none");
          $("#triste4").css("display", "none");
          $("#triste5").css("display", "none");
          $("#triste6").css("display", "none");
          $("#triste7").css("display", "none");
          $("#triste8").css("display", "none");
          $("#triste9").css("display", "none");
          $("#triste10").css("display", "none");
          $("#triste11").css("display", "none");
          $("#triste12").css("display", "none");
          $("#triste13").css("display", "none");
          $("#triste14").css("display", "none");
          $("#triste15").css("display", "none");
          $("#triste16").css("display", "none");
          $("#triste17").css("display", "none");
          $("#triste18").css("display", "none");
          $("#triste19").css("display", "none");
          $("#triste20").css("display", "none");
          
          $("#vocal_a").css("display", "none");
          $("#vocal_e").css("display", "none");
          $("#vocal_i").css("display", "none");
          $("#vocal_o").css("display", "none");
          $("#vocal_u").css("display", "none");
          $("#abeja").css("display", "none");
          $("#aguila").css("display", "none");
          $("#alce").css("display", "none");
          $("#arana").css("display", "none");
          $("#ave").css("display", "none");
          $("#caballo").css("display", "none");
          $("#cocodrilo").css("display", "none");
          $("#leon").css("display", "none");
          $("#rana").css("display", "none");
          $("#elefante").css("display", "none");

          $("#AAguila").css("display", "none");
          $("#Abuho").css("display", "none");
          $("#Acotorro").css("display", "none");
          $("#Apajaro").css("display", "none");
          $("#Atucan").css("display", "none");
          $("#borrego").css("display", "none");
          $("#caballo2").css("display", "none");
          $("#conejo1").css("display", "none");
          $("#pulpo").css("display", "none");
          $("#Pballena").css("display", "none");
          $("#Pdelfin").css("display", "none");
          $("#Pglobo").css("display", "none");
          $("#Ppayaso").css("display", "none");
          $("#Ptiburon").css("display", "none");
          $("#caballo3").css("display", "none");
          $("#conejo2").css("display", "none");
          $("#flor1").css("display", "none");
          $("#hipopotamo").css("display", "none");
          $("#roca").css("display", "none");
          $("#tronco").css("display", "none");
          $("#Gborrego").css("display", "none");
          $("#Gcaballo").css("display", "none");
          $("#Ggallina").css("display", "none");
          $("#Gpuerco").css("display", "none");
          $("#Gvaca").css("display", "none");
          $("#camello").css("display", "none");
          $("#madera").css("display", "none");
          $("#pajaro").css("display", "none");
          $("#pasto").css("display", "none");
          $("#roca").css("display", "none");
          $("#tucan").css("display", "none");
          $("#Zchango").css("display", "none");
          $("#Zelefante").css("display", "none");
          $("#Zjirafa").css("display", "none");
          $("#Zleon").css("display", "none");
          $("#Ztigre").css("display", "none");
          $("#caballo4").css("display", "none");
          $("#casarbol").css("display", "none");
          $("#flor2").css("display", "none");
          $("#gato").css("display", "none");
          $("#madera1").css("display", "none");
          $("#paisaje").css("display", "none");
          $("#perro").css("display", "none");
          $("#vaca").css("display", "none");
          $("#ardilla3").css("display","none");
          $("#punto").css("display","none");
          $("#punto2").css("display","none");
          $("#punto3").css("display","none");
          $("#punto4").css("display","none");
          $("#punto5").css("display","none");
          $("#punto6").css("display","none");
          $("#punto7").css("display","none");

          $("#lh1").css("display","none");
          $("#lh2").css("display","none");
          $("#lh3").css("display","none");
          $("#lh4").css("display","none");
          $("#lh5").css("display","none");
          $("#lh6").css("display","none");
          $("#lh7").css("display","none");
          $("#lh8").css("display","none");
          $("#lh9").css("display","none");
          $("#lh10").css("display","none");
          $("#lh11").css("display","none");
          $("#lh12").css("display","none");
          $("#lh13").css("display","none");
          $("#lh14").css("display","none");
          $("#lh15").css("display","none");
          $("#lh16").css("display","none");
          $("#lh17").css("display","none");
          $("#lh18").css("display","none");
          $("#lh19").css("display","none");
          $("#lh20").css("display","none");
          $("#lh21").css("display","none");
          $("#lh22").css("display","none");
          $("#lh23").css("display","none");
          $("#lh24").css("display","none");
          $("#lh25").css("display","none");
          $("#lh26").css("display","none");
          $("#lh27").css("display","none");
          $("#lh28").css("display","none");
          $("#lh29").css("display","none");
          $("#lh30").css("display","none");
          $("#lh31").css("display","none");
          $("#lh32").css("display","none");
          $("#lh33").css("display","none");
          $("#lh34").css("display","none");
          $("#lh35").css("display","none");
          $("#lh36").css("display","none");
          $("#lh37").css("display","none");
          $("#lh38").css("display","none");
          $("#lh39").css("display","none");
          $("#lh40").css("display","none");
          $("#lh41").css("display","none");
          $("#lh42").css("display","none");
          $("#lh43").css("display","none");
          $("#lh44").css("display","none");
          $("#lh45").css("display","none");
          $("#lh46").css("display","none");
          $("#lh47").css("display","none");
          $("#lh48").css("display","none");
          $("#lh49").css("display","none");
          $("#lh50").css("display","none");
          $("#lh51").css("display","none");
          $("#lh52").css("display","none");
          $("#lh53").css("display","none");
          $("#lh54").css("display","none");
          $("#lh55").css("display","none");
          $("#lh56").css("display","none");
          $("#lh57").css("display","none");
          $("#lh58").css("display","none");
          $("#lh59").css("display","none");
          $("#lh60").css("display","none");
          $("#lh61").css("display","none");
          $("#lh62").css("display","none");
          $("#lh63").css("display","none");
          $("#lh64").css("display","none");
          $("#lh65").css("display","none");
          $("#lh66").css("display","none");
          $("#lh67").css("display","none");
          $("#lh68").css("display","none");
          $("#lh69").css("display","none");
          $("#lh70").css("display","none");
          $("#lh71").css("display","none");
          $("#lh72").css("display","none");
          $("#lh73").css("display","none");
          $("#lh74").css("display","none");
          $("#lh75").css("display","none");
          $("#lh76").css("display","none");
          $("#lh77").css("display","none");
          $("#lh78").css("display","none");
          $("#lh79").css("display","none");
          $("#lh80").css("display","none");
          $("#lh81").css("display","none");
          $("#lh82").css("display","none");
          $("#lh83").css("display","none");
          $("#lh84").css("display","none");
          $("#lh85").css("display","none");
          $("#lh86").css("display","none");
          $("#lh87").css("display","none");
          $("#lh88").css("display","none");
          $("#lh89").css("display","none");
          $("#lh90").css("display","none");
          $("#lh91").css("display","none");
          $("#lh92").css("display","none");
          $("#lh93").css("display","none");
          $("#lh94").css("display","none");
          $("#lh95").css("display","none");
          $("#lh96").css("display","none");
          $("#lh97").css("display","none");
          $("#lh98").css("display","none");
          $("#lh99").css("display","none");
          $("#lh100").css("display","none");

          $("#lv1").css("display","none");
          $("#lv2").css("display","none");
          $("#lv3").css("display","none");
          $("#lv4").css("display","none");
          $("#lv5").css("display","none");
          $("#lv6").css("display","none");
          $("#lv7").css("display","none");
          $("#lv8").css("display","none");
          $("#lv9").css("display","none");
          $("#lv10").css("display","none");
          $("#lv11").css("display","none");
          $("#lv12").css("display","none");
          $("#lv13").css("display","none");
          $("#lv14").css("display","none");
          $("#lv15").css("display","none");
          $("#lv16").css("display","none");
          $("#lv17").css("display","none");
          $("#lv18").css("display","none");
          $("#lv19").css("display","none");
          $("#lv20").css("display","none");
          $("#lv21").css("display","none");
          $("#lv22").css("display","none");
          $("#lv23").css("display","none");
          $("#lv24").css("display","none");
          $("#lv25").css("display","none");
          $("#lv26").css("display","none");
          $("#lv27").css("display","none");
          $("#lv28").css("display","none");
          $("#lv29").css("display","none");
          $("#lv30").css("display","none");
          $("#lv31").css("display","none");
          $("#lv32").css("display","none");
          $("#lv33").css("display","none");
          $("#lv34").css("display","none");
          $("#lv35").css("display","none");
          $("#lv36").css("display","none");
          $("#lv37").css("display","none");
          $("#lv38").css("display","none");
          $("#lv39").css("display","none");
          $("#lv40").css("display","none");
          $("#lv41").css("display","none");
          $("#lv42").css("display","none");
          $("#lv43").css("display","none");
          $("#lv44").css("display","none");
          $("#lv45").css("display","none");
          $("#lv46").css("display","none");
          $("#lv47").css("display","none");
          $("#lv48").css("display","none");
          $("#lv49").css("display","none");
          $("#lv50").css("display","none");
          $("#lv51").css("display","none");
          $("#lv52").css("display","none");
          $("#lv53").css("display","none");
          $("#lv54").css("display","none");
          $("#lv55").css("display","none");
          $("#lv56").css("display","none");
          $("#lv57").css("display","none");
          $("#lv58").css("display","none");
          $("#lv59").css("display","none");
          $("#lv60").css("display","none");
          $("#lv61").css("display","none");
          $("#lv62").css("display","none");
          $("#lv63").css("display","none");
          $("#lv64").css("display","none");
          $("#lv65").css("display","none");
          $("#lv66").css("display","none");
          $("#lv67").css("display","none");
          $("#lv68").css("display","none");
          $("#lv69").css("display","none");
          $("#lv70").css("display","none");
          $("#lv71").css("display","none");
          $("#lv72").css("display","none");
          $("#lv73").css("display","none");
          $("#lv74").css("display","none");
          $("#lv75").css("display","none");
          $("#lv76").css("display","none");
          $("#lv77").css("display","none");
          $("#lv78").css("display","none");
          $("#lv79").css("display","none");
          $("#lv80").css("display","none");
          $("#lv81").css("display","none");
          $("#lv82").css("display","none");
          $("#lv83").css("display","none");
          $("#lv84").css("display","none");
          $("#lv85").css("display","none");
          $("#lv86").css("display","none");
          $("#lv87").css("display","none");
          $("#lv88").css("display","none");
          $("#lv89").css("display","none");
          $("#lv90").css("display","none");
          $("#lv91").css("display","none");
          $("#lv92").css("display","none");
          $("#lv93").css("display","none");
          $("#lv94").css("display","none");
          $("#lv95").css("display","none");
          $("#lv96").css("display","none");
          $("#lv97").css("display","none");
          $("#lv98").css("display","none");
          $("#lv99").css("display","none");
          $("#lv100").css("display","none");

          $("#llanta1t").css("display","none");
          $("#llanta2t").css("display","none");
          $("#llanta3t").css("display","none");

          $("#lne1").css("display","none");
          $("#lne2").css("display","none");
          $("#lne3").css("display","none");
          $("#lne4").css("display","none");
          $("#lne5").css("display","none");
          $("#lne6").css("display","none");
          $("#lne7").css("display","none");
          $("#lne8").css("display","none");
          $("#lne9").css("display","none");
          $("#lne10").css("display","none");
          $("#lne11").css("display","none");
          $("#lne12").css("display","none");
          $("#lne13").css("display","none");
          $("#lne14").css("display","none");
          $("#lne15").css("display","none");
          $("#lne16").css("display","none");
          $("#lne17").css("display","none");
          $("#lne18").css("display","none");
          $("#lne19").css("display","none");
          $("#lne20").css("display","none");
          $("#lne21").css("display","none");
          $("#lne22").css("display","none");
          $("#lne23").css("display","none");
          $("#lne24").css("display","none");
          $("#lne25").css("display","none");
          $("#lne26").css("display","none");
          $("#lne27").css("display","none");
          $("#lne28").css("display","none");
          $("#lne29").css("display","none");
          $("#lne30").css("display","none");
          $("#lne31").css("display","none");
          $("#lne32").css("display","none");
          $("#lne33").css("display","none");
          $("#lne34").css("display","none");
          $("#lne35").css("display","none");
          $("#lne36").css("display","none");
          $("#lne37").css("display","none");
          $("#lne38").css("display","none");
          $("#lne39").css("display","none");
          $("#lne40").css("display","none");
          $("#lne41").css("display","none");
          $("#lne42").css("display","none");
          $("#lne43").css("display","none");
          $("#lne44").css("display","none");
          $("#lne45").css("display","none");
          $("#lne46").css("display","none");
          $("#lne47").css("display","none");
          $("#lne48").css("display","none");
          $("#lne49").css("display","none");
          $("#lne50").css("display","none");
          $("#lne51").css("display","none");
          $("#lne52").css("display","none");
          $("#lne53").css("display","none");
          $("#lne54").css("display","none");
          $("#lne55").css("display","none");
          $("#lne56").css("display","none");
          $("#lne57").css("display","none");
          $("#lne58").css("display","none");
          $("#lne59").css("display","none");
          $("#lne60").css("display","none");
          $("#lne61").css("display","none");
          $("#lne62").css("display","none");
          $("#lne63").css("display","none");
          $("#lne64").css("display","none");
          $("#lne65").css("display","none");
          $("#lne66").css("display","none");
          $("#lne67").css("display","none");
          $("#lne68").css("display","none");
          $("#lne69").css("display","none");
          $("#lne70").css("display","none");
          $("#lne71").css("display","none");
          $("#lne72").css("display","none");
          $("#lne73").css("display","none");
          $("#lne74").css("display","none");
          $("#lne75").css("display","none");
          $("#lne76").css("display","none");
          $("#lne77").css("display","none");
          $("#lne78").css("display","none");
          $("#lne79").css("display","none");
          $("#lne80").css("display","none");
          $("#lne81").css("display","none");
          $("#lne82").css("display","none");
          $("#lne83").css("display","none");
          $("#lne84").css("display","none");
          $("#lne85").css("display","none");
          $("#lne86").css("display","none");
          $("#lne87").css("display","none");
          $("#lne88").css("display","none");
          $("#lne89").css("display","none");
          $("#lne90").css("display","none");
          $("#lne91").css("display","none");
          $("#lne92").css("display","none");
          $("#lne93").css("display","none");
          $("#lne94").css("display","none");
          $("#lne95").css("display","none");
          $("#lne96").css("display","none");
          $("#lne97").css("display","none");
          $("#lne98").css("display","none");
          $("#lne99").css("display","none");
          $("#lne100").css("display","none");

          $("#lno1").css("display","none");
          $("#lno2").css("display","none");
          $("#lno3").css("display","none");
          $("#lno4").css("display","none");
          $("#lno5").css("display","none");
          $("#lno6").css("display","none");
          $("#lno7").css("display","none");
          $("#lno8").css("display","none");
          $("#lno9").css("display","none");
          $("#lno10").css("display","none");
          $("#lno11").css("display","none");
          $("#lno12").css("display","none");
          $("#lno13").css("display","none");
          $("#lno14").css("display","none");
          $("#lno15").css("display","none");
          $("#lno16").css("display","none");
          $("#lno17").css("display","none");
          $("#lno18").css("display","none");
          $("#lno19").css("display","none");
          $("#lno20").css("display","none");
          $("#lno21").css("display","none");
          $("#lno22").css("display","none");
          $("#lno23").css("display","none");
          $("#lno24").css("display","none");
          $("#lno25").css("display","none");
          $("#lno26").css("display","none");
          $("#lno27").css("display","none");
          $("#lno28").css("display","none");
          $("#lno29").css("display","none");
          $("#lno30").css("display","none");
          $("#lno31").css("display","none");
          $("#lno32").css("display","none");
          $("#lno33").css("display","none");
          $("#lno34").css("display","none");
          $("#lno35").css("display","none");
          $("#lno36").css("display","none");
          $("#lno37").css("display","none");
          $("#lno38").css("display","none");
          $("#lno39").css("display","none");
          $("#lno40").css("display","none");
          $("#lno41").css("display","none");
          $("#lno42").css("display","none");
          $("#lno43").css("display","none");
          $("#lno44").css("display","none");
          $("#lno45").css("display","none");
          $("#lno46").css("display","none");
          $("#lno47").css("display","none");
          $("#lno48").css("display","none");
          $("#lno49").css("display","none");
          $("#lno50").css("display","none");
          $("#lno51").css("display","none");
          $("#lno52").css("display","none");
          $("#lno53").css("display","none");
          $("#lno54").css("display","none");
          $("#lno55").css("display","none");
          $("#lno56").css("display","none");
          $("#lno57").css("display","none");
          $("#lno58").css("display","none");
          $("#lno59").css("display","none");
          $("#lno60").css("display","none");
          $("#lno61").css("display","none");
          $("#lno62").css("display","none");
          $("#lno63").css("display","none");
          $("#lno64").css("display","none");
          $("#lno65").css("display","none");
          $("#lno66").css("display","none");
          $("#lno67").css("display","none");
          $("#lno68").css("display","none");
          $("#lno69").css("display","none");
          $("#lno70").css("display","none");
          $("#lno71").css("display","none");
          $("#lno72").css("display","none");
          $("#lno73").css("display","none");
          $("#lno74").css("display","none");
          $("#lno75").css("display","none");
          $("#lno76").css("display","none");
          $("#lno77").css("display","none");
          $("#lno78").css("display","none");
          $("#lno79").css("display","none");
          $("#lno80").css("display","none");
          $("#lno81").css("display","none");
          $("#lno82").css("display","none");
          $("#lno83").css("display","none");
          $("#lno84").css("display","none");
          $("#lno85").css("display","none");
          $("#lno86").css("display","none");
          $("#lno87").css("display","none");
          $("#lno88").css("display","none");
          $("#lno89").css("display","none");
          $("#lno90").css("display","none");
          $("#lno91").css("display","none");
          $("#lno92").css("display","none");
          $("#lno93").css("display","none");
          $("#lno94").css("display","none");
          $("#lno95").css("display","none");
          $("#lno96").css("display","none");
          $("#lno97").css("display","none");
          $("#lno98").css("display","none");
          $("#lno99").css("display","none");
          $("#lno100").css("display","none");
          $("#blan").css("display","none");
          $("#blan1").css("display","none");
          $("#blan2").css("display","none");
          $("#blan3").css("display","none");
          $("#blan4").css("display","none");
          $("#blan5").css("display","none");
          $("#blan6").css("display","none");
          $("#blan7").css("display","none");
          $("#blan8").css("display","none");
          $("#blan9").css("display","none");
          $("#blan10").css("display","none");

          $("#airplane").css("display","none");
          $("#ambulance").css("display","none");
          $("#angel").css("display","none");
          $("#elephant").css("display","none");
          $("#esmeralda").css("display","none");
          $("#exercise").css("display","none");
          $("#igloo").css("display","none");
          $("#insect").css("display","none");
          $("#inyeccion").css("display","none");
          $("#ocean").css("display","none");
          $("#office").css("display","none");
          $("#oyster").css("display","none");
          $("#unicorn").css("display","none");
          $("#uniform").css("display","none");
          $("#universe").css("display","none");

          $("#balonN").css("display","none");
          $("#carroN").css("display","none");
          $("#libroN").css("display","none");
          $("#osoN").css("display","none");
          $("#solN").css("display","none");
           
          
             if(response.data.objects.length  > 0){
          response.data.objects.forEach(element => {
            if (element.type.type == "Objetivo"){
              Goals = {
                Type: element.type.name,
                TypeO: element.type.type,
                postx: element.init_x,
                posty: element.init_y,
                bCorrect: null,
                iPriority: element.priority,
                avatar_Number: element.avatar_number
                //temp_valor: 0
              };
            ArrarObjects.push(Goals);
            }
            if( element.type.active == 1 ){
              $("#" + element.type.name).css("top", element.position_y + "px");
              $("#"+ element.type.name).css("left", element.position_x + "px");
              $("#"+ element.type.name).css("display", "block");
            }
            if(element.priority == 3){
              $("#" + element.type.name).css("top", element.position_y + "px");
              $("#"+ element.type.name).css("left", element.position_x + "px");
              $("#"+ element.type.name).css("display", "none");
            }
          });
          }
          window.GoalsArray =ArrarObjects;
        });
    },
    async evaluate() {
      //validamos que haya usado al menos una vez los bloques
      let allBlocksUsed = 0;
      let model = {}
      let blockType = '';
      const blocksToUse = this.blocksToUse.split('|')
      let arrayBlocksToUse = []
      let arrayBlocksUsed = this.$refs["blockly1"].workspace.getAllBlocks()
      this.arrayBlocks.forEach( element => {
        arrayBlocksToUse.push({
          block: element.type,
          used: 0
        })
      } )
      console.log(arrayBlocksToUse)
      arrayBlocksUsed.forEach( block => {
        console.log('block', block)
        if(block.type != 'initial' && block.type != 'procedures_defnoreturn') {
          let blockUsed = arrayBlocksToUse.find( element => element.block == block.type )
          if(blockUsed != undefined) {
            console.log('blockUsed', blockUsed)
            blockUsed.used = 1
          }
        }
      } )
      //validamos que se hayan usado todos los bloques
      if(this.$route.query.single_activity != 1) {
        if(this.help == 1)
          this.score = 80
        if(this.help == 2)
          this.score = 60
        model = {
          activity_id: this.activity_id,
          person_id: localStorage.idPerson,
          activation_id: localStorage.idActivation,
          opportunity_number: this.help + 1,
          score: this.score,
        };
      }

      if(this.$route.query.single_activity == 1) {
        if(this.help == 1)
          this.score = 80
        if(this.help == 2)
          this.score = 60
        model = {
          activity_id: this.activity_id,
          person_id: this.$route.query.id_person,
          activation_id: this.$route.query.id_activation,
          opportunity_number: this.help + 1,
          score: this.score,
        };
      }
      console.log('Positx', posiitx);
      console.log('window.positx_goal', window.positx_goal);

      if (this.activityDone == 1) {
        /*arrayBlocksToUse.forEach( element => {
          this.activityDone = element.used
          if(element.used == 0)
            blockType = blockType + element.block + ' '
        } )*/

        if(this.activityDone == 1) {
          this.openLoading();
          await axios
            .post(apiETC + "api/smartc3/evaluate-activity", model)
            .then((response) => {
              Swal.close();
              $("#AppModal").modal("show");
              //this.showMessage("Exito", response.data.message, "success", "", 1);
            })
            .error((error) => {
              Swal.close();
            });
        } else {
          alert('Tu solución funciona, pero te falto usar algunos bloques (' + blockType + ')')
        }
      } else {
        $("#AppModal").modal("show");
      }
    },
    showModalCode() {
      $("#AppModalCode").appendTo("body").modal("show")
      Fire.$emit('startBlockly')
    }
  },
  async mounted() {
    $(document).ready(function () {
      App.init();

      $('#AppModalCode').on('shown.bs.modal', function() {
        $(document).off('focusin.modal');
      });

      window.canvas = document.querySelector('#canvas');
      window.ctxCanvas = window.canvas.getContext('2d');
    });

    await this.configActivity(this.$route.query.idQuestion);

    Fire.$on("clearWorkspace", () => {
      this.$refs["blockly1"].workspace.clear();
      Fire.$emit("reinitialize");
    });

    Fire.$on("activityDone", () => {
      this.activityDone = 1
    })
  },
  watch: {
    $route(to, from) {
      this.score = 100;
      this.activity_id = 0;
      this.activityDone = 0;
      this.configActivity(to.query.idQuestion);
    },
  },
};
</script>

<style>
#blockly1 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.scenario {
  background-repeat: no-repeat;
  position: relative;
  background-size: 95% 95%;
  width: 600px;
  height: 600px;
}

#character {
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
  background-size: 100% 100%;
  height: 100px;
  width: 80px;
  display: none;
}
#character2 {
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
  background-size: 100% 100%;
  height: 100px;
  width: 80px;
  display: none;
}
#character3 {
  background-repeat: no-repeat;
  position: absolute;
  z-index: 3;
  background-size: 100% 100%;
  height: 70px;
  width: 70px;
  display: none;
}
#gem {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem2 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem3 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem4 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem5 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem6 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem7 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem8 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem9 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem10 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem11 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem12 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem13 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem14 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem15 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem16 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem17 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem18 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#gem19 {
  background-image: url("../assets/gifs/gema.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#palette {
  background-image: url("../assets/img/distractores/paleta.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 70px;
  width: 80px;
  display: none;
}
#sweet {
  background-image: url("../assets/img/distractores/dulce.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 70px;
  width: 80px;
  display: none;
}
#icecream {
  background-image: url("../assets/img/distractores/helado.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 70px;
  width: 80px;
  display: none;
}
#gift {
  background-image: url("../assets/img/distractores/regalo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#gift2 {
  background-image: url("../assets/img/distractores/regalo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#gift3 {
  background-image: url("../assets/img/distractores/regalo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#eyes {
  background-image: url("../assets/gifs/gif_zoo/ojo.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#tail {
  background-image: url("../assets/gifs/gif_zoo/cola.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#panda {
  background-image: url("../assets/gifs/gif_zoo/panda.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#duck1 {
  background-image: url("../assets/gifs/gif_zoo/1pato.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#duck2 {
  background-image: url("../assets/gifs/gif_zoo/2pato.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#delfin {
  background-image: url("../assets/gifs/gif_zoo/delfin.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 100px;
  display: none;
}
#oso {
  background-image: url("../assets/gifs/gif_zoo/cachorro.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 100px;
  display: none;
}
#rusa {
  background-image: url("../assets/gifs/gif_parq/rusa.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 565px;
  width: 220px;
  display: none;
}
#Payaso1 {
  background-image: url("../assets/gifs/gif_parq/Payaso1.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 200px;
  width: 134px;
  display: none;
}
#targetShooting {
  background-image: url("../assets/gifs/gif_parq/TiroPatos.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 80px;
  width: 120px;
  display: none;
}
#waterJet {
  background-image: url("../assets/gifs/gif_parq/waterJet.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 190px;
  display: none;
}
#swing {
  background-image: url("../assets/gifs/gif_parq/columpio.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 130px;
  width: 180px;
  display: none;
}
#bellota {
  background-image: url("../assets/img/activity/bellota.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#semilla-01 {
  background-image: url("../assets/gifs/gif_hadas/semilla-01.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 35px;
  width: 25px;
  display: none;
}
#circle-dark {
  background-image: url("../assets/gifs/gif_hadas/circle-dark.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 35px;
  width: 25px;
  display: none;
}
#circle-dark1 {
  background-image: url("../assets/gifs/gif_hadas/circle-dark.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 35px;
  width: 25px;
  display: none;
}
#hada2 {
  background-image: url("../assets/gifs/gif_hadas/hada2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 155px;
  width: 195px;
  display: none;
}
#catarina {
  background-image: url("../assets/gifs/gif_hadas/catarina.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 95px;
  width: 65px;
  display: none;
}
#hada3 {
  background-image: url("../assets/gifs/gif_hadas/hada3.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 155px;
  width: 195px;
  display: none;
}
#LamparaCorazon {
  background-image: url("../assets/gifs/gif_hadas/LamparaCorazon.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 100px;
  width: 115px;
  display: none;
}
#z {
  background-image: url("../assets/gifs/gif_hadas/z.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 65px;
  width: 45px;
  display: none;
}
#hada1 {
  background-image: url("../assets/gifs/gif_hadas/hada1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 155px;
  width: 195px;
  display: none;
}
#luzventana {
  background-image: url("../assets/gifs/gif_hadas/luzventana.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 120px;
  width: 80px;
  display: none;
}
#bellota1 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#bellota2 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#bellota3 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#bellota4 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#bellota5 {
  background-image: url("../assets/gifs/gif_hadas/bellota.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}

#Rana {
  background-image: url("../assets/gifs/gif_hadas/Rana.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 155px;
  width: 195px;
  display: none;
}
#Principe {
  background-image: url("../assets/gifs/gif_hadas/Principe.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 120px;
  width: 80px;
  display: none;
}

#hojas {
  background-image: url("../assets/gifs/gif_hadas/hojas.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}

#hojas2 {
  background-image: url("../assets/gifs/gif_hadas/hojas.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}

#gnomo1 {
  background-image: url("../assets/gifs/gif_gnomos/gnomo1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#gnomo2 {
  background-image: url("../assets/gifs/gif_gnomos/gnomo2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#canngreyconcha {
  background-image: url("../assets/gifs/gif_playa/canngreyconcha.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#tiburon {
  background-image: url("../assets/gifs/gif_playa/tiburon.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#notas {
  background-image: url("../assets/gifs/gif_playa/notas.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#cangrejo {
  background-image: url("../assets/gifs/gif_Cueva/cangrejo.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#gaviota {
  background-image: url("../assets/gifs/gif_playa/gaviota.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#barrilenagua {
  background-image: url("../assets/gifs/gif_Cueva/barrilenagua.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#ola1 {
  background-image: url("../assets/gifs/gif_Cueva/ola1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#shine {
  background-image: url("../assets/gifs/gif_Cueva/shine.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#arana1 {
  background-image: url("../assets/gifs/gif_Cueva/arana1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#arana2 {
  background-image: url("../assets/gifs/gif_Cueva/arana2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#tesoropirata {
  background-image: url("../assets/gifs/gif_Cueva/tesoropirata.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#mancha {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha1 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha2 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha3 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha4 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha5 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha6 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#mancha7 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#mancha8 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#mancha9 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#mancha10 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}
#mancha11 {
  background-image: url("../assets/gifs/mancha.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 90px;
  width: 90px;
  display: none;
}

#tentacle {
  background-image: url("../assets/gifs/gif_valle/tentacle.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Spike {
  background-image: url("../assets/gifs/gif_valle/Spike.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#ShakeBush {
  background-image: url("../assets/gifs/gif_valle/ShakeBush.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Planta-Carnivora2 {
  background-image: url("../assets/gifs/gif_valle/Planta-Carnivora2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Planta-Carnivora1 {
  background-image: url("../assets/gifs/gif_valle/Planta-Carnivora1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Mushroom {
  background-image: url("../assets/gifs/gif_valle/Mushroom.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#MudMonster {
  background-image: url("../assets/gifs/gif_valle/MudMonster.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Monstruo2 {
  background-image: url("../assets/gifs/gif_valle/Monstruo2.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}

#Monstruo1 {
  background-image: url("../assets/gifs/gif_valle/Monstruo1.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 130px;
  width: 100px;
  display: none;
}

#eyeTree {
  background-image: url("../assets/gifs/gif_valle/eyeTree.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#Craneo {
  background-image: url("../assets/gifs/gif_valle/Craneo.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

#nieve {
  background-image: url("../assets/gifs/nieve.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#nieve2 {
  background-image: url("../assets/gifs/nieve.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#nieve3 {
  background-image: url("../assets/gifs/nieve.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#hoyo {
  background-image: url("../assets/gifs/hoyo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#hoyo2 {
  background-image: url("../assets/gifs/hoyo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}
#hoyo3 {
  background-image: url("../assets/gifs/hoyo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 35px;
  display: none;
}

#Esquiador {
  background-image: url("../assets/gifs/gif_moustros/Esquiador.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#IceMan {
  background-image: url("../assets/gifs/gif_moustros/IceMan.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#FrozenFish {
  background-image: url("../assets/gifs/gif_moustros/FrozenFish.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#YetiJump {
  background-image: url("../assets/gifs/gif_moustros/YetiJump.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#YetiWalk {
  background-image: url("../assets/gifs/gif_moustros/YetiWalk.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#Termo {
  background-image: url("../assets/gifs/gif_moustros/Termo.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#Grieta {
  background-image: url("../assets/gifs/gif_moustros/Grieta.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#IceCream {
  background-image: url("../assets/gifs/gif_moustros/IceCream.gif");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}
#totalNieve1 {
  /*background-image: url("../assets/gifs/gif_moustros/IceCream.gif");*/
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 180px;
  width: 100px;
  display: none;
}

#salida {
  background-image: url("../assets/gifs/nuevos/salida.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#meta {
  background-image: url("../assets/gifs/nuevos/meta.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre1 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre2 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre3 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre4 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre5 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre6 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre7 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre8 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre9 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre10 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre11 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre12 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre13 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre14 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre15 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre16 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre17 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre18 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre19 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alegre20 {
  background-image: url("../assets/gifs/nuevos/alegre_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado1 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado2 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado3 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado4 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado5 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado6 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado7 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado8 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado9 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado10 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado11 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado12 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado13 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado14 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado15 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado16 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado17 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado18 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado19 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#angustiado20 {
  background-image: url("../assets/gifs/nuevos/angustiado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}

#enojado1 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado2 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado3 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado4 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado5 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado6 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado7 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado8 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado9 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado10 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado11 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado12 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado13 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado14 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado15 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado16 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado17 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado18 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado19 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#enojado20 {
  background-image: url("../assets/gifs/nuevos/enojado_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz1 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;

  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}

#feliz2 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz3 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz4 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz5 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz6 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz7 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz8 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz9 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz10 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz11 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz12 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz13 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz14 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz15 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz16 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz17 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz18 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz19 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#feliz20 {
  background-image: url("../assets/gifs/nuevos/feliz_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido1 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido2 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido3 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido4 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido5 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido6 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido7 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido8 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido9 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido10 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido11 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido12 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido13 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido14 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido15 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido16 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido17 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido18 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido19 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#sorprendido20 {
  background-image: url("../assets/gifs/nuevos/sorpendido_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste1 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste2 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste3 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 75px;
  width: 75px;
  display: none;
}
#triste4 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste5 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste6 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste7 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste8 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste9 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste10 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste11 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste12 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste13 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste14 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste15 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste16 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste17 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste18 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste19 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#triste20 {
  background-image: url("../assets/gifs/nuevos/triste_72x72.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}


#vocal_a {
  background-image: url("../assets/gifs/nuevos/vocal_a.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#vocal_e {
  background-image: url("../assets/gifs/nuevos/vocal_e.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#vocal_i {
  background-image: url("../assets/gifs/nuevos/vocal_i.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#vocal_o {
  background-image: url("../assets/gifs/nuevos/vocal_o.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#vocal_u {
  background-image: url("../assets/gifs/nuevos/vocal_u.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}

#abeja {
  background-image: url("../assets/gifs/nuevos/abeja.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#aguila {
  background-image: url("../assets/gifs/nuevos/aguila.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#alce {
  background-image: url("../assets/gifs/nuevos/alce.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#arana {
  background-image: url("../assets/gifs/nuevos/arana.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#ave {
  background-image: url("../assets/gifs/nuevos/ave.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#caballo {
  background-image: url("../assets/gifs/nuevos/caballo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#cocodrilo {
  background-image: url("../assets/gifs/nuevos/cocodrilo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#leon {
  background-image: url("../assets/gifs/nuevos/leon.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#rana {
  background-image: url("../assets/gifs/nuevos/rana.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#elefante {
  background-image: url("../assets/gifs/nuevos/elefante.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}

#AAguila {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/AAguila.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#ardilla3 {
  background-image: url("../assets/gifs/nuevos/ardilla.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Abuho {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/Abuho.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Acotorro {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/Acotorro.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Apajaro {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/Apajaro.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Atucan {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/Atucan.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#borrego {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/borrego.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#caballo2 {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/caballo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#conejo1 {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/conejo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#pulpo {
  background-image: url("../assets/gifs/nuevos/Diapositiva2y3/pulpo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}


#Pballena {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/Pballena.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Pdelfin {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/Pdelfin.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Pglobo {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/Pglobo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Ppayaso {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/Ppayaso.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Ptiburon {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/Ptiburon.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#roca {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/roca.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#tronco {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/tronco.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#caballo3 {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/caballo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#conejo2 {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/conejo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#flor1 {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/flor.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#hipopotamo {
  background-image: url("../assets/gifs/nuevos/Diapositiva4y5/hipopotamo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}


#Gborrego {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/Gborrego.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Gcaballo {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/Gcaballo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Ggallina {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/Ggallina.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Gpuerco {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/Gpuerco.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Gvaca {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/Gvaca.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#tucan {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/tucan.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#camello {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/camello.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#madera {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/madera.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#pajaro {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/pajaro.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#pasto {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/pasto.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#roca {
  background-image: url("../assets/gifs/nuevos/Diapositiva6y7/roca.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}


#Zchango {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/Zchango.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Zelefante {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/Zelefante.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Zjirafa {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/Zjirafa.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Zleon {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/Zleon.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#Ztigre {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/Ztigre.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#caballo4 {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/caballo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#casarbol {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/casarbol.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#flor2 {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/flor.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#gato {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/gato.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#madera1 {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/madera.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#paisaje {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/paisaje.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#perro {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/perro.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#vaca {
  background-image: url("../assets/gifs/nuevos/Diapositiva8y9/vaca.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}

#punto {
  background-image: url("../assets/gifs/trazo/punto.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
  display: none;
}
#punto2 {
  background-image: url("../assets/gifs/trazo/punto.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
  display: none;
}
#punto3 {
  background-image: url("../assets/gifs/trazo/punto.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
  display: none;
}
#punto4 {
  background-image: url("../assets/gifs/trazo/punto.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
  display: none;
}
#punto5 {
  background-image: url("../assets/gifs/trazo/punto.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
  display: none;
}
#punto6 {
  background-image: url("../assets/gifs/trazo/punto.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
  display: none;
}
#punto7 {
  background-image: url("../assets/gifs/trazo/punto.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
  display: none;
}


#lh1 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}

#lh2 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}

#lh3 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}

#lh4 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh5 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh6 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh7 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh8 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh9 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh10 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh11 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh12 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh13 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh14 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh15 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh16 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh17 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh18 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh19 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh20 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh21 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh22 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh23 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh24 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh25 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh26 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh27 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh28 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh29 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh30 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh31 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh32 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh33 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh34 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh35 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh36 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh37 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh38 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh39 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh40 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh41 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh42 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh43 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh44 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh45 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh46 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh47 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh48 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh49 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh50 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 40px;
  display: none;
}
#lh51 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh52 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh53 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh54 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh55 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh56 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh57 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh58 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh59 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh60 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh61 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}

#lh62 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh63 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh64 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh65 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh66 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh67 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh68 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh69 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh70 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh71 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh72 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh73 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh74 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh75 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh76 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh77 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh78 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh79 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh80 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh81 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh82 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh83 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh84 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh85 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh86 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh87 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh88 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh89 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh90 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh91 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh92 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh93 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh94 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh95 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh96 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh97 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh98 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh99 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}
#lh100 {
  background-image: url("../assets/gifs/trazo/horizontal.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 10px;
  width: 20px;
  display: none;
}


#lv1 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}

#lv2 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}

#lv3 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv4 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv5 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv6 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv7 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv8 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv9 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv10 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv11 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv12 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv13 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv14 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv15 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv16 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv17 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv18 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv19 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv20 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv21 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv22 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv23 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv24 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv25 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv26 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv27 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv28 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv29 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv30 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv31 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv32 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv33 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv34 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv35 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv36 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv37 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv38 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv39 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv40 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv41 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv42 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv43 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv44 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv45 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv46 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv47 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}

#lv48 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv49 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv50 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 40px;
  width: 10px;
  display: none;
}
#lv51 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv52 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}

#lv53 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv54 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv55 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv56 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv57 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv58 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv59 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv60 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv61 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv62 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv63 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv64 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv65 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv66 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv67 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv68 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv69 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv70 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv71 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv72 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv73 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv74 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv75 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv76 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv77 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv78 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv79 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv80 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv81 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv82 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv83 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv84 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv85 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv86 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv87 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv88 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}

#lv89 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv90 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv91 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv92 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv93 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}

#lv94 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv95 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv96 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv97 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv98 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv99 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}
#lv100 {
  background-image: url("../assets/gifs/trazo/vertical.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 10px;
  display: none;
}

#llanta1t {
  background-image: url("../assets/gifs/trazo/llanta1t.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#llanta2t {
  background-image: url("../assets/gifs/trazo/llanta2t.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}
#llanta3t {
  background-image: url("../assets/gifs/trazo/llanta3t.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100% 100%;
  height: 90px;
  width: 100px;
  display: none;
}

 #lne1 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne2 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne3 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne4 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne5 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne6 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne7 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne8 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne9 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne10 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 45px;
     width: 50px;
    display: none;
  }
  #lne11 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne12 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne13 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne14 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne15 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne16 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne17 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne18 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne19 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne20 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne21 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne22 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne23 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne24 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne25 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne26 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne27 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne28 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne29 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne30 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne31 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne32 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne33 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne34 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne35 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne36 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne37 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne38 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne39 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne40 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne41 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne42 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne43 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne44 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne45 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne46 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne47 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne48 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne49 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne50 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 55px;
     width: 60px;
    display: none;
  }
  #lne51 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne52 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne53 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne54 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne55 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne56 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne57 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne58 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne59 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne60 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne61 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne62 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne63 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne64 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne65 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne66 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne67 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne68 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne69 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne70 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne71 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne72 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne73 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne74 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne75 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne76 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne77 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne78 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne79 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne80 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne81 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne82 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne83 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne84 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne85 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne86 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne87 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne88 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne89 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne90 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne91 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne92 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne93 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne94 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne95 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne96 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne97 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne98 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne99 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }
  #lne100 {
    background-image: url("../assets/gifs/trazo/icd.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    height: 25px;
     width: 30px;
    display: none;
  }

#lno1 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno2 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}

#lno3 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno4 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno5 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno6 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno7 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno8 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno9 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno10 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 45px;
  width: 50px;
  display: none;
}
#lno11 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno12 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno13 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno14 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno15 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno16 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno17 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno18 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno19 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno20 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno21 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno22 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno23 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno24 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno25 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno26 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno27 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno28 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno29 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno30 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno31 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno32 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno33 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno34 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno35 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno36 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno37 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno38 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno39 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno40 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno41 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno42 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno43 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno44 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno45 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno46 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno47 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno48 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno49 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno50 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 55px;
  width: 60px;
  display: none;
}
#lno51 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno52 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno53 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno54 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno55 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno56 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno57 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno58 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno59 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno60 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno61 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno62 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno63 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno64 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno65 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno66 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno67 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno68 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno69 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno70 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno71 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno72 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno73 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno74 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno75 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno76 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno77 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno78 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno79 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno80 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno81 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno82 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno83 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno84 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno85 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno86 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno87 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno88 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno89 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno90 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno91 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno92 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno93 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno94 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno95 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno96 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno97 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno98 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno99 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#lno100 {
  background-image: url("../assets/gifs/trazo/ici.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}


#blan {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan1 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan2 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan3 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan4 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan5 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan6 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan7 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan8 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan9 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}
#blan10 {
  /*background-image: url("../assets/gifs/trazo/ici.png");*/
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 25px;
  width: 30px;
  display: none;
}

#airplane {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/airplane.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#ambulance {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/ambulance.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}

#angel {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/angel.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#elephant {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/elephant.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#esmeralda {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/esmeralda.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#exercise {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/exercise.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#igloo {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/igloo.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#insect {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/insect.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#inyeccion {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/inyeccion.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#ocean {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/ocean.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#office {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/office.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#oyster {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/oyster.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#unicorn {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/unicorn.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#uniform {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/uniform.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}
#universe {
  background-image: url("../assets/gifs/nuevos/actividades_3anos/universe.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 60px;
  width: 70px;
  display: none;
}


#osoN {
  background-image: url("../assets/gifs/trazo/osoN.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#balonN {
  background-image: url("../assets/gifs/trazo/balonN.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#carroN {
  background-image: url("../assets/gifs/trazo/carroN.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#libroN {
  background-image: url("../assets/gifs/trazo/libroN.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}
#solN {
  background-image: url("../assets/gifs/trazo/solN.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% 100%;
  height: 72px;
  width: 72px;
  display: none;
}


.speech-to-txt,
.txt-to-speech {
  display: grid;
  width: 200px;
  height: 100px;
  border-radius: 20px;
  border: 2px solid grey;
  background-color: rgb(248, 245, 245);
  font-size: 38px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  place-items: center;
}
.speech-transciption {
  width: 500px;
  padding: 20px;
  border: 2px solid grey;
  background-color: rgb(211, 228, 253);
  border-radius: 20px;
}

#mark {
  background-color: blue;
}

.quitarresaltarTexto {
 color: #070707;
 background-color: #fffffd;
}

.resaltarTexto{
    color: #F3AA04;
    background-color: #FFFF96;
}
#canvas-wrap { position:relative } /* Make this a positioned parent */
#overlay     { position:absolute; top:0px; left:0px;}
</style>
