<template>
    <div>
        <app-header></app-header>
        <div class="main-container" id="container">
            <div class="overlay"></div>
            <div class="search-overlay"></div>
            <app-sidebar></app-sidebar>
            <div id="content" class="main-content">
                <div class="layout-px-spacing">
                    <h2 class="text-center" v-if="iRol == 3">Progreso del estudiante</h2>
                    <div v-if="iRol == 2">
                        <h2 class="text-center"><i class="fa fa-star text-warning"></i> Mis notas y logros</h2>
                    </div>
                    <div class="row" v-if="iRol == 3">
                        <div class="col-3">
                            <label>Nivel escolar</label>
                            <select class="custom-select custom-select-sm" id="level_id" @change="getGrades" v-model="model.level_id">
                                <option :value="'' + level.id" v-for="level in educationLevel" :key="level.id">{{ level.sOption }}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <label>Grado escolar</label>
                            <select class="custom-select custom-select-sm" id="grade_id" @change="getGroups" v-model="model.grade_id">
                                <option :value="'' + grade.id" v-for="grade in grades" :key="grade.id">{{ grade.sOption }}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <label>Grupo</label>
                            <select class="custom-select custom-select-sm" id="group_id" v-model="model.group_id">
                                <option :value="'' + group.id" v-for="group in groups" :key="group.id">{{ group.sOption }}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <br>
                            <button class="btn btn-primary mr-1" @click.prevent="getReport(0)" v-if="model.group_id > 0">Consultar</button>
                            <button class="btn btn-primary" @click.prevent="exportExcel()" v-if="model.group_id > 0 && showExportButton">Exportar</button>
                        </div>
                    </div>
                    <div class="row" v-if="iRol == 3">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12 layout-top-spacing">
                            <div class="widget-content-area br-4">
                                <div class="widget-one">
                                    <table class="table table-responsive-lg table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="40%">Nombre</th>
                                                <th scope="col" width="30%">Avance</th>
                                                <th scope="col" width="30%">Rendimiento</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="student in students">
                                                <tr :key="student.idPerson">
                                                    <td><a href="#" @click.prevent="getReportLevel1(student)"><strong>{{student.name}}</strong></a></td>
                                                    <td> 
                                                    <small class="float-right">{{(student.dOverallProgress * 100).toFixed(0)}}%</small>            
                                                        <div class="progress br-30">
                                                            <div class="progress-bar br-30 bg-success" role="progressbar" :style="'width:' + (student.dOverallProgress * 100).toFixed(0) + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </td>
                                                    <td> 
                                                    <small class="float-right">{{(student.dPerformance * 100).toFixed(0)}}%</small>            
                                                        <div class="progress br-30">
                                                            <div class="progress-bar br-30 bg-success" role="progressbar" :style="'width:' + (student.dPerformance * 100).toFixed(0) + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr :key="'worlds' + student.idPerson" :id="'worlds' + student.idPerson" style="display:none">
                                                    <td colspan="3" style="padding-left: 0px;">
                                                        <table class="table table-responsive-lg table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" width="40%">Mundo</th>
                                                                    <th scope="col" width="30%">Avance</th>
                                                                    <th scope="col" width="30%">Rendimiento</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-for="world in student.worlds">
                                                                    <tr :key="'world_' + student.idPerson + '_' + world.id">
                                                                        <td><a href="#" @click.prevent="getReportLevel2('activityType_' + student.idPerson + '_' + world.id)">{{world.name}}</a></td>
                                                                        <td style="padding-right: 5px"> 
                                                                            <small class="float-right">{{(world.dOverallProgress * 100).toFixed(0)}}%</small>            
                                                                            <div class="progress br-30">
                                                                                <div class="progress-bar br-30 bg-success" role="progressbar" :style="'width:' + (world.dOverallProgress * 100).toFixed(0) + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </td>
                                                                        <td style="padding-right: 5px"> 
                                                                            <small class="float-right">{{(world.dPerformance * 100).toFixed(0)}}%</small>            
                                                                            <div class="progress br-30">
                                                                                <div class="progress-bar br-30 bg-success" role="progressbar" :style="'width:' + (world.dPerformance * 100).toFixed(0) + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr :key="'activityType_' + student.idPerson + '_' + world.id" style="display: none" :id="'activityType_' + student.idPerson + '_' + world.id">
                                                                        <td colspan="3">
                                                                            <table style="width: 100%">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" width="40%">Tipo</th>
                                                                                        <th scope="col" width="60%">Actividades</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="activityType in world.activityTypes" :key="`activity_${student.idPerson}_${world.id}_${activityType.activityTypeId}`">
                                                                                        <td> {{ activityType.activityTypeName }}</td>
                                                                                        <td>
                                                                                            <table style="width: 100%">
                                                                                                <tbody>
                                                                                                    <tr class="row" style="margin:0px; width: 100%; border-top: 1px solid #dee2e6;">
                                                                                                        <td style="border:none; width: 57.84px;" v-for="activity in activityType.activities" :key="`activity_${student.idPerson}_${world.id}_${activityType.activityTypeId}_${activity.id}_2`" align="center">
                                                                                                            <span style="font-weight: bold">{{ activity.activityNumber }}<br></span>
                                                                                                            <span class="badge badge-success" v-if="activity.score.toFixed(0) == 100">{{activity.score.toFixed(0)}}</span>
                                                                                                            <span class="badge badge-warning" v-if="activity.score.toFixed(0) >= 60 && activity.score.toFixed(0) < 100">{{activity.score.toFixed(0)}}</span>
                                                                                                            <span class="badge badge-light" v-if="activity.score.toFixed(0) == 0">{{activity.score.toFixed(0)}}</span>
                                                                                                            <a href="#" title="Restablecer" v-if="activity.score > 0" @click.prevent="resetScore(activity, student)"><i class="fa fa-refresh"></i></a>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <!--<tr :key="'activities_' + student.idPerson + '_' + world.id" :id="'activities_' + student.idPerson + '_' + world.id" style="display: none">
                                                                        <td colspan="3" style="padding-left: 0px">
                                                                            <table style="width: 100%">
                                                                                <thead style="margin:0px; width: 100%">
                                                                                    <tr class="row" style="margin:0px; width: 100%; border-top: 1px solid #dee2e6;">
                                                                                        <th style="border:none;" scope="col" class="col-sm-5">Actividad</th>
                                                                                        <th style="border:none; width: 57.84px; text-align: center" scope="col" v-for="studentActivity in world.activities" :key="'activity_' + studentActivity.id + '_' + student.idPerson">{{studentActivity.activityNumber}}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody style="margin:0px; width: 100%">
                                                                                    <tr class="row" style="margin:0px; width: 100%; border-top: 1px solid #dee2e6;">
                                                                                        <td style="border:none;" class="col-sm-5"></td>
                                                                                        <td style="border:none; width: 57.84px;" v-for="studentActivity in world.activities" :key="'activity_' + studentActivity.id + '_' + student.idPerson" align="center">
                                                                                            <span class="badge badge-success" v-if="studentActivity.score.toFixed(0) == 100">{{studentActivity.score.toFixed(0)}}</span>
                                                                                            <span class="badge badge-warning" v-if="studentActivity.score.toFixed(0) >= 60 && studentActivity.score.toFixed(0) < 100">{{studentActivity.score.toFixed(0)}}</span>
                                                                                            <span class="badge badge-light" v-if="studentActivity.score.toFixed(0) == 0">{{studentActivity.score.toFixed(0)}}</span>
                                                                                            <a href="#" title="Restablecer" v-if="studentActivity.score > 0" @click.prevent="resetScore(studentActivity, student)"><i class="fa fa-refresh"></i></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>-->
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="iRol == 2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-10 mx-auto layout-top-spacing">
                            <div class="widget-content-area shadow br-4">
                                <div class="widget-one">
                                    <div class="row" v-for="parentWorld in students[0].parentWorlds" :key="parentWorld.id">
                                        <div class="col-md-3 mt-1"><img :src="parentWorld.alternate_image" class="rounded-circle img-fluid mx-auto d-block" :title="parentWorld.name">
                                            <p class="text-center">
                                                <small>
                                                    <strong><i class="fa fa-home"></i> {{ parentWorld.name }}</strong>
                                                </small>
                                            </p>
                                        </div>
                                        <div class="col-md-3 mt-3" v-for="subWorld in parentWorld.subworlds" :key="subWorld.id">
                                            <a href="#" @click.prevent="showDetail(subWorld, parentWorld.subworlds)"><img :src="subWorld.alternate_image" class="rounded-circle img-fluid mx-auto d-block" :title="subWorld.name"></a>
                                            <p class="text-center"><small><strong>{{ subWorld.name }}</strong></small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2';
/* eslint-disable */
import AppHeader from "../components/AppHeader";
import AppSidebar from "../components/AppSidebar.vue";
export default {
    name: 'ProgressReport',
    data() {
        return {
            educationLevel: [],
            grades: [],
            groups: [],
            model: {
                level_id: '0',
                group_id: '0',
                grade_id: '0',
            },
            students: [],
            projects: [],
            activityTypes: [],
            activities: false,
            showExportButton: false,
            iRol: '',
        }
    },
    components: {
        AppHeader,
        AppSidebar
    },
    methods: {
        async getReport(iReportLevel) {
            this.openLoading()
            await axios.get(apiETC + 'api/rpt-ethinking/json/reportSmartC3?iReportLevel='+iReportLevel+'&idGroup=' + this.model.group_id, { headers: clientAPI })
            .then( async (response) => {
                this.students = response.data
                await axios.post(apiETC + 'api/smartc3/smartc3-report?grade_id='+ this.model.grade_id +'&school_id=' + localStorage.school_id, {students: this.students}, { headers: clientAPI })
                .then( (response2) => {
                    Swal.close()
                    this.students = response2.data.data
                    this.activityTypes = response2.data.activityTypes
                    this.buildReport(this.students, this.activityTypes)
                    this.showExportButton = true
                } )
                .catch( error => {
                    Swal.close()
                    this.catchError(error)
                })
            })
            .catch( error => {
                Swal.close()
                this.catchError(error)
            })
        },
        async getReportLevel1(student) {
            if($("#worlds" + student.idPerson).is(":visible"))
                $("#worlds" + student.idPerson).css('display', 'none')
            else
                $("#worlds" + student.idPerson).css('display', 'table-row')
        },
        async getReportLevel2(activity) {
            if($("#" + activity).is(":visible"))
                $("#" + activity).css('display', 'none')
            else
                $("#" + activity).css('display', 'table-row')
        },
        async exportExcel() {
            this.openLoading()
            await axios.post(`${apiETC}api/smartc3/report-excel`, {students: this.students}, { headers: clientAPI })
            .then( (response) => {
                Swal.close()
                let a = document.createElement("a")
                a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data.file
                a.download = response.data.name
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        async getStudentReport() {
            this.openLoading()
            this.students = [
                {
                    idActivation: localStorage.idActivation,
                    idPerson: localStorage.idPerson,
                    sName: localStorage.idActivation
                }
            ]
            await axios.post(apiETC + 'api/smartc3/smartc3-report?grade_id='+ localStorage.idGrade +'&school_id=' + localStorage.school_id, {students: this.students}, { headers: clientAPI })
            .then( (response2) => {
                Swal.close()
                this.students = response2.data.data
                let subWorlds = this.students[0].worlds

                this.students[0].parentWorlds.forEach(world => {
                    subWorlds.forEach( subWorld => {
                        if(subWorld.world_id == world.id)
                            world.subworlds.push(subWorld)
                    } )
                })
            } )
            .catch( error => {
                Swal.close()
                this.catchError(error)
            })
        },
        async resetScore(studentActivity, student) {
            Swal.fire({
                title: 'Aviso',
                text: "¿Está seguro de realizar esta acción?.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, realizarla!',
                cancelButtonText: 'Cancelar'
            })
            .then(async (result) => {
                console.log(result)
                if(result.isConfirmed){
                    this.openLoading()
                    await axios.post(apiETC + 'api/smartc3/reset-activity', {data: { student: {idPerson: student.idPerson, idActivation: student.idActivation}, studentActivity }}, { headers: clientAPI })
                    .then( (response) => {
                        Swal.close()
                        this.showMessage('Exito', response.data.message, 'success', '', 1)
                    } )
                    .catch( error => {
                        Swal.close()
                        this.catchError(error)
                    })
                }
            })
        },
        showDetail(subWorld, subWorlds) {
            this.$store.commit('setReportDetail', subWorld)
            this.$store.commit('setSubWorlds', subWorlds)
            router.push('/progress-report-detail')
        },
        buildReport(students, activityTypes) {
            students.forEach(student => {
                student.worlds.forEach( world => {
                    activityTypes.forEach(type => {
                        let filteredActivities = world.activities.filter( element => element.activity_type_id == type.id )
                        if(filteredActivities.length > 0) {
                            world.activityTypes.push({ activityTypeId: type.id, activityTypeName: type.name, activities: filteredActivities })
                        }
                    })
                })
            })
        }
    },
    async mounted() {
        $(document).ready(function () {
            App.init();
        });

        this.iRol = localStorage.iRol

        if(localStorage.iRol == 3) {
            this.openLoading()
            await this.getEducationLevel()
            Swal.close()
        }

        if(localStorage.iRol == 2) {
            await this.getStudentReport()
        }
    },
}
</script>