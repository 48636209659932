/* eslint-disable */
/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
  * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define custom blocks.
 * @author abbychau@gmail.com (Abby Chau)
 */

// More on defining blocks:
// https://developers.google.com/blockly/guides/create-custom-blocks/define-blocks

window.currentTime = 1000;
window.currentTime2 = 1000;
window.currentPositionX = 0;
window.currentPositionY = 0;
window.currentPositionX2 = 0;
window.currentPositionY2 = 0;
window.originalPositionX = 0;
window.originalPositionY = 0;
window.positx = 0;
window.posity = 0;
window.posit2x = 0;
window.posit2y = 0;
window.goalP2C = 0;
window.forVal = 0;
window.cleanStain = false;
window.stainClean = 0;
window.cleanStainA2 = false;
window.stainCleanA2 = 0;
window.Longitud = 0;
window.LongitudV = 0;
window.LongitudH = 0;
window.indexForTc = 4;
window.indexForAs = 1;
window.indexForDs = 1;
window.varLongitud1 = 0;
window.varLados1 = 0;
window.Conectar = 0;
window.TrazarCuadradoGiro_0 = 'derecha';
window.TrazarCuadradoGiro_1 = 'derecha';
window.TrazarCuadradoGiro_2 = 'izquierda';
window.currentGoals = 0;
window.idioma = localStorage.language;



import * as Blockly from 'blockly/core';
import { movePosition, calculateDirection, movePositionWithTurns, calculateDirectionDos, movePositionWithTurnsDos, typeColor} from './blockFunctions';

Blockly.Blocks['left'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/left.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Regresar");
    //this.setPrototipe.addSelect();
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['right'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/right.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Avanzar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['up'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/up.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Arriba');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Subir");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['down'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/down.png', 30, 30, '*');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Bajar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['initial'] = {

  init: function () {
   
    var image = new Blockly.FieldImage('../assets/img/activity/start.png', 60, 30, '*');
    if(window.idioma === 'es'){
      this.appendDummyInput().appendField('Iniciar');
    }if(window.idioma !== 'es'){
      this.appendDummyInput().appendField('Start');
    }    
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setTooltip("Iniciar para anclar bloques");
  }
};

Blockly.JavaScript['initial'] = (block) => {
  currentTime = 300;
  currentPositionX = window.currentPosiitionX;
  currentPositionY = window.currentPosiitionY;
  currentPositionX2 = window.currentPosiition2X;
  currentPositionY2 = window.currentPosiition2Y;
  window.linePositionX = window.drawingInitialPositionX;
  window.linePositionY = window.drawingInitialPositionY;
  window.currentLinePositionX = window.drawingInitialPositionX;
  window.currentLinePositionY = window.drawingInitialPositionY;
  window.checkColors = false;
  var code = '';
  goalP2C = 0;
  window.goalscollected = 0;
  window.goalscollected2 = 0;
  window.stainClean = 0;
  window.bCorrectA1 = false;
  window.bCorrectA2 = false;
  stainClean = 0;
  stainCleanA2 = 0;
  window.totalGoals = 0;
  window.coordenada = '';
  $("#character3").css("transform", 'rotate(0deg)');
  window.totalDegrees = 0;
  window.lastTurn = '';
  window.colorline='';

  if (window.ctxCanvas) {
    window.ctxCanvas.clearRect(0, 0, window.canvas.width, window.canvas.height);
    window.ctxCanvas.drawImage(background, 0, 0);
  }
  if (window.avatarType == 1) {
    if (window.orientacion == "FP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "DP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "IP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "EP")
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;

    if (window.orientacion2 == "FP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "DP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "IP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izqParado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "EP")
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaparado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;


  } else {

    if (window.orientacion == "FP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "DP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "IP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "EP")
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;

    if (window.orientacion2 == "FP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "DP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/derechaParado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "IP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/izqParado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "EP")
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/espaldaparado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;


  }

  //para el avatar de crayolin
  if (window.avatarType == 2) {
    code += `window.blocks[0].select();  $("#character").css("background-image","url('${window.avatarInitialGif}')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
  }
  if (window.avatarType == 3) {
    code += `window.blocks[0].select();  $("#character").css("background-image","url('${window.avatarInitialGif}')"); $("#character3").css("top","${currentPositionY}px"); $("#character3").css("left","${currentPositionX}px");`;
  }

  window.GoalsArray.forEach(element => {
    element.bCorrect = null;
    if (element.iPriority == 1 && element.TypeO == "Objetivo")
      window.totalGoals++;
    $("#" + element.Type).css("display", "block");
  });
  $("#hojas").css("display", "none");
  $("#hojas2").css("display", "none");

  window.newOrientation = window.orientacion;
  window.newOrientation2 = window.orientacion2;
  positx = window.posiitx;
  posity = window.posiity;
  posit2x = window.posiit2x;
  posit2y = window.posiit2y;
  window.Longitud = 0;
  window.LongitudV = 0;
  window.LongitudH = 0;
  /*
    $("#do_active").css("display", "none");
    $("#re_active").css("display", "none");
    $("#mi_active").css("display", "none");
    $("#fa_active").css("display", "none");
    $("#sol_active").css("display", "none");
    $("#la_active").css("display", "none");
    $("#si_active").css("display", "none");
    $("#do2_active").css("display", "none");
  */
  //reseteo de colores
  for (var x = 1; x <= 10; x++) {
    $('#azul_animated' + x).css("display", "none");
    $('#verde_animated' + x).css("display", "none");
    $('#rojo_animated' + x).css("display", "none");
    $('#amarillo_animated' + x).css("display", "none");
    $('#cuadrado_amarillo_animated' + x).css("display", "none");
    $('#cuadrado_azul_animated' + x).css("display", "none");
    $('#cuadrado_rojo_animated' + x).css("display", "none");
    $('#cuadrado_verde_animated' + x).css("display", "none");
    $('#triangulo_amarillo_animated' + x).css("display", "none");
    $('#triangulo_azul_animated' + x).css("display", "none");
    $('#triangulo_rojo_animated' + x).css("display", "none");
    $('#triangulo_verde_animated' + x).css("display", "none");
  }

  return code;
};

Blockly.JavaScript['right'] = function (block) {

  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);

  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    positx++;
    currentBlock = positx + ',' + posity;
    if (positx > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        //codigo de mono impaciente
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 1000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionX += 70
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 1000}); `;
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionX += 70;
      }
    }
  }
  return code;
};

Blockly.JavaScript['left'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(700);
    positx--;
    currentBlock = positx + ',' + posity;
    if (positx < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(700);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 1000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(700);
      if (block.childBlocks_.length == 0) {
        currentPositionX -= 70
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 1000}); `;
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionX -= 70;
      }
    }
  }
  return code;
};

Blockly.JavaScript['down'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);

  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    posity++;
    currentBlock = positx + ',' + posity;
    if (posity > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
      posity = 7;
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 1000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionY += 70
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 1000}); `;
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)

      } else {
        currentPositionY += 70;
      }
    }
  }
  return code;
};

Blockly.JavaScript['up'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    posity--;
    currentBlock = positx + ',' + posity; //armamos la coordenada actual
    if (posity < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        // si la coordenada actual es válida
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 1000});`;
        }
      } else {
        // si la coordenada actual es inválida
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1;
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionY -= 70
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 1000}); `;
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionY -= 70;
      }
    }
  }
  return code;
};

//circo

Blockly.Blocks['puzzlePayasoA'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/payaso_top.png', 200, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Payaso A");
    this.valueOf(1);
  }
};

Blockly.Blocks['puzzlePayasoB'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/payaso_middle.png', 200, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Payaso B");
  }
};

Blockly.Blocks['puzzlePayasoC'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/payaso_bottom.png', 200, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(false);
    this.setTooltip("Puzzle Payaso C");
  }
};

Blockly.Blocks['puzzlePelotaA'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/pelota-sola-A.png', 200, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Pelota A");
    this.valueOf(1);
  }
};

Blockly.Blocks['puzzlePelotaB'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/pelota-sola-B.png', 200, 72, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(false);
    this.setTooltip("Puzzle Pelota B");
  }
};

Blockly.Blocks['puzzleGloboA'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/puzzle-globo-a.png', 200, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Globo A");
    this.valueOf(1);
  }
};

Blockly.Blocks['puzzleGloboB'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/puzzle-globo-b.png', 200, 72, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(false);
    this.setTooltip("Puzzle Globo B");
  }
};

Blockly.Blocks['puzzlePalomasA'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/puzzle-palomitas-a.png', 200, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Palomas A");
    this.valueOf(1);
  }
};

Blockly.Blocks['puzzlePalomasB'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/puzzle-palomitas-b.png', 200, 72, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(false);
    this.setTooltip("Puzzle Palomas B");
  }
};

Blockly.Blocks['puzzleCarpaA'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/puzzle-carpa-a.png', 200, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Carpa A");
    this.valueOf(1);
  }
};

Blockly.Blocks['puzzleCarpaB'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/puzzle-carpa-b.png', 200, 72, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(false);
    this.setTooltip("Puzzle Carpa B");
  }
};

Blockly.Blocks['puzzleCarretaA'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/carreta_circo_01.png', 20, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Carreta A");
    this.valueOf(1);
  }
};

Blockly.Blocks['puzzleCarretaB'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/carreta_circo_02.png', 20, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Carreta B");
  }
};

Blockly.Blocks['puzzleCarretaC'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/carreta_circo_03.png', 20, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Puzzle Carreta C");
    this.valueOf(1);
  }
};

Blockly.Blocks['puzzleCarretaD'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/circo/carreta_circo_04.png', 20, 80, '*');
    this.appendDummyInput('IMG').appendField(image);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(false);
    this.setTooltip("Puzzle Carreta D");
  }
};

Blockly.JavaScript['puzzleCarretaA'] = function (block) {
  var code = `setTimeout(function(){$("#ImgA").css("background-image","url('../assets/img/circo/carreta_circo_01.png')");}, 500);`;
  return code
};

Blockly.JavaScript['puzzleCarretaB'] = function (block) {
  var code = `setTimeout(function(){$("#ImgB").css("background-image","url('../assets/img/circo/carreta_circo_02.png')");}, 500);`;
  return code
};

Blockly.JavaScript['puzzleCarretaC'] = function (block) {
  var code = `setTimeout(function(){$("#ImgC").css("background-image","url('../assets/img/circo/carreta_circo_03.png')");}, 500);`;
  return code
};

Blockly.JavaScript['puzzleCarretaD'] = function (block) {
  var code = `setTimeout(function(){$("#ImgD").css("background-image","url('../assets/img/circo/carreta_circo_04.png')");}, 500);`;
  return code
};

//segundo bloque
Blockly.Blocks['bellota'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Recoger';
    }if(window.idioma !== 'es'){
      traduccion = 'Pick up';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/bellota.png', 30, 30, '*');
    this.setColour(200);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image);
  }
};

Blockly.JavaScript['bellota'] = function (block) {
  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.GoalsArray.length > 0) {
      if (window.GoalsArray.length == 1) {
        var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
        if (positx == window.GoalsArray[0].postx && posity == window.GoalsArray[0].posty) {
          window.goalscollected++;
          setTimeout(() => {
            $("#" + window.GoalsArray[0].Type).css("display", "none");
            window.GoalsArray[0].bCorrect = true;
            Fire.$emit('activityDone')
          }, currentTime)
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime});`;
        }
      } else if (window.GoalsArray.length > 1) {
        window.GoalsArray.forEach(element => {
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            window.goalscollected++;
            setTimeout(() => {
              $("#" + element.Type).css("display", "none");
            }, currentTime)
            element.bCorrect = true;
          } else if (block.childBlocks_.length == 0 && element.iPriority == 1 && element.bCorrect == null) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          } else if (block.childBlocks_.length == 0) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }
        });
      } else {
        console.log("Aqui no hay semilla");
      }
    }
  }
  return code;
};

Blockly.Blocks['sembrar'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Sembrar';
    }if(window.idioma !== 'es'){
      traduccion = 'Plant';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/hojas.png', 30, 30, '*');
    this.setColour(200);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image);
  }
};

Blockly.JavaScript['sembrar'] = function (block) {
  var code = ``;
  var goalC = 0;
  var goalI = 0;
  var goalP2 = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
      window.GoalsArray.forEach(elem => {
        if (elem.iPriority == 1 && elem.bCorrect == true)
          goalC++;
        else if (elem.iPriority == 1 && elem.bCorrect == false)
          goalI++;
        else if (elem.iPriority == 2)
          goalP2++;
      });
      if (goalC > 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
        window.GoalsArray.forEach(element => {
          if (window.goalscollected > 0 && positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 2) {
            window.GoalsArray.forEach(hoja => {
              if (element.postx == hoja.postx && element.posty == hoja.posty && hoja.iPriority == 3 && hoja.bCorrect == null) {
                goalP2C++;
                window.goalscollected--;
                setTimeout(() => {
                  $("#" + hoja.Type).css("display", "block");
                  $("#" + element.Type).css("display", "none");
                  hoja.bCorrect == true;
                }, currentTime)
              }
            });
            element.bCorrect = true;
          } else if (window.goalscollected == 0 && goalP2C != goalP2)
            console.log("All");
          //code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        });
        if (goalP2C == goalP2) {
          Fire.$emit('activityDone')
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 3000}); `;
        }
        else
          console.log("All")
        //code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
      }
    }

  }
  return code;
};

Blockly.Blocks['avanzar'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Avanzar';
    }if(window.idioma !== 'es'){
      traduccion = 'Move';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(208);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Hace avanzar al personaje");
  }
};

Blockly.JavaScript['avanzar'] = function (block) {
  console.log(window.avatarType)
  var code = ``;
  var currentBlock = '';
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  var findRepeat = window.blocks.findIndex(element => element.type == 'repeats');
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `

  if (window.avatarType == 1) {
    if (window.invalid == 0) {
      if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
        window.GoalsArray.forEach(elem => {
          if (elem.iPriority == 1)
            goalT++;
          else if (elem.iPriority == 1 && elem.bCorrect == false)
            goalI++;
          else if (elem.iPriority > 1)
            goalP2++;
        });
      }
      currentTime = parseInt(currentTime) + parseInt(1000);

      if (window.newOrientation != null) {
        if (window.newOrientation == "FP") {
          posity++;
          currentBlock = positx + ',' + posity;
          if (posity > 7) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            currentTime = parseInt(currentTime) + parseInt(1000);
            posity = 7;
          }
          else {
            if (!window.blocksNotAllowed.includes(currentBlock)) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
              if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
                // Fire.$emit('activityDone');
                code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime + 3000});`;
              } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
                console.log("aqui");
                code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            } else {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
              if (window.orientacion == "FP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "DP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "IP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "EP")
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

              window.invalid = 1
            }
            currentPositionY += 70;
          }
        } else if (window.newOrientation == "DP") {
          positx++;
          currentBlock = positx + ',' + posity;
          if (positx > 7) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
            } else if (window.orientacion == "DP") {
              code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
            } else if (window.orientacion == "IP") {
              code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
            } else if (window.orientacion == "EP")
              code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
            currentTime = parseInt(currentTime) + parseInt(1000);
          }
          else {
            if (!window.blocksNotAllowed.includes(currentBlock)) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
              if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
                // Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime + 3000});`;
              } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
              }
            } else {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
              if (window.orientacion == "FP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "DP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "IP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "EP")
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

              window.invalid = 1
            }

          }
        } else if (window.newOrientation == "IP") {
          positx--;
          currentBlock = positx + ',' + posity;
          if (positx < 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            currentTime = parseInt(currentTime) + parseInt(1000);
          }
          else {
            if (!window.blocksNotAllowed.includes(currentBlock)) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
              if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
                // Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime + 3000});`;
              } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
              }
            } else {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
              if (window.orientacion == "FP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "DP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "IP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "EP")
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

              window.invalid = 1
            }
            currentPositionX -= 70;
          }
        } else if (window.newOrientation == "EP") {
          posity--;
          currentBlock = positx + ',' + posity;
          if (posity < 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            currentTime = parseInt(currentTime) + parseInt(1000);
          }
          else {
            if (!window.blocksNotAllowed.includes(currentBlock)) {
              // si la coordenada actual es válida
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
              if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
                // Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime + 3000});`;
              } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
              }
            } else {
              // si la coordenada actual es inválida
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
              if (window.orientacion == "FP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "DP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "IP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "EP")
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

              window.invalid = 1;
            }
            currentPositionY -= 70;
          }
        }
      }
    }
  } else {
    if (window.invalid == 0) {
      if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
        window.GoalsArray.forEach(elem => {
          if (elem.iPriority == 1)
            goalT++;
          else if (elem.iPriority == 1 && elem.bCorrect == false)
            goalI++;
          else if (elem.iPriority > 1)
            goalP2++;
        });
      }
      currentTime = parseInt(currentTime) + parseInt(1000);

      if (window.newOrientation != null) {
        if (window.newOrientation == "FP") {
          posity++;
          currentBlock = positx + ',' + posity;
          if (posity > 7) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;

            currentTime = parseInt(currentTime) + parseInt(1000);
            posity = 7;
          }
          else {
            if (!window.blocksNotAllowed.includes(currentBlock)) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
              if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
                // Fire.$emit('activityDone');
                code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
              } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
                console.log("aqui");
                code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            } else {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
              if (window.orientacion == "FP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "DP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "IP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "EP")
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;

              window.invalid = 1
            }
            currentPositionY += 70;
          }
        } else if (window.newOrientation == "DP") {
          positx++;
          currentBlock = positx + ',' + posity;
          if (positx > 7) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top","${originalPositionY}px"); $("#character").css("left","${originalPositionX}px");`;
            } else if (window.orientacion == "DP") {
              code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top","${originalPositionY}px"); $("#character").css("left","${originalPositionX}px");`;
            } else if (window.orientacion == "IP") {
              code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top","${originalPositionY}px"); $("#character").css("left","${originalPositionX}px");`;
            } else if (window.orientacion == "EP")
              code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top","${originalPositionY}px"); $("#character").css("left","${originalPositionX}px");`;
            currentTime = parseInt(currentTime) + parseInt(1000);
          }
          else {
            if (!window.blocksNotAllowed.includes(currentBlock)) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
              if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
              } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            } else {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
              if (window.orientacion == "FP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "DP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "IP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "EP")
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;

              window.invalid = 1
            }

          }
        } else if (window.newOrientation == "IP") {
          positx--;
          currentBlock = positx + ',' + posity;
          if (positx < 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            currentTime = parseInt(currentTime) + parseInt(1000);
          }
          else {
            if (!window.blocksNotAllowed.includes(currentBlock)) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
              if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
                // Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
              } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            } else {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
              if (window.orientacion == "FP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "DP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "IP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "EP")
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;

              window.invalid = 1
            }
            currentPositionX -= 70;
          }
        } else if (window.newOrientation == "EP") {
          posity--;
          currentBlock = positx + ',' + posity;
          if (posity < 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;

            currentTime = parseInt(currentTime) + parseInt(1000);
          }
          else {
            if (!window.blocksNotAllowed.includes(currentBlock)) {
              // si la coordenada actual es válida
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
              if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
                // Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
              } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            } else {
              // si la coordenada actual es inválida
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
              if (window.orientacion == "FP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "DP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "IP") {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;
              } else if (window.orientacion == "EP")
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.originalPositionY} + "px");  $("#character").css("left", ${window.originalPositionX} + "px"); }, ${currentTime + 3000}); `;

              window.invalid = 1;
            }
            currentPositionY -= 70;
          }
        }
      }
    }
  }
  return code;
};

Blockly.Blocks['girar'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Girar a la';
    }if(window.idioma !== 'es'){
      traduccion = 'Turn to the';
    }
    this.setColour(208);
    this.appendDummyInput().appendField(traduccion).appendField(new Blockly.FieldDropdown(this.DIRECTIONS), 'DIR');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
  }
};

var traduccion = '';
if(window.idioma === 'es'){
  Blockly.Blocks.girar.DIRECTIONS = [["Izquierda " + "↺", "izquierda"], ["Derecha " + "↻", "derecha"]];
}if(window.idioma !== 'es'){
  Blockly.Blocks.girar.DIRECTIONS = [["Left " + "↺", "izquierda"], ["Right " + "↻", "derecha"]];
}


Blockly.JavaScript['girar'] = function (block) {

  var code = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;

  if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
    window.GoalsArray.forEach(elem => {
      if (elem.iPriority == 1)
        goalT++;
      else if (elem.iPriority == 1 && elem.bCorrect == false)
        goalI++;
      else if (elem.iPriority > 1)
        goalP2++;
    });
  }

  console.log(window.avatarType)
  if (window.avatarType == 1) {

    //if (window.newOrientation != null) {
    if (window.newOrientation == "DP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime});`;
        window.newOrientation = "FP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')");}, ${currentTime});`;
        window.newOrientation = "EP";
      }
    } else if (window.newOrientation == "FP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')");}, ${currentTime});`;
        window.newOrientation = "IP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')");}, ${currentTime});`;
        window.newOrientation = "DP";
      }
    } else if (window.newOrientation == "IP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')");}, ${currentTime});`;
        window.newOrientation = "EP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime});`;
        window.newOrientation = "FP";
      }
    } else if (window.newOrientation == "EP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')");}, ${currentTime});`;
        window.newOrientation = "DP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')");}, ${currentTime});`;
        window.newOrientation = "IP";
      }
    }
    //}

    if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
      //Fire.$emit('activityDone');
      //code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime});`;
      if (window.newOrientation == "DP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime});`;
          window.newOrientation = "FP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')");}, ${currentTime});`;
          window.newOrientation = "EP";
        }
      } else if (window.newOrientation == "FP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')");}, ${currentTime});`;
          window.newOrientation = "IP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')");}, ${currentTime});`;
          window.newOrientation = "DP";
        }
      } else if (window.newOrientation == "IP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')");}, ${currentTime});`;
          window.newOrientation = "EP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime});`;
          window.newOrientation = "FP";
        }
      } else if (window.newOrientation == "EP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')");}, ${currentTime});`;
          window.newOrientation = "DP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')");}, ${currentTime});`;
          window.newOrientation = "IP";
        }
      }
    }

  } else {

    //if (window.newOrientation != null) {
    if (window.newOrientation == "DP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
        window.newOrientation = "FP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')");}, ${currentTime});`;
        window.newOrientation = "EP";
      }
    } else if (window.newOrientation == "FP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')");}, ${currentTime});`;
        window.newOrientation = "IP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')");}, ${currentTime});`;
        window.newOrientation = "DP";
      }
    } else if (window.newOrientation == "IP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')");}, ${currentTime});`;
        window.newOrientation = "EP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
        window.newOrientation = "FP";
      }
    } else if (window.newOrientation == "EP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')");}, ${currentTime});`;
        window.newOrientation = "DP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')");}, ${currentTime});`;
        window.newOrientation = "IP";
      }
    }
    //}

    if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
      Fire.$emit('activityDone');
      //code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;

    }

  }



  return code;
};

// tercer bloque
Blockly.Blocks['axion'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    if(window.idioma === 'es'){
      traduccion = 'Hacer (Daniel)';
      traduccion2 = 'Recoger';
      traduccion3 = 'Limpiar';
    }if(window.idioma !== 'es'){
      traduccion = 'Do (Daniel)';
      traduccion2 = 'Pick up';
      traduccion3 = 'Clean';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "recoger"], [traduccion3, "limpiar"]]), "DIX");
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setColour(20);
    this.setTooltip('Seleccion la Acción');
  }
};

Blockly.JavaScript['axion'] = function (block) {
  var code = ``;
  var goalTG = 0;
  var goalTS = 0;
  var goalTSC = 0;
  var cont = 0;
  currentTime = parseInt(currentTime) + parseInt(1000);
  window.GoalsArray.forEach(obj => {
    if (obj.iPriority == 2 && obj.TypeO == "Objetivo" && obj.avatar_Number == 1)
      goalTG++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.avatar_Number == 1)
      goalTS++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.bCorrect == true && obj.avatar_Number == 1)
      goalTSC++;
  });
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;

  if (goalTSC == stainClean)
    cleanStain = true;
  else if (goalTS == 0)
    cleanStain = true;

  if (block.inputList[0].fieldRow[1].value_ == "recoger" && (goalTS == 0 || goalTS == goalTSC) && cleanStain) {
    window.GoalsArray.forEach(gem => {
      cont++;
      if (gem.iPriority == 2 && gem.TypeO == "Objetivo" && gem.postx == positx && gem.posty == posity) {
        window.goalscollected++;
        setTimeout(() => {
          $("#" + gem.Type).css("display", "none");
        }, currentTime)
        gem.bCorrect = true;
      }
    });
  } else if (block.inputList[0].fieldRow[1].value_ == "limpiar" && (window.goalscollected == 0 || goalTG == 0)) {
    window.GoalsArray.forEach(stain => {
      cont++;
      if (stain.iPriority == 1 && stain.TypeO == "Objetivo" && stain.postx == positx && stain.posty == posity) {
        stainClean++;
        setTimeout(() => {
          $("#" + stain.Type).css("display", "none");
        }, currentTime)
        stain.bCorrect = true;
      }
    });

  }

  if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalTG && forVal == 0 && goalTS == 0) {
    console.log("Correcto 1");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
    Fire.$emit('activityDone');
  } else if (window.GoalsArray.length == cont && window.blocks.length - 1 == position && window.goalscollected != goalTG && forVal == 0 && goalTS == 0) {
    console.log("Espera 2");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
  } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected == goalTG && forVal == 0) {
    console.log("Correcto 2");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected == goalTG && goalTS == goalTSC) {
    console.log("Correcto 3");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected != goalTG && goalTS != goalTSC && window.blocks.length - 1 == position) {
    console.log("Espera 3");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
  } else if (goalTS > 0 && window.goalscollected != goalTG && window.blocks.length - 1 == position) {
    console.log("Espera 4");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
  }
  return code;
};

Blockly.Blocks['axion_b'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    if(window.idioma === 'es'){
      traduccion = 'Hacer (Mary)';
      traduccion2 = 'Recoger';
      traduccion3 = 'Limpiar';
    }if(window.idioma !== 'es'){
      traduccion = 'Do (Mary)';
      traduccion2 = 'Pick up';
      traduccion3 = 'Clean';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "recoger"], [traduccion3, "limpiar"]]), "DIX_B");
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setColour(70);
    this.setTooltip('Seleccion la Acción');
  }
};

Blockly.JavaScript['axion_b'] = function (block) {
  var code = ``;
  var goalTG = 0;
  var goalTS = 0;
  var goalTSC = 0;
  var cont = 0;
  currentTime2 = parseInt(currentTime2) + parseInt(1000);
  window.GoalsArray.forEach(obj => {
    if (obj.iPriority == 2 && obj.TypeO == "Objetivo" && obj.avatar_Number == 2)
      goalTG++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.avatar_Number == 2)
      goalTS++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.bCorrect == true && obj.avatar_Number == 2)
      goalTSC++;
  });
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime2}); `;

  if (goalTSC == stainCleanA2)
    cleanStainA2 = true;
  else if (goalTS == 0)
    cleanStainA2 = true;

  if (block.inputList[0].fieldRow[1].value_ == "recoger" && (goalTS == 0 || goalTS == goalTSC) && cleanStainA2) {
    window.GoalsArray.forEach(gem => {
      cont++;
      if (gem.iPriority == 2 && gem.TypeO == "Objetivo" && gem.postx == posit2x && gem.posty == posit2y) {
        window.goalscollected2++;
        setTimeout(() => {
          $("#" + gem.Type).css("display", "none");
        }, currentTime2)
        gem.bCorrect = true;
      }
    });
  } else if (block.inputList[0].fieldRow[1].value_ == "limpiar" && (window.goalscollected2 == 0 || goalTG == 0)) {
    window.GoalsArray.forEach(stain => {
      cont++;
      if (stain.iPriority == 1 && stain.TypeO == "Objetivo" && stain.postx == posit2x && stain.posty == posit2y) {
        stainCleanA2++;
        setTimeout(() => {
          $("#" + stain.Type).css("display", "none");
        }, currentTime2)
        stain.bCorrect = true;
      }
    });

  }

  if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalTG && forVal == 0 && goalTS == 0) {
    console.log("Correcto 1");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')"); }, ${currentTime2}); `;
    Fire.$emit('activityDone');
  } else if (window.GoalsArray.length == cont && window.blocks.length - 1 == position && window.goalscollected2 != goalTG && forVal == 0 && goalTS == 0) {
    console.log("Espera 2");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/espera.gif')");}, ${currentTime2 + 3000});`;
  } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected2 == goalTG && forVal == 0) {
    console.log("Correcto 2");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){$("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')"); }, ${currentTime2 + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected2 == goalTG && goalTS == goalTSC) {
    console.log("Correcto 3");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){$("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')"); }, ${currentTime2 + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected2 != goalTG && goalTS != goalTSC && window.blocks.length - 1 == position) {
    console.log("Espera 3");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/espera.gif')");}, ${currentTime2 + 3000});`;
  } else if (goalTS > 0 && window.goalscollected2 != goalTG && window.blocks.length - 1 == position) {
    console.log("Espera 4");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/espera.gif')");}, ${currentTime2 + 3000});`;
  }
  return code;
};

Blockly.Blocks['direccion'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Avanzar (Daniel)';
    }if(window.idioma !== 'es'){
      traduccion = 'Advance (Daniel)';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([["↑", "d_arriba"], ["↓ ", "d_abajo"], ["← ", "d_izq"], ["→ ", "d_der"]]), "direc");
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setColour(120);
    // this.setTooltip('Elige la dirección a avanzar');
  }
};

Blockly.JavaScript['direccion'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;
  var findRepeat = window.blocks.findIndex(element => element.type == 'repeats');
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;
  if (window.GoalsArray.length > 0) {
    window.GoalsArray.forEach(elem => {
      if (elem.iPriority == 1)
        goalT++;
      else if (elem.iPriority == 1 && elem.bCorrect == false)
        goalI++;
      else if (elem.iPriority > 1)
        goalP2++;
    });
  }
  if (block.inputList[0].fieldRow[1].value_ == "d_abajo") {
    posity++;
    currentBlock = positx + ',' + posity;
    if (posity > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
      posity = 7;
    } else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
        if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0 && blocks.length - 1 == position) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
        } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat < 1) {
          code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      }
      window.invalid = 1
    }
    currentPositionY += 70;
  } else if (block.inputList[0].fieldRow[1].value_ == "d_arriba") {
    posity--;
    currentBlock = positx + ',' + posity;
    if (posity < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        // si la coordenada actual es válida
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
        if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
        } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat < 1) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        // si la coordenada actual es inválida
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1;
      }
      currentPositionY -= 70;
    }
  } else if (block.inputList[0].fieldRow[1].value_ == "d_izq") {
    positx--;
    currentBlock = positx + ',' + posity;
    if (positx < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
        if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
        } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat < 1) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentPositionX -= 70;
    }
  } else if (block.inputList[0].fieldRow[1].value_ == "d_der") {
    positx++;
    currentBlock = positx + ',' + posity;
    if (positx > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
        if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
        } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat < 1) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        if (window.orientacion == "FP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        } else if (window.orientacion == "DP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        } else if (window.orientacion == "IP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        } else if (window.orientacion == "EP")
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

        window.invalid = 1
      }

    }
  }
  return code;
};

Blockly.Blocks['direccion_b'] = {
  init: function () {    
  var traduccion = '';
  if(window.idioma === 'es'){
    traduccion = 'Avanzar  (Mary)';
  }if(window.idioma !== 'es'){
    traduccion = 'Advance (Mary)';
  }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([["↑", "d_arriba"], ["↓ ", "d_abajo"], ["← ", "d_izq"], ["→ ", "d_der"]]), "direc_b");
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setColour(220);
    this.setTooltip('Elige la dirección a avanzar');
  }
};

Blockly.JavaScript['direccion_b'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;
  var findRepeat = window.blocks.findIndex(element => element.type == 'repeats');
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime2 = parseInt(currentTime2) + parseInt(1000);
  var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime2}); `;
  if (window.GoalsArray.length > 0) {
    window.GoalsArray.forEach(elem => {
      if (elem.iPriority == 1)
        goalT++;
      else if (elem.iPriority == 1 && elem.bCorrect == false)
        goalI++;
      else if (elem.iPriority > 1)
        goalP2++;
    });
  }
  if (block.inputList[0].fieldRow[1].value_ == "d_abajo") {
    posit2y++;
    currentBlock = posit2x + ',' + posit2y;
    if (posit2y > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/choquefrente.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/frente.gif')");}, ${currentTime2}); setTimeout(function(){$("#characte2r").animate({"top": "+=70px"}, 500);}, ${currentTime2});`;
      posit2y = 7;
    } else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/frente.gif')");}, ${currentTime2}); setTimeout(function(){$("#character2").animate({"top": "+=70px"}, 500);}, ${currentTime2});`;
        if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0 && blocks.length - 1 == position) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){ $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')");}, ${currentTime2 + 3000});`;
        } else if (position == window.blocks.length - 1 && (posit2x != window.positx_goal || posit2y != window.posity_goal) && findRepeat < 1) {
          code += `setTimeout(function(){$("#character2").css("background-image","url('../assets/img/personaje/pirata/espera.gif')");}, ${currentTime2 + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/choquefrente.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')"); $("#character2").css("top", ${window.currentPosition2Y} + "px");  $("#character2").css("left", ${window.currentPosition2X} + "px"); }, ${currentTime2 + 3000}); `;
      }
      window.invalid = 1
    }
    currentPositionY2 += 70;
  } else if (block.inputList[0].fieldRow[1].value_ == "d_arriba") {
    posit2y--;
    currentBlock = posit2x + ',' + posit2y;
    if (posit2y < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/choqueArriba.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2});`;
      currentTime2 = parseInt(currentTime2) + parseInt(1000);
    } else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        // si la coordenada actual es válida
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/arriba.gif')");}, ${currentTime2}); setTimeout(function(){$("#character2").animate({"top": "-=70px"}, 500);}, ${currentTime2});`;
        if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteParado.gif')");}, ${currentTime2 + 3000});`;
        } else if (position == window.blocks.length - 1 && (posit2x != window.positx_goal || posit2y != window.posity_goal) && findRepeat < 1) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/espera.gif')");}, ${currentTime2 + 3000});`;
        }
      } else {
        // si la coordenada actual es inválida
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/choqueArriba.gif')");}, ${currentTime2});  setTimeout(function(){audioInst.play()}, ${currentTime2});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/espaldaParado.gif')"); $("#character2").css("top", ${window.currentPosition2Y} + "px");  $("#character2").css("left", ${window.currentPosition2X} + "px"); }, ${currentTime2 + 3000}); `;
        window.invalid = 1;
      }
      currentPositionY2 -= 70;
    }
  } else if (block.inputList[0].fieldRow[1].value_ == "d_izq") {
    posit2x--;
    currentBlock = posit2x + ',' + posit2y;
    if (posit2x < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/choqueIzquierda.gif')");}, ${currentTime2};setTimeout(function(){audioInst.play()}, ${currentTime2});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/izquierdaParado.gif')"); $("#character").css("top", ${window.currentPosition2Y} + "px");  $("#character2").css("left", ${window.currentPosition2X} + "px"); }, ${currentTime2 + 3000}); `;
      currentTime2 = parseInt(currentTime2) + parseInt(1000);
    } else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/izquierda.gif')");}, ${currentTime2}); setTimeout(function(){$("#character2").animate({"left": "-=70px"}, 500);}, ${currentTime2});`;
        if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteParado.gif')");}, ${currentTime2 + 3000});`;
        } else if (position == window.blocks.length - 1 && (posit2x != window.positx_goal || posit2y != window.posity_goal) && findRepeat < 1) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/espera.gif')");}, ${currentTime2 + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/choqueIzquierda.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2});  `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/izqquierdaParado.gif')"); $("#character2").css("top", ${window.currentPosition2Y} + "px");  $("#character2").css("left", ${window.currentPosition2X} + "px"); }, ${currentTime2 + 3000}); `;
        window.invalid = 1
      }
      currentPositionX2 -= 70;
    }
  } else if (block.inputList[0].fieldRow[1].value_ == "d_der") {
    posit2x++;
    currentBlock = posit2x + ',' + posit2y;
    if (posit2x > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/choqueDerecha.gif')");}, ${currentTime2});setTimeout(function(){audioInst.play()}, ${currentTime2});`;
      code += `window.blocks[${position}].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/derechaParado.gif')"); $("#character").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
      currentTime2 = parseInt(currentTime2) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/derecha.gif')");}, ${currentTime2}); setTimeout(function(){$("#character2").animate({"left": "+=70px"}, 500);}, ${currentTime2}); `;
        if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteParado.gif')");}, ${currentTime2 + 3000});`;
        } else if (position == window.blocks.length - 1 && (posit2x != window.positx_goal || posit2y != window.posity_goal) && findRepeat < 1) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/espera.gif')");}, ${currentTime2 + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/choqueDerecha.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2}); `;
        if (window.orientacion == "FP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteParado.gif')"); $("#character2").css("top", ${window.currentPosition2Y} + "px");  $("#character2").css("left", ${window.currentPosition2X} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion == "DP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/derechaParado.gif')"); $("#character2").css("top", ${window.currentPosition2Y} + "px");  $("#character2").css("left", ${window.currentPosition2X} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion == "IP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/izquierdaParado.gif')"); $("#character2").css("top", ${window.currentPosition2Y} + "px");  $("#character2").css("left", ${window.currentPosition2X} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion == "EP")
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/pirata/espaldaParado.gif')"); $("#character2").css("top", ${window.currentPosition2Y} + "px");  $("#character2").css("left", ${window.currentPosition2X} + "px"); }, ${currentTime2 + 3000}); `;

        window.invalid = 1
      }
      currentPositionX2 += 70;
    }
  }
  return code;
};
// cuarto bloque
Blockly.Blocks['collectGema'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Recoger';
      traduccion2 = 'gema';
    }if(window.idioma !== 'es'){
      traduccion = 'Pick up';
      traduccion2 = 'gem';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/gema.gif', 30, 30, '*');
    this.setColour(20);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image).appendField(traduccion2);
  }
};

Blockly.JavaScript['collectGema'] = function (block) {
  var code = ``;
  var goalT = ``;
  var cont = 0;
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.GoalsArray.length > 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
      if (window.GoalsArray.length == 1) {
        if (positx == window.GoalsArray[0].postx && posity == window.GoalsArray[0].posty) {
          window.goalscollected++;
          setTimeout(() => {
            $("#" + window.GoalsArray[0].Type).css("display", "none");
            window.GoalsArray[0].bCorrect = true;
            Fire.$emit('activityDone')
          }, currentTime)
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime});`;
        }
      } else if (window.GoalsArray.length > 1) {
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            setTimeout(() => {
              $("#" + element.Type).css("display", "none");
            }, currentTime)
            element.bCorrect = true;
          }/*else if(block.childBlocks_.length == 0 && element.iPriority == 1 && element.bCorrect == true && window.goalscollected == goalT && forVal == 0){
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
            Fire.$emit('activityDone');
          }else if(block.childBlocks_.length == 0 && element.iPriority == 1 && element.bCorrect != null && window.blocks.length - 1 == position && window.goalscollected != goalT ){
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }*/
        });
        if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalT && forVal == 0) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
          Fire.$emit('activityDone');
        } else if (window.GoalsArray.length == cont && window.blocks.length - 1 == position && window.goalscollected != goalT && forVal == 0) {
          console.log("1-");
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected == goalT && forVal == 0) {
          code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 3000}); `;
          Fire.$emit('activityDone');
        }
      } else {
        console.log("Aqui no hay semilla");
      }
    }
  }
  return code;
};

Blockly.Blocks['haygema'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Si Hay';
      traduccion2 = 'Recoger Gema';
    }if(window.idioma !== 'es'){
      traduccion = 'Yes there are';
      traduccion2 = 'Collect Gem';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/gema.gif', 30, 30, '*');
    this.setColour(20);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image).appendField(traduccion2);
  }
};
Blockly.JavaScript['haygema'] = function (block) {
  var code = '';
  var goalT = 0;
  var cont = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });

  if (window.GoalsArray.length > 0) {
    window.GoalsArray.forEach(element => {
      if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
        window.goalscollected++;
        setTimeout(() => {
          $("#" + element.Type).css("display", "none");
        }, currentTime + 1000)
        element.bCorrect = true;
      }
      if (element.bCorrect)
        cont++;
    });

    if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalT && forVal == 0) {
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
      Fire.$emit('activityDone');
    } else if (window.GoalsArray.length == cont && window.blocks.length - 1 == position && window.goalscollected != goalT && forVal == 0) {
      console.log("1-");
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime});`;
    } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected == goalT && forVal == 0) {
      code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 3000}); `;
      Fire.$emit('activityDone');
    }
  }
  return code;
};

Blockly.Blocks['repeats'] = {
  // Repeat n times (internal number).
  init: function () {
    let traduccion = '';
    let traduccion2 = '';
    let traduccion3 = '';
    if(window.idioma === 'es'){
      traduccion = 'Repetir';
      traduccion2 = 'Hacer';
      traduccion3 = 'veces';
    }if(window.idioma !== 'es'){
      traduccion = 'Repeat';
      traduccion2 = 'do';
      traduccion3 = 'times';
    }
    this.setColour(180);
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldNumber('1', Blockly.FieldNumber.nonnegativeIntegerValidator), 'TIMES')
      .appendField(traduccion3);
    this.appendStatementInput('DO').appendField(traduccion2);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
  }
};

Blockly.JavaScript['repeats'] = function (block) {
  var code = '';
  currentTime = parseInt(currentTime);
  var position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  forVal = block.inputList[0].fieldRow[1].value_;
  for (var index = 1; index <= block.inputList[0].fieldRow[1].value_; index++) {
    forVal--;
    code += Blockly.JavaScript.blockToCode(block.childBlocks_[0]);
  }
  return code;
};

Blockly.Blocks['collectSnow'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/nieve.png', 30, 30, '*');
    this.setColour(300);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField('Recoger').appendField(image).appendField('nieve');
  }
};

Blockly.Blocks['pillHole'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/hoyo.png', 30, 30, '*');
    this.setColour(300);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField('Rellenar').appendField(image).appendField('hoyo');
  }
};

Blockly.Blocks.mientrasHacer = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    var traduccion4 = '';
    if(window.idioma === 'es'){
      traduccion = 'haya una pila';
      traduccion2 = 'haya un agujero';
      traduccion3 = 'mientras';
      traduccion4 = 'hacer';
    }if(window.idioma !== 'es'){
      traduccion = 'there is a stack';
      traduccion2 = 'there is a hole';
      traduccion3 = 'while';
      traduccion4 = 'do';
    }
    var a = [[traduccion, "pillHole"], [traduccion2, "collectSnow"]];
    this.setColour(180);
    this.appendValueInput("BOOL").setCheck("Boolean").appendField(traduccion3).appendField(new Blockly.FieldDropdown(a), "MODE");
    this.appendStatementInput("DO").appendField(traduccion4);
    this.setPreviousStatement(!0);
    this.setNextStatement(!0);
  }
};
Blockly.JavaScript['mientrasHacer'] = function (block) {

  var code = ``;
  currentTime = parseInt(currentTime);
  var position = window.blocks.findIndex(element => element.id == block.id);


  if (block.inputList[0].fieldRow[2].value_ == 'pillHole') {
    if (block.childBlocks_[0].previousConnection.sourceBlock_.type == 'collectNieve') {
      code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
      let collectNieve = new Blockly.Block(window.espacioBlockly, 'collectNieve');
      code += Blockly.JavaScript.blockToCode(collectNieve);
      collectNieve.dispose(true);
    }
    else if (block.childBlocks_[0].previousConnection.sourceBlock_.type == 'collectSnow2') {
      code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
      let collectSnow2 = new Blockly.Block(window.espacioBlockly, 'collectSnow2');
      code += Blockly.JavaScript.blockToCode(collectSnow2);
      collectSnow2.dispose(true);
    }
    else if (block.childBlocks_[0].previousConnection.sourceBlock_.type == 'collectSnow3') {
      code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
      let collectSnow3 = new Blockly.Block(window.espacioBlockly, 'collectSnow3');
      code += Blockly.JavaScript.blockToCode(collectSnow3);
      collectSnow3.dispose(true);
    }

  } else if (block.inputList[0].fieldRow[2].value_ == 'collectSnow') {
    if (block.childBlocks_[0].previousConnection.sourceBlock_.type == 'rellenarMonst') {
      code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
      let rellenarMonst = new Blockly.Block(window.espacioBlockly, 'rellenarMonst');
      code += Blockly.JavaScript.blockToCode(rellenarMonst);
      rellenarMonst.dispose(true);
    } else if (block.childBlocks_[0].previousConnection.sourceBlock_.type == 'rellenarMonstXY') {
      code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
      let rellenarMonstXY = new Blockly.Block(window.espacioBlockly, 'rellenarMonstXY');
      code += Blockly.JavaScript.blockToCode(rellenarMonstXY);
      rellenarMonstXY.dispose(true);
    }
    else if (block.childBlocks_[0].previousConnection.sourceBlock_.type == 'rellenarMonstXY2') {

      code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
      let rellenarMonstXY2 = new Blockly.Block(window.espacioBlockly, 'rellenarMonstXY2');
      code += Blockly.JavaScript.blockToCode(rellenarMonstXY2);
      rellenarMonstXY2.dispose(true);

    }
    else if (block.childBlocks_[0].previousConnection.sourceBlock_.type == 'pillHole2') {
      code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
      let pillHole2 = new Blockly.Block(window.espacioBlockly, 'pillHole2');
      code += Blockly.JavaScript.blockToCode(pillHole2);
      pillHole2.dispose(true);
    }
    else if (block.childBlocks_[0].previousConnection.sourceBlock_.type == 'pillHole3') {
      code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
      let pillHole3 = new Blockly.Block(window.espacioBlockly, 'pillHole3');
      code += Blockly.JavaScript.blockToCode(pillHole3);
      pillHole3.dispose(true);
    }

  }


  return code;
};

Blockly.Blocks['trazarCamino'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Trazar camino';
    }if(window.idioma !== 'es'){
      traduccion = 'Trace path';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función trazar camino");
  }
};

Blockly.JavaScript['trazarCamino'] = function (block) {
  let code = '';
  console.log(blocks);
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  //console.log(Blockly.JavaScript.blockToCode(bloque));
  for (let count = 0; count < window.indexForTc; count++) {
    code += Blockly.JavaScript.blockToCode(avanzar);
    code += Blockly.JavaScript.blockToCode(recogerGema);
  }
  avanzar.dispose(true)
  recogerGema.dispose(true)
  // code += Blockly.JavaScript.blockToCode(block.childBlocks_[0]);
  return code;
};

Blockly.Blocks['trazarCuadrado'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Trazar cuadrado';
    }if(window.idioma !== 'es'){
      traduccion = 'Draw square';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función trazar cuadrado");
  }
};

Blockly.JavaScript['trazarCuadrado'] = function (block) {
  let code = '';
  console.log(blocks);
  currentTime = parseInt(currentTime);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girarizq = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');

  for (let count1 = 0; count1 < 4; count1++) {
    for (let count = 0; count < 4; count++) {
      code += Blockly.JavaScript.blockToCode(recogerGema);
      code += Blockly.JavaScript.blockToCode(avanzar);
    }
    code += Blockly.JavaScript.blockToCode(girarizq);
  }
  avanzar.dispose(true)
  recogerGema.dispose(true)
  girarizq.dispose(true)
  return code;
};

Blockly.Blocks['trazarCuadrado2'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Trazar Cuadrado';
    }if(window.idioma !== 'es'){
      traduccion = 'Draw Square';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función trazar cuadrado 2");
  }
};
Blockly.JavaScript['trazarCuadrado2'] = function (block) {
  let code = '';
  //console.log(blocks);
  currentTime = parseInt(currentTime);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girarizq = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');
  let girarDer = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');

  for (let count1 = 0; count1 < window.varLados1; count1++) {
    code += Blockly.JavaScript.blockToCode(recogerGema);
    for (let count = 0; count < window.Longitud; count++) {
      code += Blockly.JavaScript.blockToCode(avanzar);
    }
    if (window.TrazarCuadradoGiro_0 == 'derecha')
      code += Blockly.JavaScript.blockToCode(girarDer);
    if (window.TrazarCuadradoGiro_0 == 'izquierda')
      code += Blockly.JavaScript.blockToCode(girarizq);
  }
  avanzar.dispose(true)
  recogerGema.dispose(true)
  girarizq.dispose(true)
  girarDer.dispose(true)
  return code;
};

Blockly.Blocks['trazarCuadrado3'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Trazar Cuadrado';
    }if(window.idioma !== 'es'){
      traduccion = 'Draw Square';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función trazar cuadrado 2");
  }
};

Blockly.JavaScript['trazarCuadrado3'] = function (block) {
  let code = '';
  //console.log(blocks);
  currentTime = parseInt(currentTime);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girarizq = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');
  let girarDer = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');

  for (let count1 = 0; count1 < window.varLados1; count1++) {

    for (let count = 0; count < window.Longitud; count++) {
      code += Blockly.JavaScript.blockToCode(recogerGema);
      code += Blockly.JavaScript.blockToCode(avanzar);
    }
    if (window.TrazarCuadradoGiro_0 == 'derecha')
      code += Blockly.JavaScript.blockToCode(girarizq);
    if (window.TrazarCuadradoGiro_0 == 'izquierda')
      code += Blockly.JavaScript.blockToCode(girarDer);
  }
  avanzar.dispose(true)
  recogerGema.dispose(true)
  girarizq.dispose(true)
  girarDer.dispose(true)
  return code;
};

Blockly.Blocks['trazarEle'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Trazar Ele';
    }if(window.idioma !== 'es'){
      traduccion = 'Draw he';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función trazar Ele");
  }
};
Blockly.JavaScript['trazarEle'] = function (block) {
  let code = '';
  console.log(blocks);
  currentTime = parseInt(currentTime);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girarDer = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');
  let girarizq = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');
  code += Blockly.JavaScript.blockToCode(girarDer);
  for (let count1 = 0; count1 < 2; count1++) {
    for (let count = 0; count < 3; count++) {
      code += Blockly.JavaScript.blockToCode(recogerGema);
      code += Blockly.JavaScript.blockToCode(avanzar);
    }
    code += Blockly.JavaScript.blockToCode(girarizq);
    code += Blockly.JavaScript.blockToCode(recogerGema);
  }
  avanzar.dispose(true)
  recogerGema.dispose(true)
  girarDer.dispose(true)
  girarizq.dispose(true)
  return code;
};

Blockly.Blocks['encuentraGema'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Encuentra gema';
    }if(window.idioma !== 'es'){
      traduccion = 'Find gem';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función encuentra gema");
  }
};

Blockly.JavaScript['encuentraGema'] = function (block) {
  let code = '';
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  //console.log(Blockly.JavaScript.blockToCode(bloque));
  code += Blockly.JavaScript.blockToCode(avanzar);
  code += Blockly.JavaScript.blockToCode(avanzar);
  code += Blockly.JavaScript.blockToCode(recogerGema);
  // code += Blockly.JavaScript.blockToCode(block.childBlocks_[0]);
  avanzar.dispose(true)
  recogerGema.dispose(true)
  return code;
};

Blockly.Blocks['caminoHorizontal'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Camino horizontal';
    }if(window.idioma !== 'es'){
      traduccion = 'Landscape path';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función camino horizontal");
  }
};

Blockly.JavaScript['caminoHorizontal'] = function (block) {
  let code = '';
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let girarDerecha = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');

  code += Blockly.JavaScript.blockToCode(girarDerecha);
  code += Blockly.JavaScript.blockToCode(avanzar);
  code += Blockly.JavaScript.blockToCode(avanzar);
  code += Blockly.JavaScript.blockToCode(girarDerecha);

  avanzar.dispose(true)
  girarDerecha.dispose(true)

  return code;
};

Blockly.Blocks['caminoVertical'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Camino vertical';
    }if(window.idioma !== 'es'){
      traduccion = 'Vertical path';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función camino vertical");
  }
};

Blockly.JavaScript['caminoVertical'] = function (block) {
  let code = '';
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girar = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');

  code += Blockly.JavaScript.blockToCode(avanzar);
  code += Blockly.JavaScript.blockToCode(avanzar);
  code += Blockly.JavaScript.blockToCode(recogerGema);
  code += Blockly.JavaScript.blockToCode(girar);
  code += Blockly.JavaScript.blockToCode(girar);
  code += Blockly.JavaScript.blockToCode(avanzar);
  code += Blockly.JavaScript.blockToCode(avanzar);

  avanzar.dispose(true)
  recogerGema.dispose(true)
  girar.dispose(true)

  return code;
};

Blockly.Blocks['caminoHorizontalVM'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Camino horizontal';
    }if(window.idioma !== 'es'){
      traduccion = 'Horizontal path';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función camino horizontal");
  }
};

Blockly.JavaScript['caminoHorizontalVM'] = function (block) {
  let code = '';
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let girarDerecha = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');

  for (let a = 0; a < 7; a++) {
    code += Blockly.JavaScript.blockToCode(avanzar);
  }
  code += Blockly.JavaScript.blockToCode(girarDerecha);

  avanzar.dispose(true)
  girarDerecha.dispose(true)

  return code;
};

Blockly.Blocks['caminoHorizontalVM2'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Camino horizontal';
    }if(window.idioma !== 'es'){
      traduccion = 'horizontal path';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función camino horizontal");
  }
};

Blockly.JavaScript['caminoHorizontalVM2'] = function (block) {
  let code = '';

  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let girarDerecha = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');

  code += Blockly.JavaScript.blockToCode(recogerGema);
  for (let a = 0; a < window.varLados1; a++) {
    code += Blockly.JavaScript.blockToCode(avanzar);
    code += Blockly.JavaScript.blockToCode(recogerGema);
  }
  code += Blockly.JavaScript.blockToCode(recogerGema);
  code += Blockly.JavaScript.blockToCode(girarDerecha);


  avanzar.dispose(true)
  girarDerecha.dispose(true)
  recogerGema.dispose(true)
  return code;
};

Blockly.Blocks['caminoVerticalVM2'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Camino vertical';
    }if(window.idioma !== 'es'){
      traduccion = 'Vertical path';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función camino vertical");
  }
};

Blockly.JavaScript['caminoVerticalVM2'] = function (block) {
  let code = '';
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  //const recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girarDerecha = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');

  for (var a = 0; a < window.Longitud; a++) {
    code += Blockly.JavaScript.blockToCode(avanzar);
  }
  // code += Blockly.JavaScript.blockToCode(recogerGema);
  code += Blockly.JavaScript.blockToCode(girarDerecha);

  avanzar.dispose(true)
  girarDerecha.dispose(true)

  return code;
};

Blockly.Blocks['caminoVerticalVM'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Camino vertical';
    }if(window.idioma !== 'es'){
      traduccion = 'Vertical path';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función camino vertical");
  }
};

Blockly.JavaScript['caminoVerticalVM'] = function (block) {
  let code = '';
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');

  for (let a = 0; a < 3; a++) {
    code += Blockly.JavaScript.blockToCode(avanzar);
  }
  code += Blockly.JavaScript.blockToCode(recogerGema);

  avanzar.dispose(true)
  recogerGema.dispose(true)

  return code;
};

Blockly.Blocks['conexionVM2'] = {
  init: function () {
    this.appendDummyInput().appendField('Conexion');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función Conexion");
  }
};

Blockly.JavaScript['conexionVM2'] = function (block) {
  let code = '';

  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let girarDerecha = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');

  code += Blockly.JavaScript.blockToCode(recogerGema);
  for (let a = 0; a < 3; a++) {
    code += Blockly.JavaScript.blockToCode(avanzar);
    code += Blockly.JavaScript.blockToCode(recogerGema);
  }
  //code += Blockly.JavaScript.blockToCode(girarDerecha);

  return code;
};

Blockly.Blocks.say = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/burbuja.jpg', 30, 30, '*');
    var dropdown = new Blockly.FieldDropdown([['serio', 'SERIO'], ['sonriente', 'SONRIENTE'], ['enojado', 'ENOJADO'], ['triste', 'TRISTE']]);
    var textInput = new Blockly.FieldTextInput('Escriba aquí');
    this.appendDummyInput().appendField('Decir').appendField(image).appendField(dropdown, "MODE").appendField(textInput, "TEXT");
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Decir");
  }
};

Blockly.Blocks.sayp = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/burbuja.jpg', 30, 30, '*');
    var dropdown = new Blockly.FieldDropdown([['P1', 'P1'], ['P2', 'P2']]);
    var animo = new Blockly.FieldDropdown([['serio', 'SERIO'], ['sonriente', 'SONRIENTE'], ['enojado', 'ENOJADO'], ['triste', 'TRISTE']]);
    var textInput = new Blockly.FieldTextInput('Escriba aquí');
    this.appendDummyInput().appendField('Decir').appendField(image).appendField(dropdown, "MODE").appendField(animo, "VALUE").appendField(textInput, "TEXT");
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Decir");
  }
};

Blockly.Blocks.avanzarp = {
  init: function () {
    var dir = new Blockly.FieldDropdown([['Arriba', 'up'], ['Abajo', 'down'], ['Izquierda', 'left'], ['Derecha', 'right']]);
    var dropdown = new Blockly.FieldDropdown([['P1', 'P1'], ['P2', 'P2']]);
    this.appendDummyInput().appendField('Avanzar').appendField(dropdown, "MODE").appendField(dir, "DIR");
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Avanzar");
  }
};

Blockly.Blocks.elegirp = {
  init: function () {
    var dropdown = new Blockly.FieldDropdown([['P1', 'P1'], ['P2', 'P2']]);
    this.appendDummyInput().appendField('Elegir').appendField(dropdown, "MODE");
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Elegir");
  }
};

Blockly.Blocks.mostrarp = {
  init: function () {
    var dropdown = new Blockly.FieldDropdown([['P1', 'P1'], ['P2', 'P2']]);
    this.appendDummyInput().appendField('Mostrar').appendField(dropdown, "MODE");
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Mostrar");
  }
};
//Inicio problemas tiene esta pza
Blockly.Blocks.elegirpg = {
  init: function () {
    
    var dropdown = new Blockly.FieldDropdown([['P1', 'P1'], ['P2', 'P2']]);
    this.appendDummyInput().appendField('Elegir').appendField(dropdown, "MODE").appendField('para girar a la').appendField(new Blockly.FieldDropdown(this.DIRECTIONS), 'DIR');
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Elegir para girar a la");
  }
};
//Fin problemas tiene esta pza
Blockly.Blocks.animop = {
  init: function () {
    var animo = new Blockly.FieldDropdown([['serio', 'SERIO'], ['sonriente', 'SONRIENTE'], ['enojado', 'ENOJADO'], ['triste', 'TRISTE']]);
    var dropdown = new Blockly.FieldDropdown([['P1', 'P1'], ['P2', 'P2']]);
    this.appendDummyInput().appendField('Elegir').appendField(dropdown, "MODE").appendField(animo, "TEXT");
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Elegir");
  }
};

Blockly.Blocks.choquep = {
  init: function () {
    var p1 = new Blockly.FieldDropdown([['P1', 'P1'], ['P2', 'P2']]);
    var p2 = new Blockly.FieldDropdown([['P2', 'P2'], ['P1', 'P1']]);
    this.appendDummyInput().appendField('Choque entre').appendField(p1, "MODE").appendField('y').appendField(p2, "TEXT");
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Choque entre y");
  }
};

Blockly.Blocks.terminarh = {
  init: function () {
    this.appendDummyInput().appendField('Terminar historia');
    this.setColour(222);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Terminar historia");
  }
};

Blockly.Blocks['stone'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/piedra.png', 30, 30, '*');
    this.setColour(200);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField('Colocar piedra').appendField(image);
  }
};

Blockly.Blocks['Linea_4_pasos'] = {
  init: function () {
    this.appendDummyInput().appendField('Línea 4 pasos');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función línea 4 pasos");
  }
};

Blockly.Blocks['caminoSalto'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Camino con Salto';
    }if(window.idioma !== 'es'){
      traduccion = 'Walk with Jump';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función Salto");
  }
};

Blockly.JavaScript['caminoSalto'] = function (block) {
  let code = '';
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  //console.log(Blockly.JavaScript.blockToCode(bloque));
  for (let index1 = 0; index1 < 2; index1++) {
    for (let index2 = 0; index2 < 2; index2++) {
      code += Blockly.JavaScript.blockToCode(avanzar);
      code += Blockly.JavaScript.blockToCode(recogerGema);
    }
    if (index1 == 0)
      code += Blockly.JavaScript.blockToCode(avanzar);
  }
  avanzar.dispose(true)
  recogerGema.dispose(true)
  return code;
};

Blockly.Blocks['diagonalAs'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Diagonal Ascendente';
    }if(window.idioma !== 'es'){
      traduccion = 'Ascending Diagonal';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función Diagonal Ascendente");
  }
};

Blockly.JavaScript['diagonalAs'] = function (block) {
  let code = '';
  let indexNum = window.indexForAs;
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girar = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');
  let girarDerecha = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');
  /*if(window.multiple == 5)
    indexNum = 5;
  if(window.multiple == 7)
    indexNum = 3;*/
  for (let index = 0; index < indexNum; index++) {
    code += Blockly.JavaScript.blockToCode(avanzar);
    code += Blockly.JavaScript.blockToCode(girar);
    code += Blockly.JavaScript.blockToCode(avanzar);
    code += Blockly.JavaScript.blockToCode(recogerGema);
    code += Blockly.JavaScript.blockToCode(girarDerecha);
  }
  avanzar.dispose(true)
  girar.dispose(true)
  recogerGema.dispose(true)
  girarDerecha.dispose(true)
  return code;
};

Blockly.Blocks['diagonalDs'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Diagonal Descendente';
    }if(window.idioma !== 'es'){
      traduccion = 'Descending Diagonal';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función Diagonal Ascendente");
  }
};

Blockly.JavaScript['diagonalDs'] = function (block) {
  let code = '';
  let indexNum = window.indexForDs;
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girar = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');
  let girarDerecha = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');

  for (let index = 0; index < indexNum; index++) {
    code += Blockly.JavaScript.blockToCode(avanzar);
    code += Blockly.JavaScript.blockToCode(girarDerecha);
    code += Blockly.JavaScript.blockToCode(avanzar);
    code += Blockly.JavaScript.blockToCode(recogerGema);
    code += Blockly.JavaScript.blockToCode(girar);
  }
  avanzar.dispose(true)
  girar.dispose(true)
  recogerGema.dispose(true)
  girarDerecha.dispose(true)
  return code;
};

Blockly.Blocks['desplazamiento'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Desplazamiento';
    }if(window.idioma !== 'es'){
      traduccion = 'Displacement';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función desplazamiento");
  }
};

Blockly.JavaScript['desplazamiento'] = function (block) {
  let code = '';
  let indexNum = 0;
  //currentTime = parseInt(currentTime) + parseInt(1000);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let caminoHorizontal = new Blockly.Block(window.espacioBlockly, 'caminoHorizontal');
  let caminoVertical = new Blockly.Block(window.espacioBlockly, 'caminoVertical');

  code += Blockly.JavaScript.blockToCode(caminoVertical);
  code += Blockly.JavaScript.blockToCode(caminoHorizontal);
  code += Blockly.JavaScript.blockToCode(caminoVertical);

  caminoHorizontal.dispose(true)
  caminoVertical.dispose(true)
  return code;
};

Blockly.Blocks['Daniel'] = {
  init: function () {
    this.appendDummyInput().appendField('to Daniel');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función Daniel");
  }
};
Blockly.JavaScript['Daniel'] = function (block) {
  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select(); Daniel();}, ${currentTime}); `;
  return code;
};


Blockly.Blocks.procedures = {};
Blockly.Blocks.procedures.HUE = 290;
Blockly.Blocks.procedures_defnoreturn = {
  init: function () {
    this.setHelpUrl(Blockly.Msg.PROCEDURES_DEFNORETURN_HELPURL);
    this.setColour(Blockly.Blocks.procedures.HUE);
    var a = Blockly.Procedures.findLegalName(Blockly.Msg.PROCEDURES_DEFNORETURN_PROCEDURE, this),
      a = new Blockly.FieldTextInput(a, Blockly.Procedures.rename);
    a.setSpellcheck(!1);
    this.appendDummyInput().appendField(Blockly.Msg.PROCEDURES_DEFNORETURN_TITLE).appendField(a, "NAME").appendField("", "PARAMS");
    //Se comenta la linea de abajo para quitar de la funcion el engrane de argumentos de la funcion
    //this.setMutator(new Blockly.Mutator(["procedures_mutatorarg"]));
    this.setTooltip(Blockly.Msg.PROCEDURES_DEFNORETURN_TOOLTIP);
    this.arguments_ = [];
    this.setStatements_(!0);
    this.statementConnection_ = null
  },
  setStatements_: function (a) {
    currentTime = 300;
    currentTime2 = 300;
    currentPositionX = window.currentPositionX;
    currentPositionY = window.currentPositionY;
    currentPositionX2 = window.currentPosition2X;
    currentPositionY2 = window.currentPosition2Y;
    var code = '';
    goalP2C = 0;
    window.goalscollected = 0;
    window.goalscollected2 = 0;
    window.stainClean = 0;
    window.bCorrectA1 = false;
    window.bCorrectA2 = false;
    stainClean = 0;
    stainCleanA2 = 0;
    if (window.orientacion == "FP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "DP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "IP") {
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
    } else if (window.orientacion == "EP")
      code += `window.blocks[0].select();  $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;

    if (window.orientacion2 == "FP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/frenteparado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "DP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/derechaParado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "IP") {
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/izqParado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
    } else if (window.orientacion2 == "EP")
      code += `window.blocks[0].select();  $("#character2").css("background-image","url('../assets/img/personaje/pirata/espaldaparado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;

    window.GoalsArray.forEach(element => {
      element.bCorrect = null;
      $("#" + element.Type).css("display", "block");
    });
    $("#hojas").css("display", "none");
    $("#hojas2").css("display", "none");
    window.newOrientation = window.orientacion;
    positx = window.positx;
    posity = window.posity;
    posit2x = window.posit2x;
    posit2y = window.posit2y;

    this.hasStatements_ !== a && (a ? (this.appendStatementInput("STACK").appendField(Blockly.Msg.PROCEDURES_DEFNORETURN_DO),
      this.getInput("RETURN") && this.moveInputBefore("STACK", "RETURN")) : this.removeInput("STACK", !0), this.hasStatements_ = a)
  },
  updateParams_: function () {
    for (var a = !1, b = {}, c = 0; c < this.arguments_.length; c++) {
      if (b["arg_" + this.arguments_[c].toLowerCase()]) {
        a = !0;
        break
      }
      b["arg_" + this.arguments_[c].toLowerCase()] = !0
    }
    a ? this.setWarningText(Blockly.Msg.PROCEDURES_DEF_DUPLICATE_WARNING) : this.setWarningText(null);
    a = "";
    this.arguments_.length && (a = Blockly.Msg.PROCEDURES_BEFORE_PARAMS + " " + this.arguments_.join(", "));
    this.setFieldValue(a, "PARAMS")
  },
  mutationToDom: function () {
    for (var a = document.createElement("mutation"), b = 0; b < this.arguments_.length; b++) {
      var c = document.createElement("arg");
      c.setAttribute("name", this.arguments_[b]);
      a.appendChild(c)
    }
    this.hasStatements_ ||
      a.setAttribute("statements", "false");
    return a
  },
  domToMutation: function (a) {
    this.arguments_ = [];
    for (var b = 0, c; c = a.childNodes[b]; b++) "arg" == c.nodeName.toLowerCase() && this.arguments_.push(c.getAttribute("name"));
    this.updateParams_();
    this.setStatements_("false" !== a.getAttribute("statements"))
  },
  decompose: function (a) {
    var b = Blockly.Block.obtain(a, "procedures_mutatorcontainer");
    b.initSvg();
    this.getInput("RETURN") ? b.setFieldValue(this.hasStatements_ ? "TRUE" : "FALSE", "STATEMENTS") : b.getInput("STATEMENT_INPUT").setVisible(!1);
    for (var c = b.getInput("STACK").connection, d = 0; d < this.arguments_.length; d++) {
      var e = Blockly.Block.obtain(a, "procedures_mutatorarg");
      e.initSvg();
      e.setFieldValue(this.arguments_[d], "NAME");
      e.oldLocation = d;
      c.connect(e.previousConnection);
      c = e.nextConnection
    }
    Blockly.Procedures.mutateCallers(this.getFieldValue("NAME"), this.workspace, this.arguments_, null);
    return b
  },
  compose: function (a) {
    this.arguments_ = [];
    this.paramIds_ = [];
    for (var b = a.getInputTargetBlock("STACK"); b;) this.arguments_.push(b.getFieldValue("NAME")),
      this.paramIds_.push(b.id), b = b.nextConnection && b.nextConnection.targetBlock();
    this.updateParams_();
    Blockly.Procedures.mutateCallers(this.getFieldValue("NAME"), this.workspace, this.arguments_, this.paramIds_);
    a = a.getFieldValue("STATEMENTS");
    if (null !== a && (a = "TRUE" == a, this.hasStatements_ != a))
      if (a) this.setStatements_(!0), a = this.getInput("STACK").connection, a.targetConnection || !this.statementConnection_ || this.statementConnection_.targetConnection || this.statementConnection_.sourceBlock_.workspace != this.workspace ?
        this.statementConnection_ = null : a.connect(this.statementConnection_);
      else {
        a = this.getInput("STACK").connection;
        if (this.statementConnection_ = a.targetConnection) a = a.targetBlock(), a.setParent(null), a.bumpNeighbours_();
        this.setStatements_(!1)
      }
  },
  dispose: function () {
    var a = this.getFieldValue("NAME");
    Blockly.Procedures.disposeCallers(a, this.workspace);
    this.constructor.prototype.dispose.apply(this, arguments)
  },
  getProcedureDef: function () {
    return [this.getFieldValue("NAME"), this.arguments_, !1]
  },
  getVars: function () {
    return this.arguments_
  },
  renameVar: function (a, b) {
    for (var c = !1, d = 0; d < this.arguments_.length; d++) Blockly.Names.equals(a, this.arguments_[d]) && (this.arguments_[d] = b, c = !0);
    if (c && (this.updateParams_(), this.mutator.isVisible()))
      for (var c = this.mutator.workspace_.getAllBlocks(), d = 0, e; e = c[d]; d++) "procedures_mutatorarg" == e.type && Blockly.Names.equals(a, e.getFieldValue("NAME")) && e.setFieldValue(b, "NAME")
  },
  customContextMenu: function (a) {
    var b = {
      enabled: !0
    },
      c = this.getFieldValue("NAME");
    b.text = Blockly.Msg.PROCEDURES_CREATE_DO.replace("%1",
      c);
    var d = goog.dom.createDom("mutation");
    d.setAttribute("name", c);
    for (var e = 0; e < this.arguments_.length; e++) c = goog.dom.createDom("arg"), c.setAttribute("name", this.arguments_[e]), d.appendChild(c);
    d = goog.dom.createDom("block", null, d);
    d.setAttribute("type", this.callType_);
    b.callback = Blockly.ContextMenu.callbackFactory(this, d);
    a.push(b);
    if (!this.isCollapsed())
      for (e = 0; e < this.arguments_.length; e++) b = {
        enabled: !0
      }, c = this.arguments_[e], b.text = Blockly.Msg.VARIABLES_SET_CREATE_GET.replace("%1", c), d = goog.dom.createDom("field",
        null, c), d.setAttribute("name", "VAR"), d = goog.dom.createDom("block", null, d), d.setAttribute("type", "variables_get"), b.callback = Blockly.ContextMenu.callbackFactory(this, d), a.push(b)
  },
  callType_: "procedures_callnoreturn"
};

Blockly.Blocks['avanzarFuncion'] = {
  init: function () {
    this.appendDummyInput().appendField('Avanzar');
    this.setColour(208);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Hace avanzar al personaje");
  }
};

Blockly.JavaScript['avanzarFuncion'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  var findRepeat = window.blocks.findIndex(element => element.type == 'repeats');
  code = ``
  if (window.invalid == 0) {
    if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
      window.GoalsArray.forEach(elem => {
        if (elem.iPriority == 1)
          goalT++;
        else if (elem.iPriority == 1 && elem.bCorrect == false)
          goalI++;
        else if (elem.iPriority > 1)
          goalP2++;
      });
    }
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.newOrientation != null) {
      if (window.newOrientation == "FP") {
        posity++;
        currentBlock = positx + ',' + posity;
        if (posity > 7) {
          var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
          if (window.orientacion == "FP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "DP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "IP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "EP")
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

          currentTime = parseInt(currentTime) + parseInt(1000);
          posity = 7;
        }
        else {
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
            if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
              Fire.$emit('activityDone');
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
            } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
              console.log("aqui");
              code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
            }
          } else {
            var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            window.invalid = 1
          }
          currentPositionY += 70;
        }
      } else if (window.newOrientation == "DP") {
        positx++;
        currentBlock = positx + ',' + posity;
        if (positx > 7) {
          var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          if (window.orientacion == "FP") {
            code += `  $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
          } else if (window.orientacion == "DP") {
            code += `  $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
          } else if (window.orientacion == "IP") {
            code += `  $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
          } else if (window.orientacion == "EP")
            code += `  $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
          currentTime = parseInt(currentTime) + parseInt(1000);
        }
        else {
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
            if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
              Fire.$emit('activityDone');
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
            } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
            }
          } else {
            var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            window.invalid = 1
          }

        }
      } else if (window.newOrientation == "IP") {
        positx--;
        currentBlock = positx + ',' + posity;
        if (positx < 0) {
          var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
          if (window.orientacion == "FP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "DP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "IP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "EP")
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

          currentTime = parseInt(currentTime) + parseInt(1000);
        }
        else {
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
            if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
              Fire.$emit('activityDone');
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
            } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
            }
          } else {
            var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            window.invalid = 1
          }
          currentPositionX -= 70;
        }
      } else if (window.newOrientation == "EP") {
        posity--;
        currentBlock = positx + ',' + posity;
        if (posity < 0) {
          var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
          if (window.orientacion == "FP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "DP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "IP") {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "EP")
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

          currentTime = parseInt(currentTime) + parseInt(1000);
        }
        else {
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            // si la coordenada actual es válida
            var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
            if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
              Fire.$emit('activityDone');
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime + 3000});`;
            } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
            }
          } else {
            // si la coordenada actual es inválida
            var code = `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            window.invalid = 1;
          }
          currentPositionY -= 70;
        }
      }
    }
  }
  return code;
};

Blockly.Blocks['collectGemaFunction'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/gema.gif', 30, 30, '*');
    this.setColour(20);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField('Recoger').appendField(image).appendField('gema');
  }
};

Blockly.JavaScript['collectGemaFunction'] = function (block) {
  var code = ``;
  var goalT = ``;
  var cont = 0;
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.GoalsArray.length > 0) {
      var code = ``;
      if (window.GoalsArray.length == 1) {
        if (positx == window.GoalsArray[0].postx && posity == window.GoalsArray[0].posty) {
          window.goalscollected++;
          setTimeout(() => {
            $("#" + window.GoalsArray[0].Type).css("display", "none");
            window.GoalsArray[0].bCorrect = true;
            Fire.$emit('activityDone')
            console.log("c1");
          }, currentTime)
          code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
        } else {
          code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime});`;
        }
      } else if (window.GoalsArray.length > 1) {
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            setTimeout(() => {
              $("#" + element.Type).css("display", "none");
            }, currentTime)
            element.bCorrect = true;
          }/*else if(block.childBlocks_.length == 0 && element.iPriority == 1 && element.bCorrect == true && window.goalscollected == goalT && forVal == 0){
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
            Fire.$emit('activityDone');
          }else if(block.childBlocks_.length == 0 && element.iPriority == 1 && element.bCorrect != null && window.blocks.length - 1 == position && window.goalscollected != goalT ){
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }*/
        });

        if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalT && forVal == 0) {
          code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime}); `;
          Fire.$emit('activityDone');
          console.log("c2");
        } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected != goalT && forVal == 0) {
          console.log("1-");
          //code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected == goalT && forVal == 0) {
          code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 3000}); `;
          Fire.$emit('activityDone');
          console.log("c3");
        }
      } else {
        console.log("Aqui no hay semilla");
      }
    }
  }
  return code;
};

Blockly.Blocks.numeric = {
  init: function () {
    this.setColour(208);
    this.appendDummyInput().appendField(new Blockly.FieldTextInput("0", Blockly.FieldTextInput.numberValidator), "NUM");
    this.setOutput(!0, "Number");
    this.setTooltip("Regresa un valor numérico")
  }
};


Blockly.Blocks.controls_repeat_ext = {
  // Repeat n times (internal number).
  init: function () {
    this.setColour(120);
    this.appendValueInput("Variable")
      .setCheck(null)
      .appendField("Repetir")
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE).appendField('veces');
    this.appendStatementInput('DO').appendField('Hacer');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
  }
};


Blockly.Blocks.repeats_ext = {
  // Repeat n times (internal number).
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    
    if(window.idioma === 'es'){
      traduccion = 'Repetir';
      traduccion2 = 'veces';
      traduccion3 = 'Hacer';
    }if(window.idioma !== 'es'){
      traduccion = 'Repeat';
      traduccion2 = 'times';
      traduccion3 = 'Do';
    }
    this.setColour(120);
    this.appendValueInput("Variable")
      .setCheck(null)
      .appendField(traduccion)
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE).appendField(traduccion2);
    this.appendStatementInput('DO').appendField(traduccion3);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
  }
};

Blockly.JavaScript['repeats_ext'] = function (block) {
  console.log(block);
  var code = ``;
  var pos = 0;
  var i = 0;
  var long = 0;
  var cont = 0;
  var blockPosition = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;
  forVal = window.Longitud;
  block.childBlocks_.forEach(childs => {
    if (block.nextConnection.targetConnection != null && childs.type != block.nextConnection.targetConnection.sourceBlock_.type
      && !childs.type.includes("variable"))
      blockPosition = cont;
    if (childs.type.includes("variable"))
      pos = cont;
    if (block.nextConnection.targetConnection == null && !childs.type.includes("variable"))
      blockPosition = cont;

    cont++;
  });

  if (block.childBlocks_.length > 1) {
    if (block.childBlocks_[pos].type == "variable") {
      long = window.Longitud;
    }
    if (block.childBlocks_[pos].type == "variable_H") {
      long = window.LongitudH;
    }
    if (block.childBlocks_[pos].type == "variable_V") {
      long = window.LongitudV;
    }
    if (block.childBlocks_[pos].type == "variable_C") {
      long = window.Conectar;
    }
  }
  do {
    i = i + 1;
    forVal--;
    code += Blockly.JavaScript.blockToCode(block.childBlocks_[blockPosition]);
  } while (i < long);
  return code;
};

Blockly.Blocks['establecer'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Establecer';
    }if(window.idioma !== 'es'){
      traduccion = 'Establish';
    }
    this.appendValueInput("Variable")
      .setCheck(null)
      .appendField(traduccion);
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE)
      .appendField("a");
    this.appendValueInput("Numero")
      .setCheck("Number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(210);
    this.setTooltip('');
    this.setHelpUrl('http://www.example.com/');
  }
};

Blockly.JavaScript['establecer'] = function (block) {
  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;
  if (block.childBlocks_.length > 0) {
    block.childBlocks_.forEach(childs => {
      if (childs.type == "variable") {
        block.childBlocks_.forEach(childsNum => {
          if (childsNum.type == "numeric")
            window.Longitud = childsNum.inputList[0].fieldRow[0].value_;
        });
      }
      if (childs.type == "variable_H") {
        block.childBlocks_.forEach(childsNum => {
          if (childsNum.type == "numeric")
            window.LongitudH = childsNum.inputList[0].fieldRow[0].value_;
        });
      }
      if (childs.type == "variable_V") {
        block.childBlocks_.forEach(childsNum => {
          if (childsNum.type == "numeric")
            window.LongitudV = childsNum.inputList[0].fieldRow[0].value_;
        });
      }
      if (childs.type == "variable_L") {
        block.childBlocks_.forEach(childsNum => {
          if (childsNum.type == "numeric")
            window.varLados1 = childsNum.inputList[0].fieldRow[0].value_;
        });
      }
      if (childs.type == "variable_C") {
        block.childBlocks_.forEach(childsNum => {
          if (childsNum.type == "numeric")
            window.Conectar = childsNum.inputList[0].fieldRow[0].value_;
        });
      }
    });
  }
  return code;
};

Blockly.Blocks['variable'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Longitud';
    }if(window.idioma !== 'es'){
      traduccion = 'Length';
    }
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput(traduccion), "Variable");
    this.setOutput(true, null);
    this.setColour(210);
    this.setTooltip('');
    this.setHelpUrl('http://www.example.com/');
  }
};

Blockly.JavaScript['variable'] = function (block) {
  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;
  return code;
};

Blockly.Blocks['variable_H'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput("Horizontal"), "Variable");
    this.setOutput(true, null);
    this.setColour(210);
    this.setTooltip('');
    this.setHelpUrl('http://www.example.com/');
  }
};

Blockly.JavaScript['variable_H'] = function (block) {
  console.log(block);
  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;
  return code;
};

Blockly.Blocks['variable_V'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput("Vertical"), "Variable");
    this.setOutput(true, null);
    this.setColour(210);
    this.setTooltip('');
    this.setHelpUrl('http://www.example.com/');
  }
};

Blockly.JavaScript['variable_V'] = function (block) {
  console.log(block);
  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;
  return code;
};

Blockly.Blocks['variable_L'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Lados';
    }if(window.idioma !== 'es'){
      traduccion = 'Sides';
    }
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput(traduccion), "Variable");
    this.setOutput(true, null);
    this.setColour(210);
    this.setTooltip('');
    this.setHelpUrl('http://www.example.com/');
  }
};

Blockly.Blocks['variable_C'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Conexión';
    }if(window.idioma !== 'es'){
      traduccion = 'Link';
    }
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput(traduccion), "Variable");
    this.setOutput(true, null);
    this.setColour(210);
    this.setTooltip('');
    this.setHelpUrl('http://www.example.com/');
  }
};

Blockly.Blocks['trazarEle'] = {
  init: function () {
    this.appendDummyInput().appendField('Trazar Ele');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función trazar Ele");
  }
};

Blockly.Blocks['Conectar'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Conector';
    }if(window.idioma !== 'es'){
      traduccion = 'Connector';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Función Conectar");
  }
};

Blockly.JavaScript['Conectar'] = function (block) {
  let code = '';
  console.log(blocks);
  currentTime = parseInt(currentTime);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girarizq = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');
  let girarDer = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');

  for (let count1 = 0; count1 < window.Conectar; count1++) {
    for (let count = 0; count < window.Longitud; count++) {
      code += Blockly.JavaScript.blockToCode(recogerGema);
      code += Blockly.JavaScript.blockToCode(avanzar);
    }
    code += Blockly.JavaScript.blockToCode(girarDer);
    code += Blockly.JavaScript.blockToCode(recogerGema);
  }
  avanzar.dispose(true)
  recogerGema.dispose(true)
  girarizq.dispose(true)
  girarDer.dispose(true)
  return code;
};

Blockly.Blocks['Enlace'] = {
  init: function () {
    this.appendDummyInput().appendField('Enlace');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función Enlace");
  }
};

Blockly.Blocks['Enlace2'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Enlace';
    }if(window.idioma !== 'es'){
      traduccion = 'Linking';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip("Función Enlace");
  }
};

Blockly.JavaScript['Enlace2'] = function (block) {

  let code = '';
  console.log(blocks);
  currentTime = parseInt(currentTime);
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  let avanzar = new Blockly.Block(window.espacioBlockly, 'avanzarFuncion');
  let recogerGema = new Blockly.Block(window.espacioBlockly, 'collectGemaFunction');
  let girarizq = new Blockly.Block(window.espacioBlockly, 'girarFunctionIzquierda');
  let girarDer = new Blockly.Block(window.espacioBlockly, 'girarFunctionDerecha');

  for (let count = 0; count < window.Longitud; count++) {

    code += Blockly.JavaScript.blockToCode(avanzar);
  }
  if (window.TrazarCuadradoGiro_1 == 'derecha')
    code += Blockly.JavaScript.blockToCode(girarDer);
  if (window.TrazarCuadradoGiro_1 == 'izquierda')
    code += Blockly.JavaScript.blockToCode(girarizq);
  code += Blockly.JavaScript.blockToCode(avanzar);
  if (window.TrazarCuadradoGiro_2 == 'derecha')
    code += Blockly.JavaScript.blockToCode(girarDer);
  if (window.TrazarCuadradoGiro_2 == 'izquierda')
    code += Blockly.JavaScript.blockToCode(girarizq);
  avanzar.dispose(true)
  recogerGema.dispose(true)
  girarizq.dispose(true)
  girarDer.dispose(true)
  return code;

};

Blockly.Blocks['girarFunctionIzquierda'] = {
  init: function () {
    this.setColour(208);
    this.appendDummyInput().appendField('Girar a la').appendField(new Blockly.FieldDropdown(this.DIRECTIONS), 'DIR');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
  }
};

Blockly.Blocks.girarFunctionIzquierda.DIRECTIONS = [["Izquierda " + "↺", "izquierda"], ["Derecha " + "↻", "derecha"]];

Blockly.JavaScript['girarFunctionIzquierda'] = function (block) {
  var code = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;

  if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
    window.GoalsArray.forEach(elem => {
      if (elem.iPriority == 1)
        goalT++;
      else if (elem.iPriority == 1 && elem.bCorrect == false)
        goalI++;
      else if (elem.iPriority > 1)
        goalP2++;
    });
  }

  if (window.newOrientation != null) {
    if (window.newOrientation == "DP") {
      code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')");}, ${currentTime});`;
      window.newOrientation = "EP";
    } else if (window.newOrientation == "FP") {
      code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')");}, ${currentTime});`;
      window.newOrientation = "DP";
    } else if (window.newOrientation == "IP") {
      code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
      window.newOrientation = "FP";
    } else if (window.newOrientation == "EP") {
      code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')");}, ${currentTime});`;
      window.newOrientation = "IP";
    }
  }

  if (window.goalscollected == goalT && goalP2 == 0) {
    Fire.$emit('activityDone');
    code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
  }

  return code;
};

Blockly.Blocks['collectNieve'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Recoger';
      traduccion2 = 'nieve';
    }if(window.idioma !== 'es'){
      traduccion = 'Pick up';
      traduccion2 = 'snow';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/nieve.png', 30, 30, '*');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image).appendField(traduccion2);
  }
};

Blockly.JavaScript['collectNieve'] = function (block) {

  var code = ``;
  var tw = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.GoalsArray.length > 0) {

      if (window.GoalsArray.length == 1) {
        var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
        if (positx == window.GoalsArray[0].postx && posity == window.GoalsArray[0].posty) {
          window.goalscollected++;
          setTimeout(() => {
            $("#" + window.GoalsArray[0].Type).css("display", "none");
            window.GoalsArray[0].bCorrect = true;
            Fire.$emit('activityDone');
            tw++;
          }, currentTime);
          code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime}); `;
        } else {
          code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime});`;
        }
      } else if (window.GoalsArray.length > 1) {
        window.GoalsArray.forEach(element => {
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            window.goalscollected++;
            setTimeout(() => {
              $("#" + element.Type).css("display", "none");
            }, currentTime);
            Fire.$emit('activityDone');
            element.bCorrect = true;
          } else if (block.childBlocks_.length == 0 && element.iPriority == 1 && element.bCorrect == null) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
          } else if (block.childBlocks_.length == 0) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
          }
        });
      } else {
        console.log("Aqui no hay Nieve");
      }
    }
  }
  return code;
};


Blockly.Blocks['rellenarMonstXY'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Rellenar';
      traduccion2 = 'Hoyo';
    }if(window.idioma !== 'es'){
      traduccion = 'Fill';
      traduccion2 = 'Hole';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/hoyo.png', 30, 30, '*');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image).appendField(traduccion2);
  }
};
//priority
Blockly.JavaScript['rellenarMonstXY'] = function (block) {
  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.GoalsArray.length > 0) {
      if (window.GoalsArray.length == 1) {
        var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
        if (positx == window.GoalsArray[0].postx && posity == window.GoalsArray[0].posty) {
          window.goalscollected++;
          setTimeout(() => {
            $("#" + window.GoalsArray[0].Type).css("display", "none");
            window.GoalsArray[0].bCorrect = true;
            Fire.$emit('activityDone')
          }, currentTime)
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime});`;
        }
      } else if (window.GoalsArray.length > 1) {
        window.GoalsArray.forEach(element => {
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            window.goalscollected++;
            setTimeout(() => {
              $("#" + element.Type).css("display", "none");
              Fire.$emit('activityDone')
            }, currentTime)
            element.bCorrect = true;
          } else if (block.childBlocks_.length == 0 && element.iPriority == 1 && element.bCorrect == null) {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
          } else if (block.childBlocks_.length == 0) {
            code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
          }
        });
      } else {
        console.log("Aqui no hay Nieve");
      }
    }
  }
  return code;
};



Blockly.Blocks['rellenarMonstXY2'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/hoyo.png', 30, 30, '*');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField('Rellenar').appendField(image).appendField('Hoyo');
  }
};

Blockly.JavaScript['rellenarMonstXY2'] = function (block) {
  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.GoalsArray.length > 0) {
      if (window.GoalsArray.length == 2) {
        var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
        if (positx == window.GoalsArray[0].postx && posity == window.GoalsArray[0].posty) {
          window.goalscollected++;
          setTimeout(() => {
            $("#" + window.GoalsArray[0].Type).css("display", "none");
            window.GoalsArray[0].bCorrect = true;
            Fire.$emit('activityDone')
          }, currentTime)
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime});`;
        }
      } else if (window.GoalsArray.length > 1) {
        window.GoalsArray.forEach(element => {
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            window.goalscollected++;
            setTimeout(() => {
              $("#" + element.Type).css("display", "none");
            }, currentTime)
            element.bCorrect = true;
          } else if (block.childBlocks_.length == 0 && element.iPriority == 1 && element.bCorrect == null) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
          } else if (block.childBlocks_.length == 0) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
          }
        });
      } else {
        console.log("Aqui no hay Nieve");
      }
    }
  }
  return code;
};












Blockly.Blocks['rellenarMonst'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/hoyo.png', 30, 30, '*');
    this.setColour(298);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField('Rellenar').appendField(image).appendField('Hoyo');
  }
};
//priority
Blockly.JavaScript['rellenarMonst'] = function (block) {
  var code = ``;
  var goalC = 0;
  var goalI = 0;
  var goalP2 = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
      window.GoalsArray.forEach(elem => {
        if (elem.iPriority == 1 && elem.bCorrect == true)
          goalC++;
        else if (elem.iPriority == 1 && elem.bCorrect == false)
          goalI++;
        else if (elem.iPriority == 2)
          goalP2++;
      });

      if (goalC > 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
        window.GoalsArray.forEach(element => {
          if (window.goalscollected > 0 && positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 2) {
            window.GoalsArray.forEach(hoja => {
              if (element.postx == hoja.postx && element.posty == hoja.posty && hoja.iPriority == 2 && hoja.bCorrect == null) {
                goalP2C++;
                window.goalscollected--;
                setTimeout(() => {
                  $("#" + hoja.Type).css("display", "block");
                  $("#" + element.Type).css("display", "none");
                  hoja.bCorrect == true;
                }, currentTime)
              }
            });
            element.bCorrect = true;
          } else if (window.goalscollected == 0 && goalP2C != goalP2)
            console.log("All");
          //code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        });
        if (goalP2C == goalP2) {
          Fire.$emit('activityDone')
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime + 3000}); `;
        }
        else
          console.log(goalP2C);
        console.log(goalP2);
        //code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
      }

    }

  }
  return code;
};

Blockly.Blocks['girarFunctionDerecha'] = {
  init: function () {
    this.setColour(208);
    this.appendDummyInput().appendField('Girar a la').appendField(new Blockly.FieldDropdown(this.DIRECTIONS), 'DIR');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
  }
};

Blockly.Blocks.girarFunctionDerecha.DIRECTIONS = [["Izquierda " + "↺", "izquierda"], ["Derecha " + "↻", "derecha"]];

Blockly.JavaScript['girarFunctionDerecha'] = function (block) {
  var code = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;

  if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
    window.GoalsArray.forEach(elem => {
      if (elem.iPriority == 1)
        goalT++;
      else if (elem.iPriority == 1 && elem.bCorrect == false)
        goalI++;
      else if (elem.iPriority > 1)
        goalP2++;
    });
  }

  if (window.newOrientation != null) {
    if (window.newOrientation == "DP") {
      code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
      window.newOrientation = "FP";
    } else if (window.newOrientation == "FP") {
      code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')");}, ${currentTime});`;
      window.newOrientation = "IP";
    } else if (window.newOrientation == "IP") {
      code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')");}, ${currentTime});`;
      window.newOrientation = "EP";
    } else if (window.newOrientation == "EP") {
      code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')");}, ${currentTime});`;
      window.newOrientation = "DP";
    }
  }

  //if(window.goalscollected == goalT && goalP2 == 0){
  //Fire.$emit('activityDone');
  //code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
  //}

  return code;
};

//nieve
Blockly.Blocks['avanzar2'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Avanzar (Luis)';
    }if(window.idioma !== 'es'){
      traduccion = 'Advance (Louis)';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(145);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Hace avanzar al personaje");
  }
};

Blockly.JavaScript['avanzar2'] = function (block) {
  console.log(window.avatarType)
  var code = ``;
  var currentBlock = '';
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  var findRepeat = window.blocks.findIndex(element => element.type == 'repeats');
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `


  if (window.invalid == 0) {
    if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
      window.GoalsArray.forEach(elem => {
        if (elem.iPriority == 1)
          goalT++;
        else if (elem.iPriority == 1 && elem.bCorrect == false)
          goalI++;
        else if (elem.iPriority > 1)
          goalP2++;
      });
    }
    currentTime = parseInt(currentTime) + parseInt(1000);

    if (window.newOrientation != null) {
      if (window.newOrientation == "FP") {
        posity++;
        currentBlock = positx + ',' + posity;
        if (posity > 7) {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
          if (window.orientacion == "FP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "DP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "IP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "EP")
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

          currentTime = parseInt(currentTime) + parseInt(1000);
          posity = 7;
        }
        else {
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
            if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
              Fire.$emit('activityDone');
              code += `setTimeout(function(){ $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime + 3000});`;
            } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
              console.log("aqui");
              code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
            }
          } else {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            window.invalid = 1
          }
          currentPositionY += 70;
        }
      } else if (window.newOrientation == "DP") {
        positx++;
        currentBlock = positx + ',' + posity;
        if (positx > 7) {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          if (window.orientacion == "FP") {
            code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
          } else if (window.orientacion == "DP") {
            code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
          } else if (window.orientacion == "IP") {
            code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
          } else if (window.orientacion == "EP")
            code += `window.blocks[${position}].select();  $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top","${currentPositionY}px"); $("#character").css("left","${currentPositionX}px");`;
          currentTime = parseInt(currentTime) + parseInt(1000);
        }
        else {
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
            if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
              Fire.$emit('activityDone');
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime + 3000});`;
            } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
            }
          } else {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            window.invalid = 1
          }

        }
      } else if (window.newOrientation == "IP") {
        positx--;
        currentBlock = positx + ',' + posity;
        if (positx < 0) {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
          if (window.orientacion == "FP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "DP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "IP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "EP")
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

          currentTime = parseInt(currentTime) + parseInt(1000);
        }
        else {
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
            if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
              Fire.$emit('activityDone');
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime + 3000});`;
            } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
            }
          } else {
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            window.invalid = 1
          }
          currentPositionX -= 70;
        }
      } else if (window.newOrientation == "EP") {
        posity--;
        currentBlock = positx + ',' + posity;
        if (posity < 0) {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
          if (window.orientacion == "FP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "DP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "IP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion == "EP")
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

          currentTime = parseInt(currentTime) + parseInt(1000);
        }
        else {
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            // si la coordenada actual es válida
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
            if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
              Fire.$emit('activityDone');
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime + 3000});`;
            } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
            }
          } else {
            // si la coordenada actual es inválida
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
            if (window.orientacion == "FP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "DP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "IP") {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
            } else if (window.orientacion == "EP")
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

            window.invalid = 1;
          }
          currentPositionY -= 70;
        }
      }
    }
  }

  return code;
};

Blockly.Blocks['collectSnow2'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Recoger';
      traduccion2 = 'nieve (Luis)';
    }if(window.idioma !== 'es'){
      traduccion = 'Pick up';
      traduccion2 = 'snow (Louis)';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/nieve.png', 30, 30, '*');
    this.setColour(240);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image).appendField(traduccion2);
  }
};

Blockly.JavaScript['collectSnow2'] = function (block) {
  var code = ``;
  var goalTG = 0;
  var goalTS = 0;
  var goalTSC = 0;
  var cont = 0;
  currentTime = parseInt(currentTime) + parseInt(1000);
  window.GoalsArray.forEach(obj => {
    if (obj.iPriority == 2 && obj.TypeO == "Objetivo" && obj.avatar_Number == 1)
      goalTG++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.avatar_Number == 1)
      goalTS++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.bCorrect == true && obj.avatar_Number == 1)
      goalTSC++;
  });

  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;

  if (goalTSC == stainClean)
    cleanStain = true;
  else if (goalTS == 0)
    cleanStain = true;

  window.GoalsArray.forEach(gem => {
    cont++;
    if (gem.iPriority == 2 && gem.TypeO == "Objetivo" && gem.postx == positx && gem.posty == posity) {
      window.goalscollected++;
      setTimeout(() => {
        $("#" + gem.Type).css("display", "none");
      }, currentTime)
      gem.bCorrect = true;
    }
  });
  if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalTG && forVal == 0 && goalTS == 0) {
    console.log("Correcto 1");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime}); `;
    Fire.$emit('activityDone');
  } else if (window.GoalsArray.length == cont && window.blocks.length - 1 == position && window.goalscollected != goalTG && forVal == 0 && goalTS == 0) {
    console.log("Espera 2");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
  } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected == goalTG && forVal == 0) {
    console.log("Correcto 2");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected == goalTG && goalTS == goalTSC) {
    console.log("Correcto 3");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected != goalTG && goalTS != goalTSC && window.blocks.length - 1 == position) {
    console.log("Espera 3");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
  } else if (goalTS > 0 && window.goalscollected != goalTG && window.blocks.length - 1 == position) {
    console.log("Espera 4");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
  }
  return code;

};

Blockly.Blocks['pillHole2'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/hoyo.png', 30, 30, '*');
    this.setColour(240);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField('Rellenar').appendField(image).appendField('hoyo  (Luis)');
  }
};

Blockly.JavaScript['pillHole2'] = function (block) {
  var code = ``;
  var goalTG = 0;
  var goalTS = 0;
  var goalTSC = 0;
  var cont = 0;
  currentTime = parseInt(currentTime) + parseInt(1000);
  window.GoalsArray.forEach(obj => {
    if (obj.iPriority == 2 && obj.TypeO == "Objetivo" && obj.avatar_Number == 1)
      goalTG++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.avatar_Number == 1)
      goalTS++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.bCorrect == true && obj.avatar_Number == 1)
      goalTSC++;
  });
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `;

  if (goalTSC == stainClean)
    cleanStain = true;
  else if (goalTS == 0)
    cleanStain = true;

  window.GoalsArray.forEach(stain => {
    cont++;
    if (stain.iPriority == 1 && stain.TypeO == "Objetivo" && stain.postx == positx && stain.posty == posity) {
      stainClean++;
      setTimeout(() => {
        $("#" + stain.Type).css("display", "none");
      }, currentTime)
      stain.bCorrect = true;
    }
  });

  if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalTG && forVal == 0 && goalTS == 0) {
    console.log("Correcto 1");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime}); `;
    Fire.$emit('activityDone');
  } else if (window.GoalsArray.length == cont && window.blocks.length - 1 == position && window.goalscollected != goalTG && forVal == 0 && goalTS == 0) {
    console.log("Espera 2");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
  } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected == goalTG && forVal == 0) {
    console.log("Correcto 2");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected == goalTG && goalTS == goalTSC) {
    console.log("Correcto 3");
    window.bCorrectA1 = true;
    code += `setTimeout(function(){$("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')"); }, ${currentTime + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected != goalTG && goalTS != goalTSC && window.blocks.length - 1 == position) {
    console.log("Espera 3");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
  } else if (goalTS > 0 && window.goalscollected != goalTG && window.blocks.length - 1 == position) {
    console.log("Espera 4");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espera.gif')");}, ${currentTime + 3000});`;
  }
  return code;

};

Blockly.Blocks['avanzar3'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Avanzar (Lucia)';
    }if(window.idioma !== 'es'){
      traduccion = 'Advance (Lucia)';
    }
    this.appendDummyInput().appendField(traduccion);
    this.setColour(208);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    // this.setTooltip("Hace avanzar al personaje");
  }
};

Blockly.JavaScript['avanzar3'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  var findRepeat = window.blocks.findIndex(element => element.type == 'repeats');
  currentTime2 = parseInt(currentTime2) + parseInt(1000);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime2}); `;


  if (window.invalid == 0) {
    if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
      window.GoalsArray.forEach(elem => {
        if (elem.iPriority == 1)
          goalT++;
        else if (elem.iPriority == 1 && elem.bCorrect == false)
          goalI++;
        else if (elem.iPriority > 1)
          goalP2++;
      });
    }
  }

  console.log(window.newOrientation2)

  if (window.newOrientation2 != null) {
    if (window.newOrientation2 == "FP") {

      posit2y++;
      currentBlock = posit2x + ',' + posit2y;
      if (posit2y > 7) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/choquefrente.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2});`;
        if (window.orientacion2 == "FP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "DP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "IP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izqParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "EP")
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;

        currentTime2 = parseInt(currentTime2) + parseInt(1000);
        posit2y = 7;
      }
      else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frente.gif')");}, ${currentTime2}); setTimeout(function(){$("#character2").animate({"top": "+=70px"}, 500);}, ${currentTime2});`;
          if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
            Fire.$emit('activityDone');
            code += `setTimeout(function(){ $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')");}, ${currentTime2 + 3000});`;
          } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posit2y != window.posity_goal) && findRepeat == 0) {
            console.log("aqui");
            code += `setTimeout(function(){$("#character2").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime2 + 3000});`;
          }
        } else {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/choquefrente.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2});`;
          if (window.orientacion2 == "FP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "DP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "IP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izqParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "EP")
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;

          window.invalid = 1
        }
        currentPositionY += 70;
      }

    } else if (window.newOrientation2 == "DP") {

      posit2x++;
      currentBlock = posit2x + ',' + posit2y;
      if (posit2x > 7) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/choquederecha.gif')");}, ${currentTime2});setTimeout(function(){audioInst.play()}, ${currentTime2});`;
        if (window.orientacion2 == "FP") {
          code += `window.blocks[${position}].select();  $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
        } else if (window.orientacion2 == "DP") {
          code += `window.blocks[${position}].select();  $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
        } else if (window.orientacion2 == "IP") {
          code += `window.blocks[${position}].select();  $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izqParado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
        } else if (window.orientacion2 == "EP")
          code += `window.blocks[${position}].select();  $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaparado.gif')"); $("#character2").css("top","${currentPositionY2}px"); $("#character2").css("left","${currentPositionX2}px");`;
        currentTime2 = parseInt(currentTime2) + parseInt(1000);
      }
      else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derecha.gif')");}, ${currentTime2}); setTimeout(function(){$("#character2").animate({"left": "+=70px"}, 500);}, ${currentTime2}); `;
          if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
            Fire.$emit('activityDone');
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')");}, ${currentTime2 + 3000});`;
          } else if (position == window.blocks.length - 1 && (posit2x != window.positx_goal || posit2y != window.posity_goal) && findRepeat == 0) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
          }
        } else {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/choquederecha.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2}); `;
          if (window.orientacion2 == "FP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "DP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "IP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izqParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime + 3000}); `;
          } else if (window.orientacion2 == "EP")
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;

          window.invalid = 1
        }

      }


    } else if (window.newOrientation2 == "IP") {
      posit2x--;
      currentBlock = posit2x + ',' + posit2y;
      if (posit2x < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/choqueizquierda.gif')");}, ${currentTime2};setTimeout(function(){audioInst.play()}, ${currentTime2});`;
        if (window.orientacion2 == "FP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "DP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "IP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izqParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "EP")
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;

        currentTime2 = parseInt(currentTime2) + parseInt(1000);
      } else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izquierda.gif')");}, ${currentTime2}); setTimeout(function(){$("#character2").animate({"left": "-=70px"}, 500);}, ${currentTime2});`;
          if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
            Fire.$emit('activityDone');
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteParado.gif')");}, ${currentTime2 + 3000});`;
          } else if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal) && findRepeat == 0) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
          }
        } else {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve/choqueizquierda.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2});  `;
          if (window.orientacion2 == "FP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "DP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "IP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izquierdaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "EP")
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;

          window.invalid = 1
        }
        currentPositionX2 -= 70;
      }
    } else if (window.newOrientation2 == "EP") {

      posit2y--;
      currentBlock = posit2x + ',' + posit2y;
      if (posit2y < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/choquearriba.gif')");}, ${currentTime2}); setTimeout(function(){audioInst.play()}, ${currentTime2});`;
        if (window.orientacion2 == "FP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "DP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "IP") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izqParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
        } else if (window.orientacion2 == "EP")
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;

        currentTime2 = parseInt(currentTime2) + parseInt(1000);
      }
      else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          // si la coordenada actual es válida
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/arriba.gif')");}, ${currentTime2}); setTimeout(function(){$("#character2").animate({"top": "-=70px"}, 500);}, ${currentTime2});`;
          if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
            Fire.$emit('activityDone');
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')");}, ${currentTime2 + 3000});`;
          } else if (position == window.blocks.length - 1 && (posit2x != window.positx_goal || posit2y != window.posity_goal) && findRepeat == 0) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
          }
        } else {
          // si la coordenada actual es inválida
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/choquearriba.gif')");}, ${currentTime2});  setTimeout(function(){audioInst.play()}, ${currentTime2});`;
          if (window.orientacion2 == "FP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "DP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "IP") {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izqParado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;
          } else if (window.orientacion2 == "EP")
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaparado.gif')"); $("#character2").css("top", ${window.currentPositionY2} + "px");  $("#character2").css("left", ${window.currentPositionX2} + "px"); }, ${currentTime2 + 3000}); `;

          window.invalid = 1;
        }
        currentPositionY2 -= 70;
      }


    }
  }

  return code;
};

Blockly.Blocks['collectSnow3'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Recoger';
      traduccion2 = 'nieve  (Lucia)';
    }if(window.idioma !== 'es'){
      traduccion = 'Pick up';
      traduccion2 = 'snow (Lucia)';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/nieve.png', 30, 30, '*');
    this.setColour(300);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image).appendField(traduccion2);
  }
};

Blockly.JavaScript['collectSnow3'] = function (block) {
  var code = ``;
  var goalTG = 0;
  var goalTS = 0;
  var goalTSC = 0;
  var cont = 0;
  currentTime2 = parseInt(currentTime2) + parseInt(1000);
  window.GoalsArray.forEach(obj => {
    if (obj.iPriority == 2 && obj.TypeO == "Objetivo" && obj.avatar_Number == 2)
      goalTG++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.avatar_Number == 2)
      goalTS++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.bCorrect == true && obj.avatar_Number == 2)
      goalTSC++;
  });
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime2}); `;

  if (goalTSC == stainCleanA2)
    cleanStainA2 = true;
  else if (goalTS == 0)
    cleanStainA2 = true;

  window.GoalsArray.forEach(gem => {
    cont++;
    if (gem.iPriority == 2 && gem.TypeO == "Objetivo" && gem.postx == posit2x && gem.posty == posit2y) {
      window.goalscollected2++;
      setTimeout(() => {
        $("#" + gem.Type).css("display", "none");
      }, currentTime2)
      gem.bCorrect = true;
    }
  });

  if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalTG && forVal == 0 && goalTS == 0) {
    console.log("Correcto 1");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); }, ${currentTime2}); `;
    Fire.$emit('activityDone');
  } else if (window.GoalsArray.length == cont && window.blocks.length - 1 == position && window.goalscollected2 != goalTG && forVal == 0 && goalTS == 0) {
    console.log("Espera 2");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
  } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected2 == goalTG && forVal == 0) {
    console.log("Correcto 2");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){$("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); }, ${currentTime2 + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected2 == goalTG && goalTS == goalTSC) {
    console.log("Correcto 3");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){$("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); }, ${currentTime2 + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected2 != goalTG && goalTS != goalTSC && window.blocks.length - 1 == position) {
    console.log("Espera 3");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
  } else if (goalTS > 0 && window.goalscollected2 != goalTG && window.blocks.length - 1 == position) {
    console.log("Espera 4");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
  }
  return code;
};

Blockly.Blocks['pillHole3'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Rellenar';
      traduccion2 = 'hoyo (Lucia)';
    }if(window.idioma !== 'es'){
      traduccion = 'Fill ';
      traduccion2 = 'hole (Lucia)';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/hoyo.png', 30, 30, '*');
    this.setColour(300);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.appendDummyInput().appendField(traduccion).appendField(image).appendField(traduccion2);
  }
};

Blockly.JavaScript['pillHole3'] = function (block) {
  var code = ``;
  var goalTG = 0;
  var goalTS = 0;
  var goalTSC = 0;
  var cont = 0;
  currentTime2 = parseInt(currentTime2) + parseInt(1000);
  window.GoalsArray.forEach(obj => {
    if (obj.iPriority == 2 && obj.TypeO == "Objetivo" && obj.avatar_Number == 2)
      goalTG++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.avatar_Number == 2)
      goalTS++;
    if (obj.iPriority == 1 && obj.TypeO == "Objetivo" && obj.bCorrect == true && obj.avatar_Number == 2)
      goalTSC++;
  });
  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime2}); `;

  if (goalTSC == stainCleanA2)
    cleanStainA2 = true;
  else if (goalTS == 0)
    cleanStainA2 = true;

  window.GoalsArray.forEach(stain => {
    cont++;
    if (stain.iPriority == 1 && stain.TypeO == "Objetivo" && stain.postx == posit2x && stain.posty == posit2y) {
      stainCleanA2++;
      setTimeout(() => {
        $("#" + stain.Type).css("display", "none");
      }, currentTime2)
      stain.bCorrect = true;
    }
  });
  if (block.childBlocks_.length == 0 && window.blocks.length - 1 == position && window.goalscollected == goalTG && forVal == 0 && goalTS == 0) {
    console.log("Correcto 1");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); }, ${currentTime2}); `;
    Fire.$emit('activityDone');
  } else if (window.GoalsArray.length == cont && window.blocks.length - 1 == position && window.goalscollected2 != goalTG && forVal == 0 && goalTS == 0) {
    console.log("Espera 2");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
  } else if (window.GoalsArray.length == cont && block.nextConnection.targetConnection == null && window.goalscollected2 == goalTG && forVal == 0) {
    console.log("Correcto 2");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){$("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); }, ${currentTime2 + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected2 == goalTG && goalTS == goalTSC) {
    console.log("Correcto 3");
    window.bCorrectA2 = true;
    code += `setTimeout(function(){$("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteparado.gif')"); }, ${currentTime2 + 3000}); `;
    Fire.$emit('activityDone');
  } else if (goalTS > 0 && window.goalscollected2 != goalTG && goalTS != goalTSC && window.blocks.length - 1 == position) {
    console.log("Espera 3");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
  } else if (goalTS > 0 && window.goalscollected2 != goalTG && window.blocks.length - 1 == position) {
    console.log("Espera 4");
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espera.gif')");}, ${currentTime2 + 3000});`;
  }
  return code;
};

Blockly.Blocks['girarLuis'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Girar Luis a la';
    }if(window.idioma !== 'es'){
      traduccion = 'Turn Luis to the';
    }

    this.setColour(255);
    this.appendDummyInput().appendField(traduccion).appendField(new Blockly.FieldDropdown(this.DIRECTIONS), 'DIR');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
  }
};
    if(window.idioma === 'es'){
      Blockly.Blocks.girarLuis.DIRECTIONS = [["Izquierda " + "↺", "izquierda"], ["Derecha " + "↻", "derecha"]];
    }if(window.idioma !== 'es'){
      Blockly.Blocks.girarLuis.DIRECTIONS = [["Left " + "↺", "izquierda"], ["Right " + "↻", "derecha"]];
    }


Blockly.JavaScript['girarLuis'] = function (block) {
  var code = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;

  if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
    window.GoalsArray.forEach(elem => {
      if (elem.iPriority == 1)
        goalT++;
      else if (elem.iPriority == 1 && elem.bCorrect == false)
        goalI++;
      else if (elem.iPriority > 1)
        goalP2++;
    });
  }

  console.log(window.avatarType)
  if (window.avatarType == 1) {

    if (window.newOrientation != null) {
      if (window.newOrientation == "DP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime});`;
          window.newOrientation = "FP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')");}, ${currentTime});`;
          window.newOrientation = "EP";
        }
      } else if (window.newOrientation == "FP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')");}, ${currentTime});`;
          window.newOrientation = "IP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')");}, ${currentTime});`;
          window.newOrientation = "DP";
        }
      } else if (window.newOrientation == "IP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/espaldaparado.gif')");}, ${currentTime});`;
          window.newOrientation = "EP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime});`;
          window.newOrientation = "FP";
        }
      } else if (window.newOrientation == "EP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/derechaParado.gif')");}, ${currentTime});`;
          window.newOrientation = "DP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/izqParado.gif')");}, ${currentTime});`;
          window.newOrientation = "IP";
        }
      }
    }

    if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
      Fire.$emit('activityDone');
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/nieve/frenteparado.gif')");}, ${currentTime});`;
    }

  } else {

    if (window.newOrientation != null) {
      if (window.newOrientation == "DP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
          window.newOrientation = "FP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')");}, ${currentTime});`;
          window.newOrientation = "EP";
        }
      } else if (window.newOrientation == "FP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')");}, ${currentTime});`;
          window.newOrientation = "IP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')");}, ${currentTime});`;
          window.newOrientation = "DP";
        }
      } else if (window.newOrientation == "IP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espaldaparado.gif')");}, ${currentTime});`;
          window.newOrientation = "EP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
          window.newOrientation = "FP";
        }
      } else if (window.newOrientation == "EP") {
        if (block.inputList[0].fieldRow[1].value_ == "derecha") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derechaParado.gif')");}, ${currentTime});`;
          window.newOrientation = "DP";
        } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izqParado.gif')");}, ${currentTime});`;
          window.newOrientation = "IP";
        }
      }
    }

    if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
      Fire.$emit('activityDone');
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");}, ${currentTime});`;
    }

  }



  return code;
};

Blockly.Blocks['girarLucia'] = {
  init: function () {
    var traduccion = '';
    if(window.idioma === 'es'){
      traduccion = 'Girar Lucia a la';
    }if(window.idioma !== 'es'){
      traduccion = 'Turn Lucia to the';
    }
    this.setColour(280);
    this.appendDummyInput().appendField(traduccion).appendField(new Blockly.FieldDropdown(this.DIRECTIONS), 'DIR');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
  }
};

    if(window.idioma === 'es'){
      Blockly.Blocks.girarLucia.DIRECTIONS = [["Izquierda " + "↺", "izquierda"], ["Derecha " + "↻", "derecha"]];
    }if(window.idioma !== 'es'){
      Blockly.Blocks.girarLucia.DIRECTIONS = [["Left " + "↺", "izquierda"], ["Right" + "↻", "derecha"]];
    }


Blockly.JavaScript['girarLucia'] = function (block) {
  var code = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime2 = parseInt(currentTime2) + parseInt(1000);
  var goalT = 0;
  var goalI = 0;
  var goalP2 = 0;

  if (window.GoalsArray.length > 0 && window.goalscollected != 0) {
    window.GoalsArray.forEach(elem => {
      if (elem.iPriority == 1)
        goalT++;
      else if (elem.iPriority == 1 && elem.bCorrect == false)
        goalI++;
      else if (elem.iPriority > 1)
        goalP2++;
    });
  }

  console.log(window.avatarType)


  if (window.newOrientation2 != null) {
    if (window.newOrientation2 == "DP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteParado.gif')");}, ${currentTime2});`;
        window.newOrientation2 = "FP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaParado.gif')");}, ${currentTime2});`;
        window.newOrientation2 = "EP";
      }
    } else if (window.newOrientation2 == "FP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izquierdaParado.gif')");}, ${currentTime2});`;
        window.newOrientation2 = "IP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')");}, ${currentTime2});`;
        window.newOrientation2 = "DP";
      }
    } else if (window.newOrientation2 == "IP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/espaldaParado.gif')");}, ${currentTime2});`;
        window.newOrientation2 = "EP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteParado.gif')");}, ${currentTime2});`;
        window.newOrientation2 = "FP";
      }
    } else if (window.newOrientation2 == "EP") {
      if (block.inputList[0].fieldRow[1].value_ == "derecha") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/derechaParado.gif')");}, ${currentTime2});`;
        window.newOrientation2 = "DP";
      } else if (block.inputList[0].fieldRow[1].value_ == "izquierda") {
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/izquierdaParado.gif')");}, ${currentTime2});`;
        window.newOrientation2 = "IP";
      }
    }
  }

  if (window.goalscollected == goalT && block.childBlocks_.length == 0 && goalP2 == 0) {
    /*Fire.$emit('activityDone');
    code += `setTimeout(function(){window.blocks[${position}].select(); $("#character2").css("background-image","url('../assets/img/personaje/nieve_girl/frenteParado.gif')");}, ${currentTime2});`;*/
  }
  return code;
};

// pzas nuevas actividades 
Blockly.Blocks['leftL'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/left.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Regresar");
    //this.setPrototipe.addSelect();
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['leftL'] = function (block) {
  var code = '';
  var currentBlock = '';

  var position = window.blocks.findIndex(element => element.id == block.id);
  positx++;
  currentBlock = positx + ',' + posity;
  var goalT = ``;
  var cont = 0;
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });
  window.GoalsArray.forEach(element => {
    cont++;
    if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
      window.goalscollected++;
      /*
       setTimeout(() => {
         $("#" + element.Type).css("display", "none");
       }, currentTime)
       */
      element.bCorrect = true;
      window.xyz++;
      window.coordenada = window.coordenada.concat(`${positx},${posity}|`);

    }
  });
  var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
  console.log('generado' + window.coordenada);
  console.log('validacion' + window.blocksYesAllowed);

  return code;
}

Blockly.Blocks['rightL'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/right.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Avanzar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['rightL'] = function (block) {
  var code = '';
  var currentBlock = '';

  var position = window.blocks.findIndex(element => element.id == block.id);
  positx++;
  currentBlock = positx + ',' + posity;
  var goalT = ``;
  var cont = 0;
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });
  window.GoalsArray.forEach(element => {
    cont++;
    if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
      window.goalscollected++;
      /*
       setTimeout(() => {
         $("#" + element.Type).css("display", "none");
       }, currentTime)
       */
      element.bCorrect = true;
      window.xyz++;
      window.coordenada = window.coordenada.concat(`${positx},${posity}|`);

    }
  });
  var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
  console.log('generado' + window.coordenada);
  console.log('validacion' + window.blocksYesAllowed);

  return code;
}

Blockly.Blocks['upL'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/up.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Arriba');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Subir");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['upL'] = function (block) {
  var code = '';
  var currentBlock = '';

  var position = window.blocks.findIndex(element => element.id == block.id);
  posity--;
  currentBlock = positx + ',' + posity;
  var goalT = ``;
  var cont = 0;
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });
  window.GoalsArray.forEach(element => {
    cont++;
    if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
      window.goalscollected++;
      /*
       setTimeout(() => {
         $("#" + element.Type).css("display", "none");
       }, currentTime)
       */
      element.bCorrect = true;
      window.xyz++;
      window.coordenada = window.coordenada.concat(`${positx},${posity}|`);

    }
  });
  var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
  console.log('generado' + window.coordenada);
  console.log('validacion' + window.blocksYesAllowed);

  return code;
}

Blockly.Blocks['downL'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/down.png', 30, 30, '*');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Bajar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['downL'] = function (block) {
  var code = '';
  var currentBlock = '';

  var position = window.blocks.findIndex(element => element.id == block.id);
  posity++;
  currentBlock = positx + ',' + posity;
  var goalT = ``;
  var cont = 0;
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });
  window.GoalsArray.forEach(element => {
    cont++;
    if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
      window.goalscollected++;
      /*
       setTimeout(() => {
         $("#" + element.Type).css("display", "none");
       }, currentTime)
       */
      element.bCorrect = true;
      window.xyz++;
      window.coordenada = window.coordenada.concat(`${positx},${posity}|`);


    }
  });
  var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
  console.log('generado' + window.coordenada);
  console.log('validacion' + window.blocksYesAllowed);

  return code;
}

Blockly.Blocks['trazo'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/lapiz.png', 30, 30, '*');
    this.appendDummyInput().appendField('Trazo');
    this.setColour(310);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Trazo");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['direccionL'] = {
  init: function () {
    this.setColour(310);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    var input = this.appendDummyInput()
      .appendField('Trazo');
    var options = [
      [{ 'src': '../assets/img/activity/leftM.png', 'width': 30, 'height': 25, 'alt': 'left' }, 'left'],
      [{ 'src': '../assets/img/activity/rightM.png', 'width': 30, 'height': 25, 'alt': 'right' }, 'right'],
      [{ 'src': '../assets/img/activity/upM.png', 'width': 30, 'height': 25, 'alt': 'up' }, 'up'],
      [{ 'src': '../assets/img/activity/downM.png', 'width': 30, 'height': 25, 'alt': 'down' }, 'down']
    ];
    input.appendField(new Blockly.FieldDropdown(options), 'Trazo');
  }
};

Blockly.Blocks['direccionL2'] = {
  init: function () {
    this.setColour(310);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    var options = [
      [{ 'src': '../assets/img/activity/leftM.png', 'width': 30, 'height': 25, 'alt': 'left' }, 'left'],
      [{ 'src': '../assets/img/activity/rightM.png', 'width': 30, 'height': 25, 'alt': 'right' }, 'right'],
      [{ 'src': '../assets/img/activity/upM.png', 'width': 30, 'height': 25, 'alt': 'up' }, 'up'],
      [{ 'src': '../assets/img/activity/downM.png', 'width': 30, 'height': 25, 'alt': 'down' }, 'down']
    ];
    var input = this.appendDummyInput()
      .appendField("")
      .appendField(new Blockly.FieldImage("../assets/img/activity/lapiz.png", 40, 40, "*"));
    input.appendField(new Blockly.FieldDropdown(options), 'Trazo');
  }
};


Blockly.Blocks['figura'] = {
  init: function () {
    this.setColour(310);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    var input = this.appendDummyInput()
      .appendField('Si hay');
    var options = [
      [{ 'src': '../assets/img/activity/amarillo.png', 'width': 30, 'height': 25, 'alt': 'amarillo' }, 'amarillo'],
      [{ 'src': '../assets/img/activity/verde.png', 'width': 30, 'height': 35, 'alt': 'verde' }, 'verde'],
      [{ 'src': '../assets/img/activity/azul.png', 'width': 30, 'height': 35, 'alt': 'azul' }, 'azul'],
      [{ 'src': '../assets/img/activity/rojo.png', 'width': 30, 'height': 35, 'alt': 'rojo' }, 'rojo']
    ];
    var input2 = this.appendDummyInput()
      .appendField('Saltar');
    input.appendField(new Blockly.FieldDropdown(options), 'Trazo');
  }
};

Blockly.Blocks['figura2'] = {
  init: function () {
    this.setColour(310);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    var input = this.appendDummyInput()
      .appendField('Si hay');
    var options = [
      [{ 'src': '../assets/img/activity/amarillo.png', 'width': 30, 'height': 25, 'alt': 'amarillo' }, 'amarillo'],
      [{ 'src': '../assets/img/activity/verde.png', 'width': 30, 'height': 35, 'alt': 'verde' }, 'verde'],
      [{ 'src': '../assets/img/activity/azul.png', 'width': 30, 'height': 35, 'alt': 'azul' }, 'azul'],
      [{ 'src': '../assets/img/activity/rojo.png', 'width': 30, 'height': 35, 'alt': 'rojo' }, 'rojo']
    ];
    input.appendField(new Blockly.FieldDropdown(options), 'color');
    var options2 = [
      [{ 'src': '../assets/img/activity/triangulo.png', 'width': 30, 'height': 25, 'alt': 'triangulo' }, 'triangulo'],
      [{ 'src': '../assets/img/activity/cuadrado.png', 'width': 30, 'height': 25, 'alt': 'cuadrado' }, 'cuadrado'],
      [{ 'src': '../assets/img/activity/circulo.png', 'width': 30, 'height': 25, 'alt': 'circulo' }, 'circulo'],
    ];
    var input2 = this.appendDummyInput()
      .appendField('Saltar');
    input.appendField(new Blockly.FieldDropdown(options2), 'figura');
  }
};

Blockly.Blocks['saltar'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/saltar.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Arriba');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Saltar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['saltar2'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/saltar.png', 30, 30, '*');
    this.appendDummyInput().appendField('Saltar');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Saltar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['saltar3'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/upsaltar.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Arriba');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Saltar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['emojis'] = {
  init: function () {
    this.setColour(310);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    var input = this.appendDummyInput()
      .appendField('Si hay');
    var options = [
      [{ 'src': '../assets/img/activity/enojonr.png', 'width': 30, 'height': 25, 'alt': 'enojonr' }, 'enojonr'],
      [{ 'src': '../assets/img/activity/enojonsorp.png', 'width': 30, 'height': 25, 'alt': 'enojonsorp' }, 'enojonsorp'],
      [{ 'src': '../assets/img/activity/enojonaa.png', 'width': 30, 'height': 25, 'alt': 'enojonaa' }, 'enojonaa'],
      [{ 'src': '../assets/img/activity/sonreir.png', 'width': 30, 'height': 25, 'alt': 'sonreir' }, 'sonreir']
    ];
    this.appendDummyInput().appendField('Saltar');
    input.appendField(new Blockly.FieldDropdown(options), 'Emojis');
  }
};

Blockly.Blocks['emojis3'] = {
  init: function () {
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setColour(310);
    var options = [
      ['Reproducir Risa', 'Risa'],
      ['Reproducir LLanto', 'LLanto']
    ];
    this.appendDummyInput()
      .appendField(new Blockly.FieldDropdown(options), 'MODE');
  }
};

Blockly.Blocks['son_pausa'] = {
  init: function () {
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setColour(360);
    var options = [
      ['Sound play wave Siren1', 'Siren1'],
      ['Sound play wave Siren2', 'Siren2']
    ];
    this.appendDummyInput()
      .appendField(new Blockly.FieldDropdown(options), 'MODE');
  }
};

Blockly.Blocks['sec_nota'] = {
  init: function () {
    this.appendValueInput("Variable")
      .setCheck(null).appendField("Sound play melody");
    this.setColour(360);
    this.appendDummyInput().setAlign(Blockly.ALIGN_CENTRE);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip('Secuencia de notas');
  }
};

Blockly.Blocks['nota_dura'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("play note")
      .appendField(new Blockly.FieldDropdown([["silence", "silence"], ["silence2", "silence2"], ["silence3", "silence3"]]), "note")
      .appendField("on octave")
      .appendField(new Blockly.FieldDropdown([["1", "1"], ["2", "2"], ["3", "3"]]), "oct")
      .appendField("for")
      .appendField(new Blockly.FieldTextInput("0.5"), "val")
      .appendField(new Blockly.FieldDropdown([["secs", "secs"], ["secs1", "secs1"], ["secs2", "secs2"]]), "sec");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(360);
    this.setTooltip("Define la nota y la duración");

  }
};

/// primeras  

Blockly.Blocks['left2'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/left.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Regresar");
    //this.setPrototipe.addSelect();
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['right2'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/right.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Avanzar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['up2'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/up.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Arriba');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Subir");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.Blocks['down2'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/down.png', 30, 30, '*');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Bajar");
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['right2'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    positx++;
    currentBlock = positx + ',' + posity;
    if (positx > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var goalT = ``;
        var cont = 0;
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        if (window.invalid == 0) {
          //currentTime = parseInt(currentTime) + parseInt(1000);
          if (window.GoalsArray.length > 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
            window.newOrientation = "DP";
            if (window.GoalsArray.length > 1) {
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  /*
                   setTimeout(() => {
                     $("#" + element.Type).css("display", "none");
                   }, currentTime)
                   */
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            }
          }
        }
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionX += 70
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionX += 70;
      }
    }
  }
  console.log(currentBlock)
  return code;
};

Blockly.JavaScript['left2'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    positx--;
    currentBlock = positx + ',' + posity;
    if (positx < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
        window.newOrientation = "IP";
        var goalT = ``;
        var cont = 0;
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        if (window.invalid == 0) {
          //currentTime = parseInt(currentTime) + parseInt(1000);
          if (window.GoalsArray.length > 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            if (window.GoalsArray.length > 1) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
              window.newOrientation = "IP";
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  /*
                   setTimeout(() => {
                     $("#" + element.Type).css("display", "none");
                   }, currentTime)
                   */
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            }
          }
        }
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionX -= 70
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionX -= 70;
      }
    }
  }
  console.log(currentBlock)
  return code;
};

Blockly.JavaScript['down2'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    posity++;
    currentBlock = positx + ',' + posity;
    if (posity > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
      posity = 7;
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var goalT = ``;
        var cont = 0;
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        if (window.invalid == 0) {
          //currentTime = parseInt(currentTime) + parseInt(1000);
          if (window.GoalsArray.length > 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            if (window.GoalsArray.length > 1) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
              window.newOrientation = "FP";
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  /*
                   setTimeout(() => {
                     $("#" + element.Type).css("display", "none");
                   }, currentTime)
                   */
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            }
          }
        }
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionY += 70
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionY += 70;
      }
    }
  }
  console.log(currentBlock)
  return code;
};

Blockly.JavaScript['up2'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    posity--;
    currentBlock = positx + ',' + posity; //armamos la coordenada actual
    if (posity < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var goalT = ``;
        var cont = 0;
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        if (window.invalid == 0) {
          //currentTime = parseInt(currentTime) + parseInt(1000);
          if (window.GoalsArray.length > 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            if (window.GoalsArray.length > 1) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
              window.newOrientation = "EP";
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  /*
                   setTimeout(() => {
                     $("#" + element.Type).css("display", "none");
                   }, currentTime)
                   */
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            }
          }
        }
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        // si la coordenada actual es inválida
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1;
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionY -= 70
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionY -= 70;
      }
    }
  }
  console.log(currentBlock)
  return code;
};

Blockly.Blocks['noroeste'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flecha_noroeste.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Noroeste");
    //this.setPrototipe.addSelect();
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['noroeste'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    positx--;
    posity--;
    currentBlock = positx + ',' + posity;
    if (positx < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else if (posity < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        //codigo de mono impaciente
        //temp
        var goalT = ``;
        var cont = 0;
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        if (window.invalid == 0) {
          //currentTime = parseInt(currentTime) + parseInt(1000);
          if (window.GoalsArray.length > 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            if (window.GoalsArray.length > 1) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=-70px","top": "+=-70px" }, 500);}, ${currentTime}); `;
              window.newOrientation = "NO";
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  /*
                   setTimeout(() => {
                     $("#" + element.Type).css("display", "none");
                   }, currentTime)
                   */
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            }
          }
        }
        //fin temp
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        positx++;
        posity++;
        currentBlock = positx + ',' + posity;
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionX += 70
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionX += 70;
      }
    }
  }
  console.log(currentBlock);
  return code;
};

Blockly.Blocks['sureste'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flecha_sureste.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Sureste");
    //this.setPrototipe.addSelect();
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['sureste'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    positx++;
    posity++;
    currentBlock = positx + ',' + posity;
    if (posity > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else if (positx > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var goalT = ``;
        var cont = 0;
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        if (window.invalid == 0) {
          //currentTime = parseInt(currentTime) + parseInt(1000);
          if (window.GoalsArray.length > 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            if (window.GoalsArray.length > 1) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px","left": "+=+70px" }, 500);}, ${currentTime}); `;
              window.newOrientation = "SE";
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  /*
                   setTimeout(() => {
                     $("#" + element.Type).css("display", "none");
                   }, currentTime)
                   */
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            }
          }
        }
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        positx--;
        posity--;
        currentBlock = positx + ',' + posity;

        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionX += 70
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionX += 70;
      }
    }
  }
  console.log(currentBlock);
  return code;
};

Blockly.Blocks['suroeste'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flecha_suroeste.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Suroeste");
    //this.setPrototipe.addSelect();
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['suroeste'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    positx--;
    posity++
    currentBlock = positx + ',' + posity;
    if (positx < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else if (posity > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        //codigo de mono impaciente
        //temp
        var goalT = ``;
        var cont = 0;
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        if (window.invalid == 0) {
          //currentTime = parseInt(currentTime) + parseInt(1000);
          if (window.GoalsArray.length > 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            if (window.GoalsArray.length > 1) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px","left": "+=-70px" }, 500);}, ${currentTime}); `;
              window.newOrientation = "SO";
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  /*
                   setTimeout(() => {
                     $("#" + element.Type).css("display", "none");
                   }, currentTime)
                   */
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }

            }
          }
        }
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        positx++;
        posity--;
        currentBlock = positx + ',' + posity;
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionX += 70
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionX += 70;
      }
    }
  }
  console.log(currentBlock);
  return code;
};

Blockly.Blocks['noreste'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flecha_noreste.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Noreste");
    //this.setPrototipe.addSelect();
  },
  onchange: function () {
    if (!this.workspace) {
      // Block has been deleted.
      return;
    }
  }
};

Blockly.JavaScript['noreste'] = function (block) {

  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  if (window.invalid == 0) {
    //currentTime = parseInt(currentTime) + parseInt(1000);
    positx++;
    posity--;
    currentBlock = positx + ',' + posity;

    if (positx > 7) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    if (posity < 0) {
      var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    else {
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        var goalT = ``;
        var cont = 0;
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        if (window.invalid == 0) {
          //currentTime = parseInt(currentTime) + parseInt(1000);
          if (window.GoalsArray.length > 0) {
            var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            if (window.GoalsArray.length > 1) {
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=-70px","left": "+=+70px" }, 500);}, ${currentTime}); `;
              window.newOrientation = "NE";
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  /*
                   setTimeout(() => {
                     $("#" + element.Type).css("display", "none");
                   }, currentTime)
                   */
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
              }
            }
          }
        }
        if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else {
        positx--;
        posity++;
        currentBlock = positx + ',' + posity;
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (block.childBlocks_.length == 0) {
        currentPositionX += 70
        setTimeout(() => {
          if (positx == window.positx_goal && posity == window.posity_goal) {
            Fire.$emit('activityDone')
            //$("#AppModal").modal("show");
            //$("#gem").css("display", "none");
          }
        }, currentTime)
      } else {
        currentPositionX += 70;
      }
    }
  }
  console.log(currentBlock);
  return code;
};

Blockly.JavaScript['saltar'] = function (block) {

  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);

  if (window.invalid == 0) {
    currentTime = parseInt(currentTime) + parseInt(1000);
    //positx++;
    currentBlock = positx + ',' + posity;

    if (window.newOrientation == "FP") {

      posity++;
      currentBlock = positx + ',' + posity;
      if (posity > 7) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
        posity = 7;
      } else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var goalT = ``;
          var cont = 0;
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          if (window.invalid == 0) {
            currentTime = parseInt(currentTime) + parseInt(1000);
            if (window.GoalsArray.length > 0) {
              var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
                window.newOrientation = "FP";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    /*
                     setTimeout(() => {
                       $("#" + element.Type).css("display", "none");
                     }, currentTime)
                     */
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
                }
              }
            }
          }
          //fin temp
          if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }
        } else {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          window.invalid = 1
        }
        currentTime = parseInt(currentTime) + parseInt(1000);
        if (block.childBlocks_.length == 0) {
          currentPositionY += 70
          setTimeout(() => {
            if (positx == window.positx_goal && posity == window.posity_goal) {
              Fire.$emit('activityDone')
              //$("#AppModal").modal("show");
              //$("#gem").css("display", "none");
            }
          }, currentTime)
        } else {
          currentPositionY += 70;
        }
      }

    } else if (window.newOrientation == "DP") {

      positx++;
      currentBlock = positx + ',' + posity;
      if (positx > 7) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      } else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var goalT = ``;
          var cont = 0;
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          if (window.invalid == 0) {
            currentTime = parseInt(currentTime) + parseInt(1000);
            if (window.GoalsArray.length > 0) {
              var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 1000);}, ${currentTime}); `;
              window.newOrientation = "DP";
              if (window.GoalsArray.length > 1) {
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    /*
                     setTimeout(() => {
                       $("#" + element.Type).css("display", "none");
                     }, currentTime)
                     */
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });

                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 1000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
                }

              }
            }
          }
          // fin temp
          if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 10000});`;
          }
        } else {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 4000}); `;
          window.invalid = 1
        }

        currentTime = parseInt(currentTime) + parseInt(1000);
        if (block.childBlocks_.length == 0) {
          currentPositionX += 70
          setTimeout(() => {
            if (positx == window.positx_goal && posity == window.posity_goal) {
              Fire.$emit('activityDone')
              //$("#AppModal").modal("show");
              //$("#gem").css("display", "none");
            }
          }, currentTime)
        } else {
          currentPositionX += 70;
        }

      }

    } else if (window.newOrientation == "IP") {

      currentTime = parseInt(currentTime) + parseInt(1000);
      positx--;
      currentBlock = positx + ',' + posity;
      if (positx < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      }
      else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var goalT = ``;
          var cont = 0;
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          if (window.invalid == 0) {
            currentTime = parseInt(currentTime) + parseInt(1000);
            if (window.GoalsArray.length > 0) {
              var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_izquierda.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
                window.newOrientation = "IP";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    /*
                     setTimeout(() => {
                       $("#" + element.Type).css("display", "none");
                     }, currentTime)
                     */
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 10000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 4000});`;
                }

              }
            }
          }
          if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }
        } else {
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          window.invalid = 1
        }
        currentTime = parseInt(currentTime) + parseInt(1000);
        if (block.childBlocks_.length == 0) {
          currentPositionX -= 70
          setTimeout(() => {
            if (positx == window.positx_goal && posity == window.posity_goal) {
              Fire.$emit('activityDone')
              //$("#AppModal").modal("show");
              //$("#gem").css("display", "none");
            }
          }, currentTime)
        } else {
          currentPositionX -= 70;
        }
      }

    } else if (window.newOrientation == "EP") {

      currentTime = parseInt(currentTime) + parseInt(1000);
      posity--;
      currentBlock = positx + ',' + posity; //armamos la coordenada actual
      if (posity < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      }
      else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var goalT = ``;
          var cont = 0;
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          if (window.invalid == 0) {
            currentTime = parseInt(currentTime) + parseInt(1000);
            if (window.GoalsArray.length > 0) {
              var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
                window.newOrientation = "EP";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    /*
                     setTimeout(() => {
                       $("#" + element.Type).css("display", "none");
                     }, currentTime)
                     */
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 10000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
                }
              }
            }
          }
          if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }
        } else {
          // si la coordenada actual es inválida
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
          window.invalid = 1;
        }
        currentTime = parseInt(currentTime) + parseInt(1000);
        if (block.childBlocks_.length == 0) {
          currentPositionY -= 70
          setTimeout(() => {
            if (positx == window.positx_goal && posity == window.posity_goal) {
              Fire.$emit('activityDone')
              //$("#AppModal").modal("show");
              //$("#gem").css("display", "none");
            }
          }, currentTime)
        } else {
          currentPositionY -= 70;
        }
      }

    } else if (window.newOrientation == "NO") {
      currentTime = parseInt(currentTime) + parseInt(1000);
      positx--;
      posity--;
      currentBlock = positx + ',' + posity;
      if (positx < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      }
      else if (posity < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      } else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          //codigo de mono impaciente
          //temp
          var goalT = ``;
          var cont = 0;
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          if (window.invalid == 0) {
            currentTime = parseInt(currentTime) + parseInt(1000);
            if (window.GoalsArray.length > 0) {
              var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=-70px","top": "+=-70px" }, 500);}, ${currentTime}); `;
                window.newOrientation = "NO";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    /*
                     setTimeout(() => {
                       $("#" + element.Type).css("display", "none");
                     }, currentTime)
                     */
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 10000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
                }
              }
            }
          }
          //fin temp
          if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }
        } else {
          positx++;
          posity++;
          currentBlock = positx + ',' + posity;
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
          window.invalid = 1
        }
        currentTime = parseInt(currentTime) + parseInt(1000);
        if (block.childBlocks_.length == 0) {
          currentPositionX += 70
          setTimeout(() => {
            if (positx == window.positx_goal && posity == window.posity_goal) {
              Fire.$emit('activityDone')
              //$("#AppModal").modal("show");
              //$("#gem").css("display", "none");
            }
          }, currentTime)
        } else {
          currentPositionX += 70;
        }
      }

    } else if (window.newOrientation == "SE") {

      currentTime = parseInt(currentTime) + parseInt(1000);
      positx++;
      posity++;
      currentBlock = positx + ',' + posity;
      if (posity > 7) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      }
      else if (positx > 7) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      }
      else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var goalT = ``;
          var cont = 0;
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          if (window.invalid == 0) {
            currentTime = parseInt(currentTime) + parseInt(1000);
            if (window.GoalsArray.length > 0) {
              var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px","left": "+=+70px" }, 500);}, ${currentTime}); `;
                window.newOrientation = "SE";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    /*
                     setTimeout(() => {
                       $("#" + element.Type).css("display", "none");
                     }, currentTime)
                     */
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 10000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
                }
              }
            }
          }
          if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }
        } else {
          positx--;
          posity--;
          currentBlock = positx + ',' + posity;

          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
          window.invalid = 1
        }
        currentTime = parseInt(currentTime) + parseInt(1000);
        if (block.childBlocks_.length == 0) {
          currentPositionX += 70
          setTimeout(() => {
            if (positx == window.positx_goal && posity == window.posity_goal) {
              Fire.$emit('activityDone')
              //$("#AppModal").modal("show");
              //$("#gem").css("display", "none");
            }
          }, currentTime)
        } else {
          currentPositionX += 70;
        }
      }

    } else if (window.newOrientation == "SO") {

      currentTime = parseInt(currentTime) + parseInt(1000);
      positx--;
      posity++
      currentBlock = positx + ',' + posity;
      if (positx < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      }
      else if (posity > 7) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      }
      else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          //codigo de mono impaciente
          //temp
          var goalT = ``;
          var cont = 0;
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          if (window.invalid == 0) {
            currentTime = parseInt(currentTime) + parseInt(1000);
            if (window.GoalsArray.length > 0) {
              var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px","left": "+=-70px" }, 500);}, ${currentTime}); `;
                window.newOrientation = "SO";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    /*
                     setTimeout(() => {
                       $("#" + element.Type).css("display", "none");
                     }, currentTime)
                     */
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 10000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
                }
              }
            }
          }
          if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }
        } else {
          positx++;
          posity--;
          currentBlock = positx + ',' + posity;
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
          window.invalid = 1
        }
        currentTime = parseInt(currentTime) + parseInt(1000);
        if (block.childBlocks_.length == 0) {
          currentPositionX += 70
          setTimeout(() => {
            if (positx == window.positx_goal && posity == window.posity_goal) {
              Fire.$emit('activityDone')
              //$("#AppModal").modal("show");
              //$("#gem").css("display", "none");
            }
          }, currentTime)
        } else {
          currentPositionX += 70;
        }
      }

    } else if (window.newOrientation == "NE") {

      currentTime = parseInt(currentTime) + parseInt(1000);
      positx++;
      posity--;
      currentBlock = positx + ',' + posity;
      if (positx < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      }
      if (posity < 0) {
        var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        currentTime = parseInt(currentTime) + parseInt(1000);
      } else {
        if (!window.blocksNotAllowed.includes(currentBlock)) {
          var goalT = ``;
          var cont = 0;
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });

          if (window.invalid == 0) {
            currentTime = parseInt(currentTime) + parseInt(1000);
            if (window.GoalsArray.length > 0) {
              var code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=-70px","left": "+=+70px" }, 500);}, ${currentTime}); `;
                window.newOrientation = "NE";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    /*
                     setTimeout(() => {
                       $("#" + element.Type).css("display", "none");
                     }, currentTime)
                     */
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 10000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
                }
              }
            }
          }
          if (position == window.blocks.length - 1 && (positx != window.positx_goal || posity != window.posity_goal)) {
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
          }
        } else {
          positx--;
          posity++;
          currentBlock = positx + ',' + posity;
          var code = `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
          window.invalid = 1
        }
        currentTime = parseInt(currentTime) + parseInt(1000);
        if (block.childBlocks_.length == 0) {
          currentPositionX += 70
          setTimeout(() => {
            if (positx == window.positx_goal && posity == window.posity_goal) {
              Fire.$emit('activityDone')
              //$("#AppModal").modal("show");
              //$("#gem").css("display", "none");
            }
          }, currentTime)
        } else {
          currentPositionX += 70;
        }
      }
    }
  }

  return code;

};



Blockly.Blocks['Salto2'] = {
  init: function () {
    let traduccion = '';
    let traduccion2 = '';
    if (window.idioma === 'es') { traduccion = 'Dirección'; traduccion2 = 'Brincar'; }
    if (window.idioma !== 'es') { traduccion = 'Direction'; traduccion2 = 'Jump'; }
    var image = new Blockly.FieldImage('../assets/img/activity/saltar.png', 30, 30, '*');
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([
        [{ 'src': '../assets/img/activity/flechas_small/left.png', 'width': 30, 'height': 25, 'alt': 'left2' }, 'left2'],//izq
        [{ 'src': '../assets/img/activity/flechas_small/right.png', 'width': 30, 'height': 25, 'alt': 'right2' }, 'right2'],//der
        [{ 'src': '../assets/img/activity/flechas_small/up.png', 'width': 30, 'height': 25, 'alt': 'up2' }, 'up2'],
        [{ 'src': '../assets/img/activity/flechas_small/down.png', 'width': 30, 'height': 25, 'alt': 'down2' }, 'down2'],
        [{ 'src': '../assets/img/activity/flechas_small/up_left.png', 'width': 30, 'height': 25, 'alt': 'noroeste' }, 'noroeste'],
        [{ 'src': '../assets/img/activity/flechas_small/up_right.png', 'width': 30, 'height': 25, 'alt': 'noreste' }, 'noreste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_right.png', 'width': 30, 'height': 25, 'alt': 'sureste' }, 'sureste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_left.png', 'width': 30, 'height': 25, 'alt': 'suroeste' }, 'suroeste']
      ]), "direccion")
      .appendField(traduccion2)
      .appendField(image)
      .appendField(new Blockly.FieldNumber(0, 1, 7), "valor");
    this.setColour(230);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip("");
  }
};

Blockly.JavaScript['Salto2'] = function (block) {

  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  var temp = '';
  var goalT = ``;
  var cont = 0;
 
  currentTime = parseInt(currentTime) + parseInt(1000);
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });

  if (block.inputList[0].fieldRow[1].value_ == "right2") {
  
    for (let index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx++;
      currentBlock = positx + ',' + posity;
      if (!window.blocksNotAllowed.includes(currentBlock) && index == block.inputList[0].fieldRow[4].value_) {
        temp = '1';
      } else { temp = '0'; }
    }

    if (temp == 1) {
      //code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}`, false);
      for (var x = 1; x <= block.inputList[0].fieldRow[4].value_; x++) {
        currentTime = parseInt(currentTime) + parseInt(1000);
        code += `setTimeout(function(){window.blocks[${blockPosition}].select();}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 1000);}, ${currentTime}); `;
        window.newOrientation = "DP";
      }
      window.GoalsArray.forEach(element => {
        cont++;
        if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
          window.goalscollected++;
          element.bCorrect = true;
          window.xyz++;
        }
      });
      if (window.xyz == goalT) {
        Fire.$emit('activityDone');
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
      } else {
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
      }
    } else if (temp == 0) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      window.invalid = 1
    }
  }

  if (block.inputList[0].fieldRow[1].value_ == "left2") {

    for (let index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx--;
      currentBlock = positx + ',' + posity;
      if (!window.blocksNotAllowed.includes(currentBlock) && index == block.inputList[0].fieldRow[4].value_) {
        temp = '1';
      } else { temp = '0'; }
    }
    if (positx < 0) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (temp == 1) {
        //code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}`, false);
        for (var x = 1; x <= block.inputList[0].fieldRow[4].value_; x++) {
          currentTime = parseInt(currentTime) + parseInt(1000);
          code += `setTimeout(function(){window.blocks[${blockPosition}].select();}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_izquierda.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=-70px"}, 1000);}, ${currentTime}); `;
          window.newOrientation = "IP";
        }
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            element.bCorrect = true;
            window.xyz++;
          }
        });
        if (window.xyz == goalT) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else if (temp == 0) {
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
    }

  }

  if (block.inputList[0].fieldRow[1].value_ == "up2") {

    for (let index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      posity--;
      currentBlock = positx + ',' + posity;
      if (!window.blocksNotAllowed.includes(currentBlock) && index == block.inputList[0].fieldRow[4].value_) { temp = '1'; } else { temp = '0'; }
    }
    if (posity < 0) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (temp == 1) {
        //code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}`, false);
        for (var x = 1; x <= block.inputList[0].fieldRow[4].value_; x++) {
          currentTime = parseInt(currentTime) + parseInt(1000);
          code += `setTimeout(function(){window.blocks[${blockPosition}].select();}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_arriba.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 1000);}, ${currentTime}); `;
          window.newOrientation = "EP";
        }
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            element.bCorrect = true;
            window.xyz++;
          }
        });
        if (window.xyz == goalT) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else if (temp == 0) {
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
    }
  }

  if (block.inputList[0].fieldRow[1].value_ == "down2") {
    for (let index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      posity++;
      currentBlock = positx + ',' + posity;
      if (!window.blocksNotAllowed.includes(currentBlock) && index == block.inputList[0].fieldRow[4].value_) { temp = '1'; } else { temp = '0'; }
    }
    if (posity > 7) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
      posity = 7;
    } else {
      if (temp == 1) {
        //code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}`, false);
        for (var x = 1; x <= block.inputList[0].fieldRow[4].value_; x++) {
          currentTime = parseInt(currentTime) + parseInt(1000);
          code += `setTimeout(function(){window.blocks[${blockPosition}].select();}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_frente.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 1000);}, ${currentTime}); `;
          window.newOrientation = "FP";
        }
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            element.bCorrect = true;
            window.xyz++;
          }
        });
        if (window.xyz == goalT) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else if (temp == 0) {
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }

    }
  }

  if (block.inputList[0].fieldRow[1].value_ == "noroeste") {
    for (let index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx--;
      posity--;
      currentBlock = positx + ',' + posity;
      if (!window.blocksNotAllowed.includes(currentBlock) && index == block.inputList[0].fieldRow[4].value_) { temp = '1'; } else { temp = '0'; }
    }
    if (positx < 0) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else if (posity < 0) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (temp == 1) {
        //code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}`, false);
        for (var x = 1; x <= block.inputList[0].fieldRow[4].value_; x++) {
          currentTime = parseInt(currentTime) + parseInt(1000);
          code += `setTimeout(function(){window.blocks[${blockPosition}].select();}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_izquierda.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=-70px","top": "+=-70px"}, 1000);}, ${currentTime}); `;
          window.newOrientation = "NO";
        }
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            element.bCorrect = true;
            window.xyz++;
          }
        });
        if (window.xyz == goalT) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else if (temp == 0) {
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }

    }
}

  if (block.inputList[0].fieldRow[1].value_ == "noreste") {
    for (let index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx++;
      posity--;
      currentBlock = positx + ',' + posity;
      if (!window.blocksNotAllowed.includes(currentBlock) && index == block.inputList[0].fieldRow[4].value_) { temp = '1'; } else { temp = '0'; }
    }
    if (positx < 0) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    }
    if (posity < 0) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (temp == 1) {
        //code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}`, false);
        for (var x = 1; x <= block.inputList[0].fieldRow[4].value_; x++) {
          currentTime = parseInt(currentTime) + parseInt(1000);
          code += `setTimeout(function(){window.blocks[${blockPosition}].select();}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=-70px","left": "+=+70px"}, 1000);}, ${currentTime}); `;
          window.newOrientation = "NE";
        }
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            element.bCorrect = true;
            window.xyz++;
          }
        });
        if (window.xyz == goalT) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else if (temp == 0) {
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }

    }
  }

  if (block.inputList[0].fieldRow[1].value_ == "sureste") {
    for (let index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx++;
      posity++;
      currentBlock = positx + ',' + posity;
      if (!window.blocksNotAllowed.includes(currentBlock) && index == block.inputList[0].fieldRow[4].value_) { temp = '1'; } else { temp = '0'; }
    }
    if (posity > 7) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else if (positx > 7) {
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (temp == 1) {
        //code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}`, false);
        for (var x = 1; x <= block.inputList[0].fieldRow[4].value_; x++) {
          currentTime = parseInt(currentTime) + parseInt(1000);
          code += `setTimeout(function(){window.blocks[${blockPosition}].select();}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px","left": "+=+70px"}, 1000);}, ${currentTime}); `;
          window.newOrientation = "SE";
        }
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            element.bCorrect = true;
            window.xyz++;
          }
        });
        if (window.xyz == goalT) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else if (temp == 0) {
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }

    }
}

  if (block.inputList[0].fieldRow[1].value_ == "suroeste") {
    for (let index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx--;
      posity++;
      currentBlock = positx + ',' + posity;
      if (!window.blocksNotAllowed.includes(currentBlock) && index == block.inputList[0].fieldRow[4].value_) { temp = '1'; } else { temp = '0'; }
    }
    if (positx < 0) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else if (posity > 7) {
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
      code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
      currentTime = parseInt(currentTime) + parseInt(1000);
    } else {
      if (temp == 1) {
        //code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/salto_vertical_der_v4.gif?a=${Math.random()}`, false);
        for (var x = 1; x <= block.inputList[0].fieldRow[4].value_; x++) {
          currentTime = parseInt(currentTime) + parseInt(1000);
          code += `setTimeout(function(){window.blocks[${blockPosition}].select();}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/salto_izquierda.gif?a=${Math.random()}')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px","left": "+=-70px"}, 1000);}, ${currentTime}); `;
          window.newOrientation = "SO";
        }
        window.GoalsArray.forEach(element => {
          cont++;
          if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
            window.goalscollected++;
            element.bCorrect = true;
            window.xyz++;
          }
        });
        if (window.xyz == goalT) {
          Fire.$emit('activityDone');
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
        } else {
          code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 3000});`;
        }
      } else if (temp == 0) {
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${blockPosition}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }

    }
}
  return code;
}


Blockly.Blocks['corre2'] = {
  init: function () {
    let traduccion = '';
    let traduccion2 = '';
    if (window.idioma === 'es') { traduccion = 'Dirección'; traduccion2 = 'Camina'; }
    if (window.idioma !== 'es') { traduccion = 'Direction'; traduccion2 = 'Walk'; }
    var image = new Blockly.FieldImage('../assets/img/activity/saltar.png', 30, 30, '*');
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([
        [{ 'src': '../assets/img/activity/flechas_small/left.png', 'width': 30, 'height': 25, 'alt': 'left2' }, 'left2'],//izq
        [{ 'src': '../assets/img/activity/flechas_small/right.png', 'width': 30, 'height': 25, 'alt': 'right2' }, 'right2'],//der
        [{ 'src': '../assets/img/activity/flechas_small/up.png', 'width': 30, 'height': 25, 'alt': 'up2' }, 'up2'],
        [{ 'src': '../assets/img/activity/flechas_small/down.png', 'width': 30, 'height': 25, 'alt': 'down2' }, 'down2'],
        [{ 'src': '../assets/img/activity/flechas_small/up_left.png', 'width': 30, 'height': 25, 'alt': 'noroeste' }, 'noroeste'],
        [{ 'src': '../assets/img/activity/flechas_small/up_right.png', 'width': 30, 'height': 25, 'alt': 'noreste' }, 'noreste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_right.png', 'width': 30, 'height': 25, 'alt': 'sureste' }, 'sureste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_left.png', 'width': 30, 'height': 25, 'alt': 'suroeste' }, 'suroeste']
      ]), "direccion")
      .appendField(traduccion2)
      .appendField(image)
      .appendField(new Blockly.FieldNumber(0, 1, 7), "valor");
    this.setColour(270);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip("");
  }
};

Blockly.JavaScript['corre2'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  var temp = ``;
  var goalT = ``;
  var cont = 0;

  currentTime = parseInt(currentTime) + parseInt(1000);
  window.GoalsArray.forEach(gem => {
    if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
      goalT++;
  });

  if (block.inputList[0].fieldRow[1].value_ == "left2") {

    for (var index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx--;
      currentTime = parseInt(currentTime) + parseInt(1000);
      currentBlock = positx + ',' + posity;
          if (!window.blocksNotAllowed.includes(currentBlock)) {
            if (positx < 0) {
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime};setTimeout(function(){audioInst.play()}, ${currentTime});`;
              code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        
            } else {
          /*
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          */
          if (window.invalid == 0) {
            if (window.GoalsArray.length > 0) {
              code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "-=70px"}, 500);}, ${currentTime});`;
                window.newOrientation = "IP";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 5000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 5000 });`;
                }
              }
            }
          }
          if (block.childBlocks_.length == 0) {
            currentPositionX -= 70
            setTimeout(() => {
              if (positx == window.positx_goal && posity == window.posity_goal) {
                Fire.$emit('activityDone')
              }
            }, currentTime)
          } else {
            currentPositionX -= 70;
          }
        }
      }else{
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});  `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }

    }
  }
  if (block.inputList[0].fieldRow[1].value_ == "right2") {
    for (var index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx++;
      currentBlock = positx + ',' + posity;
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        if (positx > 7) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        
        } else {
          /*
        window.GoalsArray.forEach(gem => {
          if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
            goalT++;
        });
        */
        if (window.invalid == 0) {
          if (window.GoalsArray.length > 0) {
            code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
            code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=70px"}, 500);}, ${currentTime}); `;
            window.newOrientation = "DP";
            if (window.GoalsArray.length > 1) {
              window.GoalsArray.forEach(element => {
                cont++;
                if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                  window.goalscollected++;
                  element.bCorrect = true;
                  window.xyz++;
                }
              });
              if (window.xyz == goalT) {
                Fire.$emit('activityDone');
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
              } else {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 4000});`;
              }
            }
          }
        }
          if (block.childBlocks_.length == 0) {
            currentPositionX += 70
            setTimeout(() => {
              if (positx == window.positx_goal && posity == window.posity_goal) {
                Fire.$emit('activityDone')
              }
            }, currentTime)
          } else {
            currentPositionX += 70;
          }
        }
      }else{
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 4000}); `;
        window.invalid = 1
      }

    }
   }
  if (block.inputList[0].fieldRow[1].value_ == "up2") {
    for (var index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      posity--;
      currentBlock = positx + ',' + posity;
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        if (posity < 0) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

        } else {
          /*
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          */
          if (window.invalid == 0) {
            if (window.GoalsArray.length > 0) {
              code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/arriba.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "-=70px"}, 500);}, ${currentTime});`;
                window.newOrientation = "EP";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 4000});`;
                }
              }
            }
          }
        }
      }else{
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquearriba.gif')");}, ${currentTime});  setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1;
      }
    }
   }
  if (block.inputList[0].fieldRow[1].value_ == "down2") { 
    for (var index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      posity++;
      currentBlock = positx + ',' + posity;
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        if (posity > 7) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

          posity = 7;
        } else {
          /*
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          */
          if (window.invalid == 0) {

            if (window.GoalsArray.length > 0) {
              code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frente.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px"}, 500);}, ${currentTime});`;
                window.newOrientation = "FP";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 4000});`;
                }
              }
            }
          }
          currentTime = parseInt(currentTime) + parseInt(1000);
          if (block.childBlocks_.length == 0) {
            currentPositionY += 70
            setTimeout(() => {
              if (positx == window.positx_goal && posity == window.posity_goal) {
                Fire.$emit('activityDone')

              }
            }, currentTime)
          } else {
            currentPositionY += 70;
          }
        }
      }else{
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquefrente.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime});`;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
    }
  }
  if (block.inputList[0].fieldRow[1].value_ == "noroeste") {
   for (var index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
    positx--;
    posity--;
    currentBlock = positx + ',' + posity;
     currentTime = parseInt(currentTime) + parseInt(1000);
     if (!window.blocksNotAllowed.includes(currentBlock)) {
       if (positx < 0) {
         code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
         code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

       } else if (posity < 0) {
         code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
         code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;
       
       } else {
         /*
         window.GoalsArray.forEach(gem => {
           if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
             goalT++;
         });
         */
         if (window.invalid == 0) {
           if (window.GoalsArray.length > 0) {
             code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
             if (window.GoalsArray.length > 1) {
               code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"left": "+=-70px","top": "+=-70px" }, 500);}, ${currentTime}); `;
               window.newOrientation = "NO";
               window.GoalsArray.forEach(element => {
                 cont++;
                 if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                   window.goalscollected++;

                   element.bCorrect = true;
                   window.xyz++;
                 }
               });
               if (window.xyz == goalT) {
                 Fire.$emit('activityDone');
                 code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
               } else {
                 code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 4000});`;
               }
             }
           }
         }
         currentTime = parseInt(currentTime) + parseInt(1000);
         if (block.childBlocks_.length == 0) {
           currentPositionX += 70
           setTimeout(() => {
             if (positx == window.positx_goal && posity == window.posity_goal) {
               Fire.$emit('activityDone')

             }
           }, currentTime)
         } else {
           currentPositionX += 70;
         }
       }
      } else {
       positx++;
       posity++;
       currentBlock = positx + ',' + posity;
       code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
       code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
       window.invalid = 1
       }
    }
   }
  if (block.inputList[0].fieldRow[1].value_ == "noreste") {
    for (var index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx++;
      posity--;
      currentBlock = positx + ',' + posity;
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (!window.blocksNotAllowed.includes(currentBlock)) { 
        if (positx < 0) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

        }
        if (posity < 0) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

        } else {
          /*
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          */
          if (window.invalid == 0) {
            if (window.GoalsArray.length > 0) {
              code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=-70px","left": "+=+70px" }, 500);}, ${currentTime}); `;
                window.newOrientation = "NE";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;

                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 4000});`;
                }
              }
            }
          }
          currentTime = parseInt(currentTime) + parseInt(1000);
          if (block.childBlocks_.length == 0) {
            currentPositionX += 70
            setTimeout(() => {
              if (positx == window.positx_goal && posity == window.posity_goal) {
                Fire.$emit('activityDone')

              }
            }, currentTime)
          } else {
            currentPositionX += 70;
          }
        }
      } else { 
        positx--;
        posity++;
        currentBlock = positx + ',' + posity;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
        window.invalid = 1
      }
    }
   }
  if (block.inputList[0].fieldRow[1].value_ == "sureste") {
    for (var index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx++;
      posity++;
      currentBlock = positx + ',' + posity;
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (!window.blocksNotAllowed.includes(currentBlock)) {
        if (posity > 7) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

        } else if (positx > 7) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

        } else {
          /*
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          */
          if (window.invalid == 0) {
            if (window.GoalsArray.length > 0) {
              code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/derecha.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px","left": "+=+70px" }, 500);}, ${currentTime}); `;
                window.newOrientation = "SE";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 4000});`;
                }
              }
            }
          }
          currentTime = parseInt(currentTime) + parseInt(1000);
          if (block.childBlocks_.length == 0) {
            currentPositionX += 70
            setTimeout(() => {
              if (positx == window.positx_goal && posity == window.posity_goal) {
                Fire.$emit('activityDone')
              }
            }, currentTime)
          } else {
            currentPositionX += 70;
          }
        }
       } else {
        positx--;
        posity--;
        currentBlock = positx + ',' + posity;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
        window.invalid = 1
        }
    }
   }
  if (block.inputList[0].fieldRow[1].value_ == "suroeste") { 
    for (var index = 1; index <= block.inputList[0].fieldRow[4].value_; index++) {
      positx--;
      posity++
      currentBlock = positx + ',' + posity;
      currentTime = parseInt(currentTime) + parseInt(1000);
      if (!window.blocksNotAllowed.includes(currentBlock)) { 
        if (positx < 0) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

        } else if (posity > 7) {
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choquederecha.gif')");}, ${currentTime});setTimeout(function(){audioInst.play()}, ${currentTime});`;
          code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); $("#character").css("top", ${window.currentPositionY} + "px");  $("#character").css("left", ${window.currentPositionX} + "px"); }, ${currentTime + 3000}); `;

        } else {
          /*
          window.GoalsArray.forEach(gem => {
            if (gem.iPriority == 1 && gem.TypeO == "Objetivo")
              goalT++;
          });
          */
          if (window.invalid == 0) {
            if (window.GoalsArray.length > 0) {
              code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});`;
              if (window.GoalsArray.length > 1) {
                code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/izquierda.gif')");}, ${currentTime}); setTimeout(function(){$("#character").animate({"top": "+=70px","left": "+=-70px" }, 500);}, ${currentTime}); `;
                window.newOrientation = "SO";
                window.GoalsArray.forEach(element => {
                  cont++;
                  if (positx == element.postx && posity == element.posty && element.bCorrect == null && element.iPriority == 1) {
                    window.goalscollected++;
                    element.bCorrect = true;
                    window.xyz++;
                  }
                });
                if (window.xyz == goalT) {
                  Fire.$emit('activityDone');
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')"); }, ${currentTime + 4000}); `;
                } else {
                  code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/espera.gif')");}, ${currentTime + 4000});`;
                }
              }
            }
          }
          currentTime = parseInt(currentTime) + parseInt(1000);
          if (block.childBlocks_.length == 0) {
            currentPositionX += 70
            setTimeout(() => {
              if (positx == window.positx_goal && posity == window.posity_goal) {
                Fire.$emit('activityDone')

              }
            }, currentTime)
          } else {
            currentPositionX += 70;
          }
        }
      } else {
        positx++;
        posity--;
        currentBlock = positx + ',' + posity;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/choqueizquierda.gif')");}, ${currentTime}); setTimeout(function(){audioInst.play()}, ${currentTime}); `;
        code += `setTimeout(function(){window.blocks[${position}].select(); $("#character").css("background-image","url('../assets/img/personaje/frenteparado.gif')");  }, ${currentTime + 3000}); `;
        window.invalid = 1
       }
    }
  }

  //console.log('xyz    ' + window.xyz + ' ' + 'goalT' + goalT)

  return code;

}

Blockly.Blocks['vocalescamina'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/saltar.png', 30, 30, '*');
    this.appendDummyInput()
      .appendField("Dirección")
      .appendField(new Blockly.FieldDropdown([

        [{ 'src': '../assets/img/activity/flechas_small/left.png', 'width': 30, 'height': 25, 'alt': 'left2' }, 'left2'],//izq
        [{ 'src': '../assets/img/activity/flechas_small/right.png', 'width': 30, 'height': 25, 'alt': 'right2' }, 'right2'],//der
        [{ 'src': '../assets/img/activity/flechas_small/up.png', 'width': 30, 'height': 25, 'alt': 'up2' }, 'up2'],
        [{ 'src': '../assets/img/activity/flechas_small/down.png', 'width': 30, 'height': 25, 'alt': 'down2' }, 'down2'],
        [{ 'src': '../assets/img/activity/flechas_small/up_left.png', 'width': 30, 'height': 25, 'alt': 'noroeste' }, 'noroeste'],
        [{ 'src': '../assets/img/activity/flechas_small/up_right.png', 'width': 30, 'height': 25, 'alt': 'noreste' }, 'noreste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_right.png', 'width': 30, 'height': 25, 'alt': 'sureste' }, 'sureste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_left.png', 'width': 30, 'height': 25, 'alt': 'suroeste' }, 'suroeste']
      ]), "direccion")
      .appendField("Trazar")
      .appendField(image)
      .appendField(new Blockly.FieldNumber(0, 1, 7), "valor");
    this.setColour(270);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip("");
  }
};

Blockly.JavaScript['vocalescamina'] = function (block) {

  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;

  //revisamos si se requiere validar orden de los objetos
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;


  if (block.inputList[0].fieldRow[1].value_ == "right2") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;
    code += movePosition("right", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "left2") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
    code += movePosition("left", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "up2") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_arriba_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/arriba.gif?a=${Math.random()}`;
    code += movePosition("up", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "down2") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_frente_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/frente.gif?a=${Math.random()}`;
    code += movePosition("down", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "noroeste") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
    code += movePosition("northwest", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "noreste") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;
    code += movePosition("northeast", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "sureste") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;
    code += movePosition("southeast", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "suroeste") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
    code += movePosition("southwest", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }
  return code;
}

Blockly.Blocks['vocalescamina2'] = {
  init: function () {
    let traduccion = '';
    let traduccion2 = '';
    if(window.idioma === 'es'){traduccion = 'Dirección'; traduccion2 = 'Trazar'}
    if(window.idioma !== 'es'){traduccion = 'Direction'; traduccion2 = 'Draw'}    
    var image = new Blockly.FieldImage('../assets/img/activity/icono_crayon.png', 30, 30, '*');
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([
        [{ 'src': '../assets/img/activity/flechas_small/left.png', 'width': 30, 'height': 25, 'alt': 'left2' }, 'left2'],//izq
        [{ 'src': '../assets/img/activity/flechas_small/right.png', 'width': 30, 'height': 25, 'alt': 'right2' }, 'right2'],//der
        [{ 'src': '../assets/img/activity/flechas_small/up.png', 'width': 30, 'height': 25, 'alt': 'up2' }, 'up2'],
        [{ 'src': '../assets/img/activity/flechas_small/down.png', 'width': 30, 'height': 25, 'alt': 'down2' }, 'down2'],
        [{ 'src': '../assets/img/activity/flechas_small/up_left.png', 'width': 30, 'height': 25, 'alt': 'noroeste' }, 'noroeste'],
        [{ 'src': '../assets/img/activity/flechas_small/up_right.png', 'width': 30, 'height': 25, 'alt': 'noreste' }, 'noreste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_right.png', 'width': 30, 'height': 25, 'alt': 'sureste' }, 'sureste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_left.png', 'width': 30, 'height': 25, 'alt': 'suroeste' }, 'suroeste']
      ]), "direccion")
      .appendField(traduccion2)
      .appendField(image)
      .appendField(new Blockly.FieldNumber(0, 1, 7), "valor");
    this.setColour(270);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip("");
  }
};

Blockly.JavaScript['vocalescamina2'] = function (block) {

  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;

  //revisamos si se requiere validar orden de los objetos
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;


  if (block.inputList[0].fieldRow[1].value_ == "right2") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;
    code += movePosition("right", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "left2") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
    code += movePosition("left", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "up2") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_arriba_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/arriba.gif?a=${Math.random()}`;
    code += movePosition("up", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "down2") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_frente_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/frente.gif?a=${Math.random()}`;
    code += movePosition("down", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "noroeste") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
    code += movePosition("northwest", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "noreste") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;
    code += movePosition("northeast", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "sureste") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;
    code += movePosition("southeast", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  if (block.inputList[0].fieldRow[1].value_ == "suroeste") {
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
    code += movePosition("southwest", 7, 1000, blockPosition, 75, true, block.inputList[0].fieldRow[4].value_, avatarMoveGif, checkOrder);
  }

  //console.log('generado' + window.coordenada.slice(0,-1) );            
  //console.log('validacion' + window.blocksYesAllowed);     
  return code;
}



Blockly.Blocks['play_note'] = {
  init: function () {
    let traduccion = '';
    let traduccion2 = '';
    let traduccion3 = '';
    if(window.idioma === 'es'){
      traduccion = 'Tocar Nota';
      traduccion2 = 'por';
      traduccion3 = 'Seleccione Nota';
    }if(window.idioma !== 'es'){
      traduccion = 'Play Note';
      traduccion2 = 'by';
      traduccion3 = 'Select Note';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion3, "Seleccione Nota"], ["1 Do", "1"], ["2 Re", "2"], ["3 Mi", "3"], ["4 Fa", "4"], ["5 Sol", "5"], ["6 La", "6"], ["7 Si", "7"], ["8 Do", "8"]]), "note")
      .appendField(traduccion2)
      .appendField(new Blockly.FieldNumber(1, 0, 1), "secD")
      .appendField("seg")
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['play_note'] = function (block) {
  /*
  var code = ``;

  var position = window.blocks.findIndex(element => element.id == block.id);
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${block.inputList[0].fieldRow[1].value_});}, ${currentTime+200});
  `;  
  
  window.coordenada = window.coordenada.concat(`${block.inputList[0].fieldRow[1].value_}|`);
  currentTime += parseFloat(block.inputList[0].fieldRow[3].value_) * 1000;

  
  if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
    Fire.$emit('activityDone');
  }
  
  console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed)
  return code;
  */
  let code = '';
  currentTime += parseFloat(block.inputList[0].fieldRow[3].value_) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `

  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${block.inputList[0].fieldRow[1].value_});}, ${currentTime + 200});
  `;

  window.coordenada = window.coordenada.concat(`${block.inputList[0].fieldRow[1].value_}|`);

  if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
    Fire.$emit('activityDone');
  }

  console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}

Blockly.Blocks['play_note2'] = {
  init: function () {
    let traduccion = '';
    let traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Tocar Nota';
      traduccion2 = 'Seleccione Nota';
    }if(window.idioma !== 'es'){
      traduccion = 'Play Note';
      traduccion2 = 'Select Note';
    }
      
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "0"], ["1 Do", "1"], ["2 Re", "2"], ["3 Mi", "3"], ["4 Fa", "4"], ["5 Sol", "5"], ["6 La", "6"], ["7 Si", "7"], ["8 Do", "8"]]), "note")
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};
Blockly.JavaScript['play_note2'] = function (block) {

  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);

  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});setTimeout(function(){window.rola(${block.inputList[0].fieldRow[1].value_});}, ${currentTime});
  `;
  window.coordenada = window.coordenada.concat(`${block.inputList[0].fieldRow[1].value_}|`);
  if (block.inputList[0].fieldRow[1].value_ == 0)
      currentTime += parseFloat(.5) * 1000;
  if (window.question_id == 26311) {
    if (block.inputList[0].fieldRow[1].value_ == 1)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 2)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 3)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 4)
      currentTime += parseFloat(.3) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 5)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 6)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 7)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 8)
      currentTime += parseFloat(.5) * 1000;
  }
  if (window.question_id == 26306 || window.question_id == 26351) {
    if (block.inputList[0].fieldRow[1].value_ == 1)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 2)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 3)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 4)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 5)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 6)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 7)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 8)
      currentTime += parseFloat(1) * 1000;
  }

  if (window.question_id == 26324) {
    if (block.inputList[0].fieldRow[1].value_ == 1)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 2)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 3)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 4)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 5)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 6)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 7)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 8)
      currentTime += parseFloat(.7) * 1000;

  }
  //currentTime += parseFloat(block.inputList[0].fieldRow[3].value_) * 1000;

  if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
    Fire.$emit('activityDone');
  }
  console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed)
  //console.log(window.question_id);
  return code;
}

Blockly.Blocks['play_note22'] = {
  
  init: function () {
    let traduccion = '';
    if (window.idioma === 'es'){ traduccion= 'Tocar Nota' }
    if (window.idioma !== 'es'){ traduccion= 'Play Note' }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([
        [{ 'src': '../assets/img/activity/do.png', 'width': 30, 'height': 25, 'alt': 'Do' }, '1'],
        [{ 'src': '../assets/img/activity/re.png', 'width': 30, 'height': 25, 'alt': 'Re' }, '2'],
        [{ 'src': '../assets/img/activity/mi.png', 'width': 30, 'height': 25, 'alt': 'Mi' }, '3'],
        [{ 'src': '../assets/img/activity/fa.png', 'width': 30, 'height': 25, 'alt': 'Fa' }, '4'],
        [{ 'src': '../assets/img/activity/sol.png', 'width': 30, 'height': 25, 'alt': 'Sol' }, '5'],
        [{ 'src': '../assets/img/activity/la.png', 'width': 30, 'height': 25, 'alt': 'La' }, '6'],
        [{ 'src': '../assets/img/activity/si.png', 'width': 30, 'height': 25, 'alt': 'Si' }, '7'],
        [{ 'src': '../assets/img/activity/do2.png', 'width': 30, 'height': 25, 'alt': 'Do' }, '8']
      ]), "note")
      //.appendField(new Blockly.FieldDropdown([["Seleccione Nota", "Seleccione Nota"], ["1 Do", "1"], ["2 Re", "2"], ["3 Mi", "3"], ["4 Fa", "4"], ["5 Sol", "5"], ["6 La", "6"], ["7 Si", "7"], ["8 Do", "8"]]), "note")
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['play_note22'] = function (block) {

  var code = ``;
  var position = window.blocks.findIndex(element => element.id == block.id);

  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime});setTimeout(function(){window.rola(${block.inputList[0].fieldRow[1].value_});}, ${currentTime});
  `;
  window.coordenada = window.coordenada.concat(`${block.inputList[0].fieldRow[1].value_}|`);
  if (window.question_id == 26311) {
    if (block.inputList[0].fieldRow[1].value_ == 1)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 2)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 3)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 4)
      currentTime += parseFloat(.3) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 5)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 6)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 7)
      currentTime += parseFloat(.5) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 8)
      currentTime += parseFloat(.5) * 1000;
  }
  if (window.question_id == 26306 || window.question_id == 26349 || window.question_id == 26350 || window.question_id == 26351) {
    if (block.inputList[0].fieldRow[1].value_ == 1)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 2)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 3)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 4)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 5)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 6)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 7)
      currentTime += parseFloat(1) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 8)
      currentTime += parseFloat(1) * 1000;
  }

  if (window.question_id == 26324) {
    if (block.inputList[0].fieldRow[1].value_ == 1)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 2)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 3)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 4)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 5)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 6)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 7)
      currentTime += parseFloat(.7) * 1000;
    if (block.inputList[0].fieldRow[1].value_ == 8)
      currentTime += parseFloat(.7) * 1000;

  }
  //currentTime += parseFloat(block.inputList[0].fieldRow[3].value_) * 1000;

  if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
    Fire.$emit('activityDone');
  }
  console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed)
  //console.log(window.question_id);
  return code;
}



Blockly.Blocks['secs_note'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldNumber(0, 0, 2), "secV")
      .appendField(new Blockly.FieldDropdown([["secs", "secs"]]), "selectSecs");
    this.setOutput(true, null);
    this.setColour(345);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['block_color'] = {
  init: function () {
    var field = new Blockly.FieldColour('#ff4040');
    field.setColours(
      ['#403FFF', '#40FF3F', '#ff4040', '#FFC616'],
      ['Blue', 'Green', 'Red', 'Yellow ']);
    field.setColumns(3);
    this.appendDummyInput()
      .appendField('Color:')
      .appendField(field, 'COLOUR');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(255);
  }
};

Blockly.JavaScript['block_color'] = function (block) {
  var code = ``;
  var imagens = ``;
  var nActiviti = ``;
  var goalT = ``;
  var cont = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  let indice = window.figeometrico.indexOf("_");
  let figura = window.figeometrico.substring(0, indice);
  var Fig = '';
  if (figura == "triangulo") { Fig = 1; }
  if (figura == "cuadrado") { Fig = 2 }
  if (figura == "circulo") { Fig = 3; }
  //console.log(window.xyz);


  if (block.inputList[0].fieldRow[1].value_ == '#403fff') {
    imagens = 1;
    code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.imgs(${imagens},${window.namediv},${Fig});}, ${currentTime}); `;
    window.coordenada = window.coordenada.substring(0, window.coordenada.length - 1) + ',#403fff' + '|';
  }
  if (block.inputList[0].fieldRow[1].value_ == '#40ff3f') {
    imagens = 2;
    code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.imgs(${imagens},${window.namediv},${Fig});}, ${currentTime}); `;
    window.coordenada = window.coordenada.substring(0, window.coordenada.length - 1) + ',#40ff3f' + '|';
  }
  if (block.inputList[0].fieldRow[1].value_ == '#ff4040') {
    imagens = 3;
    code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.imgs(${imagens},${window.namediv},${Fig});}, ${currentTime}); `;
    window.coordenada = window.coordenada.substring(0, window.coordenada.length - 1) + ',#ff4040' + '|';
  }
  if (block.inputList[0].fieldRow[1].value_ == '#ffc616') {
    imagens = 4;
    code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.imgs(${imagens},${window.namediv},${Fig});}, ${currentTime}); `;
    window.coordenada = window.coordenada.substring(0, window.coordenada.length - 1) + ',#ffc616' + '|';
  }

  window.currentTime += 1000;


  //console.log("Extraída: ", figura);
  //console.log(window.coordenada);
  window.checkColors = true;
  return code;
}

Blockly.Blocks['caminocolor'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Dirección';
      traduccion2 = 'Caminar';
    }if(window.idioma !== 'es'){
      traduccion = 'Direction';
      traduccion2 = 'To walk';
    }
    var image = new Blockly.FieldImage('../assets/img/activity/saltar.png', 30, 30, '*');
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([
        [{ 'src': '../assets/img/activity/flechas_small/left.png', 'width': 30, 'height': 25, 'alt': 'left2' }, 'left2'],//izq
        [{ 'src': '../assets/img/activity/flechas_small/right.png', 'width': 30, 'height': 25, 'alt': 'right2' }, 'right2'],//der
        [{ 'src': '../assets/img/activity/flechas_small/up.png', 'width': 30, 'height': 25, 'alt': 'up2' }, 'up2'],
        [{ 'src': '../assets/img/activity/flechas_small/down.png', 'width': 30, 'height': 25, 'alt': 'down2' }, 'down2'],
        [{ 'src': '../assets/img/activity/flechas_small/up_left.png', 'width': 30, 'height': 25, 'alt': 'noroeste' }, 'noroeste'],
        [{ 'src': '../assets/img/activity/flechas_small/up_right.png', 'width': 30, 'height': 25, 'alt': 'noreste' }, 'noreste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_right.png', 'width': 30, 'height': 25, 'alt': 'sureste' }, 'sureste'],
        [{ 'src': '../assets/img/activity/flechas_small/down_left.png', 'width': 30, 'height': 25, 'alt': 'suroeste' }, 'suroeste']
      ]), "direccion")
      .appendField(traduccion2)
      .appendField(image)
      .appendField(new Blockly.FieldNumber(0, 1, 7), "valor");
    this.setColour(270);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip("");
  }
};

Blockly.JavaScript['caminocolor'] = function (block) {

  var code = ``;
  var currentBlock = '';

  var blockPosition = window.blocks.findIndex(element => element.id == block.id);


  if (block.inputList[0].fieldRow[1].value_ == "right2") {
    code += movePosition("right", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/derecha.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "left2") {
    code += movePosition("left", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/izquierda.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "up2") {
    code += movePosition("up", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/arriba.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "down2") {
    code += movePosition("down", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/frente.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "noroeste") {
    code += movePosition("northwest", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/izquierda.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "noreste") {
    code += movePosition("northeast", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/derecha.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "sureste") {
    code += movePosition("southeast", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/derecha.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "suroeste") {
    code += movePosition("southwest", 7, 1000, blockPosition, 75, false, block.inputList[0].fieldRow[4].value_, `../assets/img/personaje/izquierda.gif?a=${Math.random()}`, false);
  }


  return code;
}

Blockly.Blocks['stoptime'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    if(window.idioma === 'es'){
      traduccion = 'Espera 1 segundo';
      traduccion2 = 'Espera';
    }if(window.idioma !== 'es'){
      traduccion = 'wait 1 second';
      traduccion2 = 'Wait';
    }
    this.appendDummyInput()
      .appendField(traduccion);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(60);
    this.setTooltip(traduccion2);
  }
};
Blockly.JavaScript['stoptime'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);

  window.currentTime += 1000;

  return code;
}
Blockly.Blocks['direction_pix'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    var traduccion4 = '';
    if(window.idioma === 'es'){
      traduccion = 'Mover Hacia';
      traduccion2 = 'Adelante';
      traduccion3 = 'Atras';
      traduccion4 = 'Pixeles';
    }if(window.idioma !== 'es'){
      traduccion = 'Move to';
      traduccion2 = 'Ahead';
      traduccion3 = 'Back';
      traduccion4 = 'Pixels';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "Adelante"], [traduccion3, "Atras"]]), "direccion")
      .appendField(new Blockly.FieldDropdown([["73", "73"]]), "pixeles")
      .appendField(traduccion4);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(195);
    this.setTooltip("Mover");
    this.setHelpUrl("");
  }
};
Blockly.JavaScript['direction_pix'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var newOrientationTem = window.newOrientation;
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = ''; let checkOrder = false; let direction = '';
  let pixelsToMove = Number(block.inputList[0].fieldRow[2].value_);
  //alert(`${window.newOrientation}, ${window.lastTurn}`);
  window.sentido = block.inputList[0].fieldRow[1].value_;

  //revisamos si se requiere validar orden de los objetos
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;

  if (block.inputList[0].fieldRow[1].value_ == "Adelante") {
    if (window.lastTurn == 'Derecha') {
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE')
        direction = 'eastsoutheast';
      if (window.newOrientation == 'SSE')
        direction = 'southsoutheast';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW')
        direction = 'southsouthwest';
      if (window.newOrientation == 'WSW')
        direction = 'westsouthwest';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW')
        direction = 'westnorthwest';
      if (window.newOrientation == 'NNW')
        direction = 'northnorthwest';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE')
        direction = 'northnortheast';
      if (window.newOrientation == 'ENE')
        direction = 'eastnortheast';
    }
    if (window.lastTurn == '') {
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE')
        direction = 'eastsoutheast';
      if (window.newOrientation == 'SSE')
        direction = 'southsoutheast';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW')
        direction = 'southsouthwest';
      if (window.newOrientation == 'WSW')
        direction = 'westsouthwest';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW')
        direction = 'westnorthwest';
      if (window.newOrientation == 'NNW')
        direction = 'northnorthwest';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE')
        direction = 'northnortheast';
      if (window.newOrientation == 'ENE')
        direction = 'eastnortheast';
    }

    if (window.lastTurn == 'Izquierda') {
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE')
        direction = 'eastsoutheast';
      if (window.newOrientation == 'SSE')
        direction = 'southsoutheast';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW')
        direction = 'southsouthwest';
      if (window.newOrientation == 'WSW')
        direction = 'westsouthwest';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW')
        direction = 'westnorthwest';
      if (window.newOrientation == 'NNW')
        direction = 'northnorthwest';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE')
        direction = 'northnortheast';
      if (window.newOrientation == 'ENE')
        direction = 'eastnortheast';
    }
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurns(direction, 7, 500, blockPosition, Number(block.inputList[0].fieldRow[2].value_), false, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  if (block.inputList[0].fieldRow[1].value_ == "Atras") {

    if (window.lastTurn == 'Derecha') {

      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE')
        direction = 'eastsoutheast';
      if (window.newOrientation == 'SSE')
        direction = 'southsoutheast';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW')
        direction = 'southsouthwest';
      if (window.newOrientation == 'WSW')
        direction = 'westsouthwest';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW')
        direction = 'westnorthwest';
      if (window.newOrientation == 'NNW')
        direction = 'northnorthwest';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE')
        direction = 'northnortheast';
      if (window.newOrientation == 'ENE')
        direction = 'eastnortheast';


    }

    if (window.lastTurn == '') {
      if (window.newOrientation == 'FP')
        direction = 'up';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'DP')
        direction = 'left';
    }

    if (window.lastTurn == 'Izquierda') {

      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE')
        direction = 'eastsoutheast';
      if (window.newOrientation == 'SSE')
        direction = 'southsoutheast';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW')
        direction = 'southsouthwest';
      if (window.newOrientation == 'WSW')
        direction = 'westsouthwest';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW')
        direction = 'westnorthwest';
      if (window.newOrientation == 'NNW')
        direction = 'northnorthwest';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE')
        direction = 'northnortheast';
      if (window.newOrientation == 'ENE')
        direction = 'eastnortheast';
    }

    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurns(direction, 7, 500, blockPosition, pixelsToMove, false, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  window.lastTurn = '';

  //console.log('generado' + window.coordenada.slice(0, -1));
  //console.log('validacion' + window.blocksYesAllowed);
  return code;
}

Blockly.Blocks['direction_pix_pintar'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    var traduccion4 = '';
    if(window.idioma === 'es'){
      traduccion = 'Mover y Pintar';
      traduccion2 = 'Adelante';
      traduccion3 = 'Atras';
      traduccion4 = 'Pixeles';
    }if(window.idioma !== 'es'){
      traduccion = 'Move and Paint';
      traduccion2 = 'Forward';
      traduccion3 = 'Back';
      traduccion4 = 'Pixels';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "Adelante"], [traduccion3, "Atras"]]), "direccion")
      .appendField(new Blockly.FieldDropdown([["73", "73"]]), "pixeles")
      .appendField(traduccion4);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(195);
    this.setTooltip("Mover");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['direction_pix_pintar'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var newOrientationTem = window.newOrientation;
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = ''; let checkOrder = false; let direction = '';
  let pixelsToMove = Number(block.inputList[0].fieldRow[2].value_);
  //alert(`${window.newOrientation}, ${window.lastTurn}`);
  window.sentido = block.inputList[0].fieldRow[1].value_;
  console.log(window.newOrientation);
  //revisamos si se requiere validar orden de los objetos
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;

  if (block.inputList[0].fieldRow[1].value_ == "Adelante") {
    if (window.lastTurn == 'Derecha') {

      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }
    if (window.lastTurn == '') {
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }

    if (window.lastTurn == 'Izquierda') {

      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurns(direction, 7, 500, blockPosition, Number(block.inputList[0].fieldRow[2].value_), true, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  if (block.inputList[0].fieldRow[1].value_ == "Atras") {

    if (window.lastTurn == 'Derecha') {

      if (window.newOrientation == 'DP')
        direction = 'left';
      if (window.newOrientation == 'ENE15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'ENE30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'NE')
        direction = 'southwest';
      if (window.newOrientation == 'NNE60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'NNE75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'NNW75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'NNW60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'NW')
        direction = 'southeast';
      if (window.newOrientation == 'WNW30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'WNW15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'WSW15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'WSW30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SW')
        direction = 'northeast';
      if (window.newOrientation == 'SSW60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'SSW75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'FP')
        direction = 'up';

      if (window.newOrientation == 'SSE75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'SSE60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'SE')
        direction = 'northwest';
      if (window.newOrientation == 'ESE30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'ESE15')
        direction = 'westnorthwest15';

    }
    if (window.lastTurn == '') {

      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';

    }

    if (window.lastTurn == 'Izquierda') {
      if (window.newOrientation == 'DP')
        direction = 'left';
      if (window.newOrientation == 'ENE15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'ENE30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'NE')
        direction = 'southwest';
      if (window.newOrientation == 'NNE60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'NNE75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'NNW75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'NNW60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'NW')
        direction = 'southeast';
      if (window.newOrientation == 'WNW30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'WNW15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'WSW15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'WSW30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SW')
        direction = 'northeast';
      if (window.newOrientation == 'SSW60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'SSW75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'FP')
        direction = 'up';
      if (window.newOrientation == 'SSE75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'SSE60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'SE')
        direction = 'northwest';
      if (window.newOrientation == 'ESE30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'ESE15')
        direction = 'westnorthwest15';

    }

    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurns(direction, 7, 500, blockPosition, pixelsToMove, true, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  window.lastTurn = '';

  console.log('generado' + window.coordenada.slice(0, -1));
  //console.log('validacion' + window.blocksYesAllowed);
  //console.log('x: ' + window.positx + ' ' + 'y: ' + window.posity)
  return code;
}

Blockly.Blocks['direction_pix_pintar2'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    var traduccion4 = '';
    if(window.idioma === 'es'){
      traduccion = 'Mover y Pintar';
      traduccion2 = 'Adelante';
      traduccion3 = 'Atras';
      traduccion4 = 'Pixeles';
    }if(window.idioma !== 'es'){
      traduccion = 'Move and Paint';
      traduccion2 = 'Ahead';
      traduccion3 = 'Back';
      traduccion4 = 'Pixels';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "Adelante"], [traduccion3, "Atras"]]), "direccion")
      .appendField(new Blockly.FieldDropdown([["73", "73"]]), "pixeles")
      .appendField(traduccion4);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(195);
    this.setTooltip("Mover");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['direction_pix_pintar2'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var newOrientationTem = window.newOrientation;
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = ''; let checkOrder = false; let direction = '';
  let pixelsToMove = Number(block.inputList[0].fieldRow[2].value_);
  //alert(`${window.newOrientation}, ${window.lastTurn}`);
  window.sentido = block.inputList[0].fieldRow[1].value_;
  console.log(window.newOrientation);
  //revisamos si se requiere validar orden de los objetos
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;

  if (block.inputList[0].fieldRow[1].value_ == "Adelante") {
    if (window.lastTurn == 'Derecha') {

      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }
    if (window.lastTurn == '') {
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }

    if (window.lastTurn == 'Izquierda') {

      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurns(direction, 8, 500, blockPosition, Number(block.inputList[0].fieldRow[2].value_), true, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  if (block.inputList[0].fieldRow[1].value_ == "Atras") {

    if (window.lastTurn == 'Derecha') {

      if (window.newOrientation == 'DP')
        direction = 'left';
      if (window.newOrientation == 'ENE15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'ENE30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'NE')
        direction = 'southwest';
      if (window.newOrientation == 'NNE60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'NNE75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'NNW75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'NNW60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'NW')
        direction = 'southeast';
      if (window.newOrientation == 'WNW30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'WNW15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'WSW15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'WSW30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SW')
        direction = 'northeast';
      if (window.newOrientation == 'SSW60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'SSW75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'FP')
        direction = 'up';

      if (window.newOrientation == 'SSE75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'SSE60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'SE')
        direction = 'northwest';
      if (window.newOrientation == 'ESE30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'ESE15')
        direction = 'westnorthwest15';

    }
    if (window.lastTurn == '') {

      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';

    }

    if (window.lastTurn == 'Izquierda') {
      if (window.newOrientation == 'DP')
        direction = 'left';
      if (window.newOrientation == 'ENE15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'ENE30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'NE')
        direction = 'southwest';
      if (window.newOrientation == 'NNE60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'NNE75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'NNW75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'NNW60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'NW')
        direction = 'southeast';
      if (window.newOrientation == 'WNW30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'WNW15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'WSW15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'WSW30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SW')
        direction = 'northeast';
      if (window.newOrientation == 'SSW60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'SSW75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'FP')
        direction = 'up';
      if (window.newOrientation == 'SSE75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'SSE60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'SE')
        direction = 'northwest';
      if (window.newOrientation == 'ESE30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'ESE15')
        direction = 'westnorthwest15';

    }

    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurns(direction, 8, 500, blockPosition, pixelsToMove, true, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  window.lastTurn = '';

  console.log('generado' + window.coordenada.slice(0, -1));
  //console.log('validacion' + window.blocksYesAllowed);
  console.log('x: ' + window.positx + ' ' + 'y: ' + window.posity)
  return code;
}


Blockly.Blocks['direccion_pintar_variable'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Mover  y Pintar ")
      .appendField(new Blockly.FieldDropdown([["Adelante", "Adelante"], ["Atras", "Atras"]]), "direccion")
      .appendField(new Blockly.FieldTextInput("35"), "pixeles")
      .appendField("Pixeles");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['direccion_pintar_variable'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var newOrientationTem = window.newOrientation;
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = ''; let checkOrder = false; let direction = '';
  let pixelsToMove = Number(block.inputList[0].fieldRow[2].value_);
  //alert(`${window.newOrientation}, ${window.lastTurn}`);
  window.sentido = block.inputList[0].fieldRow[1].value_;
  //console.log(window.newOrientation);
  //revisamos si se requiere validar orden de los objetos
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;

  if (block.inputList[0].fieldRow[1].value_ == "Adelante") {
    if (window.lastTurn == 'Derecha') {

      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }
    if (window.lastTurn == '') {
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }

    if (window.lastTurn == 'Izquierda') {

      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurns(direction, 15, 500, blockPosition, Number(block.inputList[0].fieldRow[2].value_), true, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  if (block.inputList[0].fieldRow[1].value_ == "Atras") {

    if (window.lastTurn == 'Derecha') {
      /*
            if (window.newOrientation == 'FP')
              direction = 'down';
            if (window.newOrientation == 'IP')
              direction = 'left';
            if (window.newOrientation == 'EP')
              direction = 'up';
            if (window.newOrientation == 'DP')
              direction = 'right';
            if (window.newOrientation == 'SE')
              direction = 'southeast';
            if (window.newOrientation == 'ESE')
              direction = 'eastsoutheast';
            if (window.newOrientation == 'SSE')
              direction = 'southsoutheast';
            if (window.newOrientation == 'SW')
              direction = 'southwest';
            if (window.newOrientation == 'SSW')
              direction = 'southsouthwest';
            if (window.newOrientation == 'WSW')
              direction = 'westsouthwest';
            if (window.newOrientation == 'NW')
              direction = 'northwest';
            if (window.newOrientation == 'WNW')
              direction = 'westnorthwest';
            if (window.newOrientation == 'NNW')
              direction = 'northnorthwest';
            if (window.newOrientation == 'NE')
              direction = 'northeast';
            if (window.newOrientation == 'NNE')
              direction = 'northnortheast';
            if (window.newOrientation == 'ENE')
              direction = 'eastnortheast';
      *//*
            if (window.newOrientation == 'DP')
              direction = 'right';
            if (window.newOrientation == 'ENE15')
              direction = 'eastsoutheast15';
            if (window.newOrientation == 'ENE30')
              direction = 'eastsoutheast30';
            if (window.newOrientation == 'NE')
              direction = 'southeast';
            if (window.newOrientation == 'NNE60')
              direction = 'southsoutheast60';
            if (window.newOrientation == 'NNE75')
              direction = 'southsoutheast75';
            if (window.newOrientation == 'EP')
              direction = 'down';
            if (window.newOrientation == 'NNW75')
              direction = 'southsouthwest75';
            if (window.newOrientation == 'NNW60')
              direction = 'southsouthwest60';
            if (window.newOrientation == 'NW')
              direction = 'southwest';
            if (window.newOrientation == 'WNW30')
              direction = 'westsouthwest30';
            if (window.newOrientation == 'WNW15')
              direction = 'westsouthwest15';
            if (window.newOrientation == 'IP')
              direction = 'right';
            if (window.newOrientation == 'WSW15')
              direction = 'westnorthwest15';
            if (window.newOrientation == 'WSW30')
              direction = 'westnorthwest30';
            if (window.newOrientation == 'SW')
              direction = 'northwest';
            if (window.newOrientation == 'SSW60')
              direction = 'northnorthwest60';
            if (window.newOrientation == 'SSW75')
              direction = 'northnorthwest75';
            if (window.newOrientation == 'FP')
              direction = 'down';
            if (window.newOrientation == 'SSE75')
              direction = 'northnortheast75';
            if (window.newOrientation == 'SSE60')
              direction = 'northnortheast60';
            if (window.newOrientation == 'SE')
              direction = 'northeast';
            if (window.newOrientation == 'ESE30')
              direction = 'eastnortheast30';
            if (window.newOrientation == 'ESE15')
              direction = 'eastnortheast15';
              */
      if (window.newOrientation == 'DP')
        direction = 'left';
      if (window.newOrientation == 'ENE15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'ENE30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'NE')
        direction = 'southwest';
      if (window.newOrientation == 'NNE60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'NNE75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'NNW75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'NNW60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'NW')
        direction = 'southeast';
      if (window.newOrientation == 'WNW30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'WNW15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'WSW15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'WSW30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SW')
        direction = 'northeast';
      if (window.newOrientation == 'SSW60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'SSW75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'FP')
        direction = 'up';
      if (window.newOrientation == 'SSE75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'SSE60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'SE')
        direction = 'northwest';
      if (window.newOrientation == 'ESE30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'ESE15')
        direction = 'westnorthwest15';

    }

    if (window.lastTurn == '') {

      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';

    }

    if (window.lastTurn == 'Izquierda') {
      /*
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
*/
      /*
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE')
        direction = 'eastsoutheast';
      if (window.newOrientation == 'SSE')
        direction = 'southsoutheast';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW')
        direction = 'southsouthwest';
      if (window.newOrientation == 'WSW')
        direction = 'westsouthwest';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW')
        direction = 'westnorthwest';
      if (window.newOrientation == 'NNW')
        direction = 'northnorthwest';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE')
        direction = 'northnortheast';
      if (window.newOrientation == 'ENE')
        direction = 'eastnortheast';
        */
      if (window.newOrientation == 'DP')
        direction = 'left';
      if (window.newOrientation == 'ENE15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'ENE30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'NE')
        direction = 'southwest';
      if (window.newOrientation == 'NNE60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'NNE75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'NNW75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'NNW60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'NW')
        direction = 'southeast';
      if (window.newOrientation == 'WNW30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'WNW15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'WSW15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'WSW30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SW')
        direction = 'northeast';
      if (window.newOrientation == 'SSW60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'SSW75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'FP')
        direction = 'up';
      if (window.newOrientation == 'SSE75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'SSE60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'SE')
        direction = 'northwest';
      if (window.newOrientation == 'ESE30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'ESE15')
        direction = 'westnorthwest15';

    }

    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurns(direction, 15, 500, blockPosition, pixelsToMove, true, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  window.lastTurn = '';

  console.log('generado' + window.coordenada.slice(0, -1));
  //console.log('validacion' + window.blocksYesAllowed);
  return code;
}


Blockly.Blocks['block_color2'] = {
    init: function () {
      var field = new Blockly.FieldColour('#ff4040');
      field.setColours(
        ['#403FFF', '#40FF3F', '#ff4040', '#FFC616'],
        ['Blue', 'Green', 'Red', 'Yellow']);
      field.setColumns(3);
      this.appendDummyInput()
        .appendField('Color:')
        .appendField(field, 'COLOUR');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(255);
    }
  };

Blockly.JavaScript['block_color2'] = function (block) {
  var code = '';
  var grado = '';
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  window.typeColor = block.inputList[0].fieldRow[1].value_;

  return code;
}

Blockly.Blocks['direction_grado'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    var traduccion4 = '';
    if(window.idioma === 'es'){
      traduccion = 'Girar Hacia';
      traduccion2 = 'Derecha';
      traduccion3 = 'Izquierda';
      traduccion4 = 'Grados';
    }if(window.idioma !== 'es'){
      traduccion = 'Turn Towards';
      traduccion2 = 'Right';
      traduccion3 = 'Left';
      traduccion4 = 'Degrees';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "Derecha"], [traduccion3, "Izquierda"]]), "direccion")
      .appendField(new Blockly.FieldAngle(90), "grado")
      .appendField(traduccion4);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(195);
    this.setTooltip("Girar");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['direction_grado'] = function (block) {

  var code = '';
  var grado = '';
  let validDirection = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  window.totalDegrees += calculateDirection(window.newOrientation, block.inputList[0].fieldRow[2].value_, block.inputList[0].fieldRow[1].value_);
  //alert(`grados ${window.newOrientation}`);
  code += `
      setTimeout(function(){
        window.blocks[${position}].select();
        $("#character3").css("transform",'rotate(${window.totalDegrees}deg)');
        $("#character3").css("transition",'transform 0.5s ease-out');
      }, ${currentTime});`;
  window.currentTime += 500;
  return code;
};


//flor
Blockly.Blocks['direction_grado_dos'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    var traduccion4 = '';
    if(window.idioma === 'es'){
      traduccion = 'Girar Hacia';
      traduccion2 = 'Derecha';
      traduccion3 = 'Izquierda';
      traduccion4 = 'Grados';
    }if(window.idioma !== 'es'){
      traduccion = 'Turn Towards';
      traduccion2 = 'Right';
      traduccion3 = 'Left';
      traduccion4 = 'Degrees';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "Derecha"], [traduccion3, "Izquierda"]]), "direccion")
      .appendField(new Blockly.FieldAngle(90), "grado")
      .appendField(traduccion4);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(195);
    this.setTooltip("Girar");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['direction_grado_dos'] = function (block) {

  var code = '';
  var grado = '';
  let validDirection = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  currentTime = parseInt(currentTime) + parseInt(1000);
  window.totalDegrees += calculateDirectionDos(window.newOrientation, block.inputList[0].fieldRow[2].value_, block.inputList[0].fieldRow[1].value_);
  //alert(`grados ${window.newOrientation}`);
  code += `
      setTimeout(function(){
        window.blocks[${position}].select();
        $("#character3").css("transform",'rotate(${window.totalDegrees}deg)');
        $("#character3").css("transition",'transform 0.5s ease-out');
      }, ${currentTime});`;
  window.currentTime += 500;
  //console.log('en grados   ' + window.newOrientation);
  return code;
};


Blockly.Blocks['direction_pix_pintar_dos'] = {
  init: function () {
    var traduccion = '';
    var traduccion2 = '';
    var traduccion3 = '';
    var traduccion4 = '';
    if(window.idioma === 'es'){
      traduccion = 'Mover y Pintar';
      traduccion2 = 'Adelante';
      traduccion3 = 'Atras';
      traduccion4 = 'Pixeles';
    }if(window.idioma !== 'es'){
      traduccion = 'Move and Paint';
      traduccion2 = 'Ahead';
      traduccion3 = 'Back';
      traduccion4 = 'Pixels';
    }
    this.appendDummyInput()
      .appendField(traduccion)
      .appendField(new Blockly.FieldDropdown([[traduccion2, "Adelante"], [traduccion3, "Atras"]]), "direccion")
      .appendField(new Blockly.FieldDropdown([["73", "73"]]), "pixeles")
      .appendField(traduccion4);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(195);
    this.setTooltip("Mover");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['direction_pix_pintar_dos'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var newOrientationTem = window.newOrientation;
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = ''; let checkOrder = false; let direction = '';
  let pixelsToMove = Number(block.inputList[0].fieldRow[2].value_);
  //alert(`${window.newOrientation}, ${window.lastTurn}`);
  window.sentido = block.inputList[0].fieldRow[1].value_;
  //console.log(window.newOrientation);
  //revisamos si se requiere validar orden de los objetos
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;

  if (block.inputList[0].fieldRow[1].value_ == "Adelante") {
    if (window.lastTurn == 'Derecha') {

      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }
    if (window.lastTurn == '') {
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }

    if (window.lastTurn == 'Izquierda') {

      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'IP')
        direction = 'left';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
    }
    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurnsDos(direction, 7, 500, blockPosition, Number(block.inputList[0].fieldRow[2].value_), true, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  if (block.inputList[0].fieldRow[1].value_ == "Atras") {

    if (window.lastTurn == 'Derecha') {

      if (window.newOrientation == 'DP')
        direction = 'left';
      if (window.newOrientation == 'ENE15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'ENE30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'NE')
        direction = 'southwest';
      if (window.newOrientation == 'NNE60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'NNE75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'NNW75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'NNW60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'NW')
        direction = 'southeast';
      if (window.newOrientation == 'WNW30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'WNW15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'WSW15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'WSW30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SW')
        direction = 'northeast';
      if (window.newOrientation == 'SSW60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'SSW75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'FP')
        direction = 'up';

      if (window.newOrientation == 'SSE75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'SSE60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'SE')
        direction = 'northwest';
      if (window.newOrientation == 'ESE30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'ESE15')
        direction = 'westnorthwest15';

    }
    if (window.lastTurn == '') {

      if (window.newOrientation == 'NW')
        direction = 'northwest';
      if (window.newOrientation == 'EP')
        direction = 'up';
      if (window.newOrientation == 'NE')
        direction = 'northeast';
      if (window.newOrientation == 'DP')
        direction = 'right';
      if (window.newOrientation == 'SE')
        direction = 'southeast';
      if (window.newOrientation == 'FP')
        direction = 'down';
      if (window.newOrientation == 'SW')
        direction = 'southwest';
      if (window.newOrientation == 'WNW30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'WNW15')
        direction = 'westnorthwest15';
      if (window.newOrientation == 'NNW75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'NNW60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'NNE60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'NNE75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'ESE30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'ESE15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'SSE75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'SSE60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'ENE15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'ENE30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SSW60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'SSW75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'WSW15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'WSW30')
        direction = 'westsouthwest30';

    }

    if (window.lastTurn == 'Izquierda') {
      if (window.newOrientation == 'DP')
        direction = 'left';
      if (window.newOrientation == 'ENE15')
        direction = 'westsouthwest15';
      if (window.newOrientation == 'ENE30')
        direction = 'westsouthwest30';
      if (window.newOrientation == 'NE')
        direction = 'southwest';
      if (window.newOrientation == 'NNE60')
        direction = 'southsouthwest60';
      if (window.newOrientation == 'NNE75')
        direction = 'southsouthwest75';
      if (window.newOrientation == 'EP')
        direction = 'down';
      if (window.newOrientation == 'NNW75')
        direction = 'southsoutheast75';
      if (window.newOrientation == 'NNW60')
        direction = 'southsoutheast60';
      if (window.newOrientation == 'NW')
        direction = 'southeast';
      if (window.newOrientation == 'WNW30')
        direction = 'eastsoutheast30';
      if (window.newOrientation == 'WNW15')
        direction = 'eastsoutheast15';
      if (window.newOrientation == 'IP')
        direction = 'right';
      if (window.newOrientation == 'WSW15')
        direction = 'eastnortheast15';
      if (window.newOrientation == 'WSW30')
        direction = 'eastnortheast30';
      if (window.newOrientation == 'SW')
        direction = 'northeast';
      if (window.newOrientation == 'SSW60')
        direction = 'northnortheast60';
      if (window.newOrientation == 'SSW75')
        direction = 'northnortheast75';
      if (window.newOrientation == 'FP')
        direction = 'up';
      if (window.newOrientation == 'SSE75')
        direction = 'northnorthwest75';
      if (window.newOrientation == 'SSE60')
        direction = 'northnorthwest60';
      if (window.newOrientation == 'SE')
        direction = 'northwest';
      if (window.newOrientation == 'ESE30')
        direction = 'westnorthwest30';
      if (window.newOrientation == 'ESE15')
        direction = 'westnorthwest15';

    }

    //si se ocupa el crayolin
    if (window.avatarInitialGif.includes('crayolin'))
      avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
    else
      avatarMoveGif = `../assets/img/personaje/robot/derecha.gif?a=${Math.random()}`;
    code += movePositionWithTurnsDos(direction, 7, 500, blockPosition, pixelsToMove, true, 1, avatarMoveGif, checkOrder, window.lastTurn);
  }

  window.lastTurn = '';

  console.log('generado' + window.coordenada.slice(0, -1));
  //console.log('validacion' + window.blocksYesAllowed);
  //console.log('x: ' + window.positx + ' ' + 'y: ' + window.posity)
  return code;
}


Blockly.Blocks.sihay = {
  init: function () {
    var a = [["Enojada", "enojada"], ["Alegre", "alegre"]];
    this.setColour(180);
    this.appendValueInput("BOOL").setCheck("Boolean").appendField('mientras').appendField(new Blockly.FieldDropdown(a), "MODE");
    this.appendStatementInput("DO").appendField("hacer");
    this.setPreviousStatement(!0);
    this.setNextStatement(!0);
  }
};

Blockly.Blocks['emojis2'] = {
  init: function () {
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setColour(310);
    var input = this.appendDummyInput()
      .appendField('Si hay');
    var options = [
      [{ 'src': '../assets/gifs/nuevos/enojado_72x72.png', 'width': 30, 'height': 25, 'alt': 'enojado' }, 'enojado'],
      [{ 'src': '../assets/gifs/nuevos/alegre_72x72.png', 'width': 30, 'height': 25, 'alt': 'alegre' }, 'alegre'],
      [{ 'src': '../assets/gifs/nuevos/triste_72x72.png', 'width': 30, 'height': 25, 'alt': 'triste' }, 'triste'],
      [{ 'src': '../assets/gifs/nuevos/feliz_72x72.png', 'width': 30, 'height': 25, 'alt': 'feliz' }, 'faliz']
    ];
    input.appendField(new Blockly.FieldDropdown(options), 'color');

  }
};

Blockly.Blocks['caminauno'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/saltar.png', 30, 30, '*');
    this.appendDummyInput()
      .appendField("Dirección")
      .appendField(new Blockly.FieldDropdown([
        [{ 'src': '../assets/img/activity/leftM.png', 'width': 30, 'height': 25, 'alt': 'left2' }, 'left2'],//izq
        [{ 'src': '../assets/img/activity/rightM.png', 'width': 30, 'height': 25, 'alt': 'right2' }, 'right2'],//der
        [{ 'src': '../assets/img/activity/upM.png', 'width': 30, 'height': 25, 'alt': 'up2' }, 'up2'],
        [{ 'src': '../assets/img/activity/downM.png', 'width': 30, 'height': 25, 'alt': 'down2' }, 'down2'],
        [{ 'src': '../assets/img/activity/no.png', 'width': 30, 'height': 25, 'alt': 'noroeste' }, 'noroeste'],
        [{ 'src': '../assets/img/activity/ne.png', 'width': 30, 'height': 25, 'alt': 'noreste' }, 'noreste'],
        [{ 'src': '../assets/img/activity/se.png', 'width': 30, 'height': 25, 'alt': 'sureste' }, 'sureste'],
        [{ 'src': '../assets/img/activity/so.png', 'width': 30, 'height': 25, 'alt': 'suroeste' }, 'suroeste']
      ]), "direccion")
      .appendField("Caminar")
      .appendField(image)

    this.setColour(270);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip("");
  }
};

Blockly.JavaScript['caminauno'] = function (block) {

  var code = ``;
  var currentBlock = '';

  var blockPosition = window.blocks.findIndex(element => element.id == block.id);


  if (block.inputList[0].fieldRow[1].value_ == "right2") {
    code += movePosition("right", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/derecha.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "left2") {
    code += movePosition("left", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/izquierda.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "up2") {
    code += movePosition("up", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/arriba.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "down2") {
    code += movePosition("down", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/frente.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "noroeste") {
    code += movePosition("northwest", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/izquierda.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "noreste") {
    code += movePosition("northeast", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/derecha.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "sureste") {
    code += movePosition("southeast", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/derecha.gif?a=${Math.random()}`, false);
  }

  if (block.inputList[0].fieldRow[1].value_ == "suroeste") {
    code += movePosition("southwest", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/izquierda.gif?a=${Math.random()}`, false);
  }


  return code;
}


Blockly.Blocks['pza_do'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("      Do     ");

    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#e80000");
    this.setTooltip("Do");
  }
};

Blockly.JavaScript['pza_do'] = function (block) {
  let code = '';
  currentTime += parseFloat(1) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${1});}, ${currentTime + 200});
  `;
 
  window.coordenada = window.coordenada.concat(`${1}|`);
  let cadenaVal = window.coordenada;
  if (window.question_id == 26333) {
    let textoAreaDividido = cadenaVal.split("|");
    let total = textoAreaDividido.length;
    if ((total-1)>=5) {
      Fire.$emit('activityDone');
    }
   }
  if (window.question_id == 26334 || window.question_id == 26335 || window.question_id == 26336) { 
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
      Fire.$emit('activityDone');
    }
  }

  //console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}


Blockly.Blocks['pza_re'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("      Re     ");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#ff6600");
    this.setTooltip("Re");
  }
};

Blockly.JavaScript['pza_re'] = function (block) {
  let code = '';
  currentTime += parseFloat(1) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${2});}, ${currentTime + 200});
  `;
  window.coordenada = window.coordenada.concat(`${2}|`);
  let cadenaVal = window.coordenada;
  if (window.question_id == 26333) {
    let textoAreaDividido = cadenaVal.split("|");
    let total = textoAreaDividido.length;
    if ((total - 1) >= 5) {
      Fire.$emit('activityDone');
    }
  }
  if (window.question_id == 26334 || window.question_id == 26335 || window.question_id == 26336) { 
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
      Fire.$emit('activityDone');
    }
  }
  //console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}

Blockly.Blocks['pza_mi'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("      Mi      ");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#ffcc00");
    this.setTooltip("Mi");
  }
};

Blockly.JavaScript['pza_mi'] = function (block) {
  let code = '';
  currentTime += parseFloat(1) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${3});}, ${currentTime + 200});
  `;
  window.coordenada = window.coordenada.concat(`${3}|`);
  let cadenaVal = window.coordenada;
  if (window.question_id == 26333) {
    let textoAreaDividido = cadenaVal.split("|");
    let total = textoAreaDividido.length;
    if ((total - 1) >= 5) {
      Fire.$emit('activityDone');
    }
  }
  if (window.question_id == 26334 || window.question_id == 26335 || window.question_id == 26336) { 
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
      Fire.$emit('activityDone');
    }
  }
  //console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}

Blockly.Blocks['pza_fa'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("      Fa     ");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#2cb725");
    this.setTooltip("Fa");
  }
};

Blockly.JavaScript['pza_fa'] = function (block) {
  let code = '';
  currentTime += parseFloat(1) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${4});}, ${currentTime + 200});
  `;
  window.coordenada = window.coordenada.concat(`${4}|`);
  let cadenaVal = window.coordenada;
  if (window.question_id == 26333) {
    let textoAreaDividido = cadenaVal.split("|");
    let total = textoAreaDividido.length;
    if ((total - 1) >= 5) {
      Fire.$emit('activityDone');
    }
  }
  if (window.question_id == 26334 || window.question_id == 26335 || window.question_id == 26336) { 
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
      Fire.$emit('activityDone');
    }
  }
  //console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}


Blockly.Blocks['pza_sol'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("      Sol    ");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#00b7a8");
    this.setTooltip("Sol");
  }
};

Blockly.JavaScript['pza_sol'] = function (block) {
  let code = '';
  currentTime += parseFloat(1) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${5});}, ${currentTime + 200});
  `;
  window.coordenada = window.coordenada.concat(`${5}|`);
  let cadenaVal = window.coordenada;
  if (window.question_id == 26333) {
    let textoAreaDividido = cadenaVal.split("|");
    let total = textoAreaDividido.length;
    if ((total - 1) >= 5) {
      Fire.$emit('activityDone');
    }
  }
  if (window.question_id == 26334 || window.question_id == 26335 || window.question_id == 26336) { 
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
      Fire.$emit('activityDone');
    }
  }
 // console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}

Blockly.Blocks['pza_la'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("      La     ");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#008ffc");
    this.setTooltip("La");
  }
};

Blockly.JavaScript['pza_la'] = function (block) {
  let code = '';
  currentTime += parseFloat(1) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${6});}, ${currentTime + 200});
  `;
  window.coordenada = window.coordenada.concat(`${6}|`);
  let cadenaVal = window.coordenada;
  if (window.question_id == 26333) {
    let textoAreaDividido = cadenaVal.split("|");
    let total = textoAreaDividido.length;
    if ((total - 1) >= 5) {
      Fire.$emit('activityDone');
    }
  }
  if (window.question_id == 26334 || window.question_id == 26335 || window.question_id == 26336) { 
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
      Fire.$emit('activityDone');
    }
  }
  //console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}

Blockly.Blocks['pza_si'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("      Si      ");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#3149fc");
    this.setTooltip("Si");
  }
};

Blockly.JavaScript['pza_si'] = function (block) {
  let code = '';
  currentTime += parseFloat(1) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${7});}, ${currentTime + 200});
  `;
  window.coordenada = window.coordenada.concat(`${7}|`);
  let cadenaVal = window.coordenada;
  if (window.question_id == 26333) {
    let textoAreaDividido = cadenaVal.split("|");
    let total = textoAreaDividido.length;
    if ((total - 1) >= 5) {
      Fire.$emit('activityDone');
    }
  }
  if (window.question_id == 26334 || window.question_id == 26335 || window.question_id == 26336) { 
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
      Fire.$emit('activityDone');
    }
  }
  //console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}

Blockly.Blocks['pza_do2'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("      Do     ");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#6621fc");
    this.setTooltip("Do");
  }
};

Blockly.JavaScript['pza_do2'] = function (block) {
  let code = '';
  currentTime += parseFloat(1) * 1000;
  let position = window.blocks.findIndex(element => element.id == block.id);
  code = `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); `
  code += `
    setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.rola(${8});}, ${currentTime + 200});
  `;
  window.coordenada = window.coordenada.concat(`${8}|`);
  let cadenaVal = window.coordenada;
  if (window.question_id == 26333) {
    let textoAreaDividido = cadenaVal.split("|");
    let total = textoAreaDividido.length;
    if ((total - 1) >= 5) {
      Fire.$emit('activityDone');
    }
  }
  if (window.question_id == 26334 || window.question_id == 26335 || window.question_id == 26336) { 
    if (window.coordenada.slice(0, -1) == window.blocksYesAllowed) {
      Fire.$emit('activityDone');
    }
  }
  //console.log(window.coordenada.slice(0, -1) + '==' + window.blocksYesAllowed);
  return code;
}

// nuevas direcciones 
Blockly.Blocks['left69'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flechas_small/flecha_lapiz_oeste.png', 40, 40, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Oeste");
    //this.setPrototipe.addSelect();
  }
};
Blockly.JavaScript['left69'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;

  if (window.avatarInitialGif.includes('crayolin'))
    avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
  else
    avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
  code += movePosition("left", 7, 1000, blockPosition, 75, true, 1, avatarMoveGif, checkOrder);
  console.log('x: ' + window.positx + ' ' + 'y: ' + window.posity)
  return code;
}

Blockly.Blocks['right69'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flechas_small/flecha_lapiz_este.png', 40, 40, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Este");
  }

};
Blockly.JavaScript['right69'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;
  
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;

  if (window.avatarInitialGif.includes('crayolin'))
    avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
  else
    avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;

  code += movePosition("right", 7, 1000, blockPosition, 75, true, 1, avatarMoveGif, checkOrder);
  console.log('x: ' + window.positx + ' ' + 'y: ' + window.posity)
  return code;
}

Blockly.Blocks['up69'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flechas_small/flecha_lapiz_norte.png', 40, 40, '*');
    //this.appendDummyInput().appendField('Arriba');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Norte");
  }
};
Blockly.JavaScript['up69'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;
  if (window.avatarInitialGif.includes('crayolin'))
    avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_arriba_verde.gif?a=${Math.random()}`;
  else
    avatarMoveGif = `../assets/img/personaje/arriba.gif?a=${Math.random()}`;
  code += movePosition("up", 7, 1000, blockPosition, 75, true, 1, avatarMoveGif, checkOrder);
  console.log('x: ' + window.positx + ' ' + 'y: ' + window.posity)
  return code;
}

Blockly.Blocks['down69'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flechas_small/flecha_lapiz_sur.png', 40, 40, '*');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Sur");
  }
};
Blockly.JavaScript['down69'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;
  if (window.avatarInitialGif.includes('crayolin'))
    avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_frente_verde.gif?a=${Math.random()}`;
  else
    avatarMoveGif = `../assets/img/personaje/frente.gif?a=${Math.random()}`;
  code += movePosition("down", 7, 1000, blockPosition, 75, true, 1, avatarMoveGif, checkOrder);
  console.log('x: ' + window.positx + ' ' + 'y: ' + window.posity)
  return code;
}

Blockly.Blocks['noroeste69'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flechas_small/flecha_lapiz_noroeste.png', 40, 40, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Noroeste");
    //this.setPrototipe.addSelect();
  }
};
Blockly.JavaScript['noroeste69'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;
  if (window.avatarInitialGif.includes('crayolin'))
    avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
  else
    avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
  code += movePosition("northwest", 7, 1000, blockPosition, 75, true, 1, avatarMoveGif, checkOrder);
  return code;
}

Blockly.Blocks['sureste69'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flechas_small/flecha_lapiz_sureste.png', 40, 40, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Sureste");
    //this.setPrototipe.addSelect();
  }
};
Blockly.JavaScript['sureste69'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;
  if (window.avatarInitialGif.includes('crayolin'))
    avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
  else
    avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;
  code += movePosition("southeast", 7, 1000, blockPosition, 75, true, 1, avatarMoveGif, checkOrder);
  return code;
}

Blockly.Blocks['suroeste69'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flechas_small/flecha_lapiz_suroeste.png', 40, 40, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Suroeste");
    //this.setPrototipe.addSelect();
  } 
};
Blockly.JavaScript['suroeste69'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;
  if (window.avatarInitialGif.includes('crayolin'))
    avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_izquierda_verde.gif?a=${Math.random()}`;
  else
    avatarMoveGif = `../assets/img/personaje/izquierda.gif?a=${Math.random()}`;
  code += movePosition("southwest", 7, 1000, blockPosition, 75, true, 1, avatarMoveGif, checkOrder);
  return code;
}

Blockly.Blocks['noreste69'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/flechas_small/flecha_lapiz_noreste.png', 40, 40, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Noreste");
    //this.setPrototipe.addSelect();
  }
};
Blockly.JavaScript['noreste69'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  let avatarMoveGif = '';
  let checkOrder = false;
  if (window.blocksYesAllowed != '' && window.blocksYesAllowed != null && window.blocksYesAllowed != undefined)
    checkOrder = true;
  if (window.avatarInitialGif.includes('crayolin'))
    avatarMoveGif = `../assets/img/personaje/crayolin/crayolin_derecha_verde.gif?a=${Math.random()}`;
  else
    avatarMoveGif = `../assets/img/personaje/derecha.gif?a=${Math.random()}`;
  code += movePosition("northeast", 7, 1000, blockPosition, 75, true, 1, avatarMoveGif, checkOrder);
  return code;
}


/// nuevas


Blockly.Blocks['upPut'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/up.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Arriba');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Norte");
  }
};
Blockly.JavaScript['upPut'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  code += movePosition("up", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/arriba.gif?a=${Math.random()}`, false);
  return code;
}

Blockly.Blocks['downPut'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/down.png', 30, 30, '*');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Sur");
  }
};
Blockly.JavaScript['downPut'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  code += movePosition("down", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/frente.gif?a=${Math.random()}`, false);
  return code;
}


Blockly.Blocks['leftPut'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/left.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Izquierda');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Oeste");
    //this.setPrototipe.addSelect();
  }
};

Blockly.JavaScript['leftPut'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  code += movePosition("left", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/izquierda.gif?a=${Math.random()}`, false);
  return code;
}

Blockly.Blocks['rightPut'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/img/activity/right.png', 30, 30, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Este");
  }
};

Blockly.JavaScript['rightPut'] = function (block) {
  var code = ``;
  var currentBlock = '';
  var blockPosition = window.blocks.findIndex(element => element.id == block.id);
  code += movePosition("right", 7, 1000, blockPosition, 75, false, 1, `../assets/img/personaje/derecha.gif?a=${Math.random()}`, false);
  console.log('x: ' + window.positx + ' ' + 'y: ' + window.posity)
  return code;
}

Blockly.Blocks['CAzul'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/gifs/geometricas/azul.gif', 30, 30, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Este");
  }
};

Blockly.JavaScript['CAzul'] = function (block) {
  var code = ``;
  var imagens = ``;
  var nActiviti = ``;
  var goalT = ``;
  var cont = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  let indice = window.figeometrico.indexOf("_");
  let figura = window.figeometrico.substring(0, indice);
  var Fig = '';
  if (figura == "triangulo") { Fig = 1; }
  if (figura == "cuadrado") { Fig = 2 }
  if (figura == "circulo") { Fig = 3; }
  imagens = 1;
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.imgs(${imagens},${window.namediv},${Fig});}, ${currentTime}); `;
  window.coordenada = window.coordenada.substring(0, window.coordenada.length - 1) + ',#403fff' + '|';
  window.currentTime += 1000;
  //console.log("Extraída: ", figura);
  console.log(window.coordenada);
  window.checkColors = true;
  return code;
}

Blockly.Blocks['CRojo'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/gifs/geometricas/rojo.gif', 30, 30, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Este");
  }
};

Blockly.JavaScript['CRojo'] = function (block) {
  var code = ``;
  var imagens = ``;
  var nActiviti = ``;
  var goalT = ``;
  var cont = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  let indice = window.figeometrico.indexOf("_");
  let figura = window.figeometrico.substring(0, indice);
  var Fig = '';
  if (figura == "triangulo") { Fig = 1; }
  if (figura == "cuadrado") { Fig = 2 }
  if (figura == "circulo") { Fig = 3; }
  imagens = 3;
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.imgs(${imagens},${window.namediv},${Fig});}, ${currentTime}); `;
  window.coordenada = window.coordenada.substring(0, window.coordenada.length - 1) + ',#ff4040' + '|';
  window.currentTime += 1000;
  //console.log("Extraída: ", figura);
  //console.log(window.coordenada);
  window.checkColors = true;
  return code;
}

Blockly.Blocks['CAmartillo'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/gifs/geometricas/amarillo.gif', 30, 30, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Este");
  }
};

Blockly.JavaScript['CAmartillo'] = function (block) {
  var code = ``;
  var imagens = ``;
  var nActiviti = ``;
  var goalT = ``;
  var cont = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  let indice = window.figeometrico.indexOf("_");
  let figura = window.figeometrico.substring(0, indice);
  var Fig = '';
  if (figura == "triangulo") { Fig = 1; }
  if (figura == "cuadrado") { Fig = 2 }
  if (figura == "circulo") { Fig = 3; }
  imagens = 4;
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.imgs(${imagens},${window.namediv},${Fig});}, ${currentTime}); `;
  window.coordenada = window.coordenada.substring(0, window.coordenada.length - 1) + ',#ffc616' + '|';
  window.currentTime += 1000;
  //console.log("Extraída: ", figura);
  console.log(window.coordenada);
  window.checkColors = true;
  return code;
}

Blockly.Blocks['CVerde'] = {
  init: function () {
    var image = new Blockly.FieldImage('../assets/gifs/geometricas/verde.gif', 30, 30, '*');
    //this.appendDummyInput().appendField('Derecha');
    this.setColour(120);
    this.appendDummyInput().appendField(image);
    this.setPreviousStatement(true, 'Field');
    this.setNextStatement(true, 'Field');
    this.setHelpUrl(Blockly.Msg.TEXT_PRINT_HELPURL);
    this.setTooltip("Este");
  }
};

Blockly.JavaScript['CVerde'] = function (block) {
  var code = ``;
  var imagens = ``;
  var nActiviti = ``;
  var goalT = ``;
  var cont = 0;
  var position = window.blocks.findIndex(element => element.id == block.id);
  let indice = window.figeometrico.indexOf("_");
  let figura = window.figeometrico.substring(0, indice);
  var Fig = '';
  if (figura == "triangulo") { Fig = 1; }
  if (figura == "cuadrado") { Fig = 2 }
  if (figura == "circulo") { Fig = 3; }
  imagens = 2;
  code += `setTimeout(function(){window.blocks[${position}].select();}, ${currentTime}); setTimeout(function(){window.imgs(${imagens},${window.namediv},${Fig});}, ${currentTime}); `;
  window.coordenada = window.coordenada.substring(0, window.coordenada.length - 1) + ',#40ff3f' + '|';
  window.currentTime += 1000;
  //console.log("Extraída: ", figura);
  //console.log(window.coordenada);
  window.checkColors = true;
  return code;
}

