<template>
    <div class="header-container fixed-top">
        <header class="header navbar navbar-expand-sm">
            <div class="theme-brand-name">
                <router-link to="/worlds" v-if="$route.query.single_activity != 1"><img src="assets/img/SmartC3_logo.png"></router-link>
                <img src="assets/img/SmartC3_logo.png" v-if="$route.query.single_activity == 1">
            </div>
            <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg></a>
            <nav aria-label="breadcrumb" v-show="checkRoute()">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link :to="'/cities?world_id='+ world_id +'&world_name=' + world" v-if="$route.query.single_activity != 1">{{ world }}</router-link>
                        <a href="#"  v-if="$route.query.single_activity == 1">{{ world }}</a>
                    </li>
                    <li class="breadcrumb-item"><a href="#">{{ subWorld }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('text.levelText') }} {{ activity_number }}</li>
                </ol>
            </nav>
            <div class="mt-4" v-show="checkRoute() && $route.query.single_activity != 1">
                <ul class="pagination-custom text-center">
                    <li class="pag-link"><a href="#" data-placement="bottom" title="Anterior"><i class="fa fa-chevron-left"></i></a></li>
                    <template v-for="(activity, index) in activities">
                        <li class="pag-link current" title="2" :key="activity.id" v-if="activity.question_id == $route.query.idQuestion"><span>{{index + 1}}</span></li>
                        <li class="pag-link current" :key="activity.id" v-if="activity.question_id != $route.query.idQuestion && activity.solved.length == 0"><a :href="`${activity.route}?idQuestion=${activity.question_id}&subworld_id=${activity.subworld_id}&multiple=${activity.multiple}&activityType=${$route.query.activityType}`" class="bs-tooltip" data-placement="bottom" :title="activity.activity_number"><i class="fa fa-lock"></i></a></li>
                        <li class="pag-link disabled" title="1" :key="'solved' + activity.id" v-if="activity.solved.length > 0 && activity.question_id != $route.query.idQuestion"><a :href="`${activity.route}?idQuestion=${activity.question_id}&subworld_id=${activity.subworld_id}&multiple=${activity.multiple}&activityType=${$route.query.activityType}`" class="bs-tooltip" data-placement="bottom" :title="activity.activity_number"><i class="fa fa-check"></i></a></li>
                    </template>
                    <li class="pag-link"><a href="#" data-placement="bottom" title="Siguiente"><i class="fa fa-chevron-right"></i></a></li>
                    <!--<li class="pag-link disabled" title="1"><span><i class="fa fa-check"></i></span></li>
                    <li class="pag-link current" title="2"><span>2</span></li>
                    <li class="pag-link current" v-for="activity in activities" :key="activity.id"><router-link :to="'/activity?idQuestion=' + activity.question_id" class="bs-tooltip" data-placement="bottom" title="3"><i class="fa fa-lock"></i></router-link></li>-->

                </ul>
            </div>
            <ul class="navbar-item flex-row navbar-dropdown" v-if="$route.query.single_activity != 1">
                <li class="nav-item dropdown notification-dropdown" v-if="iRol == 6">
                    <router-link to="/assign-activities" class="nav-link" title="Dosificación">
                       <img src="assets/img/dosificacion_icon.png">
                    </router-link>
                </li>
                <li class="nav-item dropdown notification-dropdown" v-if="iRol == 3">
                    <router-link to="/teacher-panel" class="nav-link" title="Mi panel">
                       <img src="assets/img/recursos_docente_icon.png">
                    </router-link>
                </li>
                <li class="nav-item dropdown notification-dropdown">
                    <router-link to="/progress-report" class="nav-link" title="Mis reportes">
                       <img src="assets/img/reporte_icon.png">
                    </router-link>
                </li> 
                <li class="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                    <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i> <img src="assets/img/avatar_profile.png"></i>
                    </a>
                    <div class="dropdown-menu position-absolute animated fadeInUp" aria-labelledby="userProfileDropdown">
                        <div class="user-profile-section">
                            <div class="media mx-auto">
                                <img src="assets/img/90x90.jpg" class="img-fluid mr-2" alt="avatar">
                                <div class="media-body">
                                    <h5>{{ sName }}</h5>
                                    <p>{{ rol }}</p>
                                </div>
                            </div>
                        </div>
                        <!--<div class="dropdown-item">
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span>Mi cuenta</span>
                            </a>
                        </div>
                        -->
                        <div class="dropdown-item">
                            <a href="javascript:void(0);" @click.prevent="changeLanguage(language.code)" v-for="language in languages" :key="language.id">
                                <i class="fa fa-language"></i> <span>{{ language.name }}</span>
                            </a>
                        </div>
                        <div class="dropdown-item" v-if="!useTokenEtcSmartC3">
                            <a href="#" @click.prevent="logout">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> <span>{{ $t("text.logout") }}</span>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </header>
    </div>
</template>
<script>

/* eslint-disable */
import { i18n } from "@/plugins/i18n";
export default {
    name: 'AppHeader',
    data() {
        return {
            activities: [],
            languages: [],
            world: '',
            subWorld: '',
            world_id: 0,
            activity_number: 0,
            iRol: 0,
            rol: '',
            sName: '',
            useTokenEtcSmartC3: false,
        }
    },
    props: {
    visible: {
      type: Number,
    },
  },
    async mounted(){
        i18n.locale = localStorage.language;
        if (this.$route.path !='/worlds' && this.$route.path !='/cities') {
            this.getActivities()
        }

        this.getLanguages()

        this.iRol = localStorage.iRol
        this.sName = localStorage.sName
        localStorage.tokenETCSmartC3 ? this.useTokenEtcSmartC3 = true : this.useTokenEtcSmartC3 = false

        if(this.iRol == 3)
            this.rol = 'Docente'
        if(this.iRol == 6)
            this.rol = 'Asesor'
        if(this.iRol == 2)
            this.rol = 'Alumno'

        this.$store.commit('setLanguage', localStorage.language)

        Fire.$on('setBreadcrumbs', (data) => {
            console.log(data)
            this.world = data.world
            this.subWorld = data.subWorld
            this.world_id = data.world_id
            this.activity_number = data.activity_number
        })
        
    },
    methods: {
        checkRoute() {
            if (this.$route.path =='/worlds' || this.$route.path =='/cities')
                return 0
            else 
                return 1
        },
        logout() {
            localStorage.removeItem("idPerson")
            localStorage.removeItem("idActivation")
            localStorage.removeItem("idGrade")
            localStorage.removeItem("iRol")
            localStorage.removeItem("sName")
            localStorage.removeItem("tokenETCSmartC3")
            router.push('/')
        },
        async getActivities() {
            this.activities = []
            await axios.get(`${apiETC}api/smartc3/get-activities?grade_id=${localStorage.idGrade}&subworld_id=${this.$route.query.subworld_id}&role_id=${localStorage.iRol}&person_id=${localStorage.idPerson}&school_id=${localStorage.school_id}&activityType=${this.$route.query.activityType}&activation_id=${localStorage.idActivation}`)
            .then( (response) => {
                this.activities = response.data
            })
        },
        changeLanguage(locale) {
            localStorage.language = locale;
            i18n.locale = locale;
            location.reload()
        },
    }
}
</script>
    <style>
        /*
            The below code is for DEMO purpose --- Use it if you are using this demo otherwise, Remove it
        */
        .navbar .navbar-item.navbar-dropdown {
            margin-left: auto;
        }
        .layout-px-spacing {
            min-height: calc(100vh - 125px)!important;
        }

    </style>