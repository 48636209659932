<template>
    <div>
        <app-header></app-header>
        <div class="main-container" id="container">
            <div class="overlay"></div>
            <div class="search-overlay"></div>
            <app-sidebar></app-sidebar>
            <div id="content" class="main-content">
                <div class="layout-px-spacing">
                    <div>
                        <ul class="nav justify-content-center">
                            <li class="nav-item">
                                <router-link class="nav-link active" to="/progress-report"><i class="fa fa-home fa-4x text-primary" title="Regresar a Mis notas"></i></router-link>
                            </li>
                            <li class="nav-item" v-for="subWorld in subWorlds" :key="subWorld.id">
                                <a class="nav-link" href="#" @click.prevent="changeSubworld(subWorld)"><img :src="subWorld.alternate_image" class="rounded-circle img-fluid" :title="subWorld.name" style="height:70px"></a>
                            </li>
                        </ul>
                    </div>
                    <div class="row" v-if="iRol == 2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-10 mx-auto layout-top-spacing">
                            <div class="widget-content-area shadow br-4">
                                <h5>{{ reportDetail.name }}</h5>
                                <div class="widget-one">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-sm-3 card widget box box-shadow" style="margin:8px" v-for="activity in reportDetail.activities" :key="activity.id">
                                                    <div class="card-body">
                                                        <p style="font-size:15px" class="text-center">{{ activity.name }} <br> {{ activity.activity_type }}</p>
                                                            <!--estrellas-->   
                                                            <div class="text-center" v-if="activity.score == 0">
                                                                <i style="font-size:27px" class="fa fa-star-o"></i>
                                                                <i style="font-size:27px" class="fa fa-star-o"> </i>
                                                                <i style="font-size:27px" class="fa fa-star-o"> </i>
                                                            </div>
                                                            <div class="text-center" v-if="activity.score > 0">
                                                                <i style="font-size:27px" :class="activity.score >= 60 ? 'fa fa-star text-warning' : 'fa fa-star' "></i>
                                                                <i style="font-size:27px" :class="activity.score >= 80 ? 'fa fa-star text-warning' : 'fa fa-star' "> </i>
                                                                <i style="font-size:27px" :class="activity.score == 100 ? 'fa fa-star text-warning' : 'fa fa-star' "> </i>
                                                            </div>
                                                            <!--//termina estrellas--> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4" style="background-image:url(assets/img/confetti-sticker.gif); margin-top:-40px;" v-if="avatar == 1">
                                            <img :src="avatarGif" class="img-fluid mt-4">
                                        </div>
                                        <div class="col-lg-4" style="background-image:url(assets/img/fondo_animado2.gif); margin-top:-40px;" v-if="avatar == 0">
                                            <img src="assets/img/tu_puedes.png" class="img-fluid mt-4">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2';
import AppHeader from "../components/AppHeader";
import AppSidebar from "../components/AppSidebar.vue";
export default {
    name: 'ProgressReportDetail',
    data() {
        return {
            reportDetail: {},
            subWorlds: [],
            groups: [],
            model: {
                level_id: '0',
                group_id: '0',
                grade_id: '0',
            },
            students: [],
            projects: [],
            activities: false,
            iRol: '',
            avatar: 1,
            avatarGif: '',
            generalScore: 0,
            totalActivities: 0,
        }
    },
    components: {
        AppHeader,
        AppSidebar
    },
    methods: {
        changeSubworld(subWorld) {
            this.reportDetail = subWorld
            this.avatar = 1
            this.generalScore = 0
            this.avatarGif = ''
            this.totalActivities = 0
            this.reportDetail.activities.forEach(element => {
                this.generalScore += element.score
                this.totalActivities += 1
                if(element.score == 0)
                    this.avatar = 0
            });

            if((this.generalScore / this.totalActivities) >= 60)
                this.avatarGif = 'assets/img/robot-bronze.png'
            if((this.generalScore / this.totalActivities) >= 80 && (this.generalScore / this.totalActivities) < 100)
                this.avatarGif = 'assets/img/robot-silver.png'
            if((this.generalScore / this.totalActivities) == 100)
                this.avatarGif = 'assets/img/robot-gold.png'
        }
    },
    async mounted() {
        $(document).ready(function () {
            App.init();
        });

        this.iRol = localStorage.iRol

        this.reportDetail = this.$store.getters.reportDetail
        this.subWorlds = this.$store.getters.subWorlds

        this.reportDetail.activities.forEach(element => {
            this.generalScore += element.score
            this.totalActivities += 1
            if(element.score == 0)
                this.avatar = 0
        });

        if((this.generalScore / this.totalActivities) >= 60)
            this.avatarGif = 'assets/img/robot-bronze.png'
        if((this.generalScore / this.totalActivities) >= 80 && (this.generalScore / this.totalActivities) < 100)
            this.avatarGif = 'assets/img/robot-silver.png'
        if((this.generalScore / this.totalActivities) == 100)
            this.avatarGif = 'assets/img/robot-gold.png'
    },
}
</script>