<template>
<!--    <div class="login">
    <div class="container">
        <div class="row padhome loginscreen">
            <div class="col-md-1"></div>
            <div class="col-md-9">
                <div class="container">
                    <form  class="form-signin panel panel-default" action="login.php" method="POST">
                        <h2 class="form-signin-heading">Iniciar sesión</h2>
                        <label for="inputEmail" class="sr-only">Usuario</label>
                        <input  type="name" id="inputEmail"   class="form-control"  placeholder="Usuario" required autofocus name="j_username"/>
                        <label for="inputPassword" class="sr-only">Contraseña</label>
                        <input type="password" id="inputPassword"  class="form-control" placeholder="Contraseña" required name="j_password"/>
                        <div class="checkbox">
                        <p><a href="#">¿Olvidaste tu contraseña?</a></p>
                        </div>
                        <button class="btn btn-lg btn-primary btn-block" type="submit">
                        Entrar
                        </button>
               
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>-->
    <body class="form" style="background-image:url(assets/img/smartc3_bg.jpg); background-size:cover">
    

    <div class="form-container outer">
        <div class="form-form">
            <div class="form-form-wrap">
                <div class="form-container">
                    <div class="form-content">
                         <img src="assets/img/SmartC3_logo.png">
                         <br><br>
                        <h1 class="">{{ $t('text.login') }}</h1>
                        <p class="">{{ $t('text.loginText') }}</p>
                        
                        <form  class="form-signin panel panel-default" action="login.php" method="POST">
                            <div class="form">
                                <div id="school-field" class="field-wrapper input mb-2">
                                    <div class="d-flex justify-content-between">
                                        <label for="school_id">{{ $t('text.school') }}</label>
                                    </div>
                                    <select name="school_id" id="school_id" class="form-control" v-model="model.school_id">
                                        <option value="">Selecciona tu colegio</option>
                                        <option :value="school.idPartner" v-for="school in schools" :key="school.idPartner">{{ school.sPartner }}</option>
                                    </select>
                                </div>
                                <div id="school-field" class="field-wrapper input mb-2">
                                    <div class="d-flex justify-content-between">
                                        <label for="school_id">{{ $t('text.userType') }}</label>
                                    </div>
                                    <select name="school_id" id="school_id" class="form-control" v-model="model.userRole">
                                        <option value="2">Alumno</option>
                                        <option value="3">Docente</option>
                                        <option value="6">Asesor</option>
                                    </select>
                                </div>
                                <div id="username-field" class="field-wrapper input">
                                    <label for="username">{{ $t('text.user') }}</label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                    <input id="email" name="email" type="name" class="form-control" :placeholder="$t('text.placeholderUser')" v-model="model.email" required autofocus>
                                </div>

                                <div id="password-field" class="field-wrapper input mb-2">
                                    <div class="d-flex justify-content-between">
                                        <label for="password">{{ $t('text.password') }}</label>
                                        <a href="#" class="forgot-pass-link">{{ $t('text.forgot') }}</a>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                    <input id="password" name="password" type="password" class="form-control" :placeholder="$t('text.placeholderPassword')" v-model="model.password" required>
                                  
                                </div>
                                <div class="d-sm-flex justify-content-between">
                                    <div class="field-wrapper">
                                        <button type="button" @click="login" class="btn btn-primary" value="">{{ $t('text.buttonLogin') }}</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <app-modal-activation></app-modal-activation>
                    </div>                    
                </div>
            </div>
        </div>
    </div>



</body>
</template>

<script>
import Swal from 'sweetalert2';
/* eslint-disable */
import AppHeader from "../components/AppHeader";
import { i18n } from "@/plugins/i18n";
import AppModalActivation from "../components/AppModalActivation.vue";
export default {
    name: "login",
    components: { AppHeader, AppModalActivation },
    data() {
        return {
            model: {
                email: '',
                password: '',
                school_id: '',
                userRole: '2',
                hostname: hostname,
            },
            schools: [],
            school_id_persistent: 0,
        }
    },
    methods: {
        async login() {
            if(this.model.school_id == '' && this.model.userRole == '2') {
                this.showMessage('Error', 'Debes seleccionar un colegio', 'error', '', 1)
                return
            }
            this.openLoading()
            await axios.post(apiETC + 'api/smartc3/login', this.model)
            .then( response => {
                Swal.close()
                if(response.data.status == true) {
                    if(response.data.bIsStudent == true) {
                        if(response.data.bHasAccessCode == false) {
                            $("#AppModalActivation").modal("show")
                            localStorage.idGroupActivation = response.data.idActivation
                            return;
                        }
                    }
                    localStorage.idPerson = response.data.idPerson
                    localStorage.idActivation = response.data.idActivation
                    localStorage.idGrade = response.data.iGrade
                    localStorage.iRol = response.data.iRol
                    localStorage.school_id = this.model.school_id
                    localStorage.sName = response.data.sName
                    router.push('/worlds')
                }
                if(response.data.status == false) {
                    this.showMessage('Error', response.data.message, 'error', '', 1)
                }
            }).catch( error => {
                this.catchError(error)
            })
        }
    },
    async mounted() {
        i18n.locale = localStorage.language;
        this.getSchools()
        if(localStorage.school_id){
            if(localStorage.school_id > 0 && this.model.userRole == 2){
                this.school_id_persistent = localStorage.school_id
                this.model.school_id = localStorage.school_id
            }
        }
        if(localStorage.idPerson && localStorage.idActivation && localStorage.idGrade) {
            router.push('/worlds')
        }
    },
};
</script>
