<template>
    <div class="modal fade" id="AppModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Resultado</h5>
                    <button   type="button" class="close" @click="closeModal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img src="../assets/mensajes/3estrellas.png" style="width: 100%;" v-if="$parent.help == 0 && $parent.activityDone == 1">
                    <img src="../assets/mensajes/2estrellas_.png" style="width: 100%;" v-if="$parent.help == 1 && $parent.activityDone == 1">
                    <img src="../assets/mensajes/1estrella.png" style="width: 100%;" v-if="$parent.help == 2 && $parent.activityDone == 1">
                    <img src="../assets/mensajes/intentalo.png" style="width: 100%;" v-if="$parent.activityDone == 0">
                    <a href="#" @click.prevent="nextActivity"><img src="../assets/mensajes/siguiente.png" style="width: 9%;margin-top: -77px;margin-left: 128px;"></a>
                    <a href="#" @click.prevent="clear"><img src="../assets/mensajes/reiniciar.png" style="width: 9%;margin-top: -77px;margin-left: 21px;"></a>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'AppModal',
    props: {
      idQuestion: {
        type: String,
      },
      subWorldId: {
        type: String,
      },
      multiple: {
        type: Number
      },
      nextQuestionSubworldId: {
        type: String
      },
      activityType: {
        type: Number
      },
    },
    methods: {
      clear() {
        location.reload();
      },
      nextActivity() {
        $("#AppModal").modal('hide')
        if(this.$parent.activityDone == 1 && this.$route.query.single_activity != 1 && this.idQuestion > 0) {
          if(this.nextQuestionSubworldId == 0)
            location.href = `${window.nextQuestionRoute}?idQuestion=${this.idQuestion}&subworld_id=${this.subWorldId}&multiple=${this.multiple}&activityType=${this.activityType}`
          if(this.nextQuestionSubworldId > 0)
            location.href = `${window.nextQuestionRoute}?idQuestion=${this.idQuestion}&subworld_id=${this.nextQuestionSubworldId}&multiple=${this.multiple}&activityType=${this.activityType}` 
          //router.push('/activity-avatar?idQuestion=' + this.idQuestion + '&subworld_id=' + this.subWorldId)
          //Fire.$emit('clearWorkspace')
        }
      },
      closeModal() {
        this.nextActivity()
      }
    },
}
</script>