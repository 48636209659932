<template>
  <body class="sidebar-noneoverflow starterkit">
    <!--  BEGIN NAVBAR  -->
    <div class="header-container fixed-top">
      <app-header></app-header>
    </div>
    <!--  END NAVBAR  -->
    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <!-- CONTENT AREA -->
          <h2 class="display-3 text-center">{{ $t('text.worldHeader') }}</h2>
          <div class="row layout-top-spacing">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-1 col-12 layout-spacing" v-for="world in worlds" :key="world.id">
              <div class="card">
                <router-link :to="'/cities?world_id=' + world.id + '&world_name=' + world.name" class="btn btn-primary" v-if="$store.getters.language == 'es'">
                  <input type="image" name="botondeenvio" :src="world.image" class="card-img-top" alt="widget-card-2">
                </router-link>
                <template v-if="$store.getters.language != 'es'">
                  <router-link :to="`/cities?world_id=${world.id}&world_name=${world.translations.find(element => element.language_code == $store.getters.language).translation}`" class="btn btn-primary" v-if="world.translations.length > 0">
                    <input type="image" name="botondeenvio" :src="world.image" class="card-img-top" alt="widget-card-2">
                  </router-link>
                </template>
                <div class="card-body">
                  <template v-if="$store.getters.language == 'es'">
                    <h5 class="card-title" v-if="world.translations.length == 0 || $store.getters.language == 'es'">{{ world.name }}</h5>
                    <router-link :to="`/cities?world_id=${world.id}&world_name=${world.name}`" class="btn btn-primary"><i class="fa fa-sign-in"></i> {{ $t('text.buttonEnter') }}</router-link>
                  </template>
                  <template v-if="$store.getters.language != 'es'">
                    <h5 class="card-title" v-if="world.translations.length > 0 || $store.getters.language != 'es'">{{ world.translations.find(element => element.language_code == $store.getters.language).translation }}</h5>
                    <router-link :to="`/cities?world_id=${world.id}&world_name=${world.translations.find(element => element.language_code == $store.getters.language).translation}`" class="btn btn-primary"><i class="fa fa-sign-in"></i> {{ $t('text.buttonEnter') }}</router-link>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!--//row-->
        </div>
      </div>
    </div>
  </body>
</template>

<script>
/* eslint-disable */
import AppHeader from "../components/AppHeader.vue";

export default {
  name: "mundos",
  components: { AppHeader },
  data() {
    return {
      initialQuestionId: '',
      worlds: [],
    }
  },
  methods: {
    
  },
  async mounted() {
    const script = document.createElement("script");
    script.src = "js/jquery.min.js";
    document.body.appendChild(script);
    
    this.initialQuestionId = localStorage.initialQuestionId

    this.openLoading()
    await this.getWorlds()
    Swal.close()

  },
};
</script>
