<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <app-header></app-header>
        <!--  END NAVBAR  -->
        <!--  BEGIN MAIN CONTAINER  -->
        <div class="main-container" id="container">
            <div class="overlay"></div>
            <div class="search-overlay"></div>
            <!--  BEGIN SIDEBAR  -->
            <app-sidebar></app-sidebar>
            <!--  END SIDEBAR  -->
            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="main-content">
                <div class="layout-px-spacing">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                            <div class="widget-content-area">
                                <div class="widget-content-area">
                                    <div class="widget-one">
                                        <div class="row">
                                            <div class="col-md-1 col-sm-1">
                                                <img src="assets/img/icono_avatar.png">
                                            </div>
                                            <div class="col-md-10 col-sm-8">
                                                <!--<p>Lleva y une los bloques que necesitas para formar la carpa.</p>-->
                                                <div id="texto" :key="instructions">{{ instructions }}</div>
                                            </div>
                                            <!--<button class="btn btn-info rounded-circle" id="btn_1" @click="trackInstructions()"><i class="fa fa-volume-up"></i></button>-->
                                             <button id="runcodeDos" class="btn btn-info rounded-circle" @click.prevent="playSound(instructions_audio)"><i class="fa fa-volume-up"></i></button>
                                        </div>
                                        <button class="btn btn-success btn-rounded mt-n3 float-right" @click="evaluate">{{btnsig}} <i class="fa fa-arrow-right"></i> </button>
                                    </div>
                                </div>
                                <div class="widget-one">
                                    <div class="row mt-3">
                                        <div class="col-md-7">
                                            <h5><pre>{{ espacioTrabajo }} </pre></h5>
                                            <BlocklyComponent id="blockly1" ref="blockly1" :options="options">
                                                <block type="puzzleCarpaB"  class="PelotaB"></block>
                                                <block type="puzzleCarpaA"  class="PelotaA"></block>
                                             </BlocklyComponent>
                                            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                                            <!--<img src="assets/escenarios/puzzles/puzzle-pelota.png" class="d-block mx-auto">-->
                                        </div>
                                        <div class="col-md-5">
                                            <h5 class="text-center"><pre>{{ miResultado }}</pre></h5>
                                            <div class="widget-one scenario">
                                                <div id="puzzleCarpaA" v-show="false"></div>
                                                <div id="puzzleCarpaB" v-show="false"></div>
                                                <!-- <div id="ImgC"></div>-->
                                                <div id="resultadoTent" v-show="false"></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <audio id="audioInst" controls style="display: none">
                <source type="audio/mp3" src="../../../assets/instrucciones/correcto_efecto_de_sonido.mp3"/>
            </audio>
            <app-modal :idQuestion="nextQuestion" :subWorldId="subWorldId" :multiple="multipleNextQuestion" :nextQuestionSubworldId="nextQuestionSubworldId" :activityType="activityType"></app-modal>
          </div>
            <!--  END CONTENT AREA  -->
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Blockly from 'blockly'
import AppHeader from '../../../components/AppHeader.vue'
import AppSidebar from '../../../components/AppSidebar.vue'
import BlocklyComponent from "../../../components/BlocklyComponent.vue";
import "../../../blocks/stocks";
import BlocklyJS from "blockly/javascript";
import AppModal from "../../../components/AppModal.vue";
export default {
    components: { AppHeader, AppSidebar, BlocklyComponent, AppModal },
    name: 'Activity',
    data() {
        return {
            options: {
                scrollbars: true,
            },
            code: '',
            activityDone: 0,
            primIMG: '',
            // instructions: "Lleva y une los bloques que necesitas para formar la carpa.",
            // instructions_audio: "../../../assets/instrucciones/20210111-054722.mp3",
            nextQuestion: "",
            nextQuestionSubworldId: "",
            subWorldId: "",
            multipleNextQuestion: 0,
            help: 0,
            activityType: 0,
            espacioTrabajo: "",
            miResultado: "",
            btnsig: "",
            instructions:"",
            instructions_audio:""
        }
    },
    methods: {
        playSound(sound) {
            if (sound) {
                var audio = new Audio(sound);
                audio.play();
                runcodeDos.disabled = true;
            }

            jQuery.fn.extend({
                resaltar: function (busqueda, claseCSSbusqueda) {
                var regex = new RegExp("(<[^>]*>)|(" + busqueda.replace(/([-.*+?^${}()|[\]\/\\])/g, "\\$1") +")", "ig");
                var nuevoHtml = this.html(
                    this.html().replace(regex, function (a, b, c) {
                    return a.charAt(0) == "<" ? a : '<span class="' + claseCSSbusqueda + '">' + c + "</span>";
                    })
                );
                return nuevoHtml;
                },
            });

            var instrucciones = this.instructions;
            var longitud = this.instructions.length;
            var subvalor = Math.ceil(longitud / 5);
            var cadena1 = instrucciones.substr(0, subvalor);
            var cadena2 = instrucciones.substr(subvalor, subvalor);
            var cadena3 = instrucciones.substr(subvalor * 2, subvalor);
            var cadena4 = instrucciones.substr(subvalor * 3, subvalor);
            var cadena5 = instrucciones.substr(subvalor * 4, subvalor);
            var sound_delay ='300|900|1600|2500|3000|3200'
            var arreglo = sound_delay.split("|");
            let t1 = arreglo[0]
            let t2 = arreglo[1]
            let t3 = arreglo[2]
            let t4 = arreglo[3]
            let t5 = arreglo[4]
            let t6 = arreglo[5]
        
        
            setTimeout(function () {$("#texto").resaltar(cadena1, "resaltarTexto"); }, t1);
            setTimeout(function () {$("#texto").resaltar(cadena2, "resaltarTexto"); }, t2);
            setTimeout(function () {$("#texto").resaltar(cadena3, "resaltarTexto"); }, t3);
            setTimeout(function () {$("#texto").resaltar(cadena4, "resaltarTexto"); }, t4);
            setTimeout(function () {$("#texto").resaltar(cadena5, "resaltarTexto"); }, t5);
            setTimeout(function () {$("#texto").resaltar(cadena1, "quitarresaltarTexto"); }, t6);
            setTimeout(function () {$("#texto").resaltar(cadena2, "quitarresaltarTexto"); }, t6);
            setTimeout(function () {$("#texto").resaltar(cadena3, "quitarresaltarTexto"); }, t6);
            setTimeout(function () {$("#texto").resaltar(cadena4, "quitarresaltarTexto"); }, t6);
            setTimeout(function () {$("#texto").resaltar(cadena5, "quitarresaltarTexto"); }, t6);
            setTimeout(() => {runcodeDos.disabled = false;}, t6)
        },
        runCode(event) {
            const primaryEvent = event.toJson()
            let blocks = this.$refs["blockly1"].workspace.getAllBlocks()
            //console.log(primaryEvent)
            if(blocks.length > 0){
                $("#puzzleCarpaA").hide()
                $("#puzzleCarpaB").hide()
                $("#resultadoTent").hide()
                this.activityDone = 0
                if(blocks.length == 1){
                    this.primIMG = blocks[0].type;
                    $("#" + blocks[0].type).show();
                    this.activityDone = 0
                }else if(blocks.length == 2 && blocks[0].childBlocks_.length > 0){
                $("#puzzleCarpaA").hide()
                $("#puzzleCarpaB").hide()
                $("#resultadoTent").show()
                   if (primaryEvent.element == 'dragStop'){
                this.activityDone = 1
                audioInst.play()
                }
                }else{
                    $("#" + this.primIMG).show();
                    $("#resultadoTent").hide()
                    this.activityDone = 0
                }
            }else{
                $("#puzzleCarpaA").hide()
                $("#puzzleCarpaB").hide()
                $("#resultadoTent").hide()
                this.activityDone = 0
            }
        },

        nextActivity() {
            $("#AppModal").modal('hide')
            location.href = '/activity-balloon?idQuestion=25824&subworld_id=' + this.$route.query.subworld_id + '&multiple=-1';
        },
        closeModal() {
            this.nextActivity()
        },
        clear() {
            location.reload();
        },
        async evaluate() {
            let model = {}
            if(this.$route.query.single_activity != 1) {
                model = {
                    activity_id: 2,
                    person_id: localStorage.idPerson,
                    activation_id: localStorage.idActivation,
                    opportunity_number: 1,
                    score: 100
                }
            }
            if(this.$route.query.single_activity == 1) {
                model = {
                    activity_id: 2,
                    person_id: this.$route.query.id_person,
                    activation_id: this.$route.query.id_activation,
                    opportunity_number: 1,
                    score: 100,
                };
            }

            if($("#resultadoTent").is(":visible")) {
                this.openLoading()
                await axios.post(apiETC + 'api/smartc3/evaluate-activity', model)
                .then( (response) => {
                    if(response.data.status == true) {
                        Swal.close()
                        if(this.$route.query.single_activity != 1) {
                            //this.showMessage("Exito", response.data.message, "success", '/activity-balloon?idQuestion=25824&subworld_id=' + this.$route.query.subworld_id + '&multiple=-1', 1)
                            this.activityDone = 1
                            $("#AppModal").modal("show");
                        }
                        if(this.$route.query.single_activity == 1)
                           // this.showMessage("Exito", response.data.message, "success", '', 1)
                           $("#AppModal").modal("show");
                    }
                })
            } else {
                this.showMessage("Aviso", 'Debes de resolver la actividad antes de enviar tu resultado', "warning", '', 1)
            }

        },
          trackInstructions() {
            audio.play();
        },
        async configActivity(idQuestion) {
            await axios.get(apiETC + "api/smartc3/get-activity?question_id=" + idQuestion)
                .then((response) => {
                    this.multipleNextQuestion = response.data.next_question_multiple
                    this.nextQuestion = response.data.next_question.toString();
                    this.subWorldId = response.data.subworld_id.toString();
                    this.nextQuestionSubworldId = response.data.next_question_subworld_id.toString()
                    this.activityType = response.data.next_question_activity_type
                    window.nextQuestionRoute = response.data.next_question_route
                
                
                    if(this.$store.getters.language == 'es') {
                        this.instructions = response.data.instructions;
                        this.instructions_audio = response.data.instructions_audio;
                        this.sound_delay = response.data.sound_delay;
                        this.espacioTrabajo = 'Mis bloques y  espacio de trabajo';
                        this.miResultado = 'Mi resultado';
                        this.btnsig="Siguiente";
                    }
                    if(this.$store.getters.language != 'es') {
                        const translation = response.data.translations.find(element => element.language_code == this.$store.getters.language)
                        if(translation != undefined) {
                        this.instructions = translation.translation;
                        this.instructions_audio = translation.instructions_audio;
                        this.sound_delay = translation.sound_delay;
                        this.espacioTrabajo = 'My blocks and workspace';
                        this.miResultado = 'My result';
                        this.btnsig='Next';
                        } else {
                        this.instructions = response.data.instructions;
                        this.instructions_audio = response.data.instructions_audio;
                        this.sound_delay = response.data.sound_delay;
                        this.espacioTrabajo = 'My blocks and workspace';
                        this.miResultado = 'My result';
                        this.btnsig='Next';
                        }
                    }
            })
        },
    },
    async mounted() {
        $(document).ready(function() {
            App.init();
        });

        Fire.$emit('setBreadcrumbs', { world: 'La Ciudad', subWorld: 'El circo', world_id: 1 , activity_number: '2'})

        this.$refs["blockly1"].workspace.addChangeListener(this.runCode)

        await this.configActivity(this.$route.query.idQuestion);
    },
}
</script>
<style>
    #blockly1 {
        position: absolute;
        left: 120;
        width: 100%;
        height: 100%;
    }

    #puzzleCarpaA {
    background-image: url("../../../assets/img/circo/puzzle-carpa-a.png");
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    height: 130px;
    width: 220px;
    top: 10px;
    left: 130px;
    }
    #puzzleCarpaB {
    background-image: url("../../../assets/img/circo/puzzle-carpa-b.png");
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    height: 130px;
    width: 220px;
    top: 10px;
    left: 130px;
    }
/*
    #ImgC {
    background-image: url("../../../../assets/personaje/frenteparado.gif");
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    height: 130px;
    width: 220px;
    top: 0px;
    left: 130px;
    }*/

    #resultadoTent {
    background-image: url("../../../assets/gifs/circo/carpa_animada.gif");
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    height: 260px;
    width: 220px;
    top: 10px;
    left: 130px;
    }
    #mark {
  background-color: blue;
}

.quitarresaltarTexto {
 color: #070707;
 background-color: #fffffd;
}

.resaltarTexto{
    color: #F3AA04;
    background-color: #FFFF96;
}
</style>